<template>
    <div class="revenue__stats__list__item">
        <BaseCard>
            <template #card-body>
                <div class="skeleton_bar" />
                <div class="mt--10 mb--25 skeleton_bar" />
                
                <div class="mt--10 mb--25">
                    <div class="mb--10 skeleton_bar" />
                    <div class="skeleton_bar" />
                </div>
            </template>
        </BaseCard>
    </div>
</template>

<script>
import { BaseCard } from "@/components/ui/index";

export default {
    name: "StatsSkeleton",
    components: { BaseCard },
};
</script>

<style lang="scss" scoped>
.skeleton_bar {
    width: 100%;
    background-color: #E6E6E6;
    height: 5px;
}
</style>