export const authFormFields = {
    "login": {
        "email": true,
        "password": true,
        "dontHaveAnAccount": true,
        "forgotPassword": true,
        "googleAuth": true,
    },
    "signup": {
        "email": true,
        "password": true,
        "fullName": true,
        "haveAnAccount": true,
        "termsAndService": true,
        "googleAuth": true,
    },
    initiateReset: {
        "email": true,
        "haveAnAccount": true,
    },
    "resetPassword": {
        "password": true,
        "confirmPassword": true,
        "haveAnAccount": true,
    },
};

export const updateProfileFields = {
    fullName: {
        title: "Update Full name",
        fields: {
            fullName: true,
        },
    },
    contactDetails: {
        title: "Update contact details",
        fields: {
            phoneNumber: true,
            city: true,
            country: true,
            zipCode: true,  
        },
    },
    emailSettings: {
        title: "Update email address",
        fields: {
            email: true,
        },
    },
};

export const generalModalFields = {
    appearance: {
        title: "Update Apperance & theme",
        fields: {
            theme: true,
        },
    },
    timeSettings: {
        title: "Update time settings",
        fields: {
            timezone: true,
            timeFormat: true,
            dateFormat: true,
            dayOfWeek: true,  
        },
    },
    currency: {
        title: "Update language & currency",
        fields: {
            language: true,
            currency: true,
        },
    },
};

export const authCopy = {
    "login": {
        title: "Sign into your account",
        subtitle: "Don't have an account?",
        "linkText": "Sign up",
        btnLabel: "Login",
    },
    "signup": {
        title: "Create your account",
        subtitle: "Have an account already?",
        "linkText": "Login",
        btnLabel: "Sign up",
    },
    initiateReset: {
        title: "Initiate Password Reset",
        subtitle: "Enter an email address to get a password reset link",
        btnLabel: "Initiate Reset",
        "linkText": "",
    },
    "resetPassword": {
        title: "Reset your password",
        subtitle: "",
        btnLabel: "Reset",
        "linkText": "",
    },
};

export const authFieldsToValidate = {
    "login": {
        "email": true,
        "password": true,
    },
    "signup": {
        "email": true,
        "password": true,
        "fullName": true,
        "termsAndService": true,
    },
    initiateReset: {
        "email": true,
    },
    "resetPassword": {
        "newPassword": true,
        "confirmPassword": true,
    },
};