<template>
    <div class="multiple__item email" :class="[classes]">
        <div v-if="items.length" class="email--wrap">
            <div class="multiple__emails" :class="{ 'mt--10': !isFlex, 'flex align-items-center flex-wrap': isFlex }">
                <div v-for="item in items" :key="item" class="email--item">
                    <span class="inlineBlock">{{ item[itemIndex] || item }}</span>
                    <span class="pointer inline-block" :class="{ 'disabled': disabled }" type="button" @click="remove(item)">
                        <svg
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.25837 4.02991L1.08967 0.861205C0.892799 0.664505 0.892799 0.345351 1.08967 0.14865C1.28654 -0.0482175 1.60535 -0.0482175 1.80222 0.14865L4.97092 3.31735L8.13979 0.14865C8.33666 -0.0482175 8.65548 -0.0482175 8.85235 0.14865C9.04922 0.345351 9.04922 0.664505 8.85235 0.861205L5.68348 4.02991L8.85235 7.19861C9.04922 7.39531 9.04922 7.71446 8.85235 7.91116C8.75391 8.00943 8.62491 8.05865 8.49607 8.05865C8.36723 8.05865 8.23823 8.00943 8.13979 7.911L4.97092 4.74229L1.80222 7.911C1.70379 8.00943 1.57478 8.05865 1.44594 8.05865C1.31711 8.05865 1.1881 8.00943 1.08967 7.911C0.892799 7.7143 0.892799 7.39514 1.08967 7.19844L4.25837 4.02991Z"
                                fill="#696969"
                            />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="text--sm">
            No {{ itemsType }} present.
        </div>
    </div>
</template>

<script>
export default {
    name: "Multiple",
    
    props: {
        classes: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isFlex: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
        itemIndex: {
            type: String,
            default: "",
        },
        itemsType: {
            type: String,
            default: "",
        },
    },
    
    methods: {
        remove(val) {
            this.$emit("delete", val);
        },
    },
};
</script>

<style lang="scss" scoped>
    .multiple__item {
        width: 100%;
        margin-bottom: 1rem;

        &.email {
            align-items: flex-end !important;
        }
    }
    .email {
        &--wrap {
            max-width: 100%;
            border-radius: 4px;
            border: 1px solid var(--gray-300, #E0E0E0);
            background-color: var(--white, #FFF);
            padding: 8px 10px;
        }

        &--item {
            display: flex;
            align-items: center;
            background: #c7d2f5;
            border-radius: 50px;
            padding: 3px 8px;
            justify-content: center;
            font-size: 12px;
            margin-right: 5px;
            margin-bottom: 10px;
            width: fit-content;

            span {
                &:first-of-type {
                    margin-right: 5px;
                }
            }
        }
    }

    .multiple__emails {
        margin-left: 0rem;
        width: 100%;
    }
</style>