<template>
    <div style="height: 100%;">
        <div class="bg--white h-100">
            <div class="w--100">
                <div>
                    <div class="cs__wrap">
                        <h1 class="cs__heading">
                            Coming soon
                        </h1>
                        <p class="cs__text">
                            This feature isn't available yet.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>  
export default {
    name: "ComingSoonPage",
};
</script>
  
  <style lang="scss" scoped>
    .home {
      height: 100%;
    }
  </style>
  