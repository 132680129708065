<template>
    <div>
        <div>
            <template v-if="pageLoading">
                <page-spinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error" type="task">
                    <template #button>
                        <base-button 
                            label="Reload" icon-name="reload" icon-width="16px" 
                            size="sm" is-prefix-icon
                            @submit="handleFetchTasks"
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="w-100 pt--25">
                    <template v-if="!tasks.length && !showNewTask">
                        <EmptyPage v-if="isSearched" state="no_result" />
                        <EmptyPage
                            v-else state="empty" type="task" icon-name="tasks"
                            width="60px" height="60px" class="empty-page"
                        >
                            <template #button>
                                <base-button 
                                    label="+ New task" size="sm"
                                    class-names="flex align-items-center" @submit="showNewTask = true"
                                />
                            </template>
                        </EmptyPage>
                    </template>

                    <template v-else>
                        <div class="flex align-items-center w-100 mb--15 justify-content-between">
                            <div v-if="showActionBtns" class="list--count">
                                <p class="text--sm text--semi--bold text--color-gray-700">
                                    {{ tasks.length }} {{ tasks.length === 1 ? 'Task' : 'Tasks' }}
                                </p>
                            </div>
        
                            <div v-if="showActionBtns" class="filter__actions--list">
                                <base-button 
                                    label="+ New task" btn-style="outline" inner-style="primary"
                                    size="sm" class="mr--5" @submit="showNewTask = true"
                                />
                                <main-filter page-type="tasks" />
                            </div>
                        </div>
    
                        <div class="w--100 justify-content-center">
                            <div class="mt--40 mb--15">
                                <div v-for="item in tasks" :key="item._id" class="client__notes__item task__item">
                                    <div class="w--100 flex align-items-center justify-content-between" :class="{ 'disabled': taskId === item._id }">
                                        <div class="pointer flex-1">
                                            <div class="form-check mb--0">
                                                <input id="flexCheckDefault" class="form-check-input" type="checkbox" @change.stop="e => markTaskAsCompleted(e, item)">
                                                <label class="form-check-label task__item__label text--sm pointer" for="flexCheckDefault" @click.prevent="startTaskEdit(item)">
                                                    {{ item.name }}
                                                    <div class="flex align-items-center mt--5">
                                                        <div v-if="item.project" class="text--color-gray-200 text--xs task__project__title">
                                                            # {{ item.project.title }}
                                                        </div>
                                                        <span v-if="item.deadline" class="ml--5 mr--5 text--color-gray-200">|</span>
                                                        <div v-if="item.deadline" class="flex align-items-center">
                                                            <span class="mr--5">
                                                                <icon-svg name="calendar" fill="#BF2C6F" width="12px" height="12px" />
                                                            </span>
                                                            <span class="task__item__deadline">{{ $format_date(item.deadline) }}</span>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="dropdown">
                                            <button 
                                                id="noteDropdown" class="btn--ghost btn text--xs btn-sm text--bold" 
                                                type="button" data-bs-auto-close="true" 
                                                aria-expanded="false" data-bs-toggle="dropdown" 
                                            >
                                                <icon-svg name="dots-horizontal-rounded" fill="#637579" width="12px" height="12px" />
                                            </button>
                                            <ul id="noteDropdownList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="noteDropdown">
                                                <li class="dropdown-item pointer text--xs" @click="duplicateTask(item)">
                                                    Duplicate task
                                                </li>
                                                <li class="dropdown-item pointer text--xs" @click="handleDeleteTask(item)">
                                                    Delete task
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div v-if="showNewTask" class="w-100">
                                <form class="w--100 client__notes__form mt--5" @submit.prevent="">
                                    <div>
                                        <input 
                                            id="taskName"
                                            ref="newTask" 
                                            v-model="form.name" 
                                            class="form-control form-control-sm task__item__inline__input main" 
                                            type="text"
                                            placeholder="What do you want to do?"
                                        >
                                        <input 
                                            id="taskDescription" 
                                            v-model="form.description" 
                                            class="form-control form-control-sm sub task__item__inline__input" 
                                            type="text"
                                            placeholder="Add task description here..."
                                        >
                                    </div>
    
                                    <div class="client__notes__action__btns task__action__btns pr--0">
                                        <base-button
                                            class-names="mr--5" label="Cancel" btn-style="secondary" size="sm" 
                                            @submit="showNewTask = false"
                                        />
                                        <base-button 
                                            type="submit" label="Add" :loading="addTaskLoading"
                                            size="sm" :disabled="isNewBtnDisabled || addTaskLoading" @submit="handleAddTask"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </template>
                </div>              
            </template>
        </div>

        <TaskDetails 
            :show-modal="showEditModal"
            :current-task="currentTask"
            @close="closeEditModal"
            @complete="onEditComplete"
        />
    </div>
</template>

<script>
import {
    IconSvg,
    EmptyPage,
    PageSpinner,
    BaseButton,
    MainFilter,
    TaskDetails,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "ProjectTasksView",

    components: {
        EmptyPage,
        IconSvg,
        BaseButton,
        PageSpinner,
        MainFilter,
        TaskDetails,
    },

    data() {
        return {
            pageLoading: false,
            pageLoadingErr: false,
            pageLoadingErrMsg: "",
            showEditModal: false,
            showNewTask: false,
            isSearched: false,
            form: {
                name: "",
                description: "",
            },
            currentTask: {},
            addTaskLoading: false,
            taskId: "",
            notesData: {
                list: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 1,
                    pageSize: 50,
                    total: 0,
                },
            },
            tasks: [],
            pageData: {
                currentPage: 1,
                totalPages: 1,
                pageSize: 50,
                total: 0,
            },
            rowLoading: false,
        };
    },

    computed: {
        showActionBtns() {
            if (this.tasks.length) {
                return true;
            } else if (!this.tasks.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        isNewBtnDisabled() {
            if (!this.form.name) {
                return true;
            } else {
                return false;
            }
        },

        currentProject() {
            return this.$store.getters["project/currentProject"];
        },
    },

    watch: {
        "$route": {
            async handler(newVal, oldVal) {
                if (newVal.path === oldVal.path) { // this only fires when same page navigation takes place
                    await this.handleFetchTasks({ refresh: true });
                }
            },
        },

        showNewTask(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs.newTask.focus();
                });
            }
        },
    },

    async mounted() {
        if (!this.currentProject?._id) {
            await this.handleFetchProject();
        }

        if (!this.tasks.length) {
            await this.handleFetchTasks({ refresh: true });
        }
    },

    methods: {
        assembleQueryList,

        async handleFetchProject() {
            try {
                if (!this.$route.params.id) {
                    this.pageLoadingErr = true;
                    return;
                }

                this.pageLoadingErr = false;

                await this.$store.dispatch("project/getProject", this.$route.params.id);
            } catch (error) {
                this.pageLoadingErr = true;
            }
        },

        async handleFetchTasks(params) {
            try {
                this.pageLoading = true;
                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";

                const query = this.$route.query;

                let pageQueryObj = {
                    page: query?.page || 1,
                    type: "project",
                    projectId: this.$route.params.id,
                };

                const queryKeys = Object.keys(query);

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                if (query.priority) {
                    pageQueryObj.priority = query.priority;
                }

                if (query.startDate) {
                    pageQueryObj.startDate = query.startDate;
                }

                if (query.endDate) {
                    pageQueryObj.endDate = query.endDate;
                }

                const buildResult = assembleQueryList("project_tasks", pageQueryObj);

                this.tasks = await this.$store.dispatch("project/fetchTasks", { params: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.pageLoadingErr = true;
                this.pageLoadingErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },  

        resetCurrentTask() {
            this.currentTask = {};
        },

        async handleDeleteTask(task) {
            try {
                if (!task?._id) return;

                this.rowLoading = true;

                this.taskId = task._id;

                await this.$store.dispatch("project/deleteTask", this.taskId);
                
                const updatedTasks = tasks.filter(c => c._id !== task._id);

                this.tasks = updatedTasks;
                this.pageData.total = this.pageData.total--;
                this.pageData.totalPages = Math.ceil(this.tasks.length / 50);

                this.$toast.success("Task deleted");
            } catch (error) {
                const errMsg = error?.response?.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskId = "";
                this.rowLoading = false;
            }
        },

        startTaskEdit(task) {
            this.currentTask = task;
            this.showEditModal = true;
        },

        closeEditModal() {
            this.showEditModal = false;
            this.resetCurrentTask();
        },

        async handleAddTask() {
            try {
                this.addTaskLoading = true;

                const payload = { 
                    name: this.form.name, 
                    description: this.form.description,
                    deadline: null,
                    projectId: this.$route.params.id,
                    priority: "",
                    isCompleted: false,
                };

                const res = await this.$store.dispatch("project/addTask", { payload, meta: this.currentProject });
                this.tasks = [ ...this.tasks, { ...res, project: { ...this.currentProject } } ];
                
                this.pageData.total = this.pageData.total++;
                this.pageData.totalPages = Math.ceil(this.tasks.length / 50);

                this.$toast.success("Task added");
                this.form.name = "";
                this.form.description = "";
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.addTaskLoading = false;
            }
        },

        async markTaskAsCompleted(e, item) {
            try {
                this.currentTask._id = item._id;
                this.rowLoading = true;
                
                const val = e?.target?.checked;
 
                const payload = { 
                    _id: item._id,
                    isCompleted: val,
                    projectId: this.$route.params?.id,
                    name: item.name,
                };

                this.tasks = this.tasks.filter(c => c._id !== payload?._id);
                this.$toast.success("Task completed");
                await this.$store.dispatch("project/editTask", payload);
                this.currentTask._id = "";
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.rowLoading = false;
            }
        },

        async duplicateTask(task) {
            try {
                if (!task?._id) return;

                this.current = task._id;

                await this.$store.dispatch("task/duplicateTask", task);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskId = "";
            }
        },

        async onEditComplete(task) {  
            const updatedTasks = this.tasks.filter(t => {
                console.log({
                    "t.project?._id": t.project?._id,
                    "task.project?._id": task.project?._id,
                });
                
                if (t.project?._id === task.project?._id) {
                    return t;
                }
            });
            
            const arr = updatedTasks.map(c => {
                if (c?._id === task?._id) {
                    return {
                        ...c,
                        ...task,
                    };
                } else {
                    return c;
                }
            });

            console.log({ updatedTasks, arr });
            
            this.pageData.total = this.pageData.total - (arr.length - updatedTasks.length);
            this.tasks = updatedTasks;
        },
    },
};
</script>