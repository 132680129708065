export function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/* eslint-disable */
export const poller = ({ 
    fn = () => {},
    interval = 3000,
    startWhen = () => true,
    stopWhen = resp => true, 
    failWhen = resp => false,
    retries = 0,
    retryWhen = err => false, 
    onSuccess = (resp) => {}, 
    onFail = (err) => {},
    maxPolls = undefined,
}) => () => {
    let duration = 0;
    let retryCount = 0;
    let pollCounter = 0;
    return startPoll();

    function startPoll() {
        if(interval > 3000) {
            fn();
        }

        const intervalId = setInterval(() => {
            let startTime = 0;
            let endTime = 0;

            if (startWhen()) {
                const start = () => {
                    startTime = Date.now();
                    return fn();
                };

                return Promise
                    .resolve(start())
                    .then((data) => {
                        endTime = Date.now();
                        duration = endTime - startTime;
                        if (stopWhen(data)) {
                            clearInterval(intervalId);
                            onSuccess(data);
                        }
                        else if (failWhen(data)) {
                            clearInterval(intervalId);
                            onFail(data);
                        }
                        else if(maxPolls && maxPolls > 0) {
                            pollCounter = pollCounter + 1;
                            if(pollCounter >= maxPolls) {
                                clearInterval(intervalId);
                                onFail(data);
                            }
                        }
                    })
                    .catch((e) => {
                        const shouldRetry = (
                            (retries && typeof retries === "number" && retries > 0) ||
                            (retryWhen && typeof retryWhen === "function" && retryWhen(e) === true)
                        );

                        if (shouldRetry) {
                            if (retryWhen(e)) {
                                // Continue polling
                            } else if (retryCount < retries) {
                                retryCount = retryCount + 1;
                                // Increase retry count and continue polling
                            } else {
                                onFail(e);
                                return clearInterval(intervalId);
                            }
                        } else {
                            onFail(e);
                            clearInterval(intervalId);
                        }
                    });
            }
        }, interval + duration);
        return intervalId;
    }
};