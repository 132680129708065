import LandingFooter from "./LandingFooter.vue";
import Features from "./features.vue";
import Join from "./join.vue";
import JoinWaitlist from "./JoinWaitlist.vue";
import Navigation from "./Navigation.vue";

export default {
    LandingFooter, 
    Features, 
    Join, 
    Navigation,
    JoinWaitlist,
};