import api from "@/api/url";

export default {
    namespaced: true,

    state: () => {
        return {
            isAuthenticated: false,
            profile: {},
            logOutLoading: false,
            lastChecked: null,
        };
    },

    getters: {
        isAuthenticated: state => state.isAuthenticated,
        profile: state => state.profile,
        logOutLoading: state => state.logOutLoading,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setAuthStatus({ commit }, bool) {
            commit("set", ["isAuthenticated", bool]);
        },

        setLogoutLoading({ commit }, bool) {
            commit("set", ["logOutLoading", bool]);
        },

        setLastChecked({ commit }, val) {
            commit("set", ["lastChecked", val]);
        },

        login(_, { email, password }) {
            return api.auth.login({ email, password })
                .then((resp) => {
                    const data = resp.data.data;
                    return data;
                });
        },

        signup(_, payload) {
            return api.auth.signup(payload)
                .then((resp) => {
                    return resp.data.data;;
                });
        },

        externalLogin(_, payload) {
            return api.auth.externalLogin(payload)
                .then((resp) => {
                    return resp.data;
                });
        },

        signOut({ commit }) {
            return api.auth.logout()
                .then((resp) => {
                    commit("set", ["isAuthenticated", false]);
                    commit("set", ["profile", {}]);
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.replace(`${window.location.origin}/login`);
                }); 
        },

        fetchProfile({ commit, state, dispatch }, { refresh }) {
            if (state.profile?.email && !refresh) {
                dispatch("setAuthStatus", true);
                return state.profile;
            }

            return api.user.get()
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["profile", data]);
                    dispatch("setAuthStatus", true);
                });
        },

        updateProfile({ commit, state }, payload) {
            return api.user.update(payload)
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["profile", { 
                        ...state.profile,
                        ...data, 
                    }]);
                });
        },

        deleteProfile(_) {
            return api.user.delete()
                .then((res) => {});
        },

        updatePassword(_, payload) {
            return api.auth.changePassword(payload)
                .then((res) => {});
        },

        joinWaitlist(_, { email }) {
            return api.waitlist.joinWaitlist({ email })
                .then((res) => {
                    const data = res.data;
                    return data;
                });
        },

        checkAuth({ dispatch, state }) {
            const now = Date.now();
            const cachedTime = localStorage.getItem("z:cached_time");
            const parsedTime = Number(cachedTime) || 0;                    

            if (state.lastChecked && (now - state.lastChecked) < parsedTime) {
                return; // Use cached value
            }
            
            return api.auth.verifySession()
                .then((resp) => {
                    const isSuccessful = resp.data.isSuccessful;

                    if (!isSuccessful) {
                        dispatch("setAuthStatus", false);
                        dispatch("setLastChecked", now);
                    } else {
                        dispatch("setAuthStatus", true);
                        dispatch("setLastChecked", now);
                    }
                }); 
        },
    },
};