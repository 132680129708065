import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";

import LandingPageView from "@/pages/index.vue";
import DashboardView from "@/views/Dashboard.vue";

import ReportsView from "@/pages/dashboard/Reports.vue";

import BaseRevenuePage from "@/pages/dashboard/revenue/index.vue";
import Overview from "@/pages/dashboard/revenue/RevenueOverview.vue";
import History from "@/pages/dashboard/revenue/RevenueHistory.vue";

import RateCardView from "@/pages/dashboard/RateCard.vue";

import ClientsView from "@/pages/dashboard/clients/index.vue";
import ClientsDetailsView from "@/pages/dashboard/clients/_id.vue";

import ProjectsView from "@/pages/dashboard/projects/index.vue";
import ProjectsDetailsView from "@/pages/dashboard/projects/_id/index.vue";
import ProjectsOverview from "@/pages/dashboard/projects/_id/Overview.vue";
import ProjectNotes from "@/pages/dashboard/projects/_id/ProjectNotes.vue";
import ProjectInvoices from "@/pages/dashboard/projects/_id/ProjectInvoices.vue";
import ProjectTasksView from "@/pages/dashboard/projects/_id/Tasks.vue";
import ComingSoon from "../views/ComingSoon.vue";

import AccountSettings from "@/pages/dashboard/settings/Account.vue";
import GeneralSettings from "@/pages/dashboard/settings/GeneralSettings.vue";
import BillingsAndPlans from "@/pages/dashboard/settings/BillingsAndPlans.vue";
import Integrations from "@/pages/dashboard/settings/Integrations.vue";
import GoalsAndProductivity from "@/pages/dashboard/settings/GoalsAndProductivity.vue";
import RemindersAndAlerts from "@/pages/dashboard/settings/Reminders.vue";
import Notifications from "@/pages/dashboard/settings/Notifications.vue";

import LoginView from "@/pages/Login.vue";
import InitiateResetView from "@/pages/InitiateReset.vue";
import SignupView from "@/pages/Signup.vue";
import ResetView from "@/pages/Reset.vue";

import TagsView from "@/pages/dashboard/Tags.vue";
import TasksView from "@/pages/dashboard/Tasks.vue";

import InvoicesView from "@/pages/dashboard/invoices/index.vue";
import CreateEditInvoiceView from "@/pages/dashboard/invoices/CreateEdit.vue";
import InvoiceDetailsView from "@/pages/dashboard/invoices/_id/index.vue";
import api from "@/api/url";

const routes = [
    {
        path: "/",
        name: "home",
        component: LandingPageView,
        meta: { requiresAuth: false,  title: "Home | Ziner" },
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: { requiresAuth: false,  title: "Login | Ziner" },
        redirect: { name: "home" }, // redirect to the home page for now until I start working on this project again
    },
    {
        path: "/signup",
        name: "signup",
        component: SignupView,
        redirect: { name: "home" }, // redirect to the home page for now until I start working on this project again

    },
    {
        path: "/reset",
        name: "reset",
        component: ResetView,
        meta: { requiresAuth: false,  title: "Reset Password | Ziner" },
        // redirect: { name: "home" }, // redirect to the home page for now until I start working on this project again
    },
    {
        path: "/initiateReset",
        name: "intiate-reset",
        component: InitiateResetView,
        meta: { requiresAuth: false,  title: "Request Password Reset | Ziner" },
        // redirect: { name: "home" }, // redirect to the home page for now until I start working on this project again
    },
    {
        path: "/dashboard/",
        name: "dashboard",
        component: DashboardView,
        meta: { requiresAuth: true },
        redirect: to => ({ path: "/" }),

        children: [
            { path: "reports", name: "reports", component: ReportsView, meta: { requiresAuth: true, title: "Reports | Ziner" } },

            { path: "revenue", component: BaseRevenuePage, meta: { requiresAuth: true },
                children: [                
                    { path: "overview", name: "revenue-overview", component: Overview, meta: { requiresAuth: true,  title: "Revenue Overview | Ziner"  } },
                    { path: "history", name: "revenue-history", component: History, meta: { requiresAuth: true,  title: "Revenue History | Ziner"  } },
                ], 
                redirect: { name: "revenue-overview" },
            },
                  
            { path: "projects", name: "projects-view", component: ProjectsView, meta: { requiresAuth: true, title: "Projects | Ziner" } },
      
            {  path: "projects/:id", name: "project-details-view", component: ProjectsDetailsView, meta: { requiresAuth: true, title: "Project Details | Ziner" },
                // children: [                
                //     { path: "overview", name: "project-details-overview", component: ProjectsOverview, meta: { requiresAuth: true,  title: "Project Details Overview | Ziner"  } },
                //     { path: "notes", name: "project-details-notes", component: ProjectNotes, meta: { requiresAuth: true,  title: "Project Details Notes | Ziner"  } },
                //     { path: "invoices", name: "project-details-invoices", component: ProjectInvoices, meta: { requiresAuth: true,  title: "Project Details Invoices | Ziner"  } },
                // ], 
                // redirect: { name: "project-details-overview" },
            },

            {  path: "projects/:id/tasks", name: "project-details-tasks", component: ProjectTasksView, title: "Project Tasks | Ziner" },
       
            { path: "tags", name:"tags-view", component: TagsView, meta: { requiresAuth: true, title: "Tags | Ziner" } },
            
            { path: "rate-cards", name:"rate-cards-view", component: RateCardView, meta: { requiresAuth: true, title: "Rate Cards | Ziner" } },
            
            { path: "tasks", name:"tasks-view", component: TasksView, meta: { requiresAuth: true, title: "Tasks | Ziner" } },
      
            { path: "calendar-and-meetings", name: "calendar-and-meetings-view", component: ComingSoon, meta: { requiresAuth: true, title: "Calendar & Meetings | Ziner" } },
            
            { path: "invoices", name: "invoices-view", component: InvoicesView, meta: { requiresAuth: true, title: "Invoices | Ziner" } },
            
            { path: "invoices/create", name: "create-invoice-view", component: CreateEditInvoiceView, meta: { requiresAuth: true, title: "Create Invoice | Ziner" } },

            { path: "invoices/:id/edit", name: "edit-invoice-view", component: CreateEditInvoiceView, meta: { requiresAuth: true, title: "Edit Invoice | Ziner" } },
      
            { 
                path: "invoices/:id", 
                name: "invoice-details-view", 
                component: InvoiceDetailsView, 
                meta: { 
                    requiresAuth: true,
                    title: "Invoice Details | Ziner",
                }, 
            },
      
            { path: "clients", name: "clients-view", component: ClientsView, meta: { requiresAuth: true, title: "Clients | Ziner" } },

            { path: "clients/:id", name:"client-details-view", component: ClientsDetailsView, meta: { requiresAuth: true, title: "Client Details | Ziner" } },
      
            { path: "settings", name:"settings-view", meta: { requiresAuth: true },
                children: [                
                    { path: "account", name: "account-settings", component: AccountSettings, meta: { requiresAuth: true, title: "Account | Ziner" }},
                    
                    { path: "general-settings", name: "general-settings", component: GeneralSettings, meta: { requiresAuth: true, title: "General settings | Ziner" } },

                    { path: "goals-and-productivity", name: "goals-settings", component: GoalsAndProductivity, meta: { requiresAuth: true, title: "Goals & Productivity | Ziner" } },

                    { path: "billings-and-plans", name: "billings-settings", component: BillingsAndPlans, meta: { requiresAuth: true, title: "Billings & Plans | Ziner" }},

                    // { path: "reminders", name: "reminders-settings", component: RemindersAndAlerts, meta: { requiresAuth: true, title: "Reminders | Ziner" }},

                    { path: "notifications", name: "notifications-settings", component: Notifications, meta: { requiresAuth: true, title: "Notifications | Ziner" }},

                    { path: "integrations", name: "integrations-settings", component: Integrations, meta: { requiresAuth: true, title: "Integrations | Ziner" } },
                ], 
                redirect: { name: "account-settings" },
            },
        ],
    },
    {
        path: "/:catchAll(.*)*",
        component: () => import("../views/ErrorPage.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

// navigation guard for protected routes and dynamic document titles
router.beforeEach(async(to, from, next) => {
    if (to.meta?.title) {
        document.title = to.meta.title ?? "Ziner - Manage your freelance workflows";
    }

    // check for authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
        await store.dispatch("auth/checkAuth");
        const isAuthenticated = store.getters["auth/isAuthenticated"];

        console.log({ isAuthenticated });

        if (!isAuthenticated) {
            next("/login");
        }  else {
            next();
        }
    } else {
        next();
    }
});

export default router;