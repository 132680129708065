<template>
    <div class="tabs horizontal ">
        <ul class="tab-list left" role="tabList">
            <li
                v-for="tab in tabs"
                :key="tab.id"
                class="tab-list__item"
                tab-index="0"
                role="tabItem"
                :aria-selected="isTabActive($to_simple_lowercase(tab.id))"
            >
                <router-link :to="`/dashboard/${tab.route}`">
                    {{ tab.id }}
                </router-link>
            </li>
        </ul>
        <div class="tab-panel h-auto" role="tabPanel">
            <div class="tab-content h-auto">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LinkedTabs",

    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            tabs: this.list, 
        };
    },

    methods: {
        isTabActive(id) {
            if (this.$route.path.includes(id)) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>