<template>
    <auth-form 
        :auth-type="'initiateReset'"
        :loading="loading"
        :error="error"
        @submit="handleReset"
    />
</template>

<script>
import AuthForm from "../components/auth/auth-form.vue";

export default {
    name: "InitiateResetPage",
    components: {
        AuthForm,
    },

    data() {
        return {
            error: {
                show: false,
                value: "",
            },
            loading: false,
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.email) {
                return true;
            } else if (this.loading) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        handleReset(payload) {
            
        },
    },
};
</script>

<style lang="scss" scoped>
.auth--form__wrap {
    padding: 20px 40px 35px;
}
</style>