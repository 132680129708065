import { mockAxiosError } from "./formatters";

export { domUtils, trapFocus, isControlKey } from "./dom";
export { textSpacer, optimizeFileStackURL, splitArrayIntoSets } from "./formatters";
export { daysFromNow } from "./dates";
export { wait, poller } from "./timers";
export { isAdmin, allowAccess } from "./roles"; ;
export {
    supportedCountries, supportedCountriesMap, globalCountry, country,
    supportedCurrencies, paymentLinkSupportedCurrencies,
    getPaymentLinkCountry, transferCurrencies, usdTransferCurrencies, internationalTransferCurrencies,
    transferCountries, topupCurrencies, supportedBankCountries, settlementAccountCurrencies, getUserCountry, userCountry,
} from "./countries";

export function simpleUniqueID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
};

export function objectIsEmpty(obj) {
    return (Object.keys(obj).length === 0 && obj.constructor === Object);
}

export function generateReference(prefix = "", suffix = "") {
    const length = 12;
    const timestamp = new Date().getTime();

    function _getRandInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function generate() {
        const ts = timestamp.toString();
        const parts = ts.split("").reverse();
        let ref = "";

        for (let i = 0; i < length; ++i) {
            const index = _getRandInt(0, parts.length - 1);
            ref += parts[index];
        }

        return ref;
    }

    return prefix + generate() + suffix;
}

/**
 * @param {string} filename
 * @param {string} content - Raw content.
 * @param {"text"| "image"} filetype
 */
export function downloadFromString(filename, content, filetype) {
    const element = document.createElement("a");

    if (filetype === "image") {
        element.setAttribute("href", "data:image/png;base64," + encodeURIComponent(content));
    } else {
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    }

    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function generateSlug() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 12; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

export function pushOrReplace(arr = [], value, predicateFn) {
    const foundIndex = arr.findIndex(predicateFn);
    if (foundIndex <= -1) {
        arr.push(value);
    } else {
        arr[foundIndex] = value;
    }
    return arr;
}

export function redirectWithReload(path = "") {
    if (path.startsWith("/") === false) {
        path = "/" + path;
    }
    if (process.browser) {
        window.location.href = window.location.origin + path;
    }
}

export function matchUrl(pattern = "", url = "") {
    pattern = pattern.startsWith("/") ? pattern : "/" + pattern;
    url = url.startsWith("/") ? url : "/" + url;

    const patternUrl = pattern.split("?")[0] || "";
    const patternParams = pattern.split("?")[1] || "";
    const urlUrl = url.split("?")[0] || "";
    const urlParams = url.split("?")[1] || "";

    const compareUrls = (urlOne = "", urlTwo = "") => {
        const urlOneParts = urlOne.split("/");
        const urlTwoParts = urlTwo.split("/");
        if (urlOneParts.length !== urlTwoParts.length) {
            return false;
        }

        for (let i = 0; i < urlOneParts.length; i++) {
            if (urlOneParts[i].startsWith(":") || urlTwoParts[i].startsWith(":")) {
                continue;
            }
            if (urlOneParts[i] !== urlTwoParts[i]) {
                return false;
            }
        }

        return true;
    };

    const compareParams = (paramsOne = "", paramsTwo = "") => {
        const paramsOneParts = paramsOne.split("&");
        const paramsTwoParts = paramsTwo.split("&");
        if (paramsOneParts.length !== paramsTwoParts.length) {
            return false;
        }

        for (let i = 0; i < paramsOneParts.length; i++) {
            const queryOne = (paramsOneParts[i] || "").split("=")[0] || "";
            const queryTwo = (paramsTwoParts[i] || "").split("=")[0] || "";

            if (queryOne !== queryTwo) {
                return false;
            }
        }

        return true;
    };

    if (!patternParams) {
        return compareUrls(patternUrl, urlUrl);
    } else {
        return compareUrls(patternUrl, urlUrl) && compareParams(patternParams, urlParams);
    }
}

export function goBackToLastUniqueRoute(query) {
    if (process.browser === false) {
        return;
    }

    const lastRoute = getLastUniqueRoute();
    if (lastRoute) {
        window.$nuxt.$router.push({ path: lastRoute.fullPath, query });
        return;
    }

    window.$nuxt.$router.go(-1);
}

export function getLastUniqueRoute() {
    if (process.browser === false) {
        return;
    }

    const currentPath = window.location.pathname;
    const previousRoutes = JSON.parse(window.localStorage.previousRoutes || "[]");

    for (let i = previousRoutes.length - 1; i >= 0; i--) {
        const previousRoute = previousRoutes[i] || {};
        if ((previousRoute.path.includes(currentPath) === false) && previousRoute.fullPath) {
            return previousRoute;
        }
    }
}

export function waitTill({ predicate = () => true, counts = 10, interval = 400 }) {
    let intervalId;
    let count = 0;
    return new Promise((resolve, reject) => {
        try {
            const val = predicate();
            if (val) {
                resolve(val);
            }

            intervalId = setInterval(() => {
                count = count + 1;
                const val = predicate();
                if (val) {
                    resolve(val);
                    clearInterval(intervalId);
                } else if (count > counts) {
                    reject(mockAxiosError({ message: "Waiting timeout error. Action took too long" }));
                    clearInterval(intervalId);
                }
            }, interval);
        } catch (err) {
            reject(err);
        }
    });
}

export function genCorridorString({ fromCountryIso, fromCurrencyCode, toCountryIso, toCurrencyCode }) {
    return fromCountryIso + "_" + fromCurrencyCode + ":" + toCountryIso + "_" + toCurrencyCode;
};

export function splitFullName(name = "") {
    const names = String(name).split(" ");
    if (names.length === 1) {
        return [names[0], ""];
    }
    const firstName = names.slice(0, names.length - 1).join(" ");
    const lastName = names[names.length - 1];
    return [firstName, lastName];
}

export const nth = (value) => {
    if (value > 3 && value < 21) {
        return "th";
    }
    switch (value % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
    }
};

export function toFixedDown(val, digits) {
    const re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)");
    const m = val.toString().match(re);
    return m ? parseFloat(m[1]) : parseFloat(String(val.valueOf()));
}

export function removeNan(val) {
    if (isNaN(val)) {
        return 0;
    }
    return val;
}

export function stripDecimals(n) {
    return removeNan(n) || 0;
}

export const stripNegative = (val) => {
    return Math.abs(val);
};

export function toFixedRound(val, digits) {
    const re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)");
    const m = val.toString().match(re);
    return m ? stripDecimals(m[1]) : stripDecimals(String(val.valueOf()));
}