<template>
    <div>
        <main-modal
            :show-modal="showModal" is-close-button is-header title="Task details"
            size="md" @close="onCancel"
        >
            <template #body>
                <div class="modal__wrapper--onboarding mt--5">
                    <div>
                        <form @submit.prevent="">
                            <div class="flex align-items-center justify-content-between">
                                <div class="flex-2">
                                    <FormInput 
                                        id="title"
                                        v-model="form.name"
                                        type="text"
                                        size="sm"
                                        info-type="required"
                                        label="Name"
                                        required
                                    />
                                    <FormTextArea 
                                        id="taskDescription"
                                        v-model="form.description"
                                        type="text"
                                        size="sm" html-for="taskDescription"
                                        label="Description"
                                    />
                                </div>
                            </div>
                            
                            <div class=" form__row mb--15">
                                <div class="mr--15 mb--0 flex-1">
                                    <FormLabel title="Priority" html-for="priority" />
                                    <select v-model="form.priority" class="form-select text--capitalize form-select-sm text--sm">
                                        <option v-for="priority in priorities" :key="priority" :value="priority">
                                            {{ priority }}
                                        </option>
                                    </select>
                                </div>
    
                                <div class="m-w-100 mb--0 flex-1">
                                    <FormLabel title="Due date" />
                                    <v-date-picker v-model="form.deadline" class="text--sm">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                            </div>

                            <div class=" form__row mb--15 mt--20">
                                <div class="flex-1">
                                    <FormLabel title="Parent project" html-for="priority" />
                                    <CustomDropdown 
                                        id="taskDetailsDropdown" v-model="form.project"
                                        :items="projects" list-type="project" 
                                        index-label="title" show-search-field
                                        @select="setParentProject"
                                    />
                                </div>
                            </div>

                            <div class="mt--10">
                                <base-button 
                                    type="submit" 
                                    size="sm"
                                    btn-style="outline" inner-style="primary"
                                    :loading="loading" 
                                    :disabled="loading || isBtnDisabled" 
                                    label="Save"
                                    @submit="handleUpdateTask"
                                />
                            </div>
                        </form>
                    </div>

                    <div class="mt--25 mb--10">
                        <div class="task__notes__title">
                            Notes
                        </div>

                        <form class="client__notes__form mt--15 mb--25" @submit.prevent="">
                            <div class="form__textarea__group task__notes__textarea">
                                <textarea
                                    v-model="currentNote.name" type="text"
                                    class="form-control form-control-sm form__input" placeholder="Add a new note here..."
                                />
                                <div class="client__notes__action__btns pt--10">
                                    <base-button
                                        class-names="mr--5" label="Cancel" btn-style="secondary" size="sm"
                                        @submit="clearNoteValue"
                                    />
                                    <base-button 
                                        type="submit" :label="formAction === 'add' ? 'Add' : 'Save'" :loading="noteUpdateLoading" :disabled="!currentNote.name"
                                        size="sm" @submit="saveNote"
                                    />
                                </div>
                            </div>
                        </form>

                        <template v-if="!form.notes.length">
                            <EnptyPage
                                v-if="!form.notes.length" type="note" icon-name="notes" width="30px"
                                height="30px"
                            />
                        </template>

                        <template v-else>
                            <ul class="task__notes__list">
                                <li v-for="item in form.notes" :key="item._id" class="client__notes__item task__notes__item">
                                    <span class="task__notes__item__text">{{ item.name }}</span>
                                    <span class="mr--5 flex align-items-center">
                                        <span class="pointer mr--10" @click="startEdit(item)">
                                            <icon-svg name="edit" fill="#637579" width="16px" />
                                        </span>
                                        <span class="pointer" @click="startDelete(item)">
                                            <icon-svg name="delete" fill="#DA7DA0" width="16px" />
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </template>
                    </div>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import EnptyPage from "../emptyPage/EmptyPage.vue";
import { PROJECT_STATUSES, priorities } from "@/utils/enums";
import FormLabel from "../forms/FormLabel.vue";
import FormInput from "../input/FormInput.vue";
import FormTextArea from "../input/FormTextArea.vue";
import BaseButton from "../buttons/BaseButton.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "TaskDetailsModal",

    components: {
        MainModal,
        FormLabel,
        BaseButton,
        IconSvg,
        CustomDropdown,
        FormInput,
        FormTextArea,
        EnptyPage,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },

        currentTask: {
            type: Object,
            default: () => {},
        },
    },

    emits: ["cancel"],

    data() {
        return {
            statuses: PROJECT_STATUSES,
            form: {
                _id: "",
                name: "",
                description: "", 
                deadline: "", 
                priority: "low", 
                project: {
                    _id: "",
                    title: "",
                },
                notes: [],
            },
            loading: false,
            formAction: "add",
            currentNote: {
                _id: "",
                name: "",
                type: "add",
            },
            priorities,
            pageData: {
                currentPage: 1,
                totalPages: 1,
                pageSize: 50,
                total: 0,
            },
            searchProjects: false,
            noteUpdateLoading: false,
        };
    },

    computed: {
        projects() {
            return this.$store.getters["project/projects"] || [];
        },

        isBtnDisabled() {
            if (!this.form.project?._id || !this.form?._id || !this.form?.name) {
                return true;
            } else {
                return false;
            }
        },
    },

    watch: {
        currentTask: {
            handler(newVal) {
                if (newVal?._id) {
                    this.prefillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },
    },
    
    async mounted() {
        this.$refs?.titleRef?.focus();
    },

    methods: {
        onCancel() {
            this.form = {
                _id: "",
                name: "",
                description: "", 
                deadline: "", 
                priority: "low", 
                notes: [],
            };
            this.form.project = {
                _id: "",
                title: "",
            };
            this.currentNote = {
                _id: "",
                name: "",
                type: "add",
            };
            this.formAction = "add";
            this.$emit("cancel");
        },

        startEdit(note) {
            if (!note._id) return;

            this.formAction = "update";
            this.currentNote = {
                ...this.currentNote,
                name: note.name, 
                _id: note._id, 
            };
        },

        startDelete(note) {
            if (!note._id) return;

            this.formAction = "delete";
            this.currentNote = {
                ...this.currentNote,
                _id: note._id, 
                name: note.name, 
            };
            this.handleNotes();
        },

        saveNote() {
            if (this.formAction !== "add") {
                this.formAction = "update";
            }

            this.handleNotes();
            this.clearNoteValue();
        },

        clearNoteValue() {
            this.formAction = "add";
            this.currentNote._id = "";
            this.currentNote.name = "";
        },

        async handleNotes() {   
            try {
                if (!this.currentNote.name || !this.currentNote.type) return;

                this.noteUpdateLoading = true;

                const payload = {
                    name: this.currentNote.name,
                    type:  this.formAction,
                };

                if (this.formAction === "update" || this.formAction === "delete") {
                    payload._id = this.currentNote._id;
                } 
                
                const result = await this.$store.dispatch("task/updateNotes", { taskId: this.form._id, payload });

                const msg = `Note ${this.formAction === "delete" ? "deleted" : this.formAction === "update" ? "updated" : "added" }`;
                this.$toast.success(msg);
                this.clearNoteValue();
                this.form.notes = result;
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.noteUpdateLoading = false;
            };
        },

        async handleUpdateTask() {   
            try {
                if (!this.form.project._id || !this.form._id || !this.form.name) return;

                this.loading = true;

                const payload = {
                    _id: this.form._id,
                    name: this.form.name,
                    description: this.form.description || "",
                    priority: this.form.priority || "",
                    deadline: this.form.deadline,
                    projectId: this.form.project._id,
                };

                const fullData = {
                    ...payload,
                    project: this.form.project,
                };

                await this.$store.dispatch("task/editTask", { payload, fullData });
                
                this.$emit("complete", fullData);
                this.$toast.success("Task updated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            };
        },

        prefillForm(val) {
            this.form = {
                ...this.form,
                ...val,
            };
        },

        setParentProject(project) {
            this.form.project._id = project._id;
            this.form.project.title = project.title;
        },

        async searchParentProject(query) {
            try {
                this.searchProjects = true;
                
                const pageQueryObj = {
                    page: query.page || 1,
                    type: query.type || "active",
                    title: query,
                };

                const buildResult = assembleQueryList("projects", pageQueryObj);
                await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: true });
            } catch (error) {
                console.log({ error });
            } finally {
                this.searchProjects = false;
            }
        }, 
    },
};
</script>