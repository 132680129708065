<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            is-close-button is-show-header size="sm" is-footer
            title="Upgrade account" @close="$emit('cancel')"
        >
            <template #body>
                <div class="modal__wrapper--onboarding">
                    <div class="modal--section">
                        <div class="mb--20 mt--20">
                            <div>
                                <span class="text--sm">You have exceeded the allowed number of {{ type }} for your account. <strong>Upgrade</strong> your account in order to create more {{ type }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        label="Cancel" btn-style="secondary" size="sm"
                        @submit="$emit('cancel', false)"
                    />
                    <Base-Button label="Upgrade" size="sm" @submit="upgradeAccount" />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "UpgradeAccount",

    components: { MainModal, BaseButton },

    props: {
        type: {
            type: String,
            default: "",
        },
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel"],

    methods: {
        upgradeAccount() {
            this.$emit("cancel");
            this.$router.push("/dashboard/settings/billings-and-plans");
        },
    },
};
</script>