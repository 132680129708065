<template>
    <div class="report__wrap">
        <template v-if="pageLoading">
            <PageSpinner />
        </template>

        <template v-else-if="pageLoadingErr">
            <EmptyPage state="error">
                <template #button>
                    <BaseButton 
                        label="Reload" 
                        size="sm"
                        class-names="flex align-items-center"
                        @submit="handleFetchReports"
                    />
                </template> 
            </EmptyPage>
        </template>

        <template v-else>
            <div class="report__header">
                <div>
                    <p class="text--color-gray-600 text--capitalize text--medium">
                        {{ reportItemType }} report
                    </p>
                </div>

                <div class="flex align-items-center">
                    <div class="ml--10">
                        <div class="dropdown">
                            <button class="btn btn--outline__secondary btn--sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Report type
                            </button>
                            
                            <ul class="dropdown-menu">
                                <li 
                                    v-for="report in reportTypes" 
                                    :key="report" 
                                    class="dropdown-item report__item__type" 
                                    :class="{ 'selected': report === reportItemType }"
                                    @click="setReportType(report, true)"
                                >
                                    {{ report }}
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="ml--10">
                        <div class="dropdown">
                            <button class="btn btn--outline__secondary btn--sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Year
                            </button>
                            <ul class="dropdown-menu">
                                <li 
                                    v-for="year in reportYears" 
                                    :key="year" 
                                    class="dropdown-item report__item__type" 
                                    :class="{ 'selected': year === currentYear }"
                                    @click="setReportYear(year, true)"
                                >
                                    {{ year }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="ml--10">
                        <base-button
                            btn-style="outline" label="Download" size="sm" 
                            is-prefix-icon icon-name="download" icon-fill="#4A5568" icon-width="16px"
                            inner-style="secondary"
                        />     
                    </div>
                </div>
            </div>

            <ReportItem />
        </template>
    </div>
</template>

<script>
import ReportItem from "@/components/reports/ReportItem.vue";
import { EmptyPage, PageSpinner, BaseButton } from "@/components/ui/index";
import { serilaizeQuery } from "@/utils/helpers";

export default {
    name: "Reports",

    components: {
        ReportItem,
        EmptyPage,
        PageSpinner,
        BaseButton,
    },

    data() {
        return {
            reportTypes: ["projects", "invoices", "clients"],
            pageLoading: false,
            pageLoadingErr: true,
            reportYears: ["2024"],
        };
    },

    computed: {
        reportItemType() {
            return this.$store.getters["report/reportItemType"];
        },

        currentYear() {
            return this.$store.getters["report/currentYear"];
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal, oldVal) {
                if (newVal !== oldVal && typeof oldVal !== "undefined") {
                    await this.handleFetchReports();
                }
            },
            deep: true,
            immediate: true,
        },
    },

    async mounted() {
        await this.handleFetchReports();

        if (this.$route.query?.type) {
            this.setReportType(this.$route.query.type, false);
        }

        if (this.$route.query?.year) {
            this.setReportYear(this.$route.query.year, false);
        }
    },

    methods: {
        serilaizeQuery,

        setReportType(type, isUpdateRouteFilter = false) {
            this.$store.dispatch("report/setReportType", type);

            if (isUpdateRouteFilter) {
                this.setFilter({ type });
            }
        },

        setReportYear(year, isUpdateRouteFilter = false) {
            this.$store.dispatch("report/setReportYear", year);

            if (isUpdateRouteFilter) {
                this.setFilter({ year });
            }
        },

        async setFilter(data) {
            const query = {};

            if (data?.type) {
                query.type = data.type;
            }

            if (data?.year) {
                query.year = data.year;
            }

            let currentRoute = this.$route.path;
            let queryParam = this.serilaizeQuery(query);
                        
            this.$router.replace(`${currentRoute}?${queryParam}`);
        },
        
        async handleFetchReports() {
            try {
                this.pageLoadingErr = false;
                this.pageLoading = true;

                const query = this.$route.query;

                const payload = {
                    type: query?.type || this.reportItemType,
                    year: query?.year || this.currentYear,
                };

                await this.$store.dispatch("report/fetchReports", payload);
            } catch (error) {
                this.pageLoadingErr = true;
            } finally {
                this.pageLoading = false;
            }
        },
    },
};
</script>