<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            is-close-button is-show-header is-footer
            size="sm"
            :title="actionType === 'add' ? 'Add project' : 'Edit project'" 
            @close="onCancel"
        >
            <template #body>
                <div class="modal__wrapper--onboarding mb--10">
                    <template v-if="!showUpgradeAccountInfo">
                        <form class="pb--5" @submit.prevent="">
                            <template v-if="step === 1">
                                <FormInput 
                                    id="title" v-model="form.title"
                                    type="text" size="sm" info-type="required"
                                    html-for="title" label="Title"
                                />
    
                                <div class="form__row mb--15">
                                    <div class="mr--15 mb--0 flex-1">
                                        <FormLabel title="Priority" html-for="priority" />
                                        <select v-model="form.priority" class="form-select form-select-sm text--sm text--capitalize">
                                            <option v-for="priority in priorities" :key="priority" :value="priority">
                                                {{ priority }}
                                            </option>
                                        </select>
                                    </div>
    
                                    <div class="m-w-100 mb--0 flex-1">
                                        <FormLabel title="Due date" />
                                        <v-date-picker v-model="form.deadline" class="text--sm">
                                            <template #default="{ inputValue, togglePopover }">
                                                <input
                                                    class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                                    :value="inputValue"
                                                    @click="togglePopover"
                                                >
                                            </template>
                                        </v-date-picker>
                                    </div>
                                </div>
    
                                <div class="form__row mb--15">
                                    <div class="mr--15 mb--0 flex-1">
                                        <FormLabel title="Status" html-for="status" />
                                        <select v-model="form.status" class="form-select form-select-sm text--sm text--capitalize" aria-label="Default select example">
                                            <option v-for="status in statuses" :key="status" :value="status">
                                                {{ status }}
                                            </option>
                                        </select>
                                    </div>
    
                                    <div class="mb--0 flex-1">
                                        <FormLabel title="Star this project?" html-for="important" />
                                        <select v-model="form.isFavourite" class="form-select form-select-sm text--capitalize" aria-label="Default select example">
                                            <option v-for="item in favorites" :key="item" :value="item">
                                                {{ item }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </template>
    
                            <template v-else>
                                <div class="mb--15">
                                    <FormLabel title="Is this a paid project?" html-for="important" />
                                    <select v-model="form.projectType" class="form-select form-select-sm">
                                        <option v-for="item in ['paid', 'not paid']" :key="item" :value="item" class="text--capitalize">
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>

                                <template v-if="form.projectType === 'paid' && actionType === 'add'">
                                    <div class="flex justify-content-end">
                                        <Base-Button 
                                            class-names="text--xs btn--ghost__primary" 
                                            :label="!useRateCard ? 'Use rate cards' : 'Use a one-time estimate'"
                                            size="sm" btn-style="ghost" @submit="togglePaymentSelection" 
                                        />
                                    </div>

                                    <template v-if="!useRateCard">
                                        <div class="mb--15">
                                            <FormLabel title="Currency" html-for="important" />
                                            <select v-model="form.currency" class="form-select text--upper form-select-sm">
                                                <option v-for="item in supportedCountries" :key="item" :value="item">
                                                    {{ item }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="flex align-items-center mb--15">
                                            <div class="flex-1 mr--5">
                                                <FormLabel title="Billable Hours" html-for="hourlyRate" />
                                                <money
                                                    v-model="form.billableHours" v-bind="config.number"
                                                    class="form-control form-control-sm"
                                                    spellcheck="false"
                                                    @keydown.native="preventKeys"
                                                />
                                            </div>
                                            <div class="flex-1">
                                                <FormLabel title="Estimated Revenue" html-for="estimatedRevenue" />
                                                <money
                                                    v-model="form.estimatedRevenue" v-bind="config.number"
                                                    class="form-control form-control-sm"
                                                    spellcheck="false"
                                                    @keydown.native="preventKeys"
                                                />
                                            </div>
                                        </div>

                                        <div class="mb--10">
                                            <FormLabel title="Hourly Rate" html-for="hourlyRate" />
                                            <div class="flex align-items-center">
                                                <select v-model="form.hourlyRate.currency" class="form-select form-select-sm mr--5 flex-1 text--upper">
                                                    <option v-for="item in supportedCountries" :key="item" :value="item">
                                                        {{ item }}
                                                    </option>
                                                </select>
                                                <money
                                                    v-model="form.hourlyRate.amount" v-bind="config.number"
                                                    class="flex-2 form-control form-control-sm"
                                                    spellcheck="false"
                                                    @keydown.native="preventKeys"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <FormLabel title="Select one or more rate cards" html-for="rateCards" />
                                        <CustomDropdown
                                            id="rateCardDropdown" item-label="title" show-search-field :loading="dropdownLoading"
                                            :items="rateCards" list-type="rateCard" placeholder="Search rate cards"
                                            @search="handleFetchRateCards" @select="addRateCard"
                                        />

                                        <div v-if="form.rateCards.length" class="project__sub__wrap ml--0 mt--10 mb--10">
                                            <div v-for="item in form.rateCards" :key="item._id" class="project__sub__item">
                                                <div class="flex align-items-center">
                                                    <IconSvg name="money" width="16px" fill="#5e6c84" />
                                                    <span class="ml--5 title">{{ item.title }}</span>
                                                </div>
                                                <span class="close" type="button" @click="removeRateCard(item)">
                                                    <IconSvg name="close" width="16px" fill="#5e6c84" />
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <div v-if="actionType === 'add'" class="mt--20">
                                    <FormLabel title="Set reminders" html-for="important" />
                                    <div>
                                        <div v-for="day in invoiceReminders" :key="day" class="form-check form-check-inline">
                                            <input
                                                id="flexCheckDefault" v-model="form.reminders" 
                                                :value="day.id" class="form-check-input" type="checkbox"
                                            >
                                            <label class="form-check-label text--xs text--capitalize" for="flexCheckDefault">
                                                {{ day.name }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </form>
                    </template>

                    <template v-else>
                        <div>
                            <span class="text--sm">You have exceeded the allowed number of projects for your account. <strong>Upgrade</strong> your account to create more projects</span>
                        </div>
                    </template>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <template v-if="!showUpgradeAccountInfo">
                        <base-button 
                            class-names="mr--5" 
                            :label="secondaryBtnCopy" btn-style="secondary" size="sm"
                            @submit="secondaryBtnAction"
                        />
                        <base-button 
                            type="submit" size="sm" :label="btnCopy"
                            :loading="loading" :disabled="loading || !form.title" 
                            @submit="handleSubmit"
                        />
                    </template>
                    <template v-else>
                        <Base-Button label="Upgrade" size="sm" @submit="upgradeAccount" />
                    </template>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import { PROJECT_STATUSES, priorities } from "@/utils/enums";
import { invoiceReminders } from "@/utils/dummy";
import { supportedCountries } from "@/plugins/countries";
import FormLabel from "../forms/FormLabel.vue";
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "CreateProjectModal",

    components: {
        MainModal,
        FormLabel,
        BaseButton, IconSvg,
        FormInput, CustomDropdown,
    },

    props: {
        showModal: { type: Boolean, default: false },
        actionType: { type: String, default: "add" },
        projectData: { type: Object, default: () => {} },
        showClientList: { type: Boolean, default: true },
        stepValue: { type: Number, default: 1 },
    },

    data() {
        return {
            statuses: PROJECT_STATUSES,
            form: {
                _id: "",
                title: "",
                status: "pending",
                deadline: "",
                priority: "low",
                projectType: "not paid",
                isFavourite: "no",
                tags: [],
                clients: [],
                rateCards: [],
                currency: "",
                billableHours: 0,
                hourlyRate: {
                    currency: "",
                    amount: "",
                },
                estimatedRevenue: 0,
                reminders: [],
            },
            loading: false,
            step: 1,
            priorities,
            invoiceReminders,
            favorites: ["no", "yes"],
            supportedCountries,
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                },
                percentage: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "%",
                    suffix: "",
                    precision: 2,
                    masked: false,
                },
            },
            dropdownLoading: false,
            useRateCard: false,
        };
    },

    computed: {
        clients() {
            return this.$store.getters["clients/clients"] || [];
        },

        accountType() {
            return this.$store.getters["auth/profile"]?.accountType;
        },

        appCurrency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        btnCopy() {
            if (this.step === 1) {
                return "Next";
            }

            if (this.step === 2 && this.actionType === "add") {
                return "Create";
            }

            if (this.step === 2 && this.actionType === "edit") {
                return "Save";
            }
            return "Create";
        },

        secondaryBtnCopy() {
            if (this.step === 1) {
                return "Cancel";
            } else {
                return "Go Back";
            }
        },

        isMaxProjectCount() {
            return this.$store.getters["auth/profile"]?.isMaxProjectCount;
        },

        showUpgradeAccountInfo() {
            if (this.accountType === "standard" && this.isMaxProjectCount && this.actionType === "add") {
                return true;
            } else {
                return false;
            }
        },


        rateCards() {
            return this.$store.getters["revenue/rateCards"]?.list;
        },

        estimatedRevenue() {
            if (!this.form.rateCards.length) return 0;

            const total = this.form.rateCards.reduce((previous, current) => previous + current.amount, 0);

            return total;
        },
    },

    watch: {
        projectData: {
            handler(newVal) {
                if (newVal?._id) {
                    this.prefillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },

        stepValue(newVal) {
            this.step = newVal;
        },
    },

    methods: {
        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        prefillForm(val) {
            this.form = {
                ...this.form,
                _id: val?._id,
                title: val?.title,
                status: val?.status,
                deadline: val?.deadline,
                isFavourite: val?.isFavourite ? "yes" : "no",
                tags: val?.tags,
                priority: val?.priority,
                projectType: val?.projectType,
                currency:  val?.currency || this.appCurrency,
                clients: val.clients || [],
                rateCards: val.rateCards || [],
                reminders: val.reminders || [],
            };
        },

        addTag(newTag) {
            this.form.tags.push(newTag);
        },

        addRateCard(item) {
            this.form.rateCards = [ ...this.form.rateCards, item ];
        },

        removeRateCard(item) {
            this.form.rateCards = this.form.rateCards.filter(x => x._id !== item._id);
        },

        resetForm() {
            this.form = {
                _id: "",
                title: "",
                status: "pending",
                deadline: "",
                priority: "",
                projectType: "",
                amount: "",
                currency: "",
                isFavourite: "no",
                tags: [],
                clients: [],
                rateCards: [],
                billableHours: 0,
                // hourlyRate: {
                //     currency: "",
                //     amount: "",
                // },
            };
        },

        onCancel() {
            if (this.actionType === "add") {
                this.resetForm();
            }
            this.$emit("close");
        },

        setSelected(value) {
            this.form.clients = [ value, ...this.form.clients ];
        },

        buildPayload() {
            const payload = {
                title: this.form.title,
                status: this.form.status?.toLowerCase(),
                deadline: this.form.deadline,
                priority: this.form.priority,
                projectType: this.form.projectType?.toLowerCase(),
                estimatedRevenue: this.estimatedRevenue,
                isFavourite: this.form.isFavourite === "no" ? false : true,
                currency: this.form.currency,
                billableHours: Number(this.form.billableHours || ""),
                reminders: this.form.reminders,
                // hourlyRate: {
                //     currency: this.form.hourlyRate.currency,
                //     amount: Number(this.form.hourlyRate.amount),
                // },
            };

            if (this.form._id && this.actionType === "edit") {
                payload._id = this.form._id;
            }

            if (this.actionType === "add") {
                payload.clientIds = this.form.clients.length ? this.form.clients.map(x => x._id) : []; // we need only client ids
                payload.tagIds = this.form.tags.length ? this.form.tags.map(x => x._id) : [];
                payload.rateCardIds = this.form.rateCards.length ? this.form.rateCards.map(x => x._id) : [];
            }

            return payload;
        },

        async createNewProject(payload) {   
            try {
                this.loading = true;

                const resp = await this.$store.dispatch("project/addProject", payload);
                this.$toast.success("New project added");

                this.$emit("complete", { resp, type: "add" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleEditProject(payload) {  
            try {
                this.loading = true;

                this.$store.dispatch("project/updateProject", payload);
                this.$toast.success("New project added");
                this.$emit("complete", { type: "edit" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        secondaryBtnAction() {
            if (this.step === 1) {
                this.onCancel();
            } else {
                this.step = 1;
            }
        },

        async handleSubmit() {
            if (this.step === 1) {
                this.step = 2;
                return;
            }

            const payload = this.buildPayload();

            if (this.actionType === "add") {
                await this.createNewProject(payload);
            } else if (this.actionType === "edit") {
                await this.handleEditProject(payload);
            }
        },

        async handleFetchRateCards(params) {
            try {
                this.dropdownLoading = true;

                const pageQueryObj = {
                    page: 1,
                };

                if (params.title) {
                    pageQueryObj.title = params.title;
                }

                const buildResult = assembleQueryList("rateCards", pageQueryObj);

                await this.$store.dispatch("revenue/fetchRateCards",  { payload: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
            } finally {
                this.dropdownLoading = false;
            }
        },

        setReminders() {
            this.form.reminders = [];

            for (let i = 0; i < this.remindersNames.length; i++) {
                this.form.reminders.push({
                    remindersName: this.remindersNames[i],
                    reminderDate: new Date(),
                });
            } 
        },

        togglePaymentSelection() {
            if (this.useRateCard) {
                this.useRateCard = false;
                this.form.rateCards = [];
            } else {
                this.useRateCard = true;
                this.form.billableHours = 0;
                this.form.estimatedRevenue = 0;
                this.form.hourlyRate.amount = 0;
            }
        },

        upgradeAccount() {
            this.onCancel();
            this.$router.push("/dashboard/settings/billings-and-plans");
        },
    },
};
</script>