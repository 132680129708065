export const projectStatusMap = {
    "in progress": {
        tag: "tag--blue",
        iconName: "hour-glass",
    },
    completed: {
        tag: "tag--green",
        iconName: "earnings",
    },
    blocked: {
        tag: "tag--red",
        iconName: "close",
    },
    pending: {
        tag: "tag--pink",
        iconName: "timer-solid",
    },
    "requires feedback": {
        tag: "tag--grey",
        iconName: "folder-open",
    },
    "to do": {
        tag: "tag--purple-2",
        iconName: "layer",
    },
};

export const projectTypeMap = {
    "paid": {
        tag: "tag--green",
        iconName: "money",
    },
    "not paid": {
        tag: "tag--pink",
        iconName: "balance",
    },
};

export const invoiceStatusMap = {
    draft: {
        tag: "tag--blue",
        iconName: "layer",
    },
    blocked: {
        tag: "tag--purple",
        iconName: "close",
    },
    "fully paid": {
        tag: "tag--green",
        iconName: "money",
    },
    overdue: {
        tag: "tag--red",
        iconName: "alarm",
    },
    issued: {
        tag: "tag--pink",
        iconName: "receipt",
    },
    rejected: {
        tag: "tag--grey",
        iconName: "close",
    },
    "partially paid": {
        tag: "tag--purple-2",
        iconName: "signal",
    },
};

export const clientStatusMap = {
    active: {
        tag: "tag--green",
        iconName: "signal",
    },
    inactive: {
        tag: "tag--grey",
        iconName: "no-signal",
    },
    "in the works": {
        tag: "tag--blue",
        iconName: "map-pin",
    },
    "potential lead": {
        tag: "tag--cornsilk",
        iconName: "user",
    },
    "reached out": {
        tag: "tag--purple",
        iconName: "phone",
    },
    "archived": {
        tag: "tag--pink",
        iconName: "close",
    },
};

export const PROJECT_STATUSES = ["blocked", "completed", "in progress", "to do", "pending", "requires feedback"];

export const INVOICE_STATUSES = ["blocked", "draft", "overdue", "fully paid", "issued", "rejected", "partially paid"];

export const INVOICE_UPDATE_STATUSES = ["blocked", "draft", "due", "fully paid", "issued", "rejected", "partially paid"];

export const projectFilterStatuses = ["completed",  "pending",  "in progress",  "blocked",  "requires feedback",  "to do", "archived"];

export const clientFilterStatuses = ["active", "inactive", "in the works", "potential lead", "reached out", "archived"];

export const invoiceFilterStatuses = ["rejected", "fully paid", "issued", "blocked", "overdue", "draft", "partially paid"];

export const priorities = ["low", "medium", "high", "highest"];

export const taskStatus = [
    {
        name: "Completed",
        value: false,
    },
    {
        name: "Completed",
        value: true,
    },
];

export const CLIENT_STATUSES = ["active", "inactive", "in the works", "potential lead", "reached out"];

// export const computeActivityItems = {
//     "update_doc": {
//         title
//         list: {
//             "users": {
//                 title: "You made changes"
//             }
//         }
//     }
// }