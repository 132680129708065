<template>
    <div class="invoice__wrap" :class="{ 'disabled': isPageDisabled }">
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadErr">
                <EmptyPage state="error">
                    <template #button>
                        <base-button label="Reload" size="sm" @submit="handleFetchInvoice" />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else-if="unableToLoadPage">
                <EmptyPage state="error">
                    <template #button>
                        <base-button label="Go back to invoices page" size="sm" @submit="backToInvoicesPage" />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="mt--15 pb--35">
                    <div class="invoice__details__row">
                        <div class="invoice__details__row__left">
                            <div class="invoice__details__header">  
                                <div class="row__item flex align-items-center pb--0">
                                    <div class="text--bold">
                                        Invoice #{{ invoice.invoiceNo || 'N/A' }}
                                    </div>
                                    <Status type="invoice" :value="invoice.status" class="ml--10" />
                                </div>
                                <div class="relative flex align-items-center">
                                    <div v-if="!['fully paid', 'draft'].includes(invoice.status)" class="mr--5 relative pb--0">
                                        <base-button 
                                            label="Update status" btn-style="primary" size="sm" 
                                            @submit="updateStatusModal = true" 
                                        />
                                    </div>
        
                                    <div class="dropdown">
                                        <div id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="invoice__details__menu">
                                                <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                            </div>
                                        </div>
        
                                        <ul class="dropdown-menu" aria-labelledby="invoiceDetailsDropDown">
                                            <li v-if="invoice.status === 'draft'" class="dropdown-item pointer" @click="goToEditPage">
                                                <p class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="edit" fill="#667085" width="16px" />
                                                    <span class="ml--5 text--xs">Edit Invoice</span>
                                                </p>
                                            </li>
        
                                            <li v-else class="dropdown-item pointer" @click="handleFetchInvoice(true)">
                                                <p class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="download" fill="#667085" width="16px" />
                                                    <span class="ml--5 text--sm">Download as PDF</span>
                                                </p>
                                            </li>
        
                                            <li v-if="!['draft', 'failed'].includes(invoice.status)" class="dropdown-item pointer" @click="handleDuplicateInvoice">
                                                <p class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="duplicate" fill="#667085" width="16px" />
                                                    <span class="ml--5 text--sm">Duplicate</span>
                                                </p>
                                            </li>
        
                                            <li class="dropdown-item pointer" @click="showDeleteModal = true">
                                                <p class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="delete" fill="#667085" width="16px" />
                                                    <span class="ml--5 text--sm">Delete</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item">
                                <div class="invoice__details__group">
                                    <div class="mr--10">
                                        <IconSvg name="money" width="1rem" fill="#64748B" />
                                    </div>
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Currency
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ invoice.currency }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div class="mr--10">
                                        <IconSvg name="purchase-tag" width="1rem" fill="#64748B" />
                                    </div>
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Amount
                                        </p>
                                        <p class="invoice__details--text text--sm">
                                            {{ $format_number(invoice.totalAmount) }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="invoice.status !== 'draft'" class="invoice__details__group">
                                    <div class="mr--10">
                                        <IconSvg name="receipt" width="1rem" fill="#64748B" />
                                    </div>
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Date Issued
                                        </p>
                                        <p class="invoice__details--text text--sm">
                                            {{ $format_date(invoice.dueIssued) }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="!['draft', 'fully paid'].includes(invoice.status)" class="invoice__details__group">
                                    <div class="mr--10">
                                        <IconSvg name="alert" width="1rem" fill="#64748B" />
                                    </div>
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Due date
                                        </p>
                                        <p class="invoice__details--text text--sm">
                                            {{ $format_date(invoice.dueDate) }}
                                        </p>
                                    </div>                                    
                                </div>

                                <div class="invoice__details__group">
                                    <div class="mr--10">
                                        <IconSvg name="save" width="1rem" fill="#64748B" />
                                    </div>
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Date Created
                                        </p>
                                        <p class="invoice__details--text text--sm">
                                            {{ $format_date(invoice.createdAt) }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="!['draft', 'fully paid'].includes(invoice.status)" class="invoice__details__group">
                                    <div class="mr--10">
                                        <IconSvg name="hour-glass" width="1rem" fill="#64748B" />
                                    </div>
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Amount Due
                                        </p>
                                        <p class="invoice__details--text text--sm">
                                            {{ $format_date(invoice.dueDate) }}
                                        </p>
                                    </div>                                    
                                </div>
                            </div>

                            <div class="invoice__details__item">
                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Memo
                                        </p>
                                        <p class="invoice__details--text text--sm">
                                            {{ invoice.memo || '-' }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="mt--20">
                                <div class="col-12 mb--0">
                                    <p class="text--bold text--color-dark">
                                        Invoice item information
                                    </p>
                                </div>

                                <div class="col-12">
                                    <div class="invoice__item mt--5">
                                        <table class="table table-light mt--20">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="pl--0">
                                                        #
                                                    </th>
                                                    <th scope="col">
                                                        Description
                                                    </th>
                                                    <th scope="col">
                                                        Quantity
                                                    </th>
                                                    <th scope="col">
                                                        Price
                                                    </th>
                                                    <th scope="col">
                                                        Unit
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in invoice.meta.items" :key="item._id">
                                                <tr>
                                                    <td scope="row">
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>{{ item.itemName || '-' }}</td>
                                                    <td>{{ item.itemQuantity || 0 }}</td>
                                                    <td>{{ $country.getSymbol(invoice.currency) }}{{ $format_number(item.itemPrice) || 0 }}</td>
                                                    <td>{{ $country.getSymbol(invoice.currency) }}{{ $format_number(item.itemUnit) || 0 }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt--15">
                                <div class="mb--5">
                                    <p class="text--bold text--color-dark mt--10">
                                        Others
                                    </p>
                                </div>

                                <div class="pb--15">
                                    <div class="mt--10 justify-content-between flex align-items-center">
                                        <div class="invoice__compile--label">
                                            Subtotal:
                                        </div>
                                        <div class="invoice__compile--value text--medium text--upper">
                                            {{ $country.getSymbol(invoice.currency) }} {{ $format_number(computedSubTotal) }}
                                        </div>
                                    </div>

                                    <div class="mt--10 justify-content-between flex align-items-center">
                                        <div class="invoice__compile--label">
                                            Tax:
                                        </div>
                                        <div class="mt--10 justify-content-between flex align-items-center text--sm text--medium text--upper">
                                            {{ $country.getSymbol(invoice.currency) }} {{ $format_number(computedTaxes) }}
                                        </div>
                                    </div>
                                    
                                    <div class=" mt--10 flex align-items-center justify-content-between">
                                        <div class="invoice__details--text text--semi--bold">
                                            Total
                                        </div>
                                        <div class="invoice__details--text">
                                            <span class="text--semi--bold text--upper">{{ $country.getSymbol(invoice.currency) }} {{ $format_number(invoice.totalAmount) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__divider" />

                            <div class="">
                                <p class="text--bold text--color-dark mt--20 mb--15">
                                    Payment information
                                </p>
                        
                                <div class="col-12">
                                    <div class="w--100">
                                        <div class="invoice__details__group mb--20">
                                            <div>
                                                <p class="text--color-gray-100 text--sm text--medium">
                                                    Billing Method
                                                </p>
                                                <p class="invoice__details--text text--sm">
                                                    {{ invoice.billingMethod || '-' }}
                                                </p>
                                            </div>                                    
                                        </div>
                                        <div class="flex justify-content-between">
                                            <div class="invoice__client--bio mb--0">
                                                <p class="title mb--5">
                                                    Tax
                                                </p>
                                                <template v-if="invoice.meta.tax && invoice.meta.tax.length">
                                                    <ul>
                                                        <li v-for="(item, index) in invoice.meta.tax" :key="item._id" class="flex align-items-center mb--5">
                                                            <div class="mb--5 text--sm" data-v-b8d1ea9a=""> 
                                                                <p class="text--upper text--semi--bold">
                                                                    Tax item {{ index + 1 }}
                                                                </p>
                                                                <div class="text--sm" data-v-b8d1ea9a="">
                                                                    <span class="sub-title" data-v-b8d1ea9a="">{{ item.taxName }}, {{ item.taxType }},  {{ item.taxValue }}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </template>
                                                <template v-else>
                                                    <div>-</div>
                                                </template>
                                            </div>

                                            <div v-if="!invoice.status !== 'draft'" class="invoice__row__left mt--30">
                                                <div class="invoice__client--bio">
                                                    <p class="title mb--5 text--sm">
                                                        Payment timeline
                                                    </p>
                                                    <template v-if="invoice.paymentTimeline && invoice.paymentTimeline.length">
                                                        <Timeline :items="invoice.paymentTimeline">
                                                            <template #item="item">
                                                                <p class="text--xs text--upper text--color-gray-100 text--semi--bold">
                                                                    {{ invoice.currency }} {{ $format_number(item.amount) }}
                                                                </p>
                                                                <p class="text--xs text--color-normal">
                                                                    {{ $format_date(item.dateAdded) }}
                                                                </p>
                                                            </template>
                                                        </Timeline>
                                                    </template>
                                                    <template v-else>
                                                        <div>-</div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="invoice__details__row__right">
                            <div class="invoice__details__divider pb--15">
                                <p class="text--bold text--color-dark mt--10 mb--10">
                                    Client information
                                </p>
                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Client Name
                                    </p>
                                    <p class="sub-title invoice__details--text">
                                        {{ client.name || '-' }}
                                    </p>
                                </div>
                                
                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Phone Number
                                    </p>
                                    <p class="sub-title invoice__details--text">
                                        {{ client.phoneNumber || "-" }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Email
                                    </p>
                                    <p class="sub-title invoice__details--text">
                                        {{ client.email || '-' }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Status
                                    </p>
                                    <p class="sub-title text--capitalize invoice__details--text">
                                        {{ client.status || '-' }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Additional emails
                                    </p>
                                    <p class="sub-title invoice__details--text">
                                        {{ client.ccEmails || '-' }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Country
                                    </p>
                                    <p class="sub-title invoice__details--text">
                                        {{ client.country || '-' }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Industry
                                    </p>
                                    <p class="sub-title invoice__details--text text--capitalize">
                                        {{ client.industry || '-' }}
                                    </p>
                                </div>
                            </div>

                            <div class="pt--15">
                                <p class="text--bold text--color-dark mt--10 mb--10">
                                    Project information
                                </p>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Project title
                                    </p>
                                    <p class="sub-title text--capitalize invoice__details--text">
                                        {{ project.title|| '-' }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Project status
                                    </p>
                                    <p class="sub-title text--capitalize invoice__details--text">
                                        {{ project.status || '-' }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Deadline
                                    </p>
                                    <p class="sub-title invoice__details--text">
                                        {{ $format_date(project.deadline) || '-' }}
                                    </p>
                                </div>

                                <div class="invoice__client--bio">
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Estimated time of delivery
                                    </p>
                                    <p class="sub-title invoice__details--text">
                                        {{ $format_date(project.timeEstimate) || '-' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            type="invoice"
            :show-modal="showDeleteModal"
            :loading="deleteLoading"
            @cancel="toggleDelete" 
            @delete="handleDeleteInvoice"
        />

        <MainModal 
            :show-modal="updateStatusModal" 
            is-close-button is-show-header is-footer
            size="sm" title="Update status" @close="updateStatusModal = false" 
        >
            <template #body>
                <div class="status-body">
                    <form class="mb--10" @submit.prevent="">
                        <div v-for="item in INVOICE_UPDATE_STATUSES" :key="item" class="form-check text--capitalize">
                            <input
                                id="status" v-model="updateForm.status" class="form-check-input" type="radio"
                                name="inlineRadioOptions" :value="$str_to_lowercase(item)"
                            >
                            <label class="form-check-label text--sm" for="status">{{ item }}</label>
                        </div>

                        <template v-if="updateForm.status === 'partially paid'">
                            <Alert type="primary" classes="text--sm mt--20">
                                <template #text>
                                    <p class="text--xs">
                                        Selecting a partially paid status requires entering the amount paid in order to keep track of the payment
                                    </p>
                                </template>
                            </Alert>
    
                            <div class="">
                                <FormLabel title="Amount paid" html-for="hourlyRate" info-type="required" />
                                <money
                                    v-model="updateForm.amountPaid" v-bind="config.number"
                                    class="form-control form-control-sm"
                                    spellcheck="false" @keydown.native="preventKeys"
                                />
                            </div>
                        </template>
                    </form>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        class-names="mr--5" btn-style="secondary"
                        label="Cancel" size="sm" :disabled="updateLoading"
                        @submit="closeStatusUpdateModal"
                    />

                    <base-button 
                        :loading="updateLoading" :disabled="updateLoading || !updateForm.status" 
                        type="submit" size="sm" label="Update" @submit="handleStatusUpdate"
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import { convertStringAmountToInt } from "@/utils/helpers";
import { invoiceStatusMap, INVOICE_UPDATE_STATUSES } from "@/utils/enums";
import {
    ConfirmDeletionTwo,
    PageSpinner,
    Status,
    BaseButton,
    EmptyPage,
    MainModal,
    IconSvg,
    FormLabel,
    Alert,
    Timeline,
} from "@/components/ui/index";

export default {
    name: "InvoiceDetails",
    
    components: {
        ConfirmDeletionTwo,
        PageSpinner,
        Status,
        BaseButton,
        EmptyPage,
        MainModal,
        IconSvg,
        FormLabel,
        Alert,
        Timeline,
    },

    data() {
        return {
            invoiceStatusMap,
            INVOICE_UPDATE_STATUSES,
            pageLoading: false,
            pageLoadErr: false,
            isPageDisabled: false,
            deleteLoading: false,
            updateLoading: false,
            showEditInvoiceView: false,
            invoice: {
                meta: {
                    items: [],
                    tax: [],
                    reminders: [],
                },
            },
            client: {},
            project: {},
            showDeleteModal: false,
            updateStatusModal: false,
            updateForm: {
                status: "",
                amountPaid: 0,
            },
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                },
            },
            unableToLoadPage: false,
        };
    },

    computed: {
        computedSubTotal() {
            if (this.invoice.meta?.items.length > 0) {
                const transformArr = this.invoice.meta?.items.map(item => item.itemPrice * item.itemQuantity);
                const sumItemsArr = transformArr.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                return sumItemsArr;
            } else {
                return 0;
            }
        },

        computedTaxes() {
            let taxCount = 0;

            if (this.invoice?.meta.tax.length > 0) {                
                for (var i = 0; i < this.invoice?.meta.tax.length; i++) {
                    if (this.invoice?.meta.tax[i].taxValue) {
                        taxCount = this.invoice?.meta.tax[i].taxValue + taxCount;
                    }
                }
            }
            return taxCount;
        },
    },

    watch: {
        "invoice.status": {
            handler(val) {
                this.updateForm.status = val;
            },
        },
    },

    async mounted() {
        await this.handleFetchInvoice();
    },

    methods: {
        convertStringAmountToInt,

        backToInvoicesPage() {
            this.$router.push("/dashboard/invoices");
        },

        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },
        
        async handleDuplicateInvoice() {
            try {
                this.isPageDisabled = true;
    
                const payload = {
                    currency: this.invoice.currency,
                    clientId: this.client?._id,
                    projectId: this.project?._id,
                    status: this.invoice.status,
                    title: this.invoice.title,
                    dueDate: this.invoice.dueDate,
                    datePaid: this.invoice.datePaid,
                    memo: this.invoice.memo,
                    meta: {
                        ...this.invoice.meta,
                        items: this.invoice.meta.items.map(x => {
                            return {
                                itemName: x.itemName, 
                                itemPrice: x.itemPrice,
                                itemQuantity: x.itemQuantity,
                                itemUnit: x.itemUnit,
                            };
                        }),
                        reminders: this.invoice.meta.reminders.map(x => {
                            return {
                                remindersName: x.remindersName,
					            reminderDate: x.reminderDate,
                            };
                        }),
                        tax: this.invoice.meta.tax.map(x => {
                            return {
                                taxName: x.taxName,
                                taxType: x.taxType,
                                taxValue: x.taxValue,
                            };
                        }),
                    },
                    status: this.invoice.status,
                    totalAmount: this.invoice.totalAmount,
                    billingMethod: this.invoice.billingMethod || "manual_account",
                };
    
                const response = await this.$store.dispatch("invoice/duplicateInvoice", { payload, client: this.client });
                
                this.$toast.success("Duplicated invoice");
                this.$router.push({ name: "invoice-details-view", params: { id: response?._id } });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.isPageDisabled = false;
            };
        },

        async handleDeleteInvoice() {
            try {
                const id = this.$route.params.id;
                
                if (!id) return;
    
                this.deleteLoading = true;
                
                await this.$store.dispatch("invoice/deleteInvoices", [id]);

                this.$toast.success("Invoice deleted");

                $("#deleteInvoice").modal("hide");

                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            };
        },

        async handleStatusUpdate() {
            try {                
                this.updateLoading = true;

                const payload = {
                    _id: this.invoice._id,
                    clientId: this.client._id,
                    projectId: this.project._id,
                    status: this.updateForm.status,
                    amountPaid: Number(this.updateForm.amountPaid || 0),
                    totalAmount: this.invoice.totalAmount,
                    invoiceNo: this.invoice.invoiceNo,
			        currency: this.invoice.currency,
                };
                
                const res = await this.$store.dispatch("invoice/updateInvoice", payload);

                this.invoice.status = this.updateForm.status;
                this.updateStatusModal = false;
                this.$toast.success("Invoice status updated");

                this.updateForm = {
                    status: "",
                    amountPaid: 0,
                };

                this.invoice = {
                    ...this.invoice,
                    ...res,
                };
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateLoading = false;
            };
        },

        async handleFetchInvoice(download) {
            try {
                this.pageLoadErr = false;
                const id = this.$route.params.id;

                if (id === "undefined" || !id) {
                    this.unableToLoadPage = true;
                    return;
                } else {
                    this.unableToLoadPage = false;
                }
                
                if (!download) {
                    this.pageLoading = true;

                    const res = await this.$store.dispatch("invoice/getInvoice", { id });
    
                    this.invoice = res.data;
                    this.client = res?.data.client;
                    this.project = res?.data.project;
                } else {
                    const resp = await this.$store.dispatch("invoice/getInvoice", { 
                        id, 
                        params: { download: true },
                        config: {
                            responseType: "arraybuffer",
                        },
                    });

                    console.log({ handleFetchInvoice: resp });

                    const fileName = `Invoice_${this.invoice.invoiceNo}.pdf`;
                    const blob = new Blob([resp], { type: "application/pdf" });

                    console.log({ fileName, blob });

                    // Check if the blob is empty
                    if (blob.size === 0) {
                        throw new Error("Received an empty PDF");
                    }

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute("download", fileName); // or the filename you want
                    
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    
                    window.URL.revokeObjectURL(link.href);
                    this.$toast.success("Invoice downloaded");
                }
            } catch (err) {
                const errMsg = err?.response?.data?.message || err?.message;

                console.log({ errMsg });
                this.pageLoadErr = true;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        goToEditPage() {
            this.$router.push(`/dashboard/invoices/${this.$route.params.id}/edit`);
        },

        closeStatusUpdateModal() {
            this.updateForm = {
                status: "",
                totalAmount: 0,
            };
            this.updateStatusModal = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .row.hidden-xs {
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 2rem;
    }

    .form__row__left {
        max-width: 500px;
    }

    .invoice__single--btns {
        .row__item {
            padding-right: 0px !important;
        }
    }

    .status-body {
        padding: 10px 20px;
    }
</style>