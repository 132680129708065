<template>
    <div class="report__item__wrap">
        <div v-if="reportItemType === 'clients'">
            <ClientReports />
        </div>

        <div v-else-if="reportItemType === 'projects'">
            <ProjectReports />
        </div>

        <div v-else-if="reportItemType === 'invoices'">
            <InvoiceReports />
        </div>
    </div>
</template>

<script>
import { EmptyPage } from "@/components/ui/index";
import ClientReports from "./ClientReports.vue";
import ProjectReports from "./ProjectReports.vue";
import InvoiceReports from "./InvoiceReports.vue";

export default {
    name: "ReportItem",

    components: {
        EmptyPage,
        ClientReports,
        ProjectReports,
        InvoiceReports,
    },

    computed: {
        reportItemType() {
            return this.$store.getters["report/reportItemType"];
        },
    },
};
</script>