<template>
    <div class="page__overview--loader">
        <div class="flex justify-content-center align-items-center mt--40 mb--45">
            <div class="spinner-border" role="status" style="color: #289CA9 !important">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageOverlayLoader",

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.page__overview--loader {
    position: absolute;
    backdrop-filter: blur(1px);
    webkit-backdrop-filter: blur(1px);
    width: 100%;
    top: 0;
    height: 100%;
}
</style>