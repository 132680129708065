<template>
    <div class="card" :class="[cardClasses, isShadow ? 'card--shadow' : '']" v-bind="$attrs">
        <div v-if="headerTitle" class="card-header text--color-gray-300" :class="[headerClasses]">
            {{ headerTitle }}
        </div>
        <div class="card-body" :class="[cardBodyClasses]">
            <slot name="card-body" />
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseCard",

    props: {
        headerTitle: {
            type: String,
            default: "",
        },
        
        headerClasses: {
            type: String,
            default: "",
        },
        
        cardClasses: {
            type: String,
            default: "",
        },

        cardBodyClasses: {
            type: String,
            default: "",
        },

        isShadow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>