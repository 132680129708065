<template>
    <div class="content" :style="{ 'padding-left': isCollapse ? '30px' : '220px', transition: 'all .3s ease-out' }">
        <div class="header__dashboard">
            <div class="header__dashboard__left">
                <div class="collapse--content" />
                <div class="title__wrap">
                    <nav class="breadcrumb-nav" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li 
                                v-for="(path, index) in paths" 
                                :key="path" 
                                class="breadcrumb-item" 
                                :class="[computeBreadcrumbs(index) === 'last' ? 'active' : '' ]" 
                                :aria-current="computeBreadcrumbs(index) === 'last' ? 'page' : ''"
                            >
                                <span 
                                    class="breadcrumb__item breadcrumb-item"
                                    :class="[computeBreadcrumbs(index)]"
                                    @click="goBackToMainRoute(index)"
                                >
                                    {{ path }}
                                </span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div class="header__dashboard__right">
                <div class="" :class="{ 'disabled': currencyLoading }">
                    <div class="dropdown">
                        <button class="btn btn--outline__secondary btn--sm dropdown-toggle text--upper" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ currency }}
                        </button>
                        <ul class="dropdown-menu">
                            <li 
                                v-for="item in supportedCountries" 
                                :key="item" 
                                class="dropdown-item text--sm pointer"
                                :class="{ 'selected': currency === item.currency }" 
                                @click="selectCurrency(item)"
                            >
                                <span>{{ item }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="pointer" data-bs-toggle="offcanvas" href="#activityDrawer" aria-controls="activityDrawer">
                    <icon-svg :name="'bell'" :fill="'#667085'" width="24" height="24" />
                </div>

                <div class="dropdown">
                    <div id="resourcesDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                        <icon-svg :name="'grid-two'" :fill="'#667085'" width="24px" height="24px" />
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="resourcesDropDown">
                        <li v-for="item in headerResources" :key="item.name" class="dropdown-item header__dashboard__dropdown--item">
                            <a class="flex align-items-center text--color-gray-500">
                                <icon-svg :name="item.iconName" fill="#667085" width="16px" />
                                <span class="ml--5 text--xs">{{ item.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="dropdown">
                    <div id="resourcesDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                        <custom-avatar font-size="8px" :name="userFullName" />
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="resourcesDropDown">
                        <li class="dropdown-item sidebar__item__user__email">
                            <span class="text--xs">{{ userEmail }}</span>
                        </li>
                        <li class="dropdown-item header__dashboard__dropdown--item" @click="signOutOfApp">
                            <a class="flex align-items-center text--color-gray-500">
                                <IconSvg :name="'bell'" :fill="'#667085'" width="16px" height="16px" />
                                <span class="ml--5 text--xs">Log out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="pt--15 content__body" :style="collapse ? bodyStylesCollapse : bodyStyles">
            <div class="content__view">
                <router-view :key="$route.fullPath" />
            </div>
        </div>

        <base-drawer :drawer-id="'activityDrawer'" :aria-id="'ariaActivityDrawer'" :title="'Activities'" :header-class="'activity-header'">
            <template #suffix-header>
                <div class="form-check form-switch mr--10 mb--0">
                    <input
                        id="flexSwitchCheckDefault" class="form-check-input" type="checkbox" role="switch"
                        value="unreadActivitiesOnly"
                    >
                    <label class="form-check-label text--xs mb--0 pb--0" for="flexSwitchCheckDefault">Show unread</label>
                </div>
            </template>

            <template #body>
                <template v-if="activities.length">
                    <ul v-for="(activity, index) in activities" :key="index">
                        <li class="list-group-item">
                            <div class="flex align-items-center text--color-title">
                                <span class="text--semi--bold">You</span>
                                <span class="text--semi--bold">{{ activity.text }}</span>
                                <span v-if="activity.link" class="text--semi--bold">You</span>
                            </div>
                            
                            <div class="flex w--100 align-items-center">
                                <p class="">
                                    {{ activity.createdAt }}
                                </p>
                                <p class="">
                                    at
                                </p>
                                <small>3 days ago</small>
                            </div>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <EmptyPage 
                        state="empty" title="Oops! You've got nothing going on yet" 
                        icon-name="activities" subtitle="You'll see your notifications once you create projects, clients or invoices."
                    />
                </template>
            </template>
        </base-drawer>
    </div>
</template>

<script>
import { BaseDrawer,EmptyPage, IconSvg, CustomAvatar } from "@/components/ui/index";
import { supportedCountries } from "@/plugins/countries";
import { headerResources } from "@/utils/dummy";

export default {
    name: "DashboardContent",

    components: {
        IconSvg,
        BaseDrawer,
        EmptyPage,
        CustomAvatar,
    },

    props: {
        isCollapse: {
            type: Boolean,
            default: false,
        },
        showSettingsSidebar: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            collapse: this.isCollapse,
            supportedCountries,
            bodyStyles: {
                maxWidth: "97%",
                marginLeft: "auto",
                marginRight: "auto",
                transition: "all .3s ease-out",
            },
            bodyStylesCollapse: {
                maxWidth: "none",
                marginLeft: "0px",
                marginRight: "0px",
                transition: "all .3s ease-out",
            },
            activities: [],
            unreadActivitiesOnly: false,
            currencyLoading: false,
            headerResources,
        };
    },

    computed: {
        paths() {
            return this.$store.getters["app/paths"] || [];
        },

        profile() {
            return this.$store.getters["auth/profile"];
        },

        showBackArrowBtn() {
            return this.$route.params.id || this.$route.name === "create-invoice-view" ? true : false;
        },

        email() {
            return this.profile?.email;
        },

        currency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        userFullName() {
            return this.profile?.fullName || "";
        },

        userEmail() {
            return this.profile?.email || "";
        },
    },

    methods: {
        async signOutOfApp() {
            try {
                this.$store.dispatch("auth/setLogoutLoading", true);
                await this.$store.dispatch("auth/signOut");
            } catch (error) {
                
            } finally {
                this.$store.dispatch("auth/setLogoutLoading", false);
            }
        },

        async selectCurrency(val) {
            try {
                this.currencyLoading = true;
                await this.$store.dispatch("app/updateGeneralSettings", {
                    type: "general",
                    settings: {
                        currency: val.currency,
                    },
                });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.currencyLoading = false;
            }
        },

        goBackToMainRoute(index) {
            if (index && !this.paths[index]) return;
            if (index && index === this.paths.length - 1) return;

            if (index === 0) {
                this.$router.push(`/dashboard/${this.paths[0]}`);
                this.$store.dispatch("app/setCurrentRouteTwo", `/dashboard/${this.paths[0]}`);
            } else if (index === 1) {
                this.$router.push(`/dashboard/${this.paths[0]}/${this.paths[1]}`);
                this.$store.dispatch("app/setCurrentRouteTwo", `/dashboard/${this.paths[0]}/${this.paths[1]}`);
            } else {
                return;
            }
        },

        computeBreadcrumbs(idx) {
            if (idx === this.paths.length - 1) {
                if (this.paths.length === 1) {
                    return "only";
                } else {
                    return "last";
                }
            } else if (idx === 0) {
                return "head";
            } else {
                return "sub";
            }
        },
    },
};
</script>