<template>
    <div class="clients__page--view mb--35" :class="{ 'relative disabled': pageLoading }">
        <template v-if="fetchInvoiceLoading">
            <PageSpinner />
        </template>

        <template v-else-if="fetchInvoiceErr">
            <EmptyPage state="error">
                <template #button>
                    <base-button 
                        label="Reload" is-prefix-icon  
                        icon-name="reload" icon-width="16px"
                        size="sm"
                        @submit="handleFetchInvoice"
                    />
                </template> 
            </EmptyPage>
        </template>

        <template v-else>
            <div class="main__content">
                <div class="pb--5">
                    <div class="row hidden-xs mb--10 header">  
                        <div class="row__left">
                            <div class="row__item pb--0">
                                <div class="page-title__text text--bold">
                                    <template v-if="invoiceNo">
                                        INVOICE #{{ invoiceNo }}
                                    </template>
                                    <template v-else>
                                        Create Invoice
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="row__right pr--0 pl--0">
                            <BaseButton
                                label="Preview Invoice" size="sm"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                btn-style="outline" inner-style="primary" class-names="mr--5"
                            />
                            <BaseButton
                                label="Save as draft" size="sm" btn-style="secondary" class-names="mr--5"
                                :disabled="isSaveBtnDisabled || pageLoading"
                                @submit="initiateSubmitAction('save')"  
                            />
                            <BaseButton
                                label="Send Invoice" size="sm" class-names="mr--5"
                                :disabled="isSaveBtnDisabled || pageLoading"
                                @submit="initiateSubmitAction('send')"  
                            />
                        </div>
                    </div>

                    <div class="row invoice__section--item mt--10">
                        <div class="col-12 invoice__header">
                            <span class="title">Client information 
                                <span class="text--color-warning text--xs">
                                    *
                                </span>
                            </span>
                            <span v-show="client && client.name" class="text--bold text--xs text--color-primary ml--5 pointer" @click="toggleClientView()">
                                (Switch view)
                            </span>
                            <span v-if="!showClientInfoView">
                                <Base-Button 
                                    :class-names="'text--xs btn--ghost__primary'" 
                                    :label="'+ Add new client'"
                                    size="sm" 
                                    btn-style="ghost"
                                    @submit="toggleModalActionType('client', 'add')" 
                                />
                            </span>
                        </div>

                        <div class="col-6">
                            <div class="invoice__row invoice__item">
                                <div class="invoice__details--item m-w-100">
                                    <div class="flex align-items-center w--100">
                                        <div class="flex-2">
                                            <template v-if="!showClientInfoView">
                                                <SearchClientInput 
                                                    v-model:selection="selectedClient"
                                                    :disabled="invoice.status !== 'draft'" 
                                                    placeholder="Select an existing client" 
                                                    :list="clientList" :loading="searchClientsLoading"
                                                    list-type="client" index-value="name"
                                                    @search="handleSearchClients"
                                                    @select="setClient"
                                                />
                                            </template>
                                            <template v-else>
                                                <div class="w--100 mr--35">
                                                    <div class="invoice__client__text">
                                                        <span>Name: </span>
                                                        <span class="text--capitalize">{{ client.name || 'N/A' }}</span>
                                                    </div>
                                                    <div class="invoice__client__text">
                                                        <span>Email address: </span>
                                                        <span>{{ client.email || 'N/A' }}</span>
                                                    </div>
                                                    <div class="invoice__client__text">
                                                        <span>Phone number: </span>
                                                        <span>{{ client.phoneNumber || 'N/A' }}</span>
                                                    </div>
                                                    <div class="invoice__client__text">
                                                        <span>Status: </span>
                                                        <span class="text--capitalize">{{ client.status || 'N/A' }}</span>
                                                    </div>
                                                    <div class="invoice__client__text">
                                                        <span>Industry: </span>
                                                        <span class="text--capitalize">{{ client.industry || 'N/A' }}</span>
                                                    </div>                                                    
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
    
                                <div v-if="showMultipleEmailsOption" class="col-12 mt--10">
                                    <div class="invoice__details--item mt--10">
                                        <div class="flex align-items-center mt--10">
                                            <div style="min-width: 400px;">
                                                <div v-if="showMultipleEmailsField" class="link text--xs" @click="toggleOtherEmail = false">
                                                    - Use only one email address
                                                </div>
                                                <div v-else class="link text--xs" @click="toggleOtherEmail = true">
                                                    + Mail to more than one email address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- other emails -->
                    <div v-if="showMultipleEmailsField" class="row invoice__section--item pl--0 mb--0">
                        <div class="col-12 invoice__header mb--5">
                            <p class="text--bold text--color-dark">
                                Send to other emails   
                            </p>
                        </div>
                        <div class="col-12">
                            <div class="invoice__row invoice__item">
                                <div class="invoice__details--item m-w-100">
                                    <input-multiple-emails
                                        placeholder="enter a client's email"
                                        :dropdown-fields="otherclientsSearched" 
                                        :limit="3" :selected="invoice.meta.ccEmails"
                                        @update="modifyEmails"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row invoice__section--item">
                        <div class="col-12 invoice__header">
                            <span class="title">Project information  <span class="text--color-warning text--xs">
                                *
                            </span></span>
                            <span v-show="project.title" class="text--bold text--xs text--color-primary ml--5 pointer" @click="toggleProjectView()">
                                (Switch view)
                            </span>
                            <span v-if="!showProjectInfoView">
                                <Base-Button 
                                    class-names="text--xs" 
                                    label="+ Add new project"
                                    size="sm" btn-style="ghost" inner-style="primary"
                                    @submit="toggleModalActionType('project', 'add')" 
                                />
                            </span>
                        </div>

                        <div class="col-6 mb--5 flex align-items-center">
                            <div class="invoice__row invoice__item">
                                <div class="invoice__details--item">
                                    <div class="flex align-items-center w--100">
                                        <div class="flex-2">
                                            <template v-if="!showProjectInfoView">
                                                <SearchClientInput 
                                                    v-model:selection="selectedProject"
                                                    :disabled="invoice.status !== 'draft'" 
                                                    placeholder="Select an existing project" 
                                                    :list="projectList" :loading="fetchProjectsLoading"
                                                    list-type="project" index-value="title"
                                                    @search="handleSearchProjects"
                                                    @select="setProject"
                                                />
                                            </template>
                                            <template v-else>
                                                <div class="mr--35">
                                                    <p class="invoice__client__text">
                                                        Title: <span class="text--color-normal text--capitalize">{{ project.title || '-' }}</span>
                                                    </p>
                                                    <p class="invoice__client__text">
                                                        Status: <span class="text--color-normal text--capitalize">{{ project.status || '-' }}</span>
                                                    </p>
                                                    <p class="invoice__client__text">
                                                        Deadline: <span class="text--color-normal">{{ $format_date(project.deadline) || '-' }}</span>
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row invoice__section--item">
                        <div class="col-12 invoice__header">
                            <span class="title">
                                Payment information 
                                <span class="text--color-warning text--xs">
                                    *
                                </span>
                            </span>
                        </div>

                        <div class="col-12">
                            <div class="row invoice__section--item">
                                <p class="title mb--10">
                                    Currency
                                    <span class="text--color-warning text--xs">
                                        *
                                    </span>
                                </p>
                                <div class="col-6">
                                    <div class="invoice__row invoice__item">
                                        <div class="invoice__details--item">
                                            <select v-model="invoice.currency" name="currency" class="form-select form-select-sm text--upper" @change="handleFormFieldsChange">
                                                <option v-for="currency in currencies" :key="currency" :value="currency.toLowerCase()">
                                                    {{ currency }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt--30">
                                <div class="flex align-items-center">
                                    <p class="title">
                                        Billing method
                                    </p>
                                    <span>
                                        <Base-Button 
                                            class-names="'text--xs btn--ghost__primary" 
                                            label="(Choose a billing method)"
                                            size="sm" btn-style="ghost" @submit="showPaymentInfoModal = true" 
                                        />
                                    </span>
                                </div>

                                <template v-if="invoice.billingMethod === 'personal_account'">
                                    <div class="mt--10">
                                        <span class="text--color-gray-100 text--sm text--medium">
                                            Bank Name:
                                        </span>
                                        <span class="text--upper invoice__detail text--sm">
                                            {{ invoice.accountDetails.bankName }}
                                        </span>
                                    </div>
                                    <div class="mt--5">
                                        <span class="text--color-gray-100 text--sm text--medium">
                                            Account Name:
                                        </span>
                                        <span class="text--upper invoice__detail text--sm">
                                            {{ invoice.accountDetails.accountName }}
                                        </span>
                                    </div>
                                    <div class="mt--5">
                                        <span class="text--color-gray-100 text--sm text--medium">
                                            Account Number:
                                        </span>
                                        <span class="text--upper invoice__detail text--sm">
                                            {{ invoice.accountDetails.accountNumber }}
                                        </span>
                                    </div>
                                    <div class="mt--5">
                                        <span class="text--color-gray-100 text--sm text--medium">
                                            Sort Code:
                                        </span>
                                        <span class="text--upper invoice__detail text--sm">
                                            {{ invoice.accountDetails.sortCode || 'N/A' }}
                                        </span>
                                    </div>
                                    <div class="mt--5">
                                        <span class="text--color-gray-100 text--sm text--medium">
                                            IBAN:
                                        </span>
                                        <span class="text--upper invoice__detail text--sm">
                                            {{ invoice.accountDetails.iban || 'N/A' }}
                                        </span>
                                    </div>
                                </template>

                                <template v-else-if="invoice.billingMethod === 'external'">
                                    <p class="text--sm mt--10 text--color-normal">
                                        Use a third-party service. Available services are Stripe, PayPal, Paystack and Flutterwave.
                                    </p>
                                </template>
                                <template v-else>
                                    <p class="text--color-normal mt--10  text--sm">
                                        -
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="row invoice__row block">
                        <div v-if="showAddItemBtn" class="flex justify-content-end">
                            <Base-Button 
                                :class-names="'text--xs btn--ghost__primary'" 
                                :label="'+ Add new item'" size="sm"  
                                btn-style="ghost" @submit="addAnotherInvoiceItem" 
                            />
                        </div>
                        <div class="invoice__calculate--wrap invoice__header">
                            <div class="form__row__left">
                                <label class="mb-2 invoice__compile--memo--label">Items

                                    <span class="text--color-warning text--xs">
                                        *
                                    </span>
                                </label>
                            </div>
                            <div class="invoice__calculate--row form__row__right">
                                <div class="invoice__quantity flex-1">
                                    <label class="form__label text--medium">Quantity</label>
                                </div>
                                <div class="invoice__price flex-1">
                                    <label class="form__label text--medium">Price</label>
                                </div>
                                <div class="invoice__amount flex-1">
                                    <label class="form__label text--medium block">Amount</label>
                                </div>
                            </div>
                        </div>
  
                        <div v-for="(item, i) in invoice.meta.items" :key="i">
                            <div class="invoice__calculate--wrap relative" :class="{ 'mt--20' :i > 0 }">
                                <div class="form__row__left">
                                    <input v-model="item.itemName" type="text" class="form-control form-control-sm">
                                </div>
            
                                <div class="form__row__right invoice__calculate--row">
                                    <div class="invoice__calculate--wrap flex-1">
                                        <money
                                            v-model="item.itemQuantity" v-bind="config.number"
                                            class="form-control form-control-sm"
                                            style="width: 80%" spellcheck="false"
                                            @keydown.native="preventKeys"
                                        />
                                    </div>
                                    <div class="invoice__price flex-1">
                                        <money
                                            v-model="item.itemPrice" v-bind="moneyConfig"
                                            class="form-control form-control-sm"
                                            style="width: 80%" spellcheck="false"
                                            @keydown.native="preventKeys" @keyup.enter="addAnotherInvoiceItem"
                                        />                                                                    
                                    </div>
                                    <div class="invoice__amount flex-1">
                                        <div class="p-t-10 m-b-30 textRight text--sm text--upper">
                                            {{ invoice.currency }} {{ itemAmount(i) }}
                                        </div>
                                    </div>
                                </div>
                                <div v-show="i > 0" class="invoice__calculate__close" @click="removeInvoiceItem(i)">
                                    <svg
                                        width="16" height="16"
                                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect width="24" height="24" rx="12" fill="#B9B9B9" />
                                        <path
                                            d="M11.0492 12.0001L6.92 7.87094C6.66346 7.61462 6.66346 7.19873 6.92 6.94241C7.17654 6.68586 7.59199 6.68586 7.84853 6.94241L11.9777 11.0716L16.1071 6.94241C16.3636 6.68586 16.7791 6.68586 17.0356 6.94241C17.2922 7.19873 17.2922 7.61462 17.0356 7.87094L12.9062 12.0001L17.0356 16.1293C17.2922 16.3856 17.2922 16.8015 17.0356 17.0578C16.9074 17.1859 16.7392 17.25 16.5714 17.25C16.4035 17.25 16.2354 17.1859 16.1071 17.0576L11.9777 12.9284L7.84853 17.0576C7.72026 17.1859 7.55215 17.25 7.38426 17.25C7.21637 17.25 7.04827 17.1859 6.92 17.0576C6.66346 16.8013 6.66346 16.3854 6.92 16.1291L11.0492 12.0001Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>

                            <span v-if="isInvoiceItemFilled(i)" class="form__errors flex align-items-center">
                                <icon-svg name="alert" width="16px'" :fill="'rgba(128, 128, 128, 1)'" />
                                <span class="form__errors__text ml--5">
                                    Please fill the invoice item, quantity and unit price
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="row invoice__section--item">
                        <div class="col-12 invoice__header">
                            <p class="text--bold text--color-dark">
                                Others
                            </p>
                        </div>
                        <div class="col-6">
                            <div class="invoice__row invoice__item">
                                <div class="invoice__details--item flex-column">
                                    <div class="invoice__compile--row">
                                        <div class="invoice__compile--label">
                                            Subtotal
                                        </div>
                                        <div class="invoice__compile--value ml--10 text--upper">
                                            {{ invoice.currency }} {{ $format_amount(computedSubTotal) }}
                                        </div>
                                    </div>

                                    <div>
                                        <div class="invoice__compile--row">
                                            <div class="dropdown">
                                                <div
                                                    id="addTax"
                                                    type="button"
                                                    class="invoice__compile--btn pointer"
                                                    aria-expanded="false"
                                                    data-bs-toggle="dropdown" 
                                                    data-bs-auto-close="true" 
                                                >
                                                    Add Tax
                                                </div>
                                          
                                                <ul id="addTaxItems" class="dropdown-menu invoice__tax--item" aria-labelledby="addTax">
                                                    <li>
                                                        <div class="invoice__tax--item dropdown-item  mt--15">
                                                            <FormLabel title="Tax Type" font-size="text--sm" />
                                                            <select v-model="invoiceTaxType.type" class="form-select form-select-sm invoice__compile--select">
                                                                <option v-for="tax in ['percentage', 'flat']" :key="tax" :value="tax">
                                                                    {{ tax }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="invoice__tax--item dropdown-item mt--10">
                                                            <money 
                                                                v-if="invoiceTaxType.type === 'flat'"
                                                                v-model="invoiceTax" 
                                                                v-bind="moneyConfig" 
                                                                class="form-control form-control-sm" 
                                                                spellcheck="false" 
                                                                @keydown.native="preventKeys"
                                                            />
                                                            <input-money 
                                                                v-else
                                                                v-model="invoiceTaxType.value" 
                                                                class="form__input form-control-sm" 
                                                                prefix="%"
                                                                precision="2" 
                                                            /> 
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="invoice__tax--item last dropdown-item">
                                                            <BaseButton
                                                                label="Cancel" size="sm"
                                                                btn-style="secondary" class="text--xs"
                                                                @submit="closeInvoiceTaxDropdown"  
                                                            />
                                                            <BaseButton
                                                                label="Add" size="sm"
                                                                btn-style="primary" class="text--xs"
                                                                @submit="addTaxToInvoice"  
                                                            />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="invoice__compile--value text--upper">
                                                {{ invoice.currency }} {{ computedTaxes }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="invoice__compile--row invoice__row__total">
                                        <div class="invoice-computation__label bold">
                                            Total
                                        </div>
                                        <div class="invoice-computation__action" />
                                        <div class="invoice-computation__item text--bold text--upper">
                                            {{ invoice.currency }} {{ computedInvoiceTotal }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row invoice__section--item">
                        <div class="col-12 invoice__header">
                            <span class="text--bold text--color-dark">Memo</span>
                            <span class="text--xs text-label-info">
                                (Max 500 words)
                            </span>
                        </div>
                        <div class="col-6">
                            <div class="invoice__row invoice__item">
                                <div class="invoice__details--item">
                                    <textarea
                                        id="exampleFormControlTextarea1" v-model="invoice.memo" name="memo" class="form-control"
                                        rows="3"
                                        @change="handleFormFieldsChange"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row invoice__section--item">
                        <div class="col-12 invoice__header">
                            <span class="text--bold text--color-dark">Due Date</span>
                        </div>
  
                        <div class="col-6">
                            <div class="invoice__row invoice__item">
                                <div class="invoice__details--item">
                                    <div class="w--100">
                                        <v-date-picker
                                            v-model="invoice.dueDate"
                                            mode="dateTime" color="blue" name="dueDate" nav-visibility="click"
                                            @change="handleFormFieldsChange"
                                        >
                                            <template #default="{ inputValue, togglePopover }">
                                                <input class="px-3 py-1 border rounded dueDate form-control" :value="inputValue" @click="togglePopover">
                                            </template>
                                        </v-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row invoice__section--item">
                        <div class="col-6 invoice__header">
                            <div class="flex align-items-center justify-content-between">
                                <span class="title">Reminders</span>
                                <BaseButton 
                                    btn-style="ghost" label="+ Select reminders" 
                                    class-names="text--xs text--color-primary p--0" 
                                    @submit="showRemindersModal = true"
                                />
                            </div>
                        </div>
  
                        <!-- <div class="col-6">
                            <div class="invoice__row invoice__item">
                                <div class="">
                                    <div v-for="item in invoiceReminders" :key="item.id">
                                        <div class="form-check form-check-inline text--sm">
                                            <input
                                                :id="item.id" v-model="remindersNames" class="form-check-input" type="checkbox"
                                                :value="item.id" @change="e => setReminders(e, item)"
                                            >
                                            <label class="form-check-label" for="inlineCheckbox1">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </template>

        <create-client-modal 
            :show-modal="isClientModalShow" 
            :action-type="modalActionType" 
            :client-data="client" :show-billing-input="false"
            @close="closeCreateClientModal"
            @complete="onClientComplete"
        />

        <create-project-modal 
            :show-modal="isProjectModalOpen" 
            :list="clients" :show-client-list="false"
            @complete="onProjectComplete"
            @close="closeCreateProjectModal" 
        />

        <preview-invoice
            :items="invoice.meta.items"
            :invoice-data="previewInvoiceData"
        />

        <page-overlay-loader v-if="pageLoading" />

        <main-modal 
            :show-modal="showUpdateCCEmailsModal" 
            is-close-button is-show-header is-footer
            size="sm" title="Update Client CC Emails"
            @close="closeClientBillingEmailModal"
        >
            <template #body>
                <div class="mb--10 p--10">
                    <p class="text--sm">
                        Would you like to update your selected client with the additional billing emails added?
                    </p>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        class-names="mr--5" label="Cancel"
                        size="sm" btn-style="secondary" :disabled="updateClientmailsLoading"
                        @submit="closeClientBillingEmailModal"
                    />
                    <Base-Button 
                        label="Update"
                        size="sm" :loading="updateClientmailsLoading" :disabled="updateClientmailsLoading"
                        @submit="updateClientBillingEmails"                                       
                    />
                </div>
            </template>
        </main-modal>

        <ManageRemindersModal 
            :selected-reminders="remindersNames"
            :show-modal="showRemindersModal" @cancel="showRemindersModal = false"
        />

        <main-modal 
            :show-modal="showPaymentInfoModal" 
            is-close-button is-show-header is-footer
            size="sm" title="Manage payment information"
            @close="showPaymentInfoModal = false"
        >
            <template #body>
                <form class="mb--10 pt--0 pl--20 pr--20">
                    <div class="client__modal__body mb--10">
                        <div class="form--control mb--15">
                            <FormLabel classes="text--color-gray-200" title="Billing method" />
                            <select v-model="invoice.billingMethod" name="billingMethod" class="form-select form-select-sm text--capitalize" @change="handleFormFieldsChange">
                                <option v-for="billingMethod in billingMethods" :key="billingMethod.id" :value="billingMethod.id">
                                    {{ billingMethod.name }}
                                </option>
                            </select>
                        </div>

                        <template v-if="invoice.billingMethod === 'Personal account'">
                            <FormInput 
                                v-if="accountInfoByCurrency['bankName']"
                                id="bankName"
                                v-model="invoice.accountDetails.bankName"
                                size="sm" info-type="required" label="Bank Name"
                            />
                            <FormInput 
                                v-if="accountInfoByCurrency['accountName']"
                                id="accountName"
                                v-model="invoice.accountDetails.accountName"
                                size="sm" info-type="required" label="Account Name" 
                            />
                            <FormInput 
                                v-if="accountInfoByCurrency['accountNumber']"
                                id="accountNumber"
                                v-model="invoice.accountDetails.accountNumber"
                                size="sm" info-type="required" label="Account Number"
                            />
                            <FormInput 
                                v-if="accountInfoByCurrency['iban']"
                                id="iban"
                                v-model="invoice.accountDetails.iban"
                                size="sm" info-type="required" label="IBAN"
                            />
                            <FormInput 
                                v-if="accountInfoByCurrency['sortCode']"
                                id="sortCode"
                                v-model="invoice.accountDetails.sortCode" class-names="mt--10"
                                size="sm" info-type="required" label="Sort Code"
                            />
                        </template>
                    </div>
                </form>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        class-names="mr--5" label="Cancel"
                        size="sm" btn-style="secondary" @submit="showPaymentInfoModal = false"
                    />
                    <Base-Button label="Update" size="sm" @submit="updateAccountDetails" />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import PreviewInvoice from "@/components/invoices/PreviewInvoice.vue";
import {
    IconSvg, CreateClientModal,
    CreateProjectModal, BaseButton,
    FormLabel, InputMoney,
    PageOverlayLoader, InputMultipleEmails,
    SearchClientInput, PageSpinner, EmptyPage, ManageRemindersModal,
    MainModal, FormInput,
} from "@/components/ui/index";
import { assembleQueryList, formatAmount, convertStringAmountToInt } from "@/utils/helpers";
import { invoiceReminders, billingMethods } from "@/utils/dummy";
import { transferCurrencies } from "@/plugins/countries";

export default {
    name: "CreateEditInvoice",

    components: {
        SearchClientInput, FormInput, InputMultipleEmails,
        PreviewInvoice, CreateClientModal, IconSvg, CreateProjectModal, BaseButton,
        FormLabel, InputMoney, PageOverlayLoader, PageSpinner,
        EmptyPage, MainModal, ManageRemindersModal,
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.name === "edit-invoice-view") { 
                if (!vm.$route.params.id) {
                    return false;
                }
                vm.handleFetchInvoice(vm.$route.params.id);
                vm.invoiceType = "edit";
            } else {
                const accountType = vm.accountType;

                if (accountType === "standard" && vm.invoices?.length >= 5) {
                    vm.$router.push("/dashboard/invoices");
                } else {
                    vm.getStoredInvoiceData();
                    vm.invoiceType = "new";
                }
            };
        });
    },

    beforeRouteLeave(to, from, next) {
        // clear stored ongoing invoice if invoice is new
        if (from.name === "create-invoice-view") { 
            localStorage.removeItem("z_invoice_ongoing");
            this.resetInvoiceInfo();
        }
        next();
    },

    data() {
        return {
            showRemindersModal: false,
            invoiceReminders,
            billingMethods,
            invoiceType: "new",
            invoiceNo: undefined,
            invoice: {
                currency: "",
                datePaid: null,
                createdAt: null,
                dueDate: null,
                _id: undefined,
                memo: "",
                billingMethod: "",
                meta: {
                    ccEmails: [],
                    tax: [{ taxName: "vat", taxType: "flat", taxValue: 0 }],
                    reminders: [],
                    items: [{ itemName: "", itemPrice: 0.00, itemQuantity: 0, itemUnit: 0.00 }],
                },
                status: "draft",
                title: "Invoice for",
                accountDetails: {
                    accountName: "",
                    bankName: "",
                    accountNumber: "",
                    iban: "",
                    sortCode: "",
                },
            },
            client: {
                isEmpty: true,
                _id: "",
                name: "",
                phoneNumber: "",
                email: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
            },
            project: {
                isEmpty: true,
                _id: "",
                title: "",
                status: "",
                isFavourite: false,
                deadline: undefined,
            },
            otherclientsSearched: [],
            currencies: transferCurrencies,
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                },
                percentage: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "%",
                    suffix: "",
                    precision: 2,
                    masked: false,
                },
            },
            toggleOtherEmail: false,
            pageLoading: false,
            invoiceTaxType: {
                /** * @type{"percentage" | "flat"} */
                type: "percentage",
                value: 0,
            },
            isInvoiceItemEmpty: false,
            isClientModalShow: false,
            isShowClientInfoView: false,
            modalActionType: "add",
            firstItemIsEmpty: false,
            isProjectModalOpen: false,
            isShowProjectInfoView: false,
            fetchProjectsLoading: false,
            fetchInvoiceLoading: false,
            searchClientsLoading: false,
            itemErrorsObj: {},
            fetchInvoiceErr: false,
            remindersNames: [],
            reminderIsSet: false,
            showReminderDetails: false,
            showUpdateCCEmailsModal: false,
            updateClientmailsLoading: false,
            submitAction: "", // "send" | "save" | "",
            clientList: [],
            projectList: [],
            showPaymentInfoModal: false,
            invalidValues: {},
        };
    },

    computed: {
        accountInfoByCurrency() {
            if (this.invoice.billingMethod === "personal_account") {
                switch (this.invoice.currency.toLowerCase()) {
                case "ngn":
                case "ghs":
                    return {
                        accountName: true,
                        bankName: true,
                        accountNumber: true,
                    };
                
                default:
                    return {
                        accountName: true,
                        bankName: true,
                        accountNumber: true,
                        iban: true,
                        sortCode: true,
                    };
                }
            } else {
                return {};
            }
        },

        user() {
            return this.$store.getters["auth/profile"];
        },

        accountType() {
            return this.user?.accountType;
        },

        userCurrency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        projects() {
            return this.$store.getters["project/projects"];
        },

        invoices() {
            return this.$store.getters["invoice/invoices"];
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },
        
        selectedClient: {
            get: function() {
                return this.client;
            },

            set: function(newVal) {
                this.client = newVal?.client;
            },
        },

        selectedProject: {
            get: function() {
                return {
                    title: this.project.title,
                    project: this.project,
                };
            },

            set: function(newVal) {
                this.project.title = newVal.title;
                this.project = newVal.project;
                return; 
            },
        },

        showMultipleEmailsField() {
            return this.toggleOtherEmail ? true : false;
        },

        showMultipleEmailsOption() {
            if (this.client?.name && this.invoice.meta?.ccEmails?.length < 3) {
                return true;
            }
            return false;
        },
    
        moneyConfig() {
            return {
                decimal: ".",
                thousands: ",",
                // prefix: this.invoice.currency + " ",
                prefix: " ",
                suffix: "",
                precision: 2,
                masked: false,
            };
        },

        showClientInfoView() {
            if (this.client?.name && this.isShowClientInfoView) {
                return true;
            } else {
                return false;
            }
        },

        showProjectInfoView() {
            if (this.project.title && this.isShowProjectInfoView) {
                return true;
            } else {
                return false;
            }
        },

        showAddItemBtn() {
            const firstItem = this.invoice.meta.items[0];

            if (firstItem.itemName && firstItem.itemPrice && firstItem.itemQuantity && firstItem.itemUnit) {
                return true;
            } else {
                return false;
            }
        },

        invoiceSubTotal() {
            const items = this.invoice.meta.items;
            return this.sumElements( items, "itemPrice" );
        },

        invoiceTax: {
            get: function() {
                if (typeof this.invoice.meta.tax[0]?.taxValue === "string") {
                    return parseFloat(this.invoice.meta.tax[0]?.taxValue);
                } else {
                    return this.invoice.meta.tax[0]?.taxValue;
                }
            },

            set: function(val) {
                console.log({ invoiceTax: val });
                // if (this.invoice.meta.tax[0]) {
                //     if (this.invoiceTaxType.type === "percentage" && this.invoice.meta.tax[0]?.taxType) {
                //         this.invoice.meta.tax[0].taxType = "percentage";
                //     }

                //     if (this.invoiceTaxType.type === "flat" && this.invoice.meta.tax[0]?.taxType) {
                //         this.invoice.meta.tax[0].taxType = "flat";
                //     }
                //     this.invoice.meta.tax[0].taxValue = parseFloat(val);
                // }   
            },
        },

        computedSubTotal() {
            if (this.invoice.meta.items.length > 0) {
                const transformArr = this.invoice.meta.items.map(item => item.itemPrice * item.itemQuantity);
                const sumItemsArr = transformArr.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                return sumItemsArr;
            } else {
                return 0;
            }
        },

        computedInvoiceTotal() {
            return this.formatMoney(this.invoiceTax + this.computedSubTotal);
        },

        computedTaxes() {
            if (this.invoice.meta.tax.length) {
                console.log({ "this.invoice.meta.tax": this.invoice.meta.tax });
                return this.invoice.meta.tax.reduce((previousValue, currentValue) => previousValue + currentValue.taxValue, 0);
            } else {
                return 0;
            }
        },

        getCCEmailsCount() {
            return 3 - (this.client.ccEmails.length);
        },
    
        isSaveBtnDisabled() {
            if (!this.invoice.currency || !this.client?._id || !this.project?._id || !this.invoice.meta.items.length) {
                return true;
            } else {
                return false;
            }
        },

        previewInvoiceData() {
            return {
                invoice: {
                    invoiceNo: this.invoiceNo,
                    currency: this.invoice.currency,
                    createdAt: this.invoice.createdAt || new Date(),
                    dueDate: this.invoice.dueDate,
                    currency: this.invoice.currency,
                    tax: this.computedTaxes,
                    subtotal: this.invoiceSubTotal,
                    total: this.computedInvoiceTotal,
                },
                client: {
                    name: this.client?.name,
                    phoneNumber: this.client.phoneNumber,
                    email: this.client.email,
                    country: this.client.country,
                    address: this.client?.name,
                },
                user: {
                    fullName: this.user.fullName,
                    email: this.user.email,
                    phoneNumber: this.user.phoneNumber,
                },
            };
        },

        currentProject() {
            return this.$store.getters["project/currentProject"];
        },
    },

    watch: {
        invoiceSubTotal(newVal) {
            if (newVal <= 0) return;

            const taxIsPercentage = this.invoice.meta.tax[0]?.taxType === "percentage";
            const tax = parseFloat(this.invoice.meta.tax[0]?.taxValue);

            // if(taxIsPercentage && tax > 0) {
            //     this.invoiceTaxType.value = (tax / this.invoiceSubTotal) * 100;
            // }

            this.invoiceTax = parseFloat((this.invoiceTaxType.value / 100) * this.invoiceSubTotal).toFixed(2);
        },

        ["invoiceTaxType.value"](newVal) {
            if (this.invoiceSubTotal <= 0) return;
            this.invoiceTax = parseFloat((newVal / 100) * this.invoiceSubTotal).toFixed(2);
        },

        currentProject: {
            handler(newVal) {
                if (newVal) {
                    this.project = {
                        ...this.project,
                        ...newVal,
                        isEmpty: false,
                    };
                    this.isShowProjectInfoView = true;
                }
            },
            deep: true,
            immediate: true,
        },

        clients: {
            handler(newVal) {
                if (newVal.length) {
                    this.clientList = this.clients;
                }
            },
            deep: true,
            immediate: true,
        },
    },

    async mounted() {
        this.clientList = this.clients;
        this.projectList = this.projects;

        if (this.userCurrency) {
            this.invoice.currency = this.userCurrency;
        }
    },

    methods: {
        convertStringAmountToInt,

        resetInvoiceInfo() {
            this.invoice = {
                currency: "",
                datePaid: null,
                createdAt: null,
                dueDate: null,
                _id: undefined,
                memo: "",
                meta: {
                    ccEmails: [],
                    tax: [{ taxName: "vat", taxType: "flat", taxValue: 0 }],
                    reminders: [],
                    items: [{ itemName: "", itemPrice: 0.00, itemQuantity: 0, itemUnit: 0.00 }],
                },
                status: "draft",
                title: "Invoice for",
            };
            this.remindersNames = [];
            this.client = {
                isEmpty: true,
                _id: "",
                name: "",
                phoneNumber: "",
                email: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
            };
            this.project = {
                isEmpty: true,
                _id: "",
                title: "",
                status: "",
                isFavourite: false,
                deadline: undefined,
            };
            this.isShowClientInfoView = false;
            this.isShowProjectInfoView = false;
        },

        handleFormFieldsChange(event) {
            const value = event.target.value;
            const name = event.target.name;

            if (!name) return;
            
            this.invoice = {
                ...this.invoice,
                [name]: value,
            };

            this.saveInvoiceDataOnLS();
        },

        saveInvoiceDataOnLS() {
            localStorage.setItem("z_invoice_ongoing", JSON.stringify({
                ...this.invoice,
                client: this.client,
                project: this.project,
            }));
        },

        getStoredInvoiceData() {
            const res = localStorage.getItem("z_invoice_ongoing");
            const data = res ? JSON.parse(res) : null;

            if (data) {
                this.invoice = {
                    ...this.invoice,
                    ...data,
                };
                
                if (data.client) {
                    this.client = data?.client;
                    this.isShowClientInfoView = true;
                }
                if (data.project) {
                    this.project = data?.project;
                    this.isShowProjectInfoView = true;
                }

                if (data.meta.reminders.length) {
                    this.remindersNames = data.meta.reminders.map(item => item.remindersName);
                }
            }
        },

        formatMoney: function(x) {
            return formatAmount(x);
        },

        modifyEmails(arr = []) {
            this.invoice.meta.ccEmails = arr;
        },

        itemAmount(i) {
            const item = this.invoice.meta.items[ i ];
            const quantity = item.itemQuantity;
            const unit = item.itemPrice;
            const price = parseFloat(quantity) * parseFloat(unit);
            
            this.invoice.meta.items[i].itemUnit = price;
            
            return typeof price === "number" ? price.toFixed(3) : 0;
        },
    
        toggleClientView() {
            this.isShowClientInfoView = !this.isShowClientInfoView;
        },

        toggleProjectView() {
            this.isShowProjectInfoView = !this.isShowProjectInfoView;
        },

        toggleModalActionType(type, action) {
            this.modalActionType = action;

            if (type === "client") {
                this.isClientModalShow = true;
            }

            if (type === "project") {
                this.isProjectModalOpen = true;
            }
        },

        toggleReminderDetails() {
            this.showReminderDetails = !this.showReminderDetails;
        },

        addAnotherInvoiceItem() {            
            const isEmpty = this.checkIfInvoiceItemIsEmpty();

            if (isEmpty || this.firstItemIsEmpty) {
                return;
            }  

            this.saveInvoiceDataOnLS();

            this.invoice.meta.items.push({
                itemName: "",
                itemPrice: 0,
                itemQuantity: 1,
                itemUnit: 0,
            });
        },

        checkIfInvoiceItemIsEmpty() {
            let emptyCount = 0;
            const items = this.invoice.meta.items;

            // eslint-disable-next-line indent
            items.map((item, i) => {
                const emptyItemFields = (!item.itemName 
                || parseFloat(item.itemPrice) <= 0 
                || item.itemQuantity < 1 
                || item.itemUnit <= 0
                );

                if (emptyItemFields) {
                    emptyCount++;
                    this.itemErrorsObj[i] = true;
                } else {
                    delete this.itemErrorsObj[i];
                }
            });

            return emptyCount > 0;
        },

        isInvoiceItemFilled(index) {
            if (this.itemErrorsObj[index]) {
                return true;
            } else {
                return false;
            }
        },

        removeInvoiceItem(index = this.invoice.meta.items.length - 1) {
            /**
               * This will remove an invoice item from the list of items based on the index.
               * if none is defined, it removes the last item.
            */
            // this.$delete( this.invoice.meta.items, index );
            this.invoice.meta.items = this.invoice.meta.items.filter((elem, idx) => idx !== index);
        },

        closeCreateClientModal() {
            this.isClientModalShow = false; 
        },

        closeCreateProjectModal() {
            this.isProjectModalOpen = false; 
        },

        onClientComplete(data) {
            this.client = {
                isEmpty: false,
                _id: data?._id || "",
                name: data?.name || "",
                phoneNumber: data?.phoneNumber || "",
                email: data?.email || "",
                country: data?.country || "",
                address: data?.address || "",
                industry: data?.industry || "",
                status: data?.status || [],
            };
            this.closeCreateClientModal();
            this.isShowClientInfoView = true;
        },

        onProjectComplete(data) {            
            this.project = {
                isEmpty: true,
                _id: data?.resp?._id || "",
                title: data?.resp?.title || "",
                status: data?.resp?.status || "",
                deadline: data?.resp?.deadline || "",
                isFavourite: data?.resp?.isFavourite || "",
                tags: data?.resp?.tags || [],
            };

            this.closeCreateProjectModal();
            this.isShowProjectInfoView = true;
        },

        validateInvoiceItems() {
            if (!this.client.email || !this.client?._id) {
                this.invalidValues.client = true;
            } else {
                delete this.invalidValues.client;
            }

            if (!this.project.title || !this.project?._id) {
                this.invalidValues.project = true;
            } else {
                delete this.invalidValues.project;
            }

            if (!this.invoice.billingMethod) {
                this.invalidValues.billingMethod = true;
            } else {
                delete this.invalidValues.billingMethod;
            }

            if (this.invoice.billingMethod === "Personal account") {
                let count = 0;

                const keys = Object.keys(this.accountInfoByCurrency);

                for (let key of keys) {
                    if (!this.invoice.accountDetails[key]) {
                        count++;
                    }
                }

                if (count > 0) {
                    this.invalidValues.accountDetails = true;
                } else {
                    delete this.invalidValues.accountDetails;
                } 
            }

            if (this.computedInvoiceTotal <= 0) {
                this.invalidValues.computedInvoiceTotal = true;
            } else {
                delete this.invalidValues.computedInvoiceTotal;
            } 

            const checkIfItemsAreEmpty = this.checkIfInvoiceItemIsEmpty();
            
            if (checkIfItemsAreEmpty) {
                invalidValues.items = true;
            }

            if (this.invalidValues["accountDetails"]) {
                this.$toast.error("Fill in account details");
            }

            if (this.invalidValues["billingMethod"]) {
                this.$toast.error("Select a billing method");
            }

            if (this.invalidValues["project"]) {
                this.$toast.error("Select a project");
            }

            if (this.invalidValues["client"]) {
                this.$toast.error("Select or create a new client");
            }

            if (this.invalidValues["items"]) {
                this.$toast.error("Fill in invoice items to continue");
            }

            const isInvalid = !!Object.keys(this.invalidValues).length > 0;
            
            return isInvalid;
        },

        createInvoicePayload() {
            const payload = {
                invoiceNo: this.invoiceNo,
                currency: this.invoice.currency,
                clientId: this.client?._id,
                dueDate: this.invoice.dueDate,
                datePaid: this.invoice.datePaid,
                memo: this.invoice.memo,
                meta: this.invoice.meta,
                projectId: this.project?._id,
                status: this.invoice.status,
                totalAmount: this.convertStringAmountToInt(this.computedInvoiceTotal || ""),
                accountDetails: {
                    accountName: this.invoice.accountDetails.accountName,
                    bankName: this.invoice.accountDetails.bankName,
                    accountNumber: this.invoice.accountDetails.accountNumber,
                    iban: this.invoice.accountDetails.iban,
                    sortCode: this.invoice.accountDetails.sortCode,
                },
                billingMethod: this.invoice.billingMethod,
            };

            return payload;
        },

        async updateClientBillingEmails() {
            try {
                this.updateClientmailsLoading = true;
                
                await this.$store.dispatch("clients/updateClient", {
                    _id: this.client._id,
                    ccEmails: this.invoice.meta.ccEmails,
                });
                this.showUpdateCCEmailsModal = false;

                await this.handleSubmitAction();
            } catch (error) {
                console.log(error);
            } finally {
                this.updateClientmailsLoading = false;
            }
        },

        async closeClientBillingEmailModal() {
            this.showUpdateCCEmailsModal = false;
            await this.handleSubmitAction();
        },

        async saveExistingInvoice(payload) {
            try {
                this.pageLoading = true;

                await this.$store.dispatch("invoice/updateInvoice", payload);
                this.$toast.success("Invoice saved");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async saveNewInvoice(payload) {
            try {
                this.pageLoading = true;

                await this.$store.dispatch("invoice/saveInvoiceDraft", payload);
                
                this.$toast.success("New draft invoice created");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },
        
        // As draft (new or existing)
        async handleSaveInvoice() {
            const payload = this.createInvoicePayload();
            const isValid = this.validateInvoiceItems();

            console.log({ handleSaveInvoice: isValid });

            if (isValid) {
                return;
            };

            payload.status = "draft";
            
            if (this.invoiceType === "new") {
                await this.saveNewInvoice(payload);
            } else {    
                await this.saveExistingInvoice({
                    ...payload,
                    _id: this.invoice._id,
                });
            };
        },

        async sendNewInvoice(payload) {
            try {
                this.pageLoading = true;

                await this.$store.dispatch("invoice/sendNewInvoice", payload);
                
                this.$toast.success("Invoice created and sent");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async sendExistingInvoice(payload) {
            try {
                this.pageLoading = true;

                await this.$store.dispatch("invoice/sendExistingInvoice", payload);
                
                this.$toast.success("Invoice sent");
                this.$router.push("/dashboard/invoices");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async handleSendInvoice() {
            const payload = this.createInvoicePayload();
            const isValid = this.validateInvoiceItems();
            
            if (isValid) {
                return;
            };

            if (this.invoiceType === "new") {
                await this.sendNewInvoice({
                    ...payload,
                    status: "issued",
                });
            } else {
                await this.sendExistingInvoice({
                    ...payload,
                    _id: this.invoice._id,
                });
            }
        },

        async initiateSubmitAction(val) {            
            this.submitAction = val;

            if (this.client._id && this.invoice.meta.ccEmails.length) {
                this.showUpdateCCEmailsModal = true;
                return;
            } else {
                this.handleSubmitAction();
            }
        },

        async handleSubmitAction() {
            if (this.submitAction === "send") {
                await this.handleSendInvoice();
            } 

            if (this.submitAction === "save") {
                await this.handleSaveInvoice();
            } 
        },

        async deleteInvoice() {
            try {
                this.pageLoading = true;

                const id = this.$route.params.id;

                await this.$store.dispatch("auth/deleteInvoice", id);

                this.$toast.success("Invoice deleted successfully");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async handleFetchInvoice(id) { 
            try {
                this.fetchInvoiceLoading = true;
                this.fetchInvoiceErr = false;
                const invoiceId = id || this.$route.params.id;

                const res = await this.$store.dispatch("invoice/getInvoice", { id: invoiceId });

                this.invoice = {
                    ...this.invoice,
                    ...res,
                };
                this.invoiceNo = res.invoiceNo;
                this.client = res?.client;
                this.project = res?.project;

                if (res.client) {
                    this.isShowClientInfoView = true;
                }

                if (res.project) {
                    this.isShowProjectInfoView = true;
                }

                if (res.meta.reminders.length) {
                    const reminders = ["14_days_before", "7_days_before", "on_due_date", "a_day_after", "3_days_after", "7_days_after", "14_days_after"]; // TO DO: refactor this
                    
                    this.remindersNames = res.meta.reminders.map(x => {
                        if (reminders.includes(x.remindersName)) {
                            return x.remindersName;
                        }
                    });
                }
            } catch (error) {
                this.fetchInvoiceErr = true;
            } finally {
                this.fetchInvoiceLoading = false;
            }
        },

        async handleSearchClients(query) {
            try {
                this.searchClientsLoading = true;

                const pageQueryObj = {
                    page: 1,
                    type: "all",
                    name: query,
                };
    
                const buildResult = assembleQueryList("clients", pageQueryObj);
    
                const result = await this.$store.dispatch("clients/fetchClients", { params: buildResult, external: true, refresh: true });
                this.clientList = result.data;
            } catch(error) {
                console.log(error);
            } finally {
                this.searchClientsLoading = false;
            }
        },

        async handleSearchProjects(query) {
            try {
                this.fetchProjectsLoading = true;
    
                let pageQueryObj = {
                    page: 1,
                    type: "all",
                    title: query,
                };
    
                const buildResult = assembleQueryList("projects", pageQueryObj);
    
                const result = await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: true, external: true });
                this.projectList = result.data;
            } catch (error) {
                console.log(error);
            } finally {
                this.fetchProjectsLoading = false;
            }
        },

        /**
         * Returns the sum of all the elements fields in an array of elements
        */
        sumElements( elems, field ) {
            if (elems.length === 0 ) return 0;

            let sum = parseFloat(elems[ 0 ][ field ]);
            
            if (elems.length === 1 ) return sum;
            
            else {
                for( let i = 1; i < elems.length; i++ ) {
                    sum += parseFloat( elems[ i ][ field ] );
                }
                return sum;
            }
        },

        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        setReminders(event, item) {
            const checked = event.target.checked;

            if (checked) {
                this.invoice.meta.reminders.push({
                    remindersName: item.id,
                    reminderDate: new Date(),
                });
            } else {
                this.invoice.meta.reminders = this.invoice.meta.reminders.filter(x => {
                    return x.remindersName !== item.id;
                });
            } 

            this.saveInvoiceDataOnLS();
        },

        cancelReminders() {
            if (!this.remindersNames.length) {
                this.remindersNames = [];
                this.reminderIsSet = false;
            }

            this.showReminderDetails = false;
        },

        setClient(data) {
            this.client = data;
            
            if (this.data?.ccEmails) {
                this.invoice.meta.ccEmails = data?.ccEmails;
            }

            localStorage.setItem("z_invoice_ongoing", JSON.stringify({
                ...this.invoice,
                client: data,
            }));

            this.isShowClientInfoView = true;
        },

        setProject(data) {
            localStorage.setItem("z_invoice_ongoing", JSON.stringify({
                ...this.invoice,
                project: data,
            }));

            this.project = data;
            this.isShowProjectInfoView = true;
        },

        closeInvoiceTaxDropdown() {
            this.invoiceTax = 0;
            $("#addTax").dropdown("hide");
        },

        addTaxToInvoice() {
            $("#addTax").dropdown("hide");
        },

        updateAccountDetails() {
            let count = 0;

            const keys = Object.keys(this.accountInfoByCurrency);

            if (!keys.length) {
                this.showPaymentInfoModal = false;
                this.saveInvoiceDataOnLS();
                return;
            }

            for (let key of keys) {
                if (!this.invoice.accountDetails[key]) {
                    count++;
                }
            }

            if (count > 0) {
                this.$toast.error("Please fill in the required fields");
                return;
            } else {
                this.showPaymentInfoModal = false;
                this.saveInvoiceDataOnLS();
                return;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.row  {
  &.header {
    justify-content: space-between;
  }

  .row__left, .row__right {
    width: fit-content;
    max-width: fit-content;
  }
}

</style>