<template>
    <div
        v-show="isActive"
        class="tab-panel h-auto"
        :class="{ 'block': isActive }"
        role="tabPanel"
    >
        <div class="tab-content h-auto">
            <slot />
        </div>
    </div>
</template>
<script>

export default {
    name: "LinkedTab",
    props: {
        title: {
            type: String,
            default: "",
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.tab-panel {
  .tab-content {
    padding: 0.5rem;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>