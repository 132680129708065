<template>
    <div class="relative" v-bind="$attrs">
        <svg :width="size" :height="size" viewBox="0 0 36 36" class="circular-chart">
            <path
                class="circle-bg"
                :d="pathDescription"
                :stroke="backgroundColor"
                fill="none"
                stroke-width="3.8"
            />
            <path
                class="circle"
                :d="pathDescription"
                :stroke="color"
                fill="none"
                stroke-width="3.8"
                :stroke-dasharray="`${value}, 100`"
            />
            <text x="18" y="20.35" class="percentage">{{ progressText }}</text>
        </svg>
    </div>
</template>

<script>
export default {
    name: "CircularProgress",

    props: {
        size: {
            type: String,
            default: "35",
        },
        progressText: {
            type: String,
            default: "",
        },
        value: {
            type: Number,
            required: true,
            default: 0,
            validator: value => value >= 0 && value <= 100,
        },
        color: {
            type: String,
            default: "#E73B37",
        },
        backgroundColor: {
            type: String,
            default: "#e0e0de",
        },
    },

    data() {
        return {
            strokeWidth: 16 - this.size === "sm" ? 2 : 3,
            radius: 16 - this.strokeWidth,
            circumference: 2 * this.radius * Math.PI,
            trackStyles: this.trackProps || {
                cx: 16,
                cy: 16,
                r: this.radius,
                role: "presentation",
                strokeDasharray: `${this.circumference} ${this.circumference}`,
                strokeDashoffset: 0,
                transform: "rotate(-90 16 16)",
                strokeLinecap: "round",
            },
            selfMounted: this.disableAnimation ? true : false,
        };
    },

    computed: {
        percentage() {
            if (!this.selfMounted) {
                return 0;
            }

            if (this.isIndeterminate) {
                return 0.25;
            }

            return value ? clampPercentage((value - minValue) / (maxValue - minValue), 1) : 0;
        },

        pathDescription() {
            return `
        M 18 2
        a 16 16 0 0 1 0 32
        a 16 16 0 0 1 0 -32
      `;
        },

        circleContainerStyles() {
            return {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: `${this.size}px`,
                height: `${this.size}px`,
            };
        },
    },
};
</script>     

<style scoped>
@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.circular-chart {
  max-width: 100%;
  max-height: 100%;
}

.circle-bg {
  stroke-linecap: round;
}

.circle {
  stroke-linecap: round;
  transition: stroke-dasharray 0.5s ease-in-out;
}

.percentage {
  fill: #333;
  font-size: 0.625rem;
  font-family: sans-serif;
  text-anchor: middle;
}
</style>