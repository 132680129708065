import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,

    state: () => {
        return {
            projects: [],
            recentProjects: [],
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
            projectInvoices: {
                list: [],
                pageData: { 
                    currentPage: 1,
                    pageSize: 50,
                    total: 0,
                    totalPages: 1,
                },
            },
            currentProject: {
                hourlyRate: {
                    currency: "",
                    amount: 0,
                },
                tags: [],
                clients: [],
            },
            refreshList: true,
        };
    },

    getters: {
        projects: (state) => state.projects,
        pageData: (state) => state.pageData,
        currentProject: (state) => state.currentProject,
        projectInvoices: (state) => state.projectInvoices,
        currentProjectTask: state => state.currentProjectTask,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },
        
        addProject({ state, commit}, payload) {
            return api.project.create(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["projects", [ data, ...state.projects ]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: Math.ceil(state.projects.length / 50),
                    }]);
                    return data;
                });
        },
        
        fetchProjects({ commit, state }, { params, refresh, config = {}, external = false }) {
            if (!refresh && state.projects.length) return;
            
            return api.project.getAll(params, config)
                .then((resp) => {
                    if (external) {
                        return resp?.data?.data;
                    } else if (config?.responseType) {
                        const data = resp?.data;
                        return data;
                    } else {
                        const data = resp?.data?.data;
    
                        commit("set", ["projects", data.data]);
                        commit("set", ["pageData", {
                            ...state.pageData,
                            ... data.pageDetails,
                        }]);
                    }
                });
        },

        getProject({ commit }, id) {
            return api.project.getOne(id)
                .then((resp) => {
                    const data = resp?.data?.data;
                    
                    commit("set", ["currentProject", data[0]]);
                    return data[0];
                });
        }, 

        updateProject({ commit, state }, payload) {
            return api.project.updateOne(payload?._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    const projects = state.projects;

                    const updatedProjects = projects.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["projects", updatedProjects]);         
                    commit("set", ["currentProject", {
                        ...state.currentProject,
                        ...data,
                    }]);
                });
        },

        deleteProjects({ state, commit }, ids) {
            return api.project.delete({ ids: ids })
                .then((resp) => {
                    const projects = state.projects;
            
                    const updatedProjects = projects.filter(c => !ids.includes(c?._id));

                    commit("set", ["projects", updatedProjects]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedProjects.length / 50),
                    }]);
                });
        },

        sortProjects({ commit, state }, sortVal) {
            const copiedArr = state.projects.slice();
            
            sortList(sortVal, copiedArr, "title");
                        
            commit("set", ["projects", copiedArr]);
        }, 

        fetchRecentProjects({ commit, state }) {
            if (state.recentProjects.length) {
                return state.recentProjects;
            }

            return api().project.getAll(params)
                .then((resp) => {
                    const data = resp?.data?.data;

                    commit("set", ["recentProjects", data.data]);
                    return data?.data;
                });
        },

        uploadMutlipleProjects(_, { formData, progressCB }) {            
            return api.project.upload(formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: progressCB })
                .then((resp) => {});
        },

        archiveProject({ state, commit }, id) {            
            return api.project.archive(id)
                .then((resp) => {
                    const data = resp.data.data;
                    const projects = state.projects;

                    const updatedProjects = projects.map(c => {
                        if (c?._id === data?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });

                    commit("set", ["projects", updatedProjects]); 
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedProjects.length / 50),
                    }]);
                });
        },

        resetCurrentProject({ commit }) {
            commit("set", ["currentProject", { 
                hourlyRate: {
                    currency: "",
                    amount: 0,
                },
                tags: [],
                clients: [],
            }]);
            commit("set", ["projectInvoices", { 
                list: [],
                pageData: { 
                    currentPage: 1,
                    pageSize: 50,
                    total: 0,
                    totalPages: 1,
                },
            }]);
        },

        updateProjectSubs(_, { id, payload }) {
            return api.project.updateSub(id, payload)
                .then((resp) => {});
        },

        updateProjectSubsComplete({ state, commit }, { id, key, list }) {
            const projects = state.projects;

            const updatedProjects = projects.map(c => {
                if (c?._id === id) {
                    return {
                        ...c,
                        [`${key}`]: list,
                    };
                } else {
                    return c;
                }
            });

            commit("set", ["projects", updatedProjects]);         
            commit("set", ["currentProject", {
                ...state.currentProject,
                [`${key}`]: list,
            }]);
        },

        removeItemFromStore({ commit }, { storageKey, stateKey, value }) {
            localStorage.removeItem(storageKey);
            commit("set", [`${stateKey}`, value]);
        },
        
        // ------------- INVOICES ------------------
        fetchProjectInvoices({ commit, state }, { id, params, refresh }) {
            return api.project.getInvoices(id, params)
                .then((resp) => {
                    const data = resp.data.data;

                    console.log({ fetchProjectInvoices: data });

                    if (params?.isScroll) {
                        commit("set", ["projectInvoices", { 
                            ...state.projectInvoices, 
                            list: [ ...state.projectInvoices.projects, ...data.data ], 
                        }]);
                    } else {
                        commit("set", ["projectInvoices", { 
                            ...state.projectInvoices, 
                            list: data.data, 
                        }]);
                    }

                    commit("set", ["projectInvoices", { 
                        ...state.projectInvoices, 
                        pageData: data.pageDetails,
                    }]);
                });
        }, 

        // ------------ NOTES ---------------
        getNotes(_, { id, params }) {
            return api.project.getNotes(id, params)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        addNewNote(_, payload) {
            return api.project.createNote(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        updateNote(_, payload) {
            return api.project.editNote(payload.projectId, payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },
        
        deleteNote(_, payload) {
            return api.project.deleteNote(payload.projectId, payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        // ------------- TASKS ------------------
        fetchTasks(_, { params, refresh }) {
            return api.task.getTasks(params)
                .then((resp) => {
                    console.log({ "resp?.data?.data": resp?.data?.data });
                    return resp?.data?.data.data;
                });
        },

        addTask(_, { payload, meta }) {
            return api.task.addTask(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        editTask(_, payload) {
            return api.task.editTask(payload?._id, payload)
                .then((resp) => {
                    return resp.data?.data;
                });
        },

        deleteTask(_, id) {
            return api.task.deleteTask(id)
                .then((resp) => {});
        },
    },
};