<template>
    <div class="report__item__wrap">
        <div>
            <BaseCard class="pl--10 pr--10" is-shadow>
                <template #card-body>
                    <div class="report__item__header">
                        <p class="text--medium text--base">
                            Number of clients created per month
                        </p>
                    </div>
                    <template v-if="transformedData.clientsByMonth.length">
                        <column-chart :data="transformedData.clientsByMonth" />
                    </template>
                    <template v-else>
                        <p>0 clients available</p>
                    </template>
                </template>
            </BaseCard>
            
            <div class="report__item mt--40">
                <div class="flex-2">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <div class="report__item__header">
                                <p class="text--medium text--base">
                                    Client status distribution for {{ currentYear }}
                                </p>
                            </div>
                            
                            <div>
                                <div class="flex align-items-center mb--35 mt--15">
                                    <div class="report__item__total mr--25">
                                        <p class="text--color-gray-600 text--semi--bold text--2xl mb--5">
                                            {{ transformedData.totalNum }}
                                        </p>
                                        <p class="text--xs text--color-gray-700">
                                            Total number of clients
                                        </p>
                                    </div>

                                    <div class="report__item__total">
                                        <div class="flex align-items-center">
                                            <span class="text--color-gray-600 text--semi--bold text--2xl mr--5 mb--5">
                                                {{ transformedData.sumActiveClients }}
                                            </span>
                                            <span class="stats__rate green">
                                                <span class="stats__rate__value">{{ activeRate }}%</span>
                                            </span>
                                        </div>
                                        <p class="text--xs text--color-gray-700">
                                            Total number of active clients
                                        </p>
                                    </div>
                                </div>
                                
                                <template v-if="transformedData.clientsByStatus.length">
                                    <bar-chart :data="transformedData.clientsByStatus" />
                                </template>
                                <template v-else>
                                    <p>0 clients available</p>
                                </template>
                            </div>
                        </template>
                    </BaseCard>
                </div>

                <div class="flex-1 ml--20">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <div class="report__item__header">
                                <p class="text--medium text--base">
                                    Client distribution by country
                                </p>
                            </div>
                            <template v-if="transformedData.clientsByCountry.length">
                                <pie-chart :data="transformedData.clientsByCountry" />
                            </template>
                            <template v-else>
                                <p>0 clients available</p>
                            </template>
                        </template>
                    </BaseCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseCard } from "@/components/ui/index";
import { getMonthFromNum } from "@/utils/helpers";

export default {
    name: "ClientReports",

    components: {
        BaseCard,
    },

    computed: {
        currentYear() {
            return this.$store.getters["report/currentYear"];
        },

        reportItemType() {
            return this.$store.getters["report/reportItemType"];
        },

        reportDataProps() {
            return this.$store.getters["report/reportData"][this.reportItemType];
        },

        transformedData() {
            if (!this.reportDataProps) return {};
            
            const data = {};

            const keys = Object.keys(this.reportDataProps);

            if (!keys.length) return {};

            for (let i = 0; i < keys.length; i++) {
                const item = keys[i];
                
                const list = this.reportDataProps[item]; // an array

                switch(item) {
                case "clientsByCountry":
                    data.clientsByCountry = list.map(i => (i?.data)) || [];
                    break;
                        
                case "clientsByMonth":
                    data.clientsByMonth = list.map(i => ([this.getMonthFromNum(i?._id), i?.count])) || [];
                    break;

                case "sumActiveClients":
                    data.sumActiveClients = list.length && list[0]?.count;
                    break;

                case "clientsByStatus":
                    data.clientsByStatus = list.map(i => (i?.data)) || [];
                    break;


                case "totalNum":
                    data.totalNum = list.length && list[0]?.count || 0;
                    break;

                default:
                    break;
                }
            }

            return data;
        },

        activeRate() {
            if (!this.transformedData.sumActiveClients || !this.transformedData.totalNum) return 0;

            const percentageValue = (this.transformedData.sumActiveClients / this.transformedData.totalNum) * 100;

            return percentageValue && typeof percentageValue === "number" && percentageValue.toFixed(2);
        },
    },

    methods: {
        getMonthFromNum,
    },
};
</script>