<template>
    <div
        :id="drawerId"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        :aria-labelledby="ariaId"
    >
        <div class="offcanvas-header">
            <div class="offcanvas-title-wrap flex align-items-center" :class="[headerClass]">
                <div class="flex align-items-center">
                    <slot name="prefix-header" />
                    <h5 :id="ariaId" class="offcanvas-title text--semi--bold">
                        {{ title }}
                    </h5>
                </div>
                <div>
                    <slot name="suffix-header" />
                </div>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div class="offcanvas-body">
            <slot name="body" />
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseDrawer",

    props: {
        ariaId: {
            type: String,
            default: "",
        },
        drawerId: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        headerClass: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            isPasswordChangeModal: false,
            form: {
                email: "",
                fullName: "",
                phoneNumber: "",
                timeZone: "GMT 21:00 + 1",
            },
            updateProfileLoading: false,
            language: "",
        };
    },
};
</script>

<style lang="scss" scoped>
    .offcanvas-header {
        border-bottom: 1px solid #eee
    }
</style>