<template>
    <div class="h-100">
        <template v-if="pageLoading">
            <div class="flex justify-content-center align-items-center pt-5 mb--45">
                <div
                    class="spinner-border text-primary"
                    role="status"
                    style="color: #5750ec !important"
                >
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </template>
        <template v-else>
            <auth-form :auth-type="'resetPassword'" :loading="loading" @submit="handleReset" />
        </template>
    </div>
</template>

<script>
import AuthForm from "../components/auth/auth-form.vue";

export default {
    name: "ResetView",

    components: {
        AuthForm,
    },

    data() {
        return {
            pageLoading: false,
            token: "",
            email: "",
            userId: "",
            loading: false,
        };
    },

    mounted() {
        // this.validateToken();
        this.token = this.$route.query.token;
        this.email = this.$route.query.email;
    },

    methods: {
        validateToken() {
            this.loading = true;
            // this.token = this.$route.query.token;
            // this.email = this.$route.query.email;

            setTimeout(() => {
                this.loading = false;
            // this.$route.query = {}
            }, 3000);
        },

        handleReset() {
            if (!this.email || !this.token) return;
            
            const payload = { 
                email,
                token,
                password, 
            };
        },
    },
};
</script>