<template>
    <BaseCard>
        <template #card-body>
            <div>
                <span class="text--sm text--color-gray-700">{{ statTitle || "-" }}</span>
            </div>
            <div class="mt--10 mb--10">
                <span class="text--xl text--semi--bold">{{ $map_symbol(currency.toLowerCase()) || "-" }}{{ $format_amount(data.amount) || "0.00" }}</span>
            </div>
            <div class="flex align-items-center">
                <div class="stats__rate" :class="[color || transformedStat.color]">
                    <IconSvg 
                        :name="transformedStat.color === 'green' ? 'up-arrow-alt' : 'down-arrow-alt'" 
                        width="16px" 
                        height="16px" 
                    />
                    <span class="stats__rate__value">{{ transformedStat.value }}%</span>
                </div>
                <p class="text--color-gray-200 text--xs">
                    {{ statDesc }}
                </p>
            </div>
        </template>
    </BaseCard>
</template>

<script>
import { BaseCard, IconSvg } from "@/components/ui/index";
import { getMonth } from "@/utils/helpers";

export default {
    name: "RevenueStats",

    components: {
        BaseCard,
        IconSvg,
    },

    props: {
        data: {
            type: Object,
            default: () => {},
        },
        color: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
    },

    computed: {
        currentYear() {
            return this.$store.getters["revenue/currentYear"];
        },

        currency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        monthlyTarget() {
            return this.$store.getters["app/userSettings"]?.general?.monthlyTarget;
        },

        currentMonthlyTarget() {
            return this.$store.getters["app/userSettings"]?.general?.currentMonthlyTarget;
        },

        currentMonth() {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            const  d = new Date();  

            return months[d.getMonth()];
        },

        statTitle() {
            switch(this.data.revenueType) {
            case "current_total_month": 
                return `Current revenue for ${this.currentMonth}`;
            
            case "average_month": 
                return "Average revenue per month";
            
            case "average_quartely": 
                return "Average quarterly revenue";
                
            case "current_total_year":  
                return `Current total revenue for ${this.currentYear}`;
                
            default: 
                return "";
            }
        },

        statDesc() {
            switch(this.data.revenueType) {
            case "current_total_month": 
                return "vs last month";
        
            case "average_month": 
                return "vs last average";

            case "average_quartely":
                return "vs last quarter";
            case "current_total_year":  
                return "All year";
            default: 
                return "";
            }
        },

        transformedStat() {
            if (this.type !== "revenue_stats") return this.data;

            let updatedAct = this.data;
                        
            if (!this.currentMonthlyTarget || !this.currentMonthlyTarget) return {
                ...updatedAct,
                color: "blue",
                value: 0,
            };

            if (this.data.revenueType === "current_total_month") {
                const percentageValue = ((this.data.amount - this.currentMonthlyTarget) / this.currentMonthlyTarget) * 1000;
                updatedAct.value = percentageValue;
                
                if (this.data.amount > this.currentMonthlyTarget) {
                    updatedAct.color = "green";
                } else {
                    updatedAct.color = "red";
                }
            } else if (this.data.revenueType === "average_month") {
                const percentageValue = ((this.data.amount - this.monthlyTarget) / this.monthlyTarget) * 1000;
                updatedAct.value = percentageValue;
                
                if (this.data.amount > this.monthlyTarget) {
                    updatedAct.color = "green";
                    updatedAct.iconName = "up-arrow-alt";
                } else {
                    updatedAct.color = "red";
                }
            } else if (this.data.revenueType === "average_quartely") {
                const percentageValue = ((this.data.amount.currentQuarter - this.data.amount.previousQuarter) / this.data.amount.previousQuarter) * 100;
                updatedAct.value = percentageValue;
                
                if (this.data.amount.currentQuarter > this.data.amount.previousQuarter) {
                    updatedAct.color = "green";
                } else {
                    updatedAct.color = "red";
                }
            } else {
                updatedAct.value = 0;
                updatedAct.color = "blue";
            }

            return updatedAct;
        },
    },
    
    methods: {
        getMonth,

        mapStatTitle(act) {
            switch (act) {
            case "current_total_month": return `Current revenue for ${this.getMonth()}`;
            case "average_month": return "Average revenue per month";
            case "average_quartely": return "Average quarterly revenue";
            case "current_total_year": return `Current total revenue for ${this.currentYear}`;
            default: return "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.progress__line {
    width: 100%;
    background-color: #E6E6E6;

    &__inner {
        height:3px;
    }
}
</style>