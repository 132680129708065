<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            :is-close-button="true" 
            size="sm"
            :is-show-header="true"
            is-footer
            :title="`Delete ${type}`"
            @close="$emit('cancel')"
        >
            <template #body>
                <div class="modal__wrapper--onboarding">
                    <div class="modal--section">
                        <div class="mb--20 mt--20">
                            <template v-if="!customMsg">
                                <p v-if="items && items.length" class="text--sm">
                                    {{ 
                                        `Are you sure you want to delete these ${items.length} ${type}s?`
                                    }}
                                </p>
                                <p v-else class="text--sm">
                                    {{ `Are you sure you want to delete this ${type}?` }}
                                </p>
                            </template>
                            <template v-else>
                                <slot name="custom-msg" />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        class-names="mr--5" label="Cancel" 
                        btn-style="secondary" size="sm"
                        @submit="$emit('cancel')"
                    />
                    <base-button 
                        label="Delete" 
                        btn-style="danger" 
                        :loading="loading" 
                        size="sm" 
                        @submit="$emit('delete')" 
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "ConfirmDeletion",

    components: { MainModal, BaseButton },

    props: {
        type: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => [],
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        customMsg: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel", "delete"],
};
</script>