<template>
    <div>
        <div class="dropdown">
            <button 
                id="sortFilter" 
                class="btn--filter btn text--xs btn-sm text--bold mr--5" 
                type="button"
                aria-expanded="false"
                data-bs-toggle="dropdown" 
                data-bs-auto-close="true" 
            >
                <span class="mr--5 flex align-items-center">
                    <icon-svg name="sort" fill="#637579" width="16px" height="16px" />
                    <span class="ml--5 text--xs">Sort</span>
                </span>
            </button>
            <ul id="sortFilterList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="sortFilter">
                <li 
                    v-for="(item, index) in displayTypeList" 
                    :key="index" 
                    :class="{ 'selected': value === item }"
                    class="dropdown-item pointer text--xs" 
                    @click="setDisplayType(item)"
                >
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "SortFilter",

    components: { IconSvg },
    
    props: {
        value: {
            type: String,
            default: "Ascending",
        },
    },
    
    data() {
        return {
            displayTypeList: ["Ascending", "Descending"],
        };
    },

    methods: {
        setDisplayType(val) {
            this.$emit("setType", val);
            setTimeout(() => {
                document.getElementById("sortFilter").classList.remove("show");
                document.getElementById("sortFilterList").classList.remove("show");
            }, 100);
        },
    },
};
</script>