<template>
    <div class="w--100 landing__section">
        <div class="w--100">
            <div class="join__waitlist">
                <span class="join__waitlist__input">
                    <form-input 
                        id="email"
                        v-model.trim="email"
                        type="text"
                        :size="'md'"
                        required
                        autocomplete=""
                        :html-for="'email'" 
                        :placeholder="'Enter email address here'"
                        :no-margin-bottom="true"
                        :border-style="'dashed'"
                        @update:model-value="clearErrMsgOnInput"
                    />
                </span>
                <span class="join__waitlist__button">
                    <BaseButton 
                        :label="'Join our waitlist'" 
                        :loading="loading" 
                        :disabled="loading"
                        @submit="submitWaitList" 
                    />
                </span>
            </div>
            <div v-if="showErrMsg" class="err_msg">
                <p class="form__err-msg">
                    {{ error.msg }}
                </p>
            </div>
        </div>
        <join-waitlist-success :show-modal="showSuccessModal" @close="closeSuccessModal" />
    </div>
</template>

<script>
import BaseButton from "@/components/ui/buttons/BaseButton";
import FormInput from "@/components/ui/input/FormInput";
import { mapActions } from "vuex";
import JoinWaitlistSuccess from "@/components/ui/modals/JoinWaitlistSuccess.vue";

export default {
    components: {
        BaseButton,
        FormInput,
        JoinWaitlistSuccess,
    },

    data() {
        return {
            email: "",
            isEmailErr: false,
            loading: false,
            error: {
                show: false,
                msg: "",
            },
            isDuplicate: false,
            showSuccessModal: false,
        };
    },

    computed: {
        showErrMsg() {
            if (this.isEmailErr) {
                return true;
            } else if (this.error.show) {
                return true;
            } else if (this.isDuplicate) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        ...mapActions([
            "joinWaitlist",
        ]),

        checkIfEmailIsValid() {
            if (!this.email) {
                return false;
            } 

            const emailRegex = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(this.email);

            if (!emailRegex) {
                return false;
            }

            return true;
        },

        closeSuccessModal() {
            this.showSuccessModal = false;
        },

        clearFields() {
            this.error.show = false;
            this.error.msg = "";
            this.isDuplicate = false;
            this.showSuccessModal = false;
            this.isEmailErr = false;
        },

        clearOnSubmit() {
            this.clearFields();
            this.email = "";
        },

        async submitWaitList() {
            const isEmailValid = this.checkIfEmailIsValid();

            if (!isEmailValid) {
                this.isEmailErr = true;
                this.error.show = true;
                this.error.msg = "Please enter a valid email address";
                return;
            };

            try {
                this.loading = true;
                this.clearFields();

                const result = await this.joinWaitlist({ email: this.email });

                if (result.code === "duplicate_01" || result.code === "waitlist_00") {
                    this.isDuplicate = true;
                    this.error.show = true;
                    this.error.msg = "Looks like you've signed up with us already!";
                }

                if (result.code === "waitlist_01") {
                    this.showSuccessModal = true;
                }
            } catch (error) {
                const errMsg = error?.response?.data?.message;
                this.error.show = true;
                this.error.msg = errMsg;
            } finally {
                this.loading = false;
            };
        },

        clearErrMsgOnInput(value) {
            if (this.error.show && value) {
                this.clearFields();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .err_msg {
        width: 70%;
        text-align: left;
        margin: auto;
        padding-left: 15px;
    }
</style>