<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            is-close-button is-form
            size="sm"
            :title="actionType === 'add' ? 'Add client' : 'Edit client'"
            @close="closeModal"
        >
            <template #body>
                <div class="">
                    <template v-if="!showUpgradeAccountInfo">
                        <form @submit.prevent="">
                            <div class="client__modal__body mb--10" :class="{ 'scroll-height': !showUpgradeAccountInfo }">
                                <template v-if="step === 1">
                                    <FormInput 
                                        id="clientName"
                                        v-model="form.clientName"
                                        size="sm"
                                        info-type="required"
                                        label="Name"
                                    />
        
                                    <FormInput 
                                        id="companyName"
                                        v-model="form.companyName"
                                        size="sm"
                                        info-type="required"
                                        label="Company Name"
                                    />
        
                                    <FormInput 
                                        id="title"
                                        v-model="form.email"
                                        type="email"
                                        size="sm"
                                        :is-error="isEmailInvalid"
                                        :err-msg="'Please enter a valid email address'" 
                                        info-type="required"
                                        :label="'Email address'"
                                        required
                                    />

                                    <div class="form__row">
                                        <div class="form--control flex-1 mr--10" :class="{ 'flex-basis-45': actionType === 'add' }">
                                            <FormLabel classes="text--color-gray-200" title="Industry" />
                                            <select v-model="form.industry" class="form-select form-select-sm text--sm text--capitalize">
                                                <option v-for="industry in industries" :key="industry" :value="industry">
                                                    {{ industry }}
                                                </option>
                                            </select>
                                        </div>
                                        <div v-if="actionType === 'add'" class="form--control flex-1" :class="{ 'flex-basis-45': actionType === 'add' }">
                                            <FormLabel classes="text--color-gray-200" title="Status" />
                                            <select v-model="form.status" class="form-select form-select-sm text--sm text--capitalize">
                                                <option v-for="status in CLIENT_STATUSES" :key="status" :value="status">
                                                    {{ status }}
                                                </option>  
                                            </select>
                                        </div>
                                    </div>
                                </template>

                                <template v-else>
                                    <div v-if="actionType === 'add'" class="client__group">
                                        <div class="mb--5 flex align-items-center">
                                            <p class="text--md text--color-gray-500 text--medium">
                                                Billing Details
                                            </p>
                                        </div>
    
                                        <div v-if="showBillingInput" class="form--control mb--15">
                                            <multiple-billing-emails
                                                :is-flex="false"
                                                :items="form.ccEmails"
                                                :classes="'w--100'"
                                                @add="addEmail"
                                                @delete="removeEmail"
                                            />
                                            <p v-if="numberOfBillingEmailsLeft != 0" class="text--xs text--color-warning">
                                                {{ numberOfBillingEmailsLeft }} email {{ numberOfBillingEmailsLeft === 1 ? 'address' : 'addresses' }} left
                                            </p>
                                        </div>
    
                                        <div class="form__row mb--15">
                                            <div class="form--control flex-1 mr--10">
                                                <FormLabel :classes="'text--color-gray-200'" :title="'Country'" />
                                                <CustomDropdown 
                                                    id="countryDropdown" :items="countryList"
                                                    list-type="country" item-label="name"
                                                    show-search-field :default-value="form.country"
                                                    @search="searchCountry" @select="selectCountry"
                                                />
                                            </div>
            
                                            <div class="form--control flex-1">
                                                <FormInput 
                                                    id="phoneNumber"
                                                    v-model="form.phoneNumber"
                                                    type="text" size="sm" no-margin-bottom
                                                    :label="'Phone Number'"                                    
                                                />
                                            </div>
                                        </div>
    
                                        <div class="form--control mb--15">
                                            <FormInput 
                                                id="address"
                                                v-model="form.address" label="Address"  
                                                type="text" size="sm"
                                            />
                                        </div>
    
                                        <div class="form__row mb--15">
                                            <div class="form--control flex-1 mr--10">
                                                <FormInput 
                                                    id="city" v-model="form.city"           
                                                    label="City" no-margin-bottom
                                                    type="text" size="sm"
                                                />
                                            </div>
            
                                            <div class="form--control flex-1">
                                                <FormInput 
                                                    id="city" v-model="form.state"           
                                                    label="State" no-margin-bottom
                                                    type="text" size="sm"
                                                />
                                            </div>
                                        </div>
    
                                        <div class="form--control">
                                            <FormInput 
                                                id="zipCode"
                                                v-model="form.zipCode" label="Zip code"  
                                                type="text" size="sm"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="modal__footer">
                                <div class="w--100 flex justify-content-end align-items-center">
                                    <template v-if="!showUpgradeAccountInfo">
                                        <Base-Button
                                            class-names="mr--5" :label="step === 1 ? 'Cancel' : 'Go back'"
                                            size="sm" btn-style="secondary" :disabled="loading"
                                            @submit="closeModalBtn"
                                        />
                                        <Base-Button 
                                            :label="btnLabel"
                                            size="sm" :loading="loading" :disabled="isBtnDisabled"
                                            @submit="handleSubmit"                                       
                                        />
                                    </template>
                                    <template v-else>
                                        <Base-Button 
                                            label="Upgrade" size="sm"
                                            @submit="upgradeAccount"
                                        />
                                    </template>
                                </div>
                            </div>
                        </form>
                    </template>
                    <template v-else>
                        <div>
                            <span class="text--sm">You have exceeded the allowed number of clients for your account. <strong>Upgrade</strong> your account in order to create more clients</span>
                        </div>
                    </template>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import countryList from "@/assets/js/countrycodes.json";
import MainModal from "./MainModal.vue";
import FormLabel from "../forms/FormLabel.vue";
import { industryData } from "@/utils/dummy";
import MultipleBillingEmails from "../input/MultipleBillingEmails.vue";
import { validateEmail } from "@/utils/helpers";
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";
import { CLIENT_STATUSES } from "@/utils/enums";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";

export default {
    name: "CreateClient",

    components: {
        MainModal,
        FormLabel,
        MultipleBillingEmails,
        BaseButton,
        FormInput,
        CustomDropdown,
    },

    props: {
        actionType: { type: String, default: "add" },
        showBillingInput: { type: Boolean, default: true },
        clientData: { type: Object, default: () => {} },
        showModal: { type: Boolean, default: false },
    },

    data() {
        return {
            countryList,
            form: {
                _id: "",
                clientName: "",
                companyName: "",
                email: "",
                phoneNumber: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
                dialCode: "",
                status: "inactive",
                rating: 0,
                zipCode: "",
                state: "",
                city: "",
            },
            industries: industryData, 
            loading: false,  
            isEmailInvalid: false,
            CLIENT_STATUSES,
            step: 1,
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.form.clientName || !this.form.email || !this.form.companyName) {
                return true;
            } else if (this.loading) {
                return true;
            } else {
                return false;
            }
        },

        numberOfBillingEmailsLeft() {
            if (this.form.ccEmails.length > 0) {
                return 3 - this.form.ccEmails.length;
            } else {
                return 0;
            }
        },

        accountType() {
            return this.$store.getters["auth/profile"]?.accountType;
        },

        clientCount() {
            return this.$store.getters["clients/pageData"]?.total;
        },

        showUpgradeAccountInfo() {
            if (this.accountType === "standard" && this.clientCount >= 30 && this.actionType === "add") {
                return true;
            } else {
                return false;
            }
        },

        btnLabel() {
            if (this.actionType === "edit") {
                return "Save";
            }

            if (this.actionType === "add") {
                if (this.step === 1) {
                    return "Next";
                } else {
                    return "Add";
                }
            }
            return "Save";
        },
    },

    watch: {
        clientData: {
            handler(newVal, oldVal) {
                if (newVal?._id || oldVal?._id) {
                    this.fillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        closeModal() {
            this.resetForm();
            this.$emit("close", false);
        },

        closeModalBtn() {
            if (this.step === 1) {
                this.closeModal();
            } else {
                this.step = 1;
                return;
            }
        },

        resetForm() {
            this.form = {
                clientName: "",
                companyName: "",
                email: "",
                phoneNumber: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
                dialCode: "",
                status: "",
                zipCode: "",
                state: "",
                city: "",
            };
            this.step = 1;
        },

        fillForm(val) {
            this.form = {
                _id: val?._id || "",
                clientName: val?.name || "",
                companyName: val?.companyName || "",
                email: val?.email || "",
                phoneNumber: val?.phoneNumber || "",
                country: val?.country || "",
                zipCode: val?.zipCode || "",
                city: val?.city || "",
                state: val?.state || "",
                address: val?.address || "",
                industry: val?.industry || "",
                ccEmails: val?.ccEmails || [],
                status: val?.status || "",
            };
        },

        validateEmailInput(e) {
            const value = e?.target?.value;

            if (!value.includes("@")) return;

            const res = validateEmail(value);
             
            if (!res) {
                this.isEmailInvalid = true;
            } else {
                this.isEmailInvalid = false;
            }
        },

        async createNewClient() {
            try {    
                this.loading = true;

                const payload = {
                    name: this.form.clientName,
                    email: this.form.email || null,
                    phoneNumber: this.form.phoneNumber || null,
                    ccEmails: this.form.ccEmails,
                    country: this.form.country?.name || null,
                    address: this.form.address || null,
                    industry: this.form.industry || null,
                    companyName: this.form.companyName || null,
                    city: this.form.city ,
                    state: this.form.state ,
                    address: this.form.address,
                    zipCode: this.form.zipCode,
                    status: this.form.status || "inactive",
                };
    
                const resp = await this.$store.dispatch("clients/addClient", payload);

                this.$toast.success("Client created");

                this.resetForm();
                this.$emit("complete", resp);
            } catch (error) {
                console.log({ error });
                const errMsg = error?.response?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async updateClient() {
            try {
                if (!this.form._id) {
                    throw new Error("Client id is required");
                }

                this.loading = true;
    
                const payload = {
                    _id: this.form._id,
                    name: this.form.clientName,
                    email: this.form.email || null,
                    industry: this.form.industry || null,
                    companyName: this.form.companyName || null,
                };
    
                const res = await this.$store.dispatch("clients/updateClient", payload);

                this.$toast.success("Client info updated");
                this.$emit("complete", res);
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleSubmit() {
            if (this.showUpgradeAccountInfo) {
                this.upgradeAccount();
            }
            
            if (this.actionType === "edit") {
                await this.updateClient();
            } else if (this.actionType === "add") {
                if (this.step === 2) {
                    await this.createNewClient();
                } else {
                    this.step = 2;
                    return;
                }
            }
        },

        removeEmail(email) {
            this.form.ccEmails = this.form.ccEmails.filter(item => item !== email);
        },

        addEmail(value) {
            this.form.ccEmails.push(value);
        },

        computePhoneNumber(dialCode) {
            if (dialCode) {
                this.form.dialCode = dialCode;
            }
            return;
        },

        upgradeAccount() {
            this.closeModal();
            this.$router.push("/dashboard/settings/billings-and-plans");
        },

        searchCountry(obj) {
            if (!obj) {
                this.countryList = countryList;
            } else {
                this.countryList = this.countryList.filter(item => item.name.toLowerCase().includes(obj) );
            }
        },

        selectCountry(item) {
            this.form.country = item;
        },
    },
};
</script>

<style lang="scss" scoped>
.client__modal__body {
    padding: 0px 15px;
    margin-bottom: 10px;
    
    &.scroll-height {
        height: 290px;
        overflow: scroll;
    }
}
</style>