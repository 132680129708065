<template>
    <div id="offcanvasRight" class="offcanvas z_offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            />
        </div>
        <div class="offcanvas-body">
            <div class="invoice__preview--wrap">
                <div class="w--100">
                    <div class="invoice__preview--contents">
                        <div class="invoice__preview--header">
                            <h1>Invoice</h1>
                            <p>{{ invoiceData.invoice.invoiceNo || '-' }}</p>
                        </div>
                        <div class="invoice__preview--card">
                            <div class="top">
                                <div class="left">
                                    <div class="left--item">
                                        <h6 class="text--semi-bold">
                                            Billed to
                                        </h6>
                                        <div>
                                            <p>{{ invoiceData.client.name }}</p>
                                            <p>{{ invoiceData.client.email }}</p>
                                            <p>{{ invoiceData.client.phoneNumber || '-' }}</p>
                                            <p v-if="invoiceData.client.address || invoiceData.client.country">
                                                {{ invoiceData.client.address || '-' }}, {{ invoiceData.client.country || '-' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="left--item mt--40">
                                        <h6 class="text--semi-bold">
                                            Contact & payment information
                                        </h6>
                                        <div>
                                            <p>{{ invoiceData.user.fullName }}</p>
                                            <p>{{ invoiceData.user.email }}</p>
                                            <p>{{ invoiceData.user.phoneNumber || '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="right--item">
                                        <h6 class="text--semi-bold">
                                            Invoice Number
                                        </h6>
                                        <p>
                                            #{{ invoiceData.invoice.invoiceNo || '-' }}
                                        </p>
                                    </div>
                                    <div class="right--item mt--10">
                                        <h6>Date issued</h6>
                                        <p>{{ $format_date(invoiceData.invoice.createdAt) || '-' }}</p>
                                    </div>
                                    <div class="right--item mt--10">
                                        <h6>Due Date</h6>
                                        <p>{{ $format_date(invoiceData.invoice.dueDate) || '-' }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="mt--40 mb--20 text--2xl text--medium">
                                {{ items.length }} {{ computeItemsLengthText }}
                            </div>

                            <table class="table table-light mt--30">
                                <thead>
                                    <tr>
                                        <th scope="col" class="pl--0">
                                            #
                                        </th>
                                        <th scope="col">
                                            Description
                                        </th>
                                        <th scope="col">
                                            Quantity
                                        </th>
                                        <th scope="col">
                                            Price
                                        </th>
                                        <th scope="col">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-for="(item, index) in items" :key="index">
                                    <tr>
                                        <td scope="row">
                                            {{ index + 1 }}
                                        </td>
                                        <td>{{ item.itemName || "N/A" }}</td>
                                        <td>{{ item.itemQuantity || 0 }}</td>
                                        <td>{{ item.itemPrice || 0 }}</td>
                                        <td>{{ item.itemUnit || 0 }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="mt--30">
                                <div class="preview__row">
                                    <div class="text--base">
                                        Subtotal:
                                    </div>
                                    <div class="preview__row__text">
                                        {{ invoiceData.invoice.currency || 'N/A' }} {{ invoiceData.invoice.subtotal || 0 }}
                                    </div>
                                </div>
                                <div class="preview__row">
                                    <div class="text--base">
                                        Tax:
                                    </div>
                                    <div class="preview__row__text">
                                        {{ invoiceData.invoice.currency || 'N/A' }} {{ invoiceData.invoice.tax }}
                                    </div>
                                </div>
                                <div class="preview__row">
                                    <div class="text--base">
                                        Total
                                    </div>
                                    <div class="preview__row__text">
                                        {{ $country.getSymbol(invoiceData.invoice.currency) || 'N/A' }} {{ invoiceData.invoice.total || 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDateStrings } from "../../utils/helpers";

export default {
    name: "PreviewInvoice",
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        invoiceData: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        computeItemsLengthText() {
            if (this.items.length > 1 || !this.items.length) {
                return "items";
            } else {
                return "item";
            }
        },
    },
    
    methods: {
        formatDate(dateString) {
            if (typeof dateString === "object") {
                let stringDate = dateString.toString();
                return formatDateStrings(stringDate) || "N/A";
            }
            return dateString && typeof dateString === "string" ? formatDateStrings(dateString) : "N/A";
        },
    },
};
</script>

<style lang="scss" scoped>
    .offcanvas-end {
        width: 650px;
    }

    thead {
        border-style: solid !important;
    }

    th {
        padding-left: 10px !important;
    }


    .table tbody td {
        overflow-x: auto !important;
        white-space: normal !important;
        text-overflow: unset !important;
    }

    .preview {
        &__row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;
            justify-content: space-between;

            &__text {
                font-size: 0.875rem;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
</style>