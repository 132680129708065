<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div>
        <div class="clients__page--view" :class="{ 'disabled': deleteLoading }">
            <template v-if="fetchClients.loading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchClients.err">
                <EmptyPage state="error">
                    <template #button>
                        <base-button 
                            label="Reload" icon-name="reload" icon-width="16px" 
                            size="sm" is-prefix-icon
                            @submit="handleFetchClients"
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="flex align-items-center justify-content-between width-100">
                    <div v-if="showActionBtns" class="list--count">
                        <p class="text--medium">
                            {{ clients.length }} {{ clients.length !== 1 ? "clients" : "client" }}
                        </p>
                    </div>

                    <div class="filter__actions--list">
                        <template v-if="showActionBtns">
                            <div v-if="selectedClients.length > 0" class="mr--5">
                                <base-button 
                                    btn-style="danger"
                                    size="sm"
                                    @submit="toggleDelete"
                                >
                                    Delete {{ selectedClients.length }} {{ selectedClients.length === 1 ? 'client' : 'clients' }}
                                </base-button>
                            </div>
                            <import-button dropdown-class="mr--5" label="Import" width="16px" icon-name="import" @select="setImportType" />
                            <main-filter page-type="clients" />
                            <sort-filter :value="displayType" @setType="setDisplayType" />
                            <base-button 
                                label="+ Add client" 
                                size="sm"
                                class-names="'flex align-items-center mr--5"
                                @submit="showAddModal = true"
                            />
                            <import-button label="Download" icon-name="download" :disabled="downloadLoading" @select="setDownloadType" />
                        </template>
                    </div>
                </div>

                <div class="w--100 justify-content-center mt--40">
                    <template v-if="!clients.length">
                        <empty-page v-if="isSearched" state="no_result" />
                        <empty-page v-else state="empty" type="client" icon-name="client" width="60px" height="60px">
                            <template #button>
                                <base-button 
                                    label="+ New client" size="sm"
                                    :class-names="'flex align-items-center'" @submit="showAddModal = true"
                                />
                            </template>
                        </empty-page>
                    </template>
                    
                    <template v-else>
                        <div class="flex mt--40">
                            <CustomTable
                                :columns="clientHeaders"
                                :data="clients"
                                :selected-for-deletion="selectedClients"
                                router-name="client-details-view"
                                is-action-btns
                                is-row-link
                                @onRowClick="openClientDetailsPage"
                                @onRowSelected="selectClients"
                            >
                                <template #rows="data">
                                    <td class="text--capitalize">
                                        {{ data.name || "-" }}
                                    </td>
                                    <td class="text--capitalize">
                                        {{ data.companyName || "-" }}
                                    </td>
                                    <td>{{ data.email || "-" }}</td>
                                    <td class="text--capitalize">
                                        {{ data.industry || "-" }}
                                    </td>
                                    <td>
                                        <Status type="client" :value="data.status" />
                                    </td>
                                    <td>{{ $format_date(data.createdAt) || "-" }}</td>
                                </template>
                            </CustomTable>
                        </div>
                        <pagination v-if="showPagination" :pagination-data="pageData" />
                    </template>
                </div>
            </template>            
        </div>

        <CreateClientModal 
            :show-modal="showAddModal" 
            @close="closeCreateModal" 
            @complete="onCreateComplete"
        />

        <ConfirmDeletionTwo
            type="client" custom-msg
            :show-modal="showDeleteModal" :items="selectedClients"
            :loading="deleteLoading" 
            @cancel="showDeleteModal = false" 
            @delete="deleteMultipleClients"
        >
            <template #custom-msg>
                <p class="text--sm">
                    These client(s) will be removed from all related projects, meetings, and invoices. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <FileUploadModal 
            :show="showUploadModal" 
            :mime-types="importType === 'csv' ? 'text/csv' : 'text/json'"
            :file-content="uploadContent" upload-type="client"
            :is-error="uploadErr" :headers="clientUploadHeaders"
            :progress-value="uploadProgress" :is-account-upgrade="showUpgradeAccountInfo"
            @update:files="handleFileUpload" 
        />
    </div>
</template>

<script>
import {
    MainFilter,
    SortFilter,
    Pagination,
    EmptyPage,
    CreateClientModal,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CustomTable,
    ImportButton,
    Status,
    IconSvg,
    FileUploadModal,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { clientHeaders, clientUploadHeaders } from "@/utils/dummy";
import Papa from "papaparse";

export default {
    name: "ClientsPage",

    components: {
        ImportButton,
        CustomTable,
        CreateClientModal,
        ConfirmDeletionTwo,
        EmptyPage,
        Pagination,
        MainFilter,
        SortFilter,
        PageSpinner,
        BaseButton,
        Status,
        IconSvg,
        FileUploadModal,
    },

    data() {
        return {
            clientUploadHeaders,
            clientHeaders,
            showAddModal: false,
            isSearched: false,
            displayType: "",
            selectedClients: [],
            deleteLoading: false,
            showDeleteModal: false,
            fetchClients: {
                loading: false,
                err: false,
                msg: "",
            },
            importType: "",
            downloadType: "",
            showUploadModal: false,
            uploadProgress: 0,
            uploadContent: [],
            uploadErr: false,
            downloadLoading: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["clients/pageData"];
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        showUpgradeAccountInfo() {
            if (this.userProfile?.accountType === "standard") {
                return true;
            }

            if (this.clients.length >= 5) {
                return true;
            }

            return false;
        },

        showActionBtns() {
            if (this.pageData.total) {
                return true;
            } else if (!this.pageData.total && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        refreshList() {
            return this.$store.getters["project/refreshList"];
        },
    },

    watch: {
        "$route": {
            async handler(newVal, oldVal) {
                if (newVal.path === oldVal.path) { // this only fires when same page navigation takes place
                    await this.handleFetchClients({ refresh: true });
                }
            },
        },

        displayType(newType, oldType) {
            if (newType !== oldType) {
                this.sortClients();
            }
        },
    },
    
    async mounted() {
        if (!this.clients.length && this.refreshList) {
            await this.handleFetchClients({ refresh: true });
            this.$store.dispatch("clients/setRefreshList", false);
        }
    },

    methods: {
        openClientDetailsPage(row) {
            if (!row?._id) return;

            this.$router.push(`/dashboard/clients/${row._id}`);
        },

        setImportType(val) {
            this.importType = val;
            this.showUploadModal = true;
        },

        setDownloadType(val) {
            this.downloadClients(val);
        },

        async downloadClients(downloadType) {
            try {
                this.downloadLoading = true;

                const resp = await this.$store.dispatch("clients/fetchClients", { 
                    params: { download: true, type: "all" }, 
                    refresh: true, 
                    config: {
                        responseType: "blob", // Important for handling binary data
                    },
                });

                const headerType = downloadType === "csv" ? "application/csv" : "application/json";
                const fileName = downloadType === "csv" ? "clients.csv" : "clients.json";

                const url = window.URL.createObjectURL(new Blob([resp], { type: headerType }));
                const link = document.createElement("a");
    
                link.href = url;
                link.setAttribute("download", fileName); // or the filename you want
                    
                document.body.appendChild(link);
                link.click();
    
                document.body.removeChild(link);
                
                this.$toast.success("Clients list downloaded");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                console.log({ errMsg });
                this.$toast.error(errMsg);
            } finally {
                this.downloadLoading = false;
            }
        },

        closeCreateModal() {
            if (this.$route.query?.is_client) {
                this.$router.replace("/dashboard/clients");
            }
            this.showAddModal = false;
        },

        async onCreateComplete(resp) {
            this.showAddModal = false;
            this.$router.push({ name:"client-details-view", params:{ id: resp?._id } });
        },

        setDisplayType(val) {
            this.displayType = val;
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        async deleteMultipleClients() {
            try {
                this.deleteLoading = true;

                await this.$store.dispatch("clients/deleteClients", this.selectedClients);

                this.$toast.success("Client(s) deleted");
                
                this.selectClients([]);
                this.showDeleteModal = false;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        sortClients() {
            this.$store.dispatch("clients/sortClients", this.displayType);
        },

        selectClients(val) {
            this.selectedClients = val;
        },

        async handleFetchClients(params) {
            try {
                this.fetchClients.loading = true;
                this.fetchClients.err = false;
                this.fetchClients.msg = "";

                const query = this.$route.query;
                const queryKeys = Object.keys(this.$route.query);

                const pageQueryObj = {
                    page: query.page || 1,
                    type: query.type || "all",
                };

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                if (query.name) {
                    pageQueryObj.name = query.name;
                }

                if (query.status) {
                    pageQueryObj.status = query.status;
                }

                if (query.industry) {
                    pageQueryObj.industry = query.industry;
                }

                if (query.email) {
                    pageQueryObj.email = query.email;
                }

                if (query.startDate) {
                    pageQueryObj.startDate = query.startDate;
                }

                if (query.endDate) {
                    pageQueryObj.endDate = query.endDate;
                }

                const buildResult = assembleQueryList("clients", pageQueryObj);
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchClients.err = true;
                this.fetchClients.msg = errMsg;
            } finally {
                this.fetchClients.loading = false;
            }
        },

        handleFileUpload(files) {
            const file = event?.target?.files[0];

            if (file) {
                this.parseCSVFile(file);
            }
        },

        parseCSVFile(file) {
            Papa.parse(file, {
                header: true,
                complete: this.processCSVData,
                error: this.handleParseError,
            });
        },

        onUploadProgress(event) {
            this.uploadProgress = Math.round((event.loaded * 100) / event.total);
        },

        async processCSVData(results) {
            try {
                this.uploadErr = false;

                const clients = results.data;
                this.uploadContent = clients;

                const formData = new FormData();
                formData.append("clients", JSON.stringify(clients));
                
                await this.$store.dispatch("clients/uploadMutlipleClients", { formData, progressCB: this.onUploadProgress });
                
                this.$toast.success("Clients uploaded successfully");

                this.importType = "";
                this.uploadProgress = 0;
                this.showUploadModal = false;
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.$toast.error(errMsg);
                this.uploadProgress = 0;
                this.uploadErr = true;
            }
        },

        handleParseError(error) {
            console.error("Error parsing CSV file", error);
        },
    },
};
</script>