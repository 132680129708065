<template>
    <div class="dropdown" :class="dropdownClass">
        <div
            v-if="customTrigger"
            :id="id"
            data-bs-toggle="dropdown"
            class="dropdown-toggle w--100"
            data-bs-auto-close="true" 
            aria-expanded="false"
            :type="type"            
            :aria-disabled="disabled"
            :disabled="disabled"
            @click.stop=""
        >
            <slot name="dropdownTrigger" />
        </div>
       
        <div   
            v-else 
            :id="id"
            data-bs-toggle="dropdown"
            class="dropdown-toggle w--100"
            data-bs-auto-close="true" 
            aria-expanded="false"
            type="button"           
            :aria-disabled="disabled"
            :disabled="disabled"
        >
            <button type="button" class="btn btn--outline btn--sm btn--outline__secondary dropdown-btn text--sm w--100">
                <span class="flex align-items-center w--100 justify-content-between dropdown-btn-span">
                    <span class="dropdown-text">{{ displayValue }}</span>
                    <span>
                        <IconSvg name="chevron-down" width="16px" fill="#ced4da" />
                    </span>
                </span>
            </button>
        </div>

        <div class="dropdown-menu w--100">
            <form v-if="showSearchField && filteredItems.length > 5" class="dropdown-form" @submit.prevent="">
                <input
                    v-model="searchQuery"
                    name="search" type="text" autofocus
                    :placeholder="placeholder || `Search ${listType}`"
                    @input="searchList"
                > 
                <span class="pointer" @click="clearSearch">
                    <icon-svg fill="rgba(194, 200, 212, 1)" :name="searchQuery ? 'close' : 'search'" width="16px" /> 
                </span>
            </form>

            <template v-if="loading">
                <div class="text--color-title text--sm p--25 text-center">
                    <PageSpinner class="mt--5 mb--5" />
                </div>
            </template>

            <template v-else>
                <ul v-if="items.length" class="dropdown-list mb--0" :class="{'h--auto': searchQuery || items.length < 4 }">
                    <li
                        v-for="item in filteredItems"
                        :key="getItemKey(item)"
                        tabindex="0"
                        role="button"
                        class="dropdown-item"
                        @click="onSelect(item)"
                        @keyup.enter="onSelect(item)"
                    >
                        <div class="flex flex__item--grow align-items-center">
                            <slot name="prefix">
                                <component
                                    :is="{ template: item.icon }"
                                    v-if="item.icon"
                                    class="country-item__flag"
                                    preserveAspectRatio="none"
                                />
                            </slot>

                            <div class="dropdown-item-label" :class="dropdownLabelClass">
                                {{ getItemLabel(item) }}
                            </div>
                        </div>
                    </li>
                </ul>

                <div v-else class="text--color-title text--sm p--25 text-center">
                    Nothing to see here.
                </div>
            </template>
        </div>
    </div>
</template>
  
<script>  
import IconSvg from "../icons/Icon-Svg.vue";
import PageSpinner from "../loaders/PageSpinner.vue";
import { debounce } from "@/utils/helpers";

export default {
    components: { IconSvg, PageSpinner },

    props: {
        items: { type: Array, default: () => [] },
        type: { type: String, default: "button" },
        listType: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        dropdownClass: { type: String, default: "" },
        itemLabel: { type: String, default: "" },
        indexLabel: { type: String, default: "" },
        dropdownLabelClass: { type: String, default: "" },
        id: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        customTrigger: { type: Boolean, default: false },
        showSearchField: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        modelValue: { type: [String, Object], default: null },
        multi: { type: Boolean, default: false },
    },

    emits: ["update:modelValue", "select", "search"],

    data() {
        return {
            searchQuery: "",
            typingInput: null,
            noResultsFound: false,
            displayValue: "",
        };
    },

    computed: {
        filteredItems() {
            if (!this.searchQuery) return this.items;

            return this.items.filter(item => this.searchValueMatched(item));
        },
    },

    watch: {
        modelValue(newValue) {
            this.updateDisplayValue(newValue);
        },
    },

    methods: {
        onSelect(item) {
            this.$emit("select", item);
            
            if (!this.multi) {
                this.$emit("update:modelValue", item);
                this.updateDisplayValue(item);
            }

            $("#" + this.id).dropdown("hide");
        },

        getItemKey(item) {
            if (typeof item === "string") return item;

            return item[this.indexLabel] || item[this.itemLabel] || JSON.stringify(item);
        },

        getItemLabel(item) {
            if (typeof item === "string") return item;

            return item[this.indexLabel] || item[this.itemLabel] || "";
        },

        clearSearch() {
            this.searchQuery = "";
        },

        searchValueMatched(item) {
            const lowercaseSearch = (this.searchQuery || "").toLowerCase();

            if (typeof item === "string") {
                return item.toLowerCase().includes(lowercaseSearch);
            }

            return item[this.itemLabel]?.toLowerCase().includes(lowercaseSearch);
        },

        updateDisplayValue(value) {
            if (!value) return;

            const item = this.items.find((val) => {     
                if (typeof value === "string" && typeof val === "string") {
                    this.displayValue = val === value && value;
                } else if (typeof value === "object" && typeof val === "object") {
                    if (this.indexLabel) {                        
                        return val[this.indexLabel] === value[this.indexLabel];
                    } 

                    if (this.itemLabel) {
                        return val[this.itemLabel] === value[this.itemLabel];
                    } 

                    return "";
                } else {
                    return "";
                }
            });

            // console.log({ updateDisplayValue: item });

            if (!item) {
                this.displayValue = "";
                return;
            };

            this.displayValue = typeof value === "object" ? item[this.indexLabel] || item[this.itemLabel] : item;
        },

        waitTillTypingIsDone() {
            console.log("waitTillTypingIsDone");
            
            clearTimeout(this.typingInput);
            /**
             * Only make a search request after 800 milliseconds.
             */
            this.typingInput = setTimeout(this.searchList, 300);
        },

        searchList() {
            const query = this.searchQuery;
            
            debounce(() => {
                this.$emit("search", query);
            }, 500, "search-list");
        },
    },
};

</script>
  
<style lang="scss" scoped>
    .dropdown {
        &-form {
            padding: 0.3rem 0.75rem 0.3rem 0.75rem;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            display: flex;
            margin-bottom: 1rem;
        
            input {
                outline: none;
                border: none;
                width: 80%;
                font-size: 0.875rem;
                flex: 2;
            }

            >span {
                width: 10%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex: 1;
            }
        }

        &-list {
            padding-left: 0px;
            padding-right: 0px;
            height: 200px;
            overflow: scroll;
            // margin-top: 12px;
        }

        &-item {
            padding: 0.5rem 1rem !important;
            cursor: pointer;

            &-label {
                font-size: 0.875rem;
                width: 100%;
                max-width: 100%;
                overflow: hidden;
                white-space: pre-wrap;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }
        }

        &-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &:after {
                display: none !important;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: .3em solid;
                border-right: .3em solid transparent;
                border-bottom: 0;
                border-left: .3em solid transparent;
            }
        }

        &-btn-span {
            padding: .25rem 0rem !important;

            .dropdown-text {
                color: #212529;
                font-size: 0.875rem;
                font-weight: 400;
            }
        }
    }
</style>