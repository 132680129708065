import api from "@/api/url";

export default {
    namespaced: true,

    state: () => {
        return {
            tasks: [],
            refreshList: true,
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
            currentTask: {
                project: {
                    _id: "",
                    title: "",
                },
            },
        };
    },

    getters: {
        tasks: (state) => state.tasks,
        pageData: (state) => state.pageData,
        currentTask: (state) => state.currentTask,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },

        fetchTasks({ commit, state }, { params, refresh, config = {} }) {
            if (!refresh && state.tasks.length) return;

            return api.task.getTasks(params, config)
                .then((resp) => {
                    const data = resp?.data?.data;

                    commit("set", ["tasks", data.data]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        ... data.pageDetails,
                    }]);
                });
        },

        addTask({ commit, state }, { payload, meta }) {
            return api.task.addTask(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["tasks", [ ...state.tasks, { ...data, project: meta }]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: Math.ceil(state.tasks.length / 50),
                    }]);
                });
        },

        editTask({ commit, state }, { payload, fullData }) {
            return api.task.editTask(payload?._id, payload)
                .then((resp) => {
                    const data = resp.data?.data;
                    const tasks = state.tasks;
                    
                    let updatedTasks = [];

                    if (payload?.isCompleted) { // this means the task was marked as completed and should be hidden
                        updatedTasks = tasks.filter(c => c._id !== payload?._id);
                    } else {
                        const updatedData = fullData?._id ? fullData : payload;

                        updatedTasks = tasks.map(c => {
                            if (c?._id === payload?._id) {
                                return {
                                    ...c,
                                    ...data,
                                    ...updatedData,
                                };
                            } else {
                                return c;
                            }
                        });
                    }

                    commit("set", ["tasks", updatedTasks]);
                });
        },

        deleteTask({ commit, state }, id) {
            return api.task.deleteTask(id)
                .then((resp) => {
                    const tasks = state.tasks;            
                    const updatedTasks = tasks.filter(c => c._id !== id);

                    commit("set", ["tasks", updatedTasks]);
                });
        },

        setCurrentTask({ commit }, task) {
            commit("set", ["currentTask", task]);
        },

        duplicateTask({ commit, state }, payload) {
            return api.task.duplicateTask(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["tasks", [ ...state.tasks,  data]]);
                });
        },

        // ------------ NOTES ---------------
        updateNotes(_, { taskId, payload }) {
            return api.task.modifyNotes(taskId, payload)
                .then((resp) => resp.data.data);            
        },
    },
};