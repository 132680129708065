<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="content__page">
        <div class="revenue__year__select">
            <p class="text--semi--bold text--color-gray-400 text--2xl">
                Revenue Details
            </p>
            <!-- <div class="dropdown">
                <button class="btn btn--outline__secondary btn--sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ currentYear }}
                </button>
                <ul class="dropdown-menu">
                    <li 
                        v-for="year in ['2024', '2025']" 
                        :key="year" 
                        class="pointer dropdown-item"
                        :class="{ 'selected': currentYear === year }" 
                        @click="handleSetYear(year)"
                    >
                        <span class="text--sm">{{ year }}</span>
                    </li>
                </ul>
            </div> -->
        </div>

        <div>
            <LinkedTabs :list="revenueTabList" />
        </div>
    </div>
</template>

<script>
import { LinkedTabs } from "@/components/ui/index";
import { revenueTabList } from "@/utils/dummy";

export default {
    name: "Revenue",

    components: {
        LinkedTabs,
    },

    data() {
        return {
            revenueTabList,
        };
    },

    computed: {
        currentYear() {
            return this.$store.getters["revenue/currentYear"];
        },
    },

    methods: {
        handleSetYear(year) {
            this.$store.dispatch("revenue/setYear", year);
        },
    },
};
</script>