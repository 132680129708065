<template>
    <div class="h-100 content__page">
        <div>
            <template v-if="pageState.loading">
                <page-spinner />
            </template>

            <template v-else-if="pageState.err">
                <EmptyPage state="error">
                    <template #button>
                        <base-button 
                            label="Reload" icon-name="reload" icon-width="16px" 
                            size="sm" is-prefix-icon
                            @submit="handleFetchRateCards"
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div v-if="isListPresent" class="content__page__header">
                    <div class="list--count">
                        <p class="text--medium text--sm">
                            {{ rateCards.length === 1 ? `${rateCards.length} card` : `${rateCards.length} cards` }}
                        </p>
                    </div>

                    <div class="flex align-items-center">
                        <base-button 
                            label="+ Add new rate card" 
                            size="sm" class="mr--5"
                            @submit="startAddCard"
                        />

                        <template v-if="rateCards.length > 0">
                            <import-button
                                v-if="rateCards.length >= 5 && !isSearched" class="mr--5"
                                label="Import" width="16px" icon-name="import" :list="['csv']"
                                @select="setImportType"
                            />
                            <main-filter page-type="rateCards" />
                            <sort-filter v-if="rateCards.length > 5" :value="displayType" @setType="setDisplayType" />
                        </template>
                    </div>
                </div>

                <div class="content__page__body" :class="{ 'empty': !rateCards.length }">
                    <div v-if="!rateCards.length">
                        <EmptyPage 
                            v-if="!isSearched" state="empty" icon-name="notes" fill="#BDBDBD"
                            width="60px" height="60px" type="rate card"
                        >
                            <template #button>
                                <base-button 
                                    label="+ Add new card" 
                                    size="sm"
                                    @submit="startAddCard"
                                />
                            </template> 
                        </EmptyPage>
                        <EmptyPage v-else state="no_result" />
                    </div>
    
                    <div v-else class="mt--45"> 
                        <div class="rc__view">
                            <div 
                                v-for="item in rateCards" 
                                :key="item._id" class="rc__item"
                                :class="{ 'disabled': selectedCardId === item._id && deleteLoading }"
                            >
                                <div class="rc__item__dropdown">
                                    <div class="left">
                                        <p class="title text--sm text--semi--bold">
                                            {{ item.title }}
                                        </p>
                                    </div>
                                    <div class="right">
                                        <div class="mr--10" @click="openCard(item)">
                                            <IconSvg class="mr--0 flex" name="show" fill="#718096" width="16px" />  
                                        </div>
    
                                        <div
                                            id="rateCardActions" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
                                            class="rc__item__dropdown__toggle"
                                        >
                                            <div class="" tabindex="-1" title="More options">
                                                <icon-svg class="mr--0 flex" name="dots-horizontal-rounded" fill="#718096" width="16px" />  
                                            </div>
                                        </div>
    
                                        <ul class="dropdown-menu dropdown-menu--tag" aria-labelledby="rateCardActions">
                                            <li class="pointer" @click="handleDeleteCard(item._id)">
                                                <p class="dropdown-item text--xs">
                                                    Download as PDF
                                                </p>
                                            </li>
                                            <!-- <li class="pointer" @click="openSendCard(item)">
                                                <span class="dropdown-item block width-100 text--xs">
                                                    Send to client
                                                </span>
                                            </li> -->
                                            <li class="pointer" @click="handleDeleteCard(item._id)">
                                                <p class="dropdown-item text--xs text--color-warning">
                                                    Delete
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="rc__item__inner">
                                    <div class="rc__item__text">
                                        <p class="sub mt--5 text--xs">
                                            <template v-if="item.billableHours && item.rateType">
                                                <span>{{ item.billableHours }}</span>
                                                <span class="ml--5">{{ computeBillingType(item.rateType) }}</span>
                                            </template>
                                            <template v-else>
                                                <span class="text--capitalize">{{ item.rateType }}</span>
                                            </template>
                                        </p>
                                    </div>
                                    <div class="rc__amount">
                                        <p class="text--sm">
                                            {{ $map_symbol(item.currency) }} {{ $format_amount(item.amount) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <pagination v-if="showPagination" :pagination-data="pageData" />
                    </div>        
                </div>
            </template>
        </div>

        <main-modal 
            :show-modal="showAddModal" is-close-button is-show-header is-footer
            size="sm" :title="`${action === 'add' ? 'Add' : 'Edit'} rate card`" @close="closeCard"
        >
            <template #body>
                <div class="mb--15 pr--10 pl--10">
                    <template v-if="!showUpgradeAccountInfo">
                        <form class="rc__form mb--20" @submit.prevent="">
                            <FormInput 
                                id="title"
                                v-model="form.title"
                                size="sm"
                                info-type="required"
                                label="Title"
                            />

                            <FormInput 
                                id="description" v-model="form.description" size="sm" label="Description"
                            />
                            
                            <div class="form__row mb--15">
                                <div class="mr--5 mb--0 flex-1">
                                    <FormLabel title="Currency" html-for="currency" info-type="required" />
                                    <select v-model="form.currency" class="form-select form-select-sm text--sm text--upper">
                                        <option v-for="currency in paymentSupportedCurrencies" :key="currency" :value="currency">
                                            {{ currency }}
                                        </option>
                                    </select>
                                </div>
                               
                                <div class="mb--0 flex-1">
                                    <FormLabel title="Rate type" html-for="rateType" />
                                    <select v-model="form.rateType" class="form-select form-select-sm">
                                        <option v-for="item in rateTypes" :key="item" :value="item" class="text--sm text--capitalize">
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row invoice__row block ml--0 mr--0">
                                <div class="flex justify-content-end pr--0">
                                    <span class="text--xs btn--ghost__primary pointer" @click="addAnotherItem">
                                        + Add new item
                                    </span>
                                </div>
                                <div class="invoice__calculate--wrap invoice__header mt--10 pl--0">
                                    <div class="form__row__left ml--0">
                                        <label class="form-label invoice__calculate--wrap pr--0 pl--0 text--sm mb--0">Service Name
                                            <span class="text--color-warning text--xs"> * </span>
                                        </label>
                                    </div>
                                    <div class="invoice__calculate--row form__row__right ml--0">
                                        <div class="invoice__price flex-1">
                                            <label class="form-label text--sm mb--0">
                                                Amount
                                                <span class="text--color-warning text--xs"> * </span>
                                            </label>
                                        </div>
                                        <div class="invoice__amount flex-1">
                                            <label class="form-label text--sm mb--0">Platform</label>
                                        </div>
                                    </div>
                                </div>

                                <ul class="rc__item__wrap">
                                    <li v-for="(item, i) in form.items" :key="i" class="mr--0 ml--0 pr--0 pl--0">
                                        <div class="invoice__calculate--wrap relative" :class="{ 'mt--20': i > 0 }">
                                            <div class="form__row__left mr--0">
                                                <input v-model="item.serviceName" type="text" class="form-control form-control-sm">
                                            </div>
                        
                                            <div class="form__row__right invoice__calculate--row ml--5">
                                                <div class="invoice__calculate--wrap flex-1 w--40 mr--5">
                                                    <money
                                                        v-model="item.amount" v-bind="config.number"
                                                        class="form-control form-control-sm"
                                                        spellcheck="false" @keydown.native="preventKeys"
                                                    />
                                                </div>
                                                <div class="invoice__amount flex-1 w--40 mr--5">
                                                    <input v-model="item.platform" type="text" class="form-control form-control-sm">
                                                </div>
                                            </div>
                                            <div v-if="i > 0" class="invoice__calculate__close">
                                                <IconSvg name="close" width="16px" fill="#B9B9B9" @click="removeItem(i)" />
                                            </div>
                                        </div>
    
                                        <span v-if="isItemFilled(i)" class="form__errors flex align-items-center">
                                            <IconSvg name="alert" width="16px" fill="#ffea8a" />
                                            <span class="form__errors__text ml--5">
                                                Please fill in the service name and price for this item
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </template>

                    <template v-else>
                        <div>
                            <span class="text--sm">You have exceeded the allowed number of rate cards for your account. <strong>Upgrade</strong> your account to create more cards.</span>
                        </div>
                    </template>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        label="Cancel" size="sm" btn-style="secondary" :disabled="formLoading"
                        @submit="closeCard"
                    />
                    <template v-if="!showUpgradeAccountInfo">
                        <Base-Button 
                            :label="action === 'add' ? 'Add card' : 'Save card'"
                            size="sm" :loading="formLoading" 
                            :disabled="formLoading || isBtnDisabled"
                            type="submit" @submit="handleSubmit" 
                        />
                    </template>
                    <template v-else>
                        <Base-Button label="Upgrade" size="sm" @submit="upgradeAccount" />
                    </template>
                </div>
            </template>
        </main-modal>

        <main-modal 
            :show-modal="showSendModal" is-close-button is-show-header is-footer
            size="sm" title="Send rate card" @close="closeSendCard"
        >
            <template #body>
                <div class="mb--15 pr--10 pl--10">
                    <template v-if="!maxSharedSendLimit">
                        <Alert type="primary" classes="text--sm">
                            <template #text>
                                <p class="text--xs">
                                    Use this option if you want to automatically send a client this rate card with a pre-composed message body.
                                </p>
                            </template>
                        </Alert>

                        <form class="rc__form mb--20" @submit.prevent="">
                            <div class="mb--15">
                                <FormLabel title="Subject" html-for="subject" />
                                <p class="form-control form__input--none form-control-sm disabled">
                                    {{ `Rate card for ${form.title}` }}
                                </p>
                            </div>

                            <div class="mb--0 flex-1">
                                <FormLabel title="Select a client" html-for="rateType" />
                                <CustomDropdown 
                                    id="clientsDropdown" :items="clients" list-type="client" :default-value="client"
                                    :loading="fetchClientsLoading" :is-error="fetchClientsLoadingErr" item-label="name"
                                    show-search-field @select="handleSelectClient" @search="handleFetchClients"
                                />
                            </div>

                            <!-- TO DO: use a transition for a smoother transition -->
                            <template v-if="client._id && form.title">
                                <div class="mb--5 mt--15">
                                    <FormLabel title="Preview" html-for="subject" />
                                </div>

                                <div class="rc__preview">
                                    <p class="rc__preview__text">
                                        To: <span class="ml--5">{{ client.email }}</span>
                                    </p>

                                    <!-- <div v-if="client.ccEmails" class="flex flex-wrap rc__preview__text">
                                        <p>cc: </p>
                                        <p v-for="email in client.ccEmails" :key="email" class="ml--5 text--sm text--color-gray-700">
                                            {{ email }},
                                        </p>
                                    </div> -->

                                    <p class="rc__preview__text">
                                        Hello {{ client.name }},
                                    </p>

                                    <p class="rc__preview__text">
                                        Please find my rate card for {{ form.title }}, as discussed.
                                    </p>

                                    <p class="rc__preview__text">
                                        Thank you for your time. I look forward to speaking with you again!
                                    </p>

                                    <p class="rc__preview__text">
                                        Best regards,
                                    </p>
                                        
                                    <p class="rc__preview__text">
                                        {{ userProfile.fullName }}
                                    </p>
                                </div>
                            </template>
                        </form>
                    </template>

                    <template v-else>
                        <div>
                            <span class="text--sm">You have exceeded the max limit for sending rate cards to clients. <strong>Upgrade</strong> your account to send more cards.</span>
                        </div>
                    </template>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        label="Cancel" size="sm" btn-style="secondary" :disabled="formLoading"
                        @submit="closeSendCard"
                    />
                    <template v-if="!maxSharedSendLimit">
                        <Base-Button 
                            label="Send as email"
                            size="sm" :loading="formLoading" 
                            type="submit" @submit="sendEmail" 
                        />
                    </template>
                    <template v-else>
                        <Base-Button label="Upgrade" size="sm" @submit="upgradeAccount" />
                    </template>
                </div>
            </template>
        </main-modal>

        <FileUploadModal 
            :show="showUploadModal" 
            mime-types="text/csv"
            :file-content="uploadContent" custom-text
            :is-error="uploadErr" upload-type="rate card"
            :headers="rateCardUploadHeaders"
            :is-account-upgrade="showUpgradeAccountInfo"
            :progress-value="uploadProgress"
            @update:files="handleFileUpload" 
        >
            <template #custom-text>
                <Alert type="primary" classes="text--sm">
                    <template #text>
                        <p class="text--xs">
                            Note: check for duplicate titles before uploading. Allowed values for rate types are: <strong>hourly, one-off, weekly, monthly, and yearly</strong>.
                        </p>
                    </template>
                </Alert>
            </template>
        </FileUploadModal>
    </div>
</template>

<script>
import {
    PageSpinner,
    BaseButton,
    EmptyPage,
    IconSvg,
    MainModal,
    FormInput,
    MainFilter,
    SortFilter,
    FormLabel,
    FileUploadModal,
    ImportButton,
    Alert,
    Pagination,
    CustomDropdown,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { rateCardUploadHeaders } from "@/utils/dummy";
import Papa from "papaparse";
import { paymentSupportedCurrencies } from "@/plugins/countries";

export default {
    name: "RateCardView",

    components: {
        Alert,
        IconSvg,
        EmptyPage,
        PageSpinner,
        BaseButton,
        MainModal,
        FormInput,
        MainFilter,
        SortFilter,
        FormLabel,
        FileUploadModal,
        ImportButton,
        Pagination,
        CustomDropdown,
    },

    data() {
        return {
            paymentSupportedCurrencies,
            rateCardUploadHeaders,
            action: "",
            showAddModal: false,
            isSearched: false,
            deleteLoading: false,
            rateTypes: ["hourly", "weekly", "monthly", "yearly", "one-off"],
            form : {
                _id: "",
                title: "",
                description: "",
                rateType: "",
                currency: "USD",
                items: [{ amount: 0.00, serviceName: "", platform: "" }],
            },
            pageState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            formLoading: false,
            displayType: "",
            selectedCardId: "",
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                },
                percentage: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "%",
                    suffix: "",
                    precision: 2,
                    masked: false,
                },
            },
            importType: "",
            downloadType: "",
            showUploadModal: false,
            uploadProgress: 0,
            uploadContent: [],
            uploadErr: false,
            downloadLoading: false,
            client: {},
            showSendModal: false,
            fetchClientsLoading: false,
            fetchClientsLoadingErr: false,
            itemErrorsObj: {},
        };
    },

    computed: {
        isListPresent() {
            if (this.rateCards.length) {
                return true;
            } else if (!this.rateCards.length && this.isSearched) { // no result found state
                return true;
            } else {
                return false;
            }
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        maxSharedSendLimit() {
            if (this.userProfile?.sharedRateCardCount > 2) {
                return true;
            } else {
                return false;
            }
        },
        
        showUpgradeAccountInfo() {
            if (this.userProfile?.accountType !== "standard") {
                return false;
            }

            if (this.rateCards.length >= 5 && this.action !== "edit") {
                return true;
            }

            return false;
        },

        rateCards() {
            return this.$store.getters["revenue/rateCards"]?.list;
        },

        pageData() {
            return this.$store.getters["revenue/rateCards"]?.pageData;
        },

        isBtnDisabled() {
            if (!this.form.title || !this.form.currency) {
                return true;
            } else {
                return false;
            }
        },

        moneyConfig() {
            return {
                decimal: ".",
                thousands: ",",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
            };
        },

        computeBillableText() {
            if (this.form.rateType) {
                switch (this.form.rateType) {
                case "hourly":
                    return "hours";
                case "yearly":
                    return "years";
                case "monthly":
                    return "months";
                case "weekly":
                    return "weeks";
                
                default:
                    return "hours";
                }
            } else {
                return "hours";
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },

        refreshList() {
            return this.$store.getters["revenue/refreshList"];
        },

        showAddItemBtn() {
            const firstItem = this.form.items[0];

            if (firstItem.serviceName && firstItem.amount) {
                return true;
            } else {
                return false;
            }
        },
    },

    watch: {
        "$route": {
            async handler(newVal, oldVal) {
                if (newVal.path === oldVal.path) { // this only fires when same page navigation takes place
                    await this.handleFetchRateCards({ refresh: true });
                }
            },
        },

        displayType(newType, oldType) {
            if (newType !== oldType) {
                this.sortRateCards();
            }
        },
    },

    async mounted() {   
        if (!this.rateCards.length && this.refreshList) {
            await this.handleFetchRateCards({ refresh: true });
            this.$store.dispatch("revenue/setRefreshList", false);
        }
    }, 

    methods: {
        setImportType(val) {
            this.importType = val;
            this.showUploadModal = true;
        },

        openSendCard(item) {
            this.form = {
                ...this.form,
                ...item,
            };
            this.showSendModal = true;
        },

        resetCloseSendToClient() {
            this. form = {
                _id: "",
                title: "",
                memo: "",
                billableHours: 0,
                rateType: "",
                currency: "",
                items: [{ description: "", rate: 0.00, serviceName: "" }],
            };
            this.showSendModal = false;
        },

        closeSendCard() {
            this.showSendModal = false;
            
            this.form = {
                _id: "",
                title: "",
                description: "",
                billableHours: 0,
                billingRate: 0,
                rateType: "",
                currency: "",
                amount: 0,
            };
            this.client = {};
        },

        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        sortRateCards() {
            this.$store.dispatch("revenue/sortRateCards", this.displayType);
        },

        setDisplayType(val) {
            this.displayType = val;
        },

        async handleFetchRateCards(params) {
            try {
                this.pageState.err = false;
                this.pageState.errMsg = "";
                this.pageState.loading = true;

                const query = this.$route.query;
                const queryKeys = Object.keys(this.$route.query);

                const pageQueryObj = {
                    page: query.page || 1,
                };

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                if (query.title) {
                    pageQueryObj.title = query.title;
                }

                if (query.workType) {
                    pageQueryObj.workType = query.workType;
                }

                const buildResult = assembleQueryList("rateCards", pageQueryObj);

                await this.$store.dispatch("revenue/fetchRateCards",  {
                    payload: buildResult, 
                    refresh: params?.refresh, 
                });
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                
                this.pageState.err = true;
                this.pageState.errMsg = errMsg;
            } finally {
                this.pageState.loading = false;
            }
        },

        openCard(item) {
            this.action = "edit";
            this.form = {
                ...this.form,
                ...item,
            };
            this.showAddModal = true;
        },

        closeCard() {
            this.action = "";
            this.showAddModal = false;
            
            this.form = {
                _id: "",
                title: "",
                description: "",
                rateType: "",
                currency: "",
                amount: 0,
                items: [{ amount: 0.00, serviceName: "", platform: "" }],
            };
        },

        startAddCard() {
            this.action = "add";
            this.showAddModal = true;
        },

        validateRateCardItems() {
            const checkIfItemsAreEmpty = this.checkIfItemIsEmpty();
            let isInvalid = false;
            
            if (checkIfItemsAreEmpty) {
                isInvalid = true;
            } else {
                isInvalid = false;
            }
            
            return isInvalid;
        },

        addAnotherItem() {            
            const isEmpty = this.checkIfItemIsEmpty();

            if (isEmpty || this.firstItemIsEmpty) {
                return;
            }  

            this.form.items.push({
                serviceName: "",
                amount: 0,
                platform: "",
            });
        },

        checkIfItemIsEmpty() {
            let emptyCount = 0;
            const items = this.form.items;

            // eslint-disable-next-line indent
            items.map((item, i) => {
                const emptyItemFields = (!item.serviceName || parseFloat(item.amount) <= 0);

                if (emptyItemFields) {
                    emptyCount++;
                    this.itemErrorsObj[i] = true;
                } else {
                    delete this.itemErrorsObj[i];
                }
            });

            return emptyCount > 0;
        },

        isItemFilled(index) {
            if (this.itemErrorsObj[index]) {
                return true;
            } else {
                return false;
            }
        },

        removeItem(index = this.form.items.length - 1) {
            this.form.items = this.form.items.filter((elem, idx) => idx !== index);
        },

        buildPayload() {
            return {
                currency: this.form.currency,
                description: this.form.description,
                rateType: this.form.rateType,
                title: this.form.title,
                items: this.form.items,
            };
        },

        async handleAddRateCard() {
            try {
                this.formLoading = true;

                const payload = this.buildPayload();
                await this.$store.dispatch("revenue/addRateCard", payload);

                this.closeCard();
                this.$toast.success("New tag added");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                const msg = errMsg.includes("duplicate") ? "You already have a rate card with that title" : errMsg;
                this.$toast.error(msg);
            } finally {
                this.formLoading = false;
            }
        },

        async handleEditRateCard() {
            try {
                if (!this.form?._id) return;

                this.formLoading = true;

                const payload = this.buildPayload();
                await this.$store.dispatch("revenue/updateRateCard", payload);
                
                this.$toast.success("Rates updated");

                this.closeCard();
            } catch (error) {
                const errMsg = error?.response?.data?.error || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.formLoading = false;
            }
        },

        async handleDeleteCard(id) {
            try {                
                if (!id) return;
                
                this.selectedCardId = id;
                this.deleteLoading = true;

                await this.$store.dispatch("revenue/deleteRateCards", id);

                this.$toast.success("Card deleted");
            } catch (error) {
                const errMsg = error?.response?.data?.error || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
                this.selectedCardId = "";
            }
        },

        handleSubmit() {
            const isInvalid = this.validateRateCardItems();

            if (isInvalid) {
                this.$toast.error("Fill in required fields");
                return;
            }

            if (this.action === "add") {
                this.handleAddRateCard();
            } else {
                this.handleEditRateCard();
            }
        },
        
        upgradeAccount() {
            this.$router.push("/dashboard/settings/billings-and-plans");
        },

        computeBillingType(type) {
            switch (type) {
            case "hourly":
                return "hours";

            case "weekly":
                return "week(s)";

            case "monthly":
                return "month(s)";

            case "yearly":
                return "year";
            
            default:
                return "hours";
            }
        },

        handleFileUpload(event) {
            const file = event?.target?.files[0];

            if (file) {
                this.parseCSVFile(file);
            }
        },

        parseCSVFile(file) {
            Papa.parse(file, {
                header: true,
                complete: this.processCSVData,
                error: this.handleParseError,
            });
        },

        onUploadProgress(event) {
            this.uploadProgress = Math.round((event.loaded * 100) / event.total);
        },

        async processCSVData(results) {
            try {
                this.uploadErr = false;

                const rateCards = results.data;
                
                this.uploadContent = rateCards;

                const formData = new FormData();
                formData.append("rateCards", JSON.stringify(rateCards));
                
                await this.$store.dispatch("revenue/uploadRateCardSheet", { formData, progressCB: this.onUploadProgress });
                
                this.$toast.success("Rates uploaded successfully");

                this.importType = "";
                this.uploadProgress = 0;
                this.showUploadModal = false;

                await this.handleFetchRateCards({ refresh: true });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.$toast.error(errMsg);
                this.uploadProgress = 0;
                this.uploadErr = true;
            }
        },

        handleParseError(error) {
            console.error("Error parsing CSV file", error);
        },

        async handleFetchClients(params) {
            try {
                this.fetchClientsLoadingErr = false;
                this.fetchClientsLoading = true;

                const pageQueryObj = {
                    page: 1,
                    type: params?.type || "all",
                    name: params?.name || "",
                };
    
                const buildResult = assembleQueryList("clients", pageQueryObj);
    
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: params?.refresh });
            } catch(error) {
                this.fetchClientsLoadingErr = true;
            } finally {
                this.fetchClientsLoading = false;
            }
        },

        handleSelectClient(item) {
            this.client = item;
        },

        async sendEmail() {
            try {    
                if (!this.client._id || !this.form._id) {
                    this.$toast.error("Select a client");
                    return;
                }
                
                this.formLoading = true;

                const payload = {
                    rateCard: {
                        title: this.form.title,
                        description: this.form.description,
                        billableHours: this.form.billableHours,
                        currency: this.form.currency,
                        amount: this.form.amount,
                        billingRate: this.form.billingRate,
                        rateType: this.form.rateType,
                        _id: this.form._id,
                    },
                    client: {
                        _id: this.client._id,
                        name: this.client.name,
                        companyName: this.client.companyName,
                        email: this.client.email,
                        ccEmails: this.client.ccEmails,
                    },
                };
    
                await this.$store.dispatch("revenue/sendClientRateCard", payload);

                this.$toast.success("Follow-up email sent!");
                this.resetCloseSendToClient();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.formLoading = false;
            }
        },
    },
};
</script>