<template>
    <div class="settings__page">
        <div>
            <template v-if="fetchUserLoading">
                <page-spinner />
            </template>
    
            <template v-else-if="fetchUserErr">
                <div class="mt--40">
                    <EmptyPage state="error">
                        <template #button>
                            <base-button 
                                label="Reload" is-prefix-icon 
                                size="sm" icon-name="reload" 
                                icon-width="16px"
                                @submit="handleFetchProfile"
                            />
                        </template> 
                    </EmptyPage>
                </div>
            </template>
    
            <template v-else>
                <div>
                    <div class="settings__sub__header mt--0">
                        <div>
                            <p>Profile</p>
                            <!-- <CustomAvatar
                                border-style="solid" width="70px" font-size="20px"
                                name="Uzoamaka Anyanwu" class="mt--20"
                            /> -->
                        </div>
                    </div>

                    <div class="settings__sub__header mt--0">
                        <div>
                            <p class="text--semi--bold text--color-gray-700 text--sm">
                                Full name
                            </p>
                            <p class="text--sm text--color-gray-300 mt--5">
                                {{ profile.fullName }}
                            </p>
                        </div>
                        <div>
                            <base-button
                                size="sm" inner-style="secondary" btn-style="outline" label="Edit"
                                @submit="openAccountModal('fullName')"
                            />
                        </div>
                    </div>

                    <div class="settings__sub__header mt--0">
                        <div class="left">
                            <p class="text--semi--bold text--color-gray-700 text--sm">
                                Contact details
                            </p>
                            <p class="text--sm text--color-gray-300 mt--5">
                                Phone: {{ profile.phoneNumber || 'N/A' }}
                            </p>
                            <p class="text--sm text--color-gray-300 mt--5">
                                City: {{ profile.city || 'N/A' }}
                            </p>
                            <p class="text--sm text--color-gray-300 mt--5">
                                Country: {{ profile.country || '-' }}
                            </p>
                            <p class="text--sm text--color-gray-300 mt--5">
                                Zipcode: {{ profile.zipCode || '-' }}
                            </p>
                        </div>
                        <div>
                            <base-button
                                size="sm" inner-style="secondary" btn-style="outline" label="Edit"
                                @submit="openAccountModal('contactDetails')"
                            />
                        </div>
                    </div>

                    <div class="settings__row__between mt--15">
                        <div>
                            <p class="text--semi--bold text--color-gray-700 text--sm">
                                Email address
                            </p>
                           
                            <p class="text--sm text--color-gray-300 mt--5">
                                {{ profile.email }}
                            </p>
                        </div>
                        
                        <div>
                            <base-button
                                size="sm" btn-style="outline" inner-style="primary" label="Change email"
                                @submit="openAccountModal('emailSettings')"
                            />
                        </div>
                    </div>

                    <div class="settings__sub__header text--sm mt--15">
                        <base-button
                            size="sm" class="text--sm pl--0 pr--0" btn-style="ghost" inner-style="primary"
                            label="Change password"
                            @submit="togglePasswordUpdateModal"
                        />
                    </div>
                </div>

                <!-- <div>
                    <div class="settings__auth__header">
                        <p>Two-factor authentication</p>
                        <div class="mt--10">
                            <base-button size="sm" btn-style="outline" inner-style="primary" label="Disabled" />
                        </div>
                    </div>
                    
                    <div>
                        <Alert type="primary" classes="text--sm mt--10">
                            <template #text>
                                <p class="alert--title">
                                    Secure your account
                                </p>
                                <p class="alert--subtitle">
                                    Two-factor authentication adds an extra layer of security to your account. When you log in, you'll need to provide a 6-digit code.
                                </p>
                            </template>
                        </Alert>
                    </div>

                    <div>
                        <div class="flex justify-content-between align-items-center mb--25 mt--15">
                            <div class="flex align-items-baseline">
                                <span>
                                    <icon-svg name="cell-phone" width="24px" fill="#A0AEC0" />   
                                </span>
                                
                                <span class="ml--5">
                                    <p class="alert--title">Authenticator app</p>
                                    <p class="alert--subtitle">Use an authentication app to get a two-factor authentication code to complete sign-in.</p>
                                </span>
                            </div>
                            <span>
                                <icon-svg name="check-successful-outline" width="24px" fill="#289CA9" />   
                            </span>
                        </div>
                        
                        <div class="justify-content-between flex align-items-center">
                            <div class="flex align-items-baseline">
                                <span>
                                    <icon-svg name="envelope" width="24px" fill="#A0AEC0" />   
                                </span>
                                <span class="ml--5">
                                    <p class="alert--title">SMS/Text message</p>
                                    <p class="alert--subtitle">Get a one-time code sent to your phone via SMS to complete sign-in.</p>
                                </span>
                            </div>
                            <span>
                                <icon-svg name="check-successful-outline" width="24px" fill="#289CA9" />   
                            </span>
                        </div>
                    </div>
                </div> -->

                <div class="mb--45 mt--30">
                    <p class="text--color-dark text--sm text--bold">
                        Delete your account
                    </p>
                    <p class="mb--15 text--sm text--color-title">
                        Clicking the button below will delete all your data including your files and history. This action can't be undone.
                    </p>
    
                    <div class="row__item relative pl--0">
                        <base-button 
                            :loading="deleteLoading" 
                            label="Delete account" size="sm" btn-style="danger" inner-style="secondary"
                            @submit="toggleDelete"
                        />
                    </div>
                </div>
            </template>
        </div>

        <ChangePassword :show-modal="showPasswordChangeModal" @cancel="togglePasswordUpdateModal" />

        <UpdateProfileModal 
            :show-modal="showAccountModal"
            :current-selection="currentAccountModalSelection" 
            @cancel="closeAccountModal"
        />

        <ConfirmDeletionTwo
            type="account" custom-msg
            :show-modal="showDeleteModal"
            :loading="deleteLoading" 
            @cancel="toggleDelete" 
            @delete="handleDeleteProfile"
        >
            <template #custom-msg>
                <p class="text--sm">
                    Are you sure you want to delete your account? <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>
    </div>
</template>

<script>
import {
    BaseButton,
    PageSpinner,
    ConfirmDeletionTwo,
    EmptyPage,
    ChangePassword,
    Alert,
    IconSvg,
    CustomAvatar,
    UpdateProfileModal,
} from "@/components/ui/index";

export default {
    name: "SettingsAccount",

    components: {
        ChangePassword,
        BaseButton,
        PageSpinner,
        ConfirmDeletionTwo,
        EmptyPage,
        // CustomAvatar,
        Alert,
        IconSvg,
        UpdateProfileModal,
    },

    data() {
        return {
            showPasswordChangeModal: false,
            deleteLoading: false,
            fetchUserLoading: false,
            fetchUserErr: false,
            fetchUserErrMsg: "",
            showDeleteModal: false,
            showAccountModal: false,    
            currentAccountModalSelection: "",
        };
    },

    computed: {
        profile() {
            return this.$store.getters["auth/profile"];
        },

        twoFactorAuth() {
            return this.$store.getters["app/userSettings"]?.twoFactorAuth;
        },
    },

    async mounted() {
        await this.handleFetchProfile();
    },

    methods: {
        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        openAccountModal(val) {
            if (!val || typeof val !== "string") return;

            this.currentAccountModalSelection = val;
            this.showAccountModal = true;
        },

        closeAccountModal() {
            this.showAccountModal = false;
            this.currentAccountModalSelection = "";
        },

        async handleFetchProfile() {
            try {
                this.fetchUserErr = false;
                this.fetchUserErrMsg = "";
                this.fetchUserLoading = true;

                await this.$store.dispatch("auth/fetchProfile", { refresh: false });
            } catch (error) {
                this.fetchUserErr = true;
                this.fetchUserErrMsg = error?.response?.message;
            } finally {
                this.fetchUserLoading = false;
            }
        },

        async handleDeleteProfile() {
            try {
                this.$store.dispatch("app/togglePageLoading", true);

                this.deleteLoading = true;

                await this.$store.dispatch("auth/deleteProfile");

                this.$toast.success("Profile deleted successfully");
                await this.$store.dispatch("auth/signOut");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        togglePasswordUpdateModal() {
            this.showPasswordChangeModal = !this.showPasswordChangeModal;
        },
    },
};
</script>

<style scoped lang="scss">
    .form--modal {
        padding: 0px 15px 5px;
    }
    
    .alert {
        padding: 15px 10px;
        border-radius: 6px;

        &--info {
            background: #EDF2F7;
        }

        &--title {
            color: #1A202C;
            font-size: 0.875rem;
            font-weight: 600;
        }

        &--subtitle {
            color: #718096;
            font-size: 0.875rem;
        }
    
    }
</style>