<template>
    <button 
        class="btn"
        :type="type" 
        v-bind="$attrs"
        :class="[
            classNames, 
            isFullWidth ? 'btn--block' : '', 
            `btn--${btnStyle}`, 
            `btn--${size}`,
            innerStyle ? `btn--${btnStyle}__${innerStyle}` : ''
        ]" 
        :disabled="disabled || loading" 
        @click="$emit('submit')"
    >
        <span :class="{ 'flex align-items-center': isPrefixIcon }">
            <span
                v-if="loading"
                class="spinner-border spinner-border-sm mr--5"
                role="status"
                aria-hidden="true"
            />
            <span v-if="!loading && isPrefixIcon">
                <icon-svg :name="iconName" :fill="iconFill" :width="iconWidth" :height="iconWidth" />
            </span>
            <span v-if="label" :class="{ 'ml--5': isPrefixIcon }">
                {{ label }}
            </span>
            <slot name="suffix-icon" />
        </span>
        <slot />
    </button>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "BaseButton",

    components: { IconSvg },

    props: {
        loading: { type: Boolean, default: false },
        isPrefixIcon: { type: Boolean, default: false },
        isSuffixIcon: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        classNames: { type: String, default: "" },
        label: { type: String, default: "" },
        iconName: { type: String, default: "" },
        iconFill: { type: String, default: "" },
        iconWidth: { type: String, default: "" },
        type: { type: String, default: "button"},
        isFullWidth: { type: Boolean, default: false },
        btnStyle: { 
            type: String, 
            default: "primary",
            validator: (value) => {
                return ["primary", "secondary", "outline", "ghost", "danger"].includes(value);
            },
        },
        innerStyle: { 
            type: String, 
            default: "",
            validator: (value) => {
                return ["", "primary", "secondary"].includes(value);
            },
        },
        size: { 
            type: String, 
            default: "md",
            validator: (value) => {
                return ["sm", "md", "lg"].includes(value);
            },
        },
    },
};
</script>