<template>
    <div class="form-check form-check-inline">
        <!-- <label :for="uuid" class="form-check-label" :class="[checkboxClass]">
            <input
                :id="uuid"
                v-model="reactiveValues"
                type="checkbox"
                :value="value"
                class="base-checkbox__input"
                v-bind="$attrs"
                @change="onChange"
            >
            <label :for="uuid" class="form-check-label" aria-hidden="true" />
        </label> -->
        <input
            :id="id" 
            v-model="reactiveValues" 
            class="form-check-input"
            type="checkbox"
            :value="value"
            v-bind="$attrs"
            @change="onChange"
        >
        <label class="form-check-label" :for="id">{{ labelValue }}</label>

        <label v-if="$slots.default" :for="id" :class="['base-checkbox__label ml--5', labelClass]">
            <slot />
        </label>
    </div>
</template>

<script>
import { simpleUniqueID, wait } from "@/plugins/index";
/**
 * @group UI Components / Forms
 * A basic checkbox implementation with the default styling and label
 * <br>
 * ![image](https://cdn.filestackcontent.com/baI9CUMSfe0JrgTfYIr1)

 * <h2>Example</h2>
 * `BaseCheckbox value="future">Save for the future</BaseCheckbox>`
 */
export default {

    model: {
        event: "checked",
        prop: "values",
    },

    props: {
        // Define an HTML tag for your component.
        tag: {
            type: String,
            default: "div",
        },

        id: {
            type: String,
            default: "div",
        },

        labelValue: {
            type: String,
            default: "",
        },

        // Define the checkbox value. Same as with normal checkbox input.
        value: {
            type: [String, Number],
            default: undefined,
        },

        // Define a class or classes for the checkbox label
        labelClass: {
            type: String,
            default: undefined,
        },

        // Define a class or classes for the checkbox input
        checkboxClass: {
            type: String,
            default: undefined,
        },

        // Defines a list of currently selected or checked items. Use v-model instead for 2way data binding.
        values: {
            type: [Array, Boolean],
            default: () => [],
        },
    },

    data() {
        return {
            reactiveValues: this.values || [],
            uuid: undefined,
        };
    },

    watch: {
        values(newValues) {
            this.reactiveValues = newValues;
        },

        reactiveValues(newValues) {
            // Emitted when the checkbox is checked.
            this.$emit("checked", newValues);
        },
    },

    mounted() {
        /**
         * Set this only on the browser. There is a wierd behaviour where Nuxt rerenders the page (Server and Client) partially
         * and uuid become different for input and label html tags.
         */
        this.uuid = this.value + "_" + simpleUniqueID();
    },

    methods: {
        onChange(e) {
            wait(200).then(() => {
                this.$emit("change", e);
            });
        },
    },
};
</script>