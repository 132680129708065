<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            is-close-button is-show-header is-footer
            size="sm" :title="computeModalHeaderTitle()" 
            @close="onCancel"
        >
            <template #body>
                <div class="modal__wrapper--onboarding mb--15">
                    <div v-if="subType === 'billingEmails'" class="mb--10">
                        <p class="text--sm text--color-gray-400">
                            Click <span class="text--highlight--block">enter</span> to insert a new email
                        </p>
                    </div>

                    <form class="pb--5" @submit.prevent="">
                        <div v-show="subType === 'billingEmails'">
                            <div class="form--control mb--15 m-w-100">
                                <multiple-billing-emails
                                    label=""
                                    :is-flex="false"
                                    :items="form.ccEmails"
                                    :classes="'w--100'"
                                    @add="addEmail"
                                    @delete="removeEmail"
                                />
                            </div>
                        </div>

                        <div v-show="subType === 'billingDetails'">
                            <div class="form--control">
                                <FormInput 
                                    id="address"
                                    v-model="form.address" label="Address"  
                                    type="text" size="sm"
                                />
                            </div>

                            <div class="form__row mb--15">
                                <div class="form--control flex-1 mr--15">
                                    <FormLabel :classes="'text--color-gray-200'" :title="'Country'" />
                                    <select v-model="form.country" class="form-select form-select-sm text--sm" aria-label="Default select example">
                                        <option v-for="country in countryList" :key="country.code" :value="country.name">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </div>
    
                                <div class="form--control flex-1">
                                    <FormInput 
                                        id="phoneNumber"
                                        v-model="form.phoneNumber"
                                        type="text" size="sm" no-margin-bottom
                                        :label="'Phone Number'"                                    
                                    />
                                </div>
                            </div>

                            <div class="form__row mb--15">
                                <div class="form--control flex-1 mr--15">
                                    <FormInput 
                                        id="city" v-model="form.city"           
                                        label="City"
                                        type="text" size="sm" no-margin-bottom
                                    />
                                </div>
        
                                <div class="form--control flex-1">
                                    <FormInput 
                                        id="city" v-model="form.state"           
                                        label="State"
                                        type="text" size="sm" no-margin-bottom
                                    />
                                </div>
                            </div>

                            <div class="form--control">
                                <FormInput 
                                    id="zipCode"
                                    v-model="form.zipCode" label="Zip code"  
                                    type="text" size="sm" no-margin-bottom
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        class-names="mr--5" label="Cancel" btn-style="secondary" size="sm"
                        @submit="onCancel"
                    />
                    <base-button 
                        type="submit" btn-style="primary" size="sm"
                        :loading="loading" :disabled="loading || !form.ccEmails.length" 
                        label="Update" @submit="handleSubmit"
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import countryList from "@/assets/js/countrycodes.json";
import MainModal from "./MainModal.vue";
import FormLabel from "../forms/FormLabel.vue";
import FormInput from "../input/FormInput.vue";
import BaseButton from "../buttons/BaseButton.vue";
import MultipleBillingEmails from "../input/MultipleBillingEmails.vue";

export default {
    name: "EditClientBillingInfo",

    components: {
        MainModal,
        FormLabel,
        BaseButton,
        MultipleBillingEmails,
        FormInput,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        subType: {
            type: String,
            default: "",
        },
        data: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            countryList,
            form: {
                _id: "",
                city: "",
                state: "",
                address: "",
                zipCode: "",
                country: "",
                ccEmails: [],
                phoneNumber: "",
            },
            loading: false,
            emailsToRemove: [],
        };
    },

    watch: {
        data: {
            handler(newVal) {
                if (newVal?._id) {
                    this.prefillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        computeModalHeaderTitle() {
            return "Manage" + " " + this.$split_str(this.subType);
        },

        prefillForm(val) {
            this.form = {
                ...this.form,
                _id: val?._id,
                city: val?.city || "",
                state: val?.state || "",
                address: val?.address || "",
                zipCode: val?.zipCode || "",
                country: val?.country || "",
                ccEmails: val?.ccEmails || [],
                phoneNumber: val?.phoneNumber || "",
            };
        },

        resetForm() {
            this.form = {
                _id: "",
                city: "",
                state: "",
                address: "",
                zipCode: "",
                country: "",
                ccEmails: [],
            };
            this.emailsToRemove = [];
        },

        removeEmail(email) {
            this.form.ccEmails = this.form.ccEmails.filter(item => item !== email);
        },

        addEmail(value) {
            this.form.ccEmails = [ ...this.form.ccEmails, value ];
        },

        onCancel() {
            this.resetForm();
            this.$emit("close");
        },

        async handleSubmit() {
            try {
                this.loading = true;

                const payload = {
                    _id: this.form._id || this.$route.params.id,
                };

                if (this.subType === "billingEmails") {
                    payload.ccEmails = this.form?.ccEmails;
                }

                if (this.subType === "billingDetails") {
                    payload.city = this.form.city;
                    payload.state = this.form.state;
                    payload.address = this.form.address;
                    payload.zipCode = this.form.zipCode;
                    payload.phoneNumber = this.form.phoneNumber;
                    payload.country = this.form.country;
                }
                
                const res = await this.$store.dispatch("clients/updateClient", payload);

                this.$toast.success("Client info updated"); 
                this.$emit("update", res);
                this.$emit("close");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>