<template
    :style="styles"
    :width="width"
    :height="height"
    :fill="fill"
>
    <span>
        <slot v-if="name" />
    </span>
</template>

<script>
export default {
    name: "IconMain",

    components: {
    // `${this.iconName.key}`: this.iconName.value
    },
    props: ["iconName"],

    data() {
        return {
        };
    },

    computed: {
        _iconPosition() {
            switch( this.iconPosition ) {
            case "right": return "right";
            default: return "left";
            }
        },
    },

    methods: {
    },
};
</script>

<style>
</style>