<template>
    <div class="progress">
        <div 
            class="progress-bar" 
            role="progressbar" 
            :style="{ width: `${value}%` }" 
            :aria-valuenow="value" 
            aria-valuemin="0" 
            aria-valuemax="100"
        />
    </div>
</template>

<script>
export default {
    name: "LineProgress",

    props: {
        color: {
            type: String,
            default: "",
        },
        value: {
            type: Number,
            default: 0,
        },
    },
};
</script>