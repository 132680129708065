<template>
    <div class="content__page">
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error">
                    <template #button>
                        <base-button 
                            label="Reload" icon-name="reload" icon-width="16px" 
                            size="sm" is-prefix-icon
                            @submit="handleFetchProject" 
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else-if="unableToLoadPage">
                <EmptyPage state="error">
                    <template #button>
                        <base-button label="Back to projects page" size="sm" @submit="backToInvoicesPage" />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="project__header__wrap">
                    <div class="project__header__status justify-content-end w--100">
                        <base-button
                            v-if="accountType === 'standard'"
                            btn-style="ghost" label="Upgrade to add time tracking" size="sm" class-names="btn--ghost__primary text--underline"
                        />
                    </div>
                </div>

                <div class="mt--15 pb--35">
                    <div class="invoice__details__row">
                        <div class="invoice__details__row__left project__details__row__left">
                            <div class="invoice__details__header pb--10 pt--10">  
                                <div class="">
                                    <p class="text--semi--bold text--color-gray-400 text--2xl">
                                        {{ currentProject.title }}
                                    </p>
                                    <div class="project__item project__item__flex mt--5">
                                        <span class="project__item__title text--normal mt--0 text--xs">
                                            {{ $format_date(currentProject.createdAt) }}
                                        </span>
                                        <span class="ml--10 mr--10 project__item__title text--normal"> | </span>
                                        <Status type="project" :value="currentProject.status || '-'" />
                                        <span class="ml--10 mr--10 project__item__title text--normal"> | </span>
                                        <p class="project__item__title text--capitalize">
                                            {{ currentProject.priority || "-" }} priority
                                        </p>
                                    </div>
                                </div>

                                <div class="relative flex align-items-center">
                                    <div class="mr--5 relative pb--0">
                                        <BaseButton
                                            btn-style="primary" size="sm" label="Update status"
                                            class-names="text--sm "
                                            @submit="toggleUpdateStatusModal"
                                        />   
                                    </div>
        
                                    <div class="dropdown">
                                        <div id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="invoice__details__menu">
                                                <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                            </div>
                                        </div>
        
                                        <ul class="dropdown-menu" aria-labelledby="invoiceDetailsDropDown">
                                            <li class="dropdown-item pointer" @click="handleDuplicateInvoice">
                                                <p class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="duplicate" fill="#667085" width="16px" />
                                                    <span class="ml--5 text--sm">Duplicate</span>
                                                </p>
                                            </li>
        
                                            <li class="dropdown-item pointer" @click="showDeleteModal = true">
                                                <p class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="delete" fill="#667085" width="16px" />
                                                    <span class="ml--5 text--sm">Delete</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="ml--5 project__sub-border-grey pointer">
                                        <IconSvg name="alarm" fill="#667085" width="16px" />
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item pb--10 pt--10">
                                <div class="col-12 mb--10 flex align-items-center justify-content-end">
                                    <BaseButton 
                                        btn-style="ghost" label="Edit" 
                                        class-names="text--xs text--color-primary p--0 mr--10" 
                                        @submit="toggleEditModal(1)"            
                                    />
                                </div>
                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Currency
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.currency || 'N/A' }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Project type
                                        </p>
                                        <p class="text--capitalize invoice__detail text--sm">
                                            {{ currentProject.isFavourite ? 'Yes' : 'No' }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Estimated revenue
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.currency || 'USD' }} {{ currentProject.estimatedRevenue }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Track project
                                        </p>
                                        <p class="text--capitalize invoice__detail text--sm">
                                            {{ currentProject.trackProject ? "Yes" : "No" }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Deadline
                                        </p>
                                        <p class="invoice__details--text text--sm">
                                            {{ $format_date(currentProject.deadline) }}
                                        </p>
                                    </div>                                    
                                </div>
                            </div>

                            <div class="invoice__details__item pb--10 pt--10">
                                <div class="col-12 mb--10 flex align-items-center justify-content-between">
                                    <p class="text--bold text--color-dark">
                                        Estimate & billing details
                                    </p>
                                    <BaseButton 
                                        btn-style="ghost" label="Edit" 
                                        class-names="text--xs text--color-primary p--0 mr--10" 
                                        @submit="toggleEditModal(2)"            
                                    />
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Project type
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.projectType || 'Not paid' }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Billable hours
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.billableHours || 0 }} hours
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Non-billable hours
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.nonBillableHours || 0 }} hours
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Tracking time
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.trackingTime || 0 }} hours
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Billable Rate
                                        </p>
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.billingRate || 0 }} %
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center">
                                            <p class="text--color-gray-100 text--sm text--medium">
                                                Tracking days
                                            </p> 
                                            <BaseButton 
                                                btn-style="ghost" label="(Manage)" 
                                                class-names="text--xs text--color-primary p--0" 
                                                @submit="startManageSub('trackingDays')"            
                                            />
                                        </div>
                                        <template v-if="currentProject.trackingDays && currentProject.trackingDays.length">
                                            <div class="">
                                                <div 
                                                    v-for="day in currentProject.trackingDays" :key="day" 
                                                    class="block project__item__desc text--underline mr--5"
                                                >
                                                    {{ day }},
                                                </div>
                                            </div>
                                        </template>

                                        <template v-else>
                                            <p class="project__item__desc">
                                                No tracking days selected.
                                            </p>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="mt--20">
                                <div class="col-12 mb--0">
                                    <p class="text--bold text--color-dark">
                                        Tasks
                                    </p>
                                </div>

                                <div class="col-12">
                                    <div class="invoice__item mt--5">
                                        <ProjectTasks />
                                    </div>
                                </div>
                            </div>

                            <div class="">
                                <p class="text--bold text--color-dark mt--10">
                                    Notes
                                </p>
                        
                                <div class="col-12">
                                    <div class="w--100 invoice__details__item block pt--0">
                                        <ProjectNotes />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="flex align-items-center mt--20 mb--15 justify-content-between">
                                    <p class="text--bold text--color-dark mt--20 mb--15">
                                        Invoices
                                    </p>
                                    <base-button
                                        btn-style="ghost" label="+ Add invoice" size="sm"
                                        class-names="btn--ghost__primary" @submit="goToCreateInvoicePage"
                                    />
                                </div>
                        
                                <div class="col-12">
                                    <ProjectInvoices />
                                </div>
                            </div>
                        </div>

                        <div class="invoice__details__row__right">
                            <div class="invoice__details__divider pb--15">
                                <div class="flex align-items-center justify-content-between">
                                    <p class="text--bold text--color-dark mt--10 mb--10">
                                        Tags
                                    </p>

                                    <BaseButton 
                                        btn-style="ghost" label="(Manage)" 
                                        class-names="text--xs text--color-primary p--0" 
                                        @submit="startManageSub('tags')"            
                                    />
                                </div>

                                <template v-if="currentProject.tags && currentProject.tags.length">
                                    <div class="">
                                        <div 
                                            v-for="tag in currentProject.tags" :key="tag._id" 
                                            class="block project__item__desc text--underline mr--10"
                                        >
                                            {{ tag.name }}
                                        </div>
                                    </div>
                                </template>

                                <template v-else>
                                    <p class="project__item__desc">
                                        No tags added.
                                    </p>
                                </template>
                            </div>

                            <div class="invoice__details__divider pb--15">
                                <div class="flex align-items-center justify-content-between">
                                    <p class="text--bold text--color-dark mt--10 mb--10">
                                        Clients
                                    </p>

                                    <BaseButton 
                                        btn-style="ghost" label="(Manage)" 
                                        class-names="text--xs text--color-primary p--0" 
                                        @submit="startManageSub('clients')"
                                    />
                                </div>

                                <template v-if="currentProject.clients && currentProject.clients.length">
                                    <div class="">
                                        <router-link 
                                            v-for="client in currentProject.clients" 
                                            :key="client._id" 
                                            class="block project__item__desc text--underline mr--10"
                                            :to="`/dashboard/clients/${client._id}`"
                                        >
                                            {{ client.name }}
                                        </router-link>
                                    </div>
                                </template>

                                <template v-else>
                                    <p class="project__item__desc">
                                        No clients added yet.
                                    </p>
                                </template>
                            </div>

                            <div class="invoice__details__divider pb--15">
                                <div class="flex align-items-center justify-content-between">
                                    <p class="text--bold text--color-dark mt--10 mb--10">
                                        Reminders
                                    </p>

                                    <BaseButton 
                                        btn-style="ghost" label="(Manage)" 
                                        class-names="text--xs text--color-primary p--0" 
                                        @submit="showRemindersModal = true"
                                    />
                                </div>

                                <template v-if="currentProject.reminders && currentProject.reminders.length">
                                    <span 
                                        v-for="reminder in currentProject.reminders" 
                                        :key="reminder._id" 
                                        class="block project__item__desc mb--5"
                                    >
                                        {{ mapReminders[reminder.reminderName] }}
                                    </span>
                                </template>

                                <template v-else>
                                    <p class="project__item__desc">
                                        No reminders added.
                                    </p>
                                </template>
                            </div>

                            <div class="invoice__details__divider pb--15">
                                <div class="flex align-items-center justify-content-between">
                                    <p class="text--bold text--color-dark mt--10 mb--10">
                                        Rate cards
                                    </p>

                                    <BaseButton 
                                        btn-style="ghost" label="(Manage)" 
                                        class-names="text--xs text--color-primary p--0" 
                                        @submit="startManageSub('rateCards')"
                                    />
                                </div>

                                <template v-if="currentProject.rateCards && currentProject.rateCards.length">
                                    <div>
                                        <div 
                                            v-for="item in currentProject.rateCards" :key="item._id" 
                                            class="project__item__desc mb--5 text--underline pointer" @click="setRateCard(item)"
                                        >
                                            {{ item.title }}
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <p class="project__item__desc">
                                        No rate cards added.
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            type="project" custom-msg 
            :loading="deleteState.loading" 
            :show-modal="showDeleteModal" 
            @cancel="toggleDelete"
            @delete="handleDeleteProject"
        >
            <template #custom-msg>
                <p class="text--sm text--color-gray-100">
                    We will permanently remove all data on this project immediately. All clients and invoices associated with the project will still remain.
                    This action cannot be undone.
                </p>
            </template>
        </ConfirmDeletionTwo>

        <UpdateStatus
            :show-modal="showUpdateStatusModal" 
            :current-status="currentProject.status"
            :status-list="projectFilterStatuses"
            :loading="updateSubLoading"
            @cancel="toggleUpdateStatusModal"
            @submit="handleStatusUpdate"
        />

        <CreateProjectModal
            :show-modal="showEditModal" action-type="edit" type="edit" :step-value="stepValue"
            :project-data="currentProject" @close="toggleEditModal" @complete="toggleEditModal"
        />

        <ProjectSubDocsModal 
            :show-modal="projectSubs.show" 
            :sub-data="projectSubs"
            :initial-list="projectSubs.list"
            @close="closeSubModal"
            @complete="onCompleteSubUpdate"
        />

        <ArchiveRecord 
            type="project" 
            :loading="pageLoading" 
            :show-modal="showArchiveModal" 
            @cancel="toggleArchiveModal" 
            @archive="handleArchiveProject"
        />

        <ManageRemindersModal 
            v-model="reminders" :reactive-reminders="currentProject.reminders"
            :loading="updateSubLoading" :show-modal="showRemindersModal" @selected="setReminders"
            @cancel="toggleRemindersModal" @submit="handleManageReminders"
        />

        <RateCardModal :item="selectedRateCard" :show-modal="showRateCardModal" @close="showRateCardModal = false" />
    </div>
</template>

<script>
import {
    BaseButton,
    Status,
    EmptyPage,
    PageSpinner,
    UpdateStatus,
    CreateProjectModal,
    ProjectSubDocsModal,
    ArchiveRecord,
    ConfirmDeletionTwo,
    IconSvg,
    ManageRemindersModal,
    RateCardModal,
} from "@/components/ui/index";
import { projectFilterStatuses } from "@/utils/enums";
import ProjectInvoices from "./ProjectInvoices.vue";
import ProjectTasks from "./Tasks.vue";
import ProjectNotes from "./ProjectNotes.vue";
import { mapReminders } from "@/utils/dummy";

export default {
    name: "ProjectDetailsView",

    components: {
        IconSvg,
        BaseButton,
        Status,
        EmptyPage,
        PageSpinner,
        ProjectInvoices,
        UpdateStatus,
        CreateProjectModal,
        ProjectSubDocsModal,
        ArchiveRecord,
        ConfirmDeletionTwo,
        ProjectTasks, RateCardModal,
        ProjectNotes, ManageRemindersModal,
    },

    data() {
        return {
            pageLoading: false,
            pageLoadingErr: false,
            pageLoadingErrMsg: "",
            deleteState: {
                loading: false,
                errMsg: "",
                err: false,
            },
            showEditModal: false,
            showDeleteModal: false,
            showUpdateStatusModal: false,
            projectFilterStatuses,
            mapReminders,
            projectSubs: {
                type: "",
                show: false,
                list: [],
                indexLabel: "",
            },
            showArchiveModal: false,
            updateSubLoading: false,
            unableToLoadPage: false,
            showRemindersModal: false,
            reminders: [],
            selectedRateCard: {},
            showRateCardModal: false, 
            stepValue: 1,
        };
    },

    computed: {
        currentProject() {
            return this.$store.getters["project/currentProject"];
        },

        accountType() {
            return this.$store.getters["auth/profile"]?.accountType;
        },
    },

    watch: {
        "$route.params.id": {
            async handler(newVal, oldVal) {
                if (newVal === oldVal) { // this only fires when same page navigation takes place
                    await this.handleFetchProject({ refresh: true });
                }
            },
        },
    },

    async mounted() {
        if (!this.currentProject?._id) {
            await this.handleFetchProject();
        }
    },

    async beforeMount() {
        await this.$store.dispatch("project/resetCurrentProject");
    },

    methods: {
        goToCreateInvoicePage() {
            this.$store.dispatch("invoice/setSelectedProject", this.currentProject);
            this.$router.push({ path: "/dashboard/invoices/create", query: { from_route: "project" } });
        },

        goToTasks() {
            const projectId = this.$route.params.id;

            if (!projectId) return;

            this.$router.push(`/dashboard/projects/${projectId}/tasks`);
        },

        async handleFetchProject() {
            try {
                const id = this.$route.params.id;

                if (id === "undefined" || !id) {
                    this.unableToLoadPage = true;
                    return;
                } else {
                    this.unableToLoadPage = false;
                }

                this.pageLoadingErr = false;
                this.pageLoading = true;
                this.pageLoadingErrMsg = "";

                const res = await this.$store.dispatch("project/getProject", id);
                this.reminders = res.reminders;
            } catch (error) {
                const msg = error?.message;

                this.pageLoadingErr = true;
                this.$toast.error(msg);
            } finally {
                this.pageLoading = false;
            }
        },

        startManageSub(type) {
            this.projectSubs.type = type;
            this.projectSubs.show = true;

            switch (type) {
            case "tags":
                this.projectSubs.list = this.currentProject.tags;
                this.projectSubs.indexLabel = "name";
                break;

            case "clients":
                this.projectSubs.list = this.currentProject.clients;
                this.projectSubs.indexLabel = "name";
                break;

            case "rateCards":
                this.projectSubs.list = this.currentProject.rateCards;
                this.projectSubs.indexLabel = "title";
                break;

            case "trackingDays":
                this.projectSubs.list = this.currentProject.trackingDays;
                this.projectSubs.indexLabel = "";
                break;
            
            default:
                this.projectSubs.list = [];
                this.projectSubs.indexLabel = "";
                break;
            }
        },

        closeSubModal() {
            this.projectSubs.show = false;
            this.projectSubs.type = "";
            this.projectSubs.list = [];
            this.projectSubs.indexLabel = "";
        },

        onCompleteSubUpdate(resp) {
            if (!resp.key) return;

            this.$store.dispatch("project/updateProjectSubsComplete", { id: this.$route.params.id, ...resp });
            this.closeSubModal();
        },

        async handleStatusUpdate(data) {
            try {
                this.updateSubLoading = true;
                
                await this.$store.dispatch("project/updateProject", data);
                
                this.$store.dispatch("app/fetchCompletedProjectCount", { refresh: true });
                this.$toast.success("Project status updated");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateSubLoading = false;
            }
        },

        async handleDeleteProject() {
            try {
                this.deleteState.loading = true;
                this.deleteState.err = false;
                this.deleteState.errMsg = "";

                const ids = [this.currentProject?._id];

                await this.$store.dispatch("project/deleteProject", ids);

                this.$toast.success("Project deleted successfully");
                this.$route.push({ path: "/dashboard/projects" });
            } catch (error) {
                const errMsg = error?.response?.message || error.message;

                this.deleteState.err = false;
                this.deleteState.errMsg = errMsg;
                this.$toast.error(errMsg);
            } finally {
                this.deleteState.loading = false;
            }
        },

        async handleArchiveProject() {
            try {
                const id = this.currentClient?._id || this.$route.params.id;

                if (!id) {
                    this.$toast.error("No project id passed");
                    return;
                }

                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                this.pageLoading = true;

                await this.$store.dispatch("project/archiveProject", id);

                this.showArchiveModal = false;
                this.$toast.success("Project archived");
                this.$router.push("/dashboard/projects");
            } catch (error) {
                const msg = error?.message;

                this.pageLoadingErr = true;
                this.$toast.error(msg);
            } finally {
                this.pageLoading = false;
            }
        },

        toggleEditModal(step) {
            this.showEditModal = !this.showEditModal;
            this.stepValue = step;
        },
        
        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        toggleUpdateStatusModal() {
            this.showUpdateStatusModal = !this.showUpdateStatusModal;
        },

        toggleArchiveModal(bool) {
            this.showArchiveModal = bool;
        },

        toggleRemindersModal(bool) {
            this.showRemindersModal = bool;
        },

        async handleManageReminders() {
            try {
                this.updateSubLoading = true;
                
                await this.$store.dispatch("project/updateProject", { _id: this.currentProject._id, reminders: this.reminders });
                this.showRemindersModal = true;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateSubLoading = false;
            }
        },

        setReminders({ event, item }) {
            const checked = event.target.checked;

            if (checked) {
                this.reminders.push({
                    reminderName: item.id,
                    reminderDate: new Date(),
                });
            } else {
                this.reminders = this.reminders.filter(x => {
                    return x.reminderName !== item.id;
                });
            } 
        },

        setRateCard(item) {
            this.selectedRateCard = item;
            this.showRateCardModal = true;
        },
    },
};
</script>