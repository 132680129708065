<template>
    <div class="sidebar__section__group ">
        <p class="sidebar__section__title">
            Main
        </p>
        <div class="home--content__wrap">
            <router-link
                v-for="link in links" 
                :key="link" 
                :to="{ name: `${link.path}-view` }" 
                class="sidebar__section__item"
                :class="{ 'active': activeName === link.name }"
            >
                <template v-if="link.name === 'projects'">
                    <div class="w-100 flex align-items-center justify-content-between">
                        <div class="flex align-items-center">
                            <icon-svg class="sidebar__icon" :name="link.iconName" icon-position="left" :width="'16px'" />   
                            <span class="sidebar__section__group__title">{{ link.name }}</span>
                        </div>
                        <div v-if="accountType === 'standard'" class="ui-widgets">
                            <CircularProgressBar show-value-label :value="(maxProjectCount/5) * 100" :progress-text="`${maxProjectCount}/5`" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <icon-svg class="sidebar__icon" :name="link.iconName" :width="'16px'" />   
                    <span class="sidebar__section__group__title">{{ link.name }}</span>
                </template>
            </router-link>
        </div>
    </div>
</template>

<script>
import { CircularProgressBar, IconSvg } from "../index";

export default {
    name: "OperationsNavigation",

    components: {
        IconSvg,
        CircularProgressBar,
    },

    props: {
        activeName: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            links: [
                { path: "clients", name: "clients", iconName: "user", disabled: false },
                { path: "projects", name: "projects", iconName: "layer", disabled: false },
                { path: "invoices", name: "invoices", iconName: "receipt", disabled: false },
                { path: "calendar-and-meetings", name: "calendar & meetings", iconName: "calendar", disabled: false },
            ],
            projectCount: (1/5) * 100,
        };
    },

    computed: {
        accountType() {
            return this.$store.getters["auth/profile"]?.accountType;
        },

        maxProjectCount() {
            return this.$store.getters["auth/profile"]?.maxProjectCount;
        },
    },
};
</script>

<style scoped lang="scss">
    .home--content__wrap {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        padding-left: 0;
    }
</style>