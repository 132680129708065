<template>
    <div ref="editor" @input="update" />
</template>

<script>
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import Quill from "quill";

export default {
    name: "CustomEditor",

    model: {
        prop: "value",
        event: "input",
    },

    props: {
        modelValue: { default: "", type: String },
    },
    
    data() {
        return {
            editor: null,
        };
    },

    mounted() {
        var _this = this;

        this.editor = new Quill(this.$refs.editor, {
            // readOnly: true,
            modules: {
                toolbar: [
                    [
                        { header: [1, 2, 3, 4, false] },
                    ],
                    ["bold", "italic", "underline", "link"],
                ],
            },
            theme: "snow",
            formats: ["bold", "italic", "underline", "link", "header"],
            placeholder: "Add a note here...",
        });

        this.editor.root.innerHTML = this.modelValue;
        this.editor.on("text-change", function() {
            console.log("text change is happening", { "this.modelValue": this.modelValue });
            return _this.update();
        });
    },

    methods: {
        update(e) {
            console.log("update");
            this.$emit(
                "update:modelValue",
                this.editor.getText() ? this.editor.root.innerHTML : "",
            );
        },
    },
};

</script>