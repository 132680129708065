<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="btn-group mr--5">
        <button 
            id="dropdownMenuClickableInside" 
            class="btn--filter btn text--xs btn-sm text--bold" 
            type="button"
            aria-expanded="false" 
            data-bs-toggle="dropdown" 
            data-bs-auto-close="true" 
        >
            <span class="mr--5 flex align-items-center">
                <icon-svg name="filter" fill="#637579" width="12px" height="12px" />
                <span class="ml--5 text--xs">Filter</span>
            </span>
        </button>

        <div class="dropdown-menu filter__dropdown-menu mb--35">
            <form @submit.prevent="">
                <div class="action--btns__wrap">
                    <button class="action--btns__clear action--btns" type="reset" @click="clearFilter">
                        Clear all
                    </button>
                    <button class="btn--primary action--btns" :disabled="disabled" type="submit" @click="handleFilter">
                        Run
                    </button>
                </div>

                <div class="mb--15">
                    <template v-if="pageType === 'clients'">
                        <div class="filter-item-input">
                            <FormLabel title="Name" />
                            <input id="clientName" v-model="filter.clientName" class="form-control form-control-sm filter-input" type="text">
                        </div>
    
                        <div class="filter-item-input">
                            <FormLabel :title="'Industry'" />
                            <select v-model="filter.industry" class="form-select form-select-sm text--sm filter-input">
                                <option v-for="(industry, index) in industryData" :key="index" :value="industry">
                                    {{ industry }}
                                </option>
                            </select>
                        </div>
    
                        <div class="filter-item-input">
                            <FormLabel title="Email" />
                            <input id="email" v-model="filter.email" class="form-control form-control-sm filter-input" type="email">
                        </div>
    
                        <div class="filter-item-input">
                            <FormLabel :title="'Status'" />
                            <div>
                                <div v-for="status in clientFilterStatuses" :key="status" class="form-check form-check-inline">
                                    <input id="inlineCheckbox1" v-model="filter.status" class="form-check-input" type="checkbox" :value="status">
                                    <label class="form-check-label text--capitalize" for="inlineCheckbox1">{{ status }}</label>
                                </div>
                            </div>
                        </div>
    
                        <div class="filter-item-input">
                            <div class="form__row">
                                <div class="flex-1 mr--5">
                                    <FormLabel title="Start Date" />
                                    <v-date-picker v-model="filter.startDate" class="text--sm" :max-date="new Date()">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                                <div class="flex-1">
                                    <FormLabel :title="'End Date'" />
                                    <v-date-picker v-model="filter.endDate" class="text--sm" :max-date="new Date()">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                            </div>
                        </div>
                    </template>
    
                    <template v-if="pageType === 'projects'">
                        <div class="filter-item-input">
                            <FormLabel title="Title" />
                            <input id="projectTitle" v-model="filter.title" class="form-control form-control-sm filter-input" type="text">
                        </div>
    
                        <div class="filter-item-input">
                            <FormLabel title="Status" />
                            <div>
                                <div v-for="status in projectFilterStatuses" :key="status" class="form-check form-check-inline">
                                    <input :id="`check-${status}`" v-model="filter.status" :value="status" class="form-check-input" type="checkbox">
                                    <label class="form-check-label text--capitalize" for="inlineCheckbox1">{{ status }}</label>
                                </div>
                            </div>
                        </div>
    
                        <div class="filter-item-input">
                            <FormLabel title="Project type" />
                            <select v-model="filter.projectType" class="form-select form-select-sm text--sm filter-input">
                                <option v-for="status in ['paid', 'not paid']" :key="status" :value="status" class="text--capitalize">
                                    {{ status }}
                                </option>
                            </select>
                        </div>
    
                        <div class="filter-item-input">
                            <FormLabel title="Priority" />
                            <div>
                                <div v-for="priority in priorities" :key="priority" class="form-check form-check-inline">
                                    <input id="inlineCheckbox1" v-model="filter.priority" class="form-check-input" type="checkbox" :value="priority">
                                    <label class="form-check-label text--capitalize" for="inlineCheckbox1">{{ priority }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="filter-item-input">
                            <div class="form__row">
                                <div class="flex-1 mr--5">
                                    <FormLabel title="Start Date" />
                                    <v-date-picker v-model="filter.startDate" class="text--sm" :max-date="new Date()">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                                <div class="flex-1">
                                    <FormLabel :title="'End Date'" />
                                    <v-date-picker v-model="filter.endDate" class="text--sm" :max-date="new Date()">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                            </div>
                        </div>
                    </template>


                    <template v-if="pageType === 'rateCards'">
                        <div class="filter-item-input">
                            <FormLabel title="Title" />
                            <input id="projectTitle" v-model="filter.title" class="form-control form-control-sm filter-input" type="text">
                        </div>
    
                        <div class="filter-item-input">
                            <FormLabel title="Rate card type" />
                            <div>
                                <div v-for="item in ['hourly', 'weekly', 'monthly', 'yearly']" :key="item" class="form-check form-check-inline">
                                    <input :id="`check-${item}`" v-model="filter.rateType" :value="item" class="form-check-input" type="checkbox">
                                    <label class="form-check-label text--capitalize" for="inlineCheckbox1">{{ item }}</label>
                                </div>
                            </div>
                        </div>
                    </template>
    
                    <template v-if="pageType === 'tags'">
                        <div class="filter-item-input">
                            <FormLabel title="Tag Name" />
                            <input id="tagName" v-model="filter.tagName" class="form-control form-control-sm filter-input" type="text">
                        </div>
                    </template>
    
                    <template v-if="pageType === 'tasks'">
                        <div class="filter-item-input filter-status">
                            <FormLabel :title="'Priority'" :classes="'text--sm text--semi--bold'" />
                            <div>
                                <div v-for="priority in priorities" :key="priority" class="form-check form-check-inline">
                                    <input id="inlineCheckbox1" v-model="filter.priority" class="form-check-input" type="checkbox" :value="priority">
                                    <label class="form-check-label text--capitalize" for="inlineCheckbox1">{{ priority }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="filter-item-input">
                            <div class="form__row">
                                <div class="flex-1 mr--5">
                                    <FormLabel title="Start Date" />
                                    <v-date-picker v-model="filter.startDate" class="text--sm" :max-date="new Date()">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                                <div class="flex-1">
                                    <FormLabel :title="'End Date'" />
                                    <v-date-picker v-model="filter.endDate" class="text--sm" :max-date="new Date()">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                            </div>
                        </div>
                    </template>
    
                    <template v-if="pageType === 'invoices'">
                        <div class="filter-item-input">
                            <FormLabel title="Client email" classes="ml--0" />
                            <input id="clientEmail" v-model="filter.clientEmail" class="form-control form-control-sm filter-input" type="email">
                        </div>

                        <div class="flex align-items-center filter-item-input flex-row">
                            <div class="col mr--5">
                                <FormLabel title="Currency" classes="ml--0" />
                                <input 
                                    id="currency" v-model="filter.currency" class="form-control form-control-sm"                                    
                                    type="text"
                                >
                            </div>

                            <div class="col">
                                <FormLabel title="Invoice No." />
                                <input id="invoiceNo" v-model="filter.invoiceNo" class="form-control form-control-sm" type="text">
                            </div>
                        </div>
                    
                        <div class="filter-item-input filter-status">
                            <FormLabel title="Invoice Status" />
                            <div>
                                <div v-for="status in invoiceFilterStatuses" :key="status" class="form-check form-check-inline">
                                    <input id="inlineCheckbox1" v-model="filter.status" class="form-check-input" type="checkbox" :value="status">
                                    <label class="form-check-label text--capitalize" for="inlineCheckbox1">{{ status }}</label>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="pageType === 'revenue'">
                        <div class="filter-item-input">
                            <FormLabel title="Project title" classes="ml--0" />
                            <input id="projectTitle" v-model="filter.title" class="form-control form-control-sm filter-input" type="text">
                        </div>
                        <div class="flex align-items-center filter-item-input flex-row">
                            <div class="col mr--5">
                                <FormLabel title="Currency" classes="ml--0" />
                                <input 
                                    id="currency" 
                                    v-model="filter.currency" 
                                    class="form-control form-control-sm filter-input"
                                    type="text"
                                >
                            </div>
                            <div class="col">
                                <FormLabel :title="'Invoice No.'" />
                                <input id="invoiceNo" v-model="filter.invoiceNo" class="form-control form-control-sm filter-input" type="text">
                            </div>
                        </div>
                    </template>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { projectFilterStatuses, priorities, taskStatus, invoiceFilterStatuses, clientFilterStatuses } from "@/utils/enums";
import { industryData } from "@/utils/dummy";
import { serilaizeQuery } from "@/utils/helpers";
import FormLabel from "../forms/FormLabel.vue";
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "MainFilter",

    components: {
        FormLabel,
        IconSvg,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        pageType: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            filter: {
                deadline: this.$route.query.deadline || "",
                datePaid: this.$route.query.paid || "",                
                isCompleted: this.$route.query.isCompleted || false,
                clientEmail: this.$route.query.clientEmail || "",
                clientName: this.$route.query.name || "",
                industry: this.$route.query.industry || "",
                email: this.$route.query.email || "",
                title: this.$route.query.title || "",
                status: this.$route.query?.status?.split(",") || [],
                currency: this.$route.query.currency || "",
                invoiceNo: this.$route.query.invoiceNo || "",
                priority: this.$route.query?.priority?.split(",") || [],
                projectType: this.$route.query.projectType || "",
                taskName: this.$route.query.name || "",
                startDate: this.$route.query.startDate || "",
                endDate: this.$route.query.endDate || "",
                tagName: this.$route.query.tagName || "", 
                rateType: this.$route.query.tagName || "", 
            },
            query: {},
            projectFilterStatuses,
            priorities,
            taskStatus,
            invoiceFilterStatuses,
            clientFilterStatuses,
            industryData,
        };
    },

    methods: {
        serilaizeQuery, 

        clearFilter() {
            this.filter = {
                status: [],
                projectTitle: "",
                deadline: "",
                clientName: "",
                industry: "",
                currency: "",
                datePaid: "",
                invoiceNo: "",
                clientEmail: "",
                isCompleted: false,
                priority: [],
                email: "",
                startDate: "",
                endDate: "",
                tagName: "",
            };
            let currentRoute = this.$route.path;
            let queryParam = this.serilaizeQuery({});
            
            this.$router.replace(`${currentRoute}?${queryParam}`);
        },

        handleFilter() {
            let query = this.query;

            if (this.$route.query.page){
                delete query.page;
            }

            if (this.$route.query.limit){
                delete query.limit;
            }

            if (this.pageType === "tags") {
                if (this.filter.tagName) {
                    query.tagName = this.filter.tagName;
                }
            }

            if (this.pageType === "clients") {
                if (this.filter.clientName) {
                    query.name = this.filter.clientName;
                }

                if (this.filter.industry) {
                    query.industry = this.filter.industry;
                }

                if (this.filter.email) {
                    query.email = this.filter.email;
                }

                if (this.filter.status.length) {
                    query.status = this.filter.status;
                }

                if (this.filter.startDate) {
                    query.startDate = new Date(this.filter.startDate).toLocaleDateString();
                }

                if (this.filter.endDate) {
                    query.endDate = new Date(this.filter.endDate).toLocaleDateString();
                }
            }

            if (this.pageType === "invoices") {
                if (this.filter.clientEmail) {
                    query.clientEmail = this.filter.clientEmail;
                }

                if (this.filter.status.length) {
                    query.status = this.filter.status;
                }

                if (this.filter.currency) {
                    query.currency = this.filter.currency;
                }

                if (this.filter.invoiceNo) {
                    query.invoiceNo = this.filter.invoiceNo;
                }

                if (this.filter.startDate) {
                    query.startDate = new Date(this.filter.startDate).toLocaleDateString();
                }

                if (this.filter.endDate) {
                    query.endDate = new Date(this.filter.endDate).toLocaleDateString();
                }
            }

            if (this.pageType === "revenue") {
                if (this.filter.currency) {
                    query.currency = this.filter.currency;
                }

                if (this.filter.invoiceNo) {
                    query.invoiceNo = this.filter.invoiceNo;
                }

                if (this.filter.title) {
                    query.title = this.filter.title;
                }
            }

            if (this.pageType === "projects") {
                if (this.filter.title) {
                    query.title = this.filter.title;
                }

                if (this.filter.status.length) {
                    query.status = this.filter.status;
                }

                if (this.filter.priority.length) {
                    query.priority = this.filter.priority;
                }

                if (this.filter.startDate) {
                    query.startDate = new Date(this.filter.startDate).toLocaleDateString();
                }

                if (this.filter.endDate) {
                    query.endDate = new Date(this.filter.endDate).toLocaleDateString();
                }
            }

            if (this.pageType === "tasks") {
                if (this.filter.priority.length) {
                    query.priority = this.filter.priority;
                }

                if (this.filter.isCompleted) {
                    query.isCompleted = this.filter.isCompleted;
                }

                if (this.filter.startDate) {
                    query.startDate = new Date(this.filter.startDate).toLocaleDateString();
                }

                if (this.filter.endDate) {
                    query.endDate = new Date(this.filter.endDate).toLocaleDateString();
                }
            }

            if (this.pageType === "rateCards") {
                if (this.filter.rateType) {
                    query.rateType = this.filter.rateType;
                }

                if (this.filter.title) {
                    query.title = this.filter.title;
                }

                if (this.filter.startDate) {
                    query.startDate = new Date(this.filter.startDate).toLocaleDateString();
                }

                if (this.filter.endDate) {
                    query.endDate = new Date(this.filter.endDate).toLocaleDateString();
                }
            }

            let currentRoute = this.$route.path;
            let queryParam = this.serilaizeQuery(query);
                        
            this.$router.replace(`${currentRoute}?${queryParam}`);
        },
    },
};
</script>

<style lang="scss" scoped>
    .action--btns__wrap {
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        padding-top: 10px;
        background-color: #f6f8fa !important;
    }

    .dropdown-menu.show {
        transform: translate3d(-10px, 30px, 0px) !important;
        transform: translate3d(0px, 30px, 0px) !important;
        width: 300px !important;
        inset: 0px 0px auto auto !important;
        padding: 0px !important;
        box-shadow: inset 0 -1px rgba(235, 238, 241, 0) !important; 
        border-radius: 5px;
   }

   .filter {
        &-input {
            font-size: 12px !important;
            border-radius: 6px;
        }

        &-item-input {
            display: flex;
            flex-direction: column;
            padding: 10px 11px;

            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }

            &:last-of-type {
                padding-bottom: 0px;
            }

            label {
                font-size: 12px !important;
            }
        }

        &-status {
            &-item {
                flex-direction: row !important;
                flex-wrap: wrap;

                label {
                    margin-bottom: 0px !important;
                }
            }
        }
   }
</style>