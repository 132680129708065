<template>
    <hr class="divider">
</template>

<style lang="scss" scoped>
.divider {
    opacity: .25;
    color: #212529;
    border: 0;
    border-width: 0px 0px 1px;
    border-image: initial;
    border-color: inherit;
    border-style: solid;
    width: 100%;
}
</style>