import { createStore, createLogger } from "vuex";
import auth from "./auth";
import clients from "./clients";
import invoice from "./invoices";
import project from "./projects";
import report from "./reports";
import tag from "./tags";
import app from "./app";
import revenue from "./revenue";
import task from "./tasks";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
    modules: {
        auth,
        clients,
        invoice,
        project,
        report,
        revenue,
        report,
        tag,
        app,
        task,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});