// import { createApp } from 'vue/dist/vue.esm-bundler.js';
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import { PopoverRow, Calendar, DatePicker } from "v-calendar";
import { Money3Component } from "v-money3";
import VueChartkick from "vue-chartkick";
import "chartkick/chart.js";
import VueMultiselect from "@suadelabs/vue3-multiselect";
import vSelect from "vue-select";
import Vue3Toastify from "vue3-toastify";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "v-calendar/dist/style.css";

import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-select/dist/vue-select.css";
import filters from "./plugins/filters";
import countries from "./plugins/countries";
import toast from "./plugins/toast";
// import { vClickOutside } from "./directives/click-outside";

createApp(App)
    .use(router)
    .use(store)
    .use(filters)
    .use(countries)
    .use(toast)
    .use(VueChartkick)
    .use(Vue3Toastify, { 
        autoClose: 3000,
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        toastClassName: "toast-class",
        containerClassName: "toast-container",
    })
    .component("vue-multi-select", VueMultiselect)
    .component("money", Money3Component)
    .component("popover-row", PopoverRow)
    .component("v-select", vSelect)
    .component("v-calendar", Calendar)
    .component("v-date-picker-two", VueDatePicker)
    .component("v-date-picker", DatePicker)
    // .directive("click-outside", {
    //     bind(el, binding, vNode) {
    //         // Provided expression must evaluate to a function.
    //         if (typeof binding.value !== "function") {
    //             const compName = vNode.context.name;
    //             let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
    //             if (compName) {
    //                 warn += `Found in component '${compName}'`;
    //             }
    
//             console.warn(warn);
//         }
//         // Define Handler and cache it on the element
//         const bubble = binding.modifiers.bubble;
//         const handler = (e) => {
//             if (bubble || (!el.contains(e.target) && el !== e.target)) {
//                 binding.value(e);
//             }
//         };
//         el.__vueClickOutside__ = handler;
    
//         // add Event Listeners
//         document.addEventListener("click", handler);
//     },
    
    //     unbind(el, binding) {
    //         // Remove Event Listeners
    //         document.removeEventListener("click", el.__vueClickOutside__);
    //         el.__vueClickOutside__ = null;
    //     },
    // })
    // .directive("click-outside", vClickOutside)
    .mount("#app");

window.debounceId = undefined;