<!-- eslint-disable vue/no-custom-modifiers-on-v-model -->
<template>
    <div class="h-100">
        <div class="auth__bg">
            <div class="auth__wrapper">
                <div class="auth__wrap--center">
                    <div class="auth--form__logo">
                        <logo type="auth" />
                    </div>
                    
                    <div>
                        <form class="form auth--form" @submit.prevent="">
                            <div class="auth--form__wrap">
                                <div class="auth__title-wrap">
                                    <h2 class="auth__title">
                                        {{ copy.title }}
                                    </h2>
                                </div>

                                <div>
                                    <div v-if="hasField('googleAuth')">
                                        <GoogleAuthBtn :loading="externalAuthLoading" @authenticated="handleGoogleAuth" />

                                        <div class="or-1thbq71">
                                            <div class="or-rj288j" />
                                            <span>OR</span>
                                            <div class="or-1y5oso0" />
                                        </div>
                                    </div>

                                    <div v-if="hasField('fullName')">
                                        <FormInput 
                                            id="fullName"
                                            v-model.trim="form.fullName.value"
                                            type="text" size="lg"
                                            required autocomplete="" label="Full Name"
                                            border="none" bg="gray"
                                            :is-error="form.fullName.error"
                                            :err-msg="form.fullName.msg"
                                        />
                                        <!-- @blur="validateField(field)" -->
                                    </div>

                                    <div v-if="hasField('email')">
                                        <FormInput 
                                            id="email"
                                            v-model.trim="form.email.value"
                                            type="email" required border="none" bg="gray"
                                            label="Email Address" size="lg"
                                            :is-error="form.email.error"
                                            :err-msg="form.email.msg"
                                        />
                                    </div>

                                    <div v-if="hasField('password')">
                                        <InputPassword 
                                            id="password"
                                            v-model="form.password.value"
                                            required autocomplete border="none" bg="gray"
                                            label="Password" size="lg"
                                            :err-msg="form.password.msg"
                                            @keyup="passwordKeyUp"
                                        />

                                        <div v-if="authType === 'signup' || authType === 'resetPassword'" class="auth--form__password__checks">
                                            <div v-for="item in passwordChecks" :key="item">
                                                <BadgeCheck :label="item" :checked="!!passwordChecksData[`${item}`]" />
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="hasField('confirmPassword')">
                                        <InputPassword 
                                            id="confirmPassword"
                                            v-model="form.confirmPassword.value"
                                            label="Confirm New Password" size="lg"
                                            required autocomplete border="none" bg="gray"
                                            :err-msg="errors['confirmPassword'].msg"
                                        />
                                    </div>

                                    <div v-if="hasField('forgotPassword')" class="mt--5 flex justify-content-end">
                                        <router-link class="text--xs text--underline text--color-gray-200" to="/initiateReset">
                                            Forgot password?
                                        </router-link>
                                    </div>

                                    <div v-if="hasField('termsAndService')" class="checkbox__item auth--checkbox">
                                        <input 
                                            id="termsAndService" 
                                            v-model="form.termsAndService.value" 
                                            class="checkbox__input" 
                                            type="checkbox"
                                            :class="{ 'form__input-error': form.termsAndService.error }"
                                            required
                                        >
                                        <span class="checkbox--label p--0 text--xs" for="termsAndService">
                                            By signing up, you agree to the 
                                            <router-link class="auth--form__link text--sm btn--link" :to="{ name: 'signup' }">
                                                Terms and Service
                                            </router-link>
                                        </span>
                                    </div>

                                    <div class="w--100 mt--20">
                                        <base-button 
                                            :loading="loading" :disabled="loading || externalAuthLoading"
                                            is-full-width :label="copy.btnLabel" type="submit" 
                                            @submit="handleContinue"
                                        />
                                    </div>
                                
                                    <div v-if="hasField('dontHaveAnAccount')" class="signup--notify__wrap">
                                        <div class="text--xs">
                                            Don't have an account? 
                                            <router-link class="text--xs btn--link" to="/signup">
                                                Sign up
                                            </router-link>
                                        </div>
                                    </div>

                                    <div v-if="hasField('haveAnAccount')" class="signup--notify__wrap ">
                                        <div class="text--xs">
                                            Have an account? 
                                            <router-link class="btn--link" to="/login">
                                                Login
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</template>

<script>
import { authCopy, authFormFields, authFieldsToValidate } from "./auth";
import GoogleAuthBtn from "./GoogleAuthBtn.vue";
import { InputPassword, BaseButton, Logo, FormInput, BadgeCheck } from "../ui/index";

export default {
    name: "AuthForm",

    components: {
        Logo,
        BaseButton,
        InputPassword,
        FormInput,
        GoogleAuthBtn,
        BadgeCheck,
    },

    props: {
        authType: {
            type: String,
            default: "",
            validator: (value) => {
                return ["signup", "login", "resetPassword", "initiateReset"].includes(value);
            },
        },

        loading: {
            type: Boolean,
            default: false,
        },

        error: {
            type: Object,
            default: () => {},
        },
    },

    emits: ["form-submit"],

    data() {
        return {
            showPassword: false,
            passwordErr: false,
            isEmailInvalid: false,
            form: {
                email: {
                    label: "Email address",
                    value: "",
                    error: false,
                    msg: "",
                },
                password: {
                    label: "Password",
                    value: "",
                    error: false,
                    msg: "",
                },
                fullName: {
                    label: "Full name",
                    value: "",
                    error: false,
                    msg: "",
                },
                confirmPassword: {
                    label: "Confirm new password",
                    value: "",
                    error: false,
                    msg: "",
                },
                termsAndService:  {
                    value: false,
                    error: false,
                    msg: "",
                },
            },
            authCopy,
            authFormFields,
            authFieldsToValidate,
            passwordChecks: ["8 characters", "Uppercase", "Lowercase", "Special character", "Number"],
            passwordChecksData: {},
            errorCount: 0,
            externalAuthErr: false,
            externalAuthLoading: false,
        };
    },

    computed: {
        copy() {
            return this.authCopy[this.authType];
        },
    },

    mounted() {
        const input = document.getElementsByTagName("input")[0];
        
        if (input) {
            input.focus();
        }
    },

    methods: {
        resetFormFields() {
            this.form.email.value = "";
            this.form.password.value = "";
            this.form.fullName.value = "";
            this.form.confirmPassword.value = "";
            this.form.termsAndService.value = "";
        },

        passwordKeyUp(e) {
            const val = e?.target.value;

            this.passwordChecksData["8 characters"] = val.length > 8;
            this.passwordChecksData.Uppercase = /[A-Z]/.test(val);
            this.passwordChecksData.Lowercase = /[a-z]/.test(val);
            this.passwordChecksData.Number = /\d/.test(val);
            this.passwordChecksData["Special character"] = /[!@#$%^&*(),.?":{}|<>]/.test(val);
        },

        validateFunction(type, value) {            
            switch (type) {
            case "email":
                const isValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);

                if (value.length < 5) {
                    return "Email address must have a length of more than 5 characters";
                } if (!isValid) {
                    return "Please enter a valid email address";
                } else {
                    return "";
                }

            case "password":
            case "confirmPassword":
                if (value.length < 8) {
                    return "Password length should be more than 8 characters";
                } else {
                    const isValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
                        
                    if (!isValid) {
                        return "Password should contain at least 8 characters, uppercase, lowercase, special characters and a number";
                    } 
                }
                return "";
            
            case "fullName":
                if (value.length < 4) {
                    return "Full name should be greater than 4 characters";
                } else {
                    return "";
                }

            case "termsAndService":
                if (!value) {
                    return "You need to accept terms and services condition";
                } else {
                    return "";
                }
        
            default:
                return "";
            }
        },

        validateFormFields(key, label) {
            const field = this.form[`${key}`];

            if (!field.value) {
                this.form[`${key}`].error = true;
                this.form[`${key}`].msg = `${label} is required`;

                this.errorCount++;
            } 
                        
            const invalidField = this.validateFunction(key, field.value);

            if (invalidField) {
                this.form[`${key}`].error = true;
                this.form[`${key}`].msg = invalidField;
                
                this.errorCount++;
            } else {
                this.form[`${key}`].error = false;
                this.form[`${key}`].msg = "";
            }
        },

        handleContinue() {      
            this.errorCount = 0;

            for (var key of Object.keys(this.authFieldsToValidate[this.authType])) {
                const item = this.form[key];

                this.validateFormFields(key, item.value);
            }

            if (this.errorCount > 0) {
                return;
            } else {
                const payload = {};
    
                switch (this.authType) {
                case "login":
                    payload.email = this.form.email.value;
                    payload.password = this.form.password.value;
                    break;
    
                case "signup":
                    payload.email = this.form.email.value;
                    payload.password = this.form.password.value;
                    payload.fullName = this.form.fullName.value;
                    break;
                
                case "resetPassword":
                    payload.password = this.form.password.value;
                    payload.confirmPassword = this.form.confirmPassword.value;
                    break;
    
                case "initiateReset":
                    payload.email = this.form.email.value;
                    break;
    
                default:
                    break;
                }
    
                this.$emit("form-submit", payload);
            }
        },

        hasField(field) {
            return !this.authFormFields[this.authType] ? false : this.authFormFields[this.authType][field]; 
        },

        async handleGoogleAuth(resp) {
            try {     
                this.externalAuthLoading = true;  

                const payload = {
                    provider: "Google",
                    token: resp?.credential,
                    // ipAddress: getTimeZone(),
                    accountType: "",
                };

                const result = await this.$store.dispatch("auth/externalLogin", payload);

                localStorage.setItem("z:cached_time", JSON.stringify(5 * 60 * 100000));

                this.$store.dispatch("auth/setAuthStatus", true);
                this.$store.dispatch("auth/setLastChecked", Date.now());
                this.$toast.success(result.message);
                this.$router.push("/dashboard/reports");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.externalAuthErr = errMsg;
                this.$toast.error(errMsg);
            } finally {
                this.externalAuthLoading = false;
            }
        },
    },
};
</script>