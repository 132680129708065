<template>
    <money
        v-model="reactiveValue"
        v-bind="config.number"
        spellcheck="false"
        @keyup.native="checkRange"
        @keydown.native="preventKeys"
    />
</template>
  
<script>
export default {
    name: "InputMoney",
    props: {
        value: { type: [ String, Number ], default: undefined },
        prefix: { type: String, default: undefined },
        suffix: { type: String, default: "" },
        precision: { type: [ Number, String ], default: 0 },
        max: { type: [ Number, String ], default: undefined },
    },
  
    data() {
        return {
            reactiveValue: this.value,
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: this.prefix ? this.prefix + " " : "",
                    suffix: this.suffix,
                    precision: parseInt( this.precision ),
                    masked: false,
                },
            },
        };
    },
  
    watch: {
        prefix( newVal ) {
            if( newVal === undefined ) return;
            this.config.number.prefix = newVal + " ";
        },
  
        value( newVal ) {
            this.reactiveValue = newVal;
        },
  
        reactiveValue( newVal ) {
            this.$emit( "change", newVal );
            this.$emit( "input", newVal );
        },
    },
  
    methods: {
        preventKeys(event) {      
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },
  
        checkRange( event ) {
            if( this.max && this.reactiveValue >= parseFloat( this.max ) ) this.reactiveValue = parseFloat( this.max );
        },
    },
};
</script>
  