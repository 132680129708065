import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,
    
    state: () => {
        return {
            clients: [],
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
            clientProjects: {
                _id: "",
                projects: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 50,
                    total: 0,
                },
            },
            clientInvoices: {
                _id: "",
                invoices: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 50,
                    total: 0,
                },
            },
            documents: [],
            refreshList: true,
        };
    },

    getters: {
        clients: (state) => state.clients,
        pageData: (state) => state.pageData,
        clientNotes: (state) => state.clientNotes,
        clientProjects: (state) => state.clientProjects,
        clientInvoices: state => state.clientInvoices,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },

        addClient({ state, commit }, payload) {
            return api.client.create(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["clients", [ data, ...state.clients ]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: Math.ceil(state.clients.length / 50),
                    }]);
                    return data;
                });
        },

        fetchClients({ commit, state }, { params, refresh, config = {}, external = false }) {
            if (!refresh && state.clients.length) return;

            return api.client.getAll(params, config)
                .then((resp) => {
                    if (external) {
                        return resp?.data.data;
                    }

                    if (config?.responseType) {
                        return resp?.data.data;
                    } 

                    const data = resp?.data?.data;

                    commit("set", ["clients", data.data]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        ... data.pageDetails,
                    }]);
                });
        },

        getClient(_, id) { 
            return api.client.getOne(id)
                .then((resp) => {
                    const data = resp?.data?.data;
                    return data;
                });
        },

        updateClient({ state, commit }, payload) {
            return api.client.updateOne(payload?._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    const clients = state.clients;

                    const updatedClients = clients.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["clients", updatedClients]);         
                    return data;
                });
        },

        archiveClient({ state, commit }, id) {
            return api.client.archive(id)
                .then((resp) => {
                    const data = resp.data.data;
                    const clients = state.clients;

                    const updatedClients = clients.map(c => {
                        if (c?._id === data?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });

                    // update client list
                    commit("set", ["clients", updatedClients]); 
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedClients.length / 50),
                    }]);
                });
        },

        deleteClients({ state, commit }, ids) {
            return api.client.delete({ ids: ids })
                .then((resp) => {
                    const clients = state.clients;
            
                    const updatedClients = clients.filter(c => !ids.includes(c?._id));

                    commit("set", ["clients", updatedClients]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedClients.length / 50),
                    }]);
                });
        },

        uploadMutlipleClients(_, { formData, progressCB }) {            
            return api.client.upload(formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: progressCB })
                .then((resp) => {});
        },

        followUpWithClient(_, payload) {
            return api.client.followUp(payload)
                .then((resp) => {});
        },

        sortClients({ commit, state }, sortVal) {
            const copiedArr = state.clients.slice();
            
            sortList(sortVal, copiedArr, "name");
                        
            commit("set", ["clients", copiedArr]);
        },

        // ------------- NOTES ------------------
        getClientNotes(_, { id, params }) {
            return api.client.getNotes(id, params)
                .then((resp) => {
                    console.log({ getClientNotes: resp });
                    return resp.data.data;
                });
        },

        addNewNote(_, payload) {
            return api.client.createNote(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        updateNote(_, payload) {
            return api.client.editNote(payload.clientId, payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        deleteNote(_, payload) {
            return api.client.deleteNote(payload.clientId, payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        // -------------- PROJECTS ----------------
        getClientProjects({ commit, state }, { id, params }) {
            return api.client.getProjects(id, params)
                .then((resp) => {
                    const data = resp.data.data;

                    if (params?.isScroll) {
                        commit("set", ["clientProjects", { 
                            ...state.clientProjects, 
                            projects: [ ...state.clientProjects.projects, ...data.data ], 
                        }]);
                    } else {
                        commit("set", ["clientProjects", { 
                            ...state.clientProjects, 
                            projects: data.data, 
                        }]);
                    }

                    commit("set", ["clientProjects", { 
                        ...state.clientProjects, 
                        _id: id,
                        pageData: data.pageDetails,
                    }]);
                });
        },
        
        // ------------- INVOICES ------------------
        getClientInvoices({ commit, state }, { id, params }) {
            return api.client.getInvoices(id, params)
                .then((resp) => {
                    const data = resp.data.data;

                    if (params?.isScroll) {
                        commit("set", ["clientInvoices", { 
                            ...state.clientInvoices, 
                            projects: [ ...state.clientInvoices.projects, ...data.data ], 
                        }]);
                    } else {
                        commit("set", ["clientInvoices", { 
                            ...state.clientInvoices, 
                            projects: data.data, 
                        }]);
                    }

                    commit("set", ["clientInvoices", { 
                        ...state.clientInvoices, 
                        _id: id,
                        pageData: data.pageDetails,
                    }]);
                });
        },

        sortInvoices({ commit, state }, sortVal) {
            const copiedArr = state.invoicesData.invoices.slice();
            
            sortList(sortVal, copiedArr, "status");
                        
            commit("set", ["invoicesData", { ...state.invoicesData, invoices: copiedArr }]);
        },

        setInvoiceListPagination({ state, commit, dispatch }, id) {            
            const projects = state.projects.filter(c => c?._id !== id);

            dispatch("setCurrentProject", { payload: null, type: "reset" });

            commit("set", ["projects", projects]); 
        },
    },
};