import decode from "jwt-decode";
// import AES from 'crypto-js/aes';
var CryptoJS = require("crypto-js");

const LOCAL_STORAGE_KEYS_CLEAR = [

];

export function clearLocalStorage() {
    for (const i = 0; i < LOCAL_STORAGE_KEYS_CLEAR.length; i++) {
        const item = LOCAL_STORAGE_KEYS_CLEAR[i];

        localStorage.removeItem(item);
    }
}

// Check if token in LS is expired
function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

export function redirectAndReload(path = "") {
    if (!path.startsWith("/")) {
        path = "/" + path;
    }
    window.location.href = window.location.origin + path;
}

export function useRequestHeader({ config, headerKey, headerValue, urlPatterns = [] }) {
    if (urlPatterns.length > 0) {
        const foundRequests = urlPatterns.find(p => matchUrl(p, config.url));
        if (!foundRequests) {
            return;
        }
    }

    if (config.headers.common) {
        config.headers.common[headerKey] = headerValue;
    } else {
        config.headers[headerKey] = headerValue;
    }
};