
<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            is-close-button is-show-header is-footer
            size="sm"
            title="Manage reminders" @close="closeModal"
        >
            <template #body>
                <div class="pl--15 pr--15">
                    <form class="mb--10" @submit.prevent="">
                        <div v-for="item in invoiceReminders" :key="item.id">
                            <div class="form-check form-check-inline text--sm">
                                <!-- v-model="reminders" -->
                                <input
                                    class="form-check-input" type="checkbox"
                                    :value="item.id" @change="e => emitValue(e, item)"
                                >
                                <label class="form-check-label" for="inlineCheckbox1">{{ item.name }}</label>
                            </div>
                        </div>
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        class-names="mr--5" btn-style="secondary"
                        label="Cancel" size="sm"
                        :disabled="loading" @submit="closeModal"
                    />

                    <BaseButton 
                        :loading="loading" :disabled="loading" 
                        type="submit" size="sm" label="Update" @submit="handleUpdate"
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import BaseButton from "../buttons/BaseButton.vue";
import { invoiceReminders } from "@/utils/dummy";

export default {
    name: "ManageReminders",

    components: { MainModal, BaseButton },

    props: {
        modelValue: { type: Object, default: () => {} },
        reactiveReminders: { type: Array, default: () => [] },
        showModal: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },

    emits: ["cancel", "submit", "update:modelValue", "selected"],

    data() {
        return {
            reminders: this.reactiveReminders || [],
            invoiceReminders,
        };
    },

    watch: {
        reactiveReminders: {
            async handler(newVal) {
                if (newVal.length) {
                    this.reminders = newVal;
                }
            },
            deep: true,
            immediate: true,
        },
    },

    mounted() {
        this.reminders = this.reactiveReminders;
    },

    methods: {
        closeModal() {
            this.$emit("cancel", false);
        },

        handleContinue() {
            this.$emit("submit", this.reminders);
            this.closeModal();
        },

        emitValue(event, item) {
            this.$emit("selected", {
                item: {
                    ...item,
                    date: new Date(),
                },
                event,
            });
            // this.$emit("update:modelValue", []);
        },

        handleUpdate() {
            this.$emit("submit", this.reminders);
        },
    },
};
</script>