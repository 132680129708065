<template>
    <div class="empty--wrap" v-bind="$attrs">
        <div class="empty--image">
            <svg-asset
                v-if="state === 'error'"
                name="error"
                width="50px" height="60px"
                :fill="fill"
            />
            <svg-asset
                v-else-if="state === 'no_result'"
                name="search"
                width="50px" height="60px"
                fill="#BDBDBD"
            />
            <slot v-else-if="isCustomIcon" name="custom-icon" />
            
            <svg-asset
                v-else
                :name="iconName"
                :width="width"
                :height="height"
                :fill="fill"
            />
        </div>
        <div class="empty--text">   
            <template v-if="state === 'error'">
                <div>
                    <h2 class="empty--title mb--5">
                        Unable to load page
                    </h2>
                    <p class="empty--subtitle">
                        Something happened while trying to fetching data for this page
                    </p>
                </div>
            </template> 

            <template v-else-if="state === 'no_result'">
                <div>
                    <p class="empty--subtitle">
                        No result found
                    </p>
                    <p class="empty--subtitle">
                        Type in something else and try again.
                    </p>
                </div>
            </template> 

            <template v-else>
                <div v-if="title && subtitle">
                    <h2 class="empty--title mb--5">
                        {{ title }}
                    </h2>
                    <span class="empty--subtitle">{{ subtitle }}</span>
                </div>
                <div v-else>
                    <h2 class="empty--title mb--5">
                        {{ `You have no ${type}s ` }}
                    </h2>
                    <span class="empty--subtitle">{{ `Your ${type}s will show up here when you create them.` }}</span>
                </div>
            </template>                     
            <div class="flex justify-content-center mt--15">
                <slot name="button" />
            </div>
        </div>
    </div>
</template>

<script>
import SvgAsset from "../../ui/icons/SvgAssets.vue";

export default {
    name: "EmptyPage",
    
    components: {
        SvgAsset,
    },

    props: {
        isCustomIcon: {
            type: Boolean,
            default: false,
        },
        state: {
            type: String,
            default: "empty",
            validator: (value) => {
                return ["error", "empty", "no_result"].includes(value);
            },
        },
        subtitle: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        iconName: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "60px",
        },
        height: {
            type: String,
            default: "60px",
        },
        fill: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss" scoped>
    .empty {
        &--wrap {
            max-width: 550px;
            margin: 6rem auto;
        }

        &--image {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &--text {
            text-align: center;
            margin-top: 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &--title {
            font-size: 18px;
            color: #1a1f36;
        }
        
        &--subtitle {
            color: #697386;
            font-size: 14px;
            max-width: 484px ;
        }
    }  
</style>