<template>
    <BaseButton
        :label="isIcon ? '' : 'Copy'" v-bind="$attrs" :btn-style="btnStyle" :size="size"
        @submit="handleCopy"
    >
        <div ref="textRef" class="hide">
            {{ value }}
        </div>
        <span class="m-r-8 pointer">
            <icon-svg v-if="isIcon" name="copy" width="16px" height="16px" />
        </span>
    </BaseButton>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "CopyButton",

    components: {
        BaseButton,
        IconSvg,
    },

    props: {
        value: {
            type: String,
            default: "",
        },

        isIcon: {
            type: Boolean,
            default: false,
        },
        btnStyle: { 
            type: String, 
            default: "primary",
            validator: (value) => {
                return ["primary", "secondary", "outline", "ghost", "danger"].includes(value);
            },
        },
        innerStyle: { 
            type: String, 
            default: "",
            validator: (value) => {
                return ["", "primary", "secondary"].includes(value);
            },
        },
        size: { 
            type: String, 
            default: "md",
            validator: (value) => {
                return ["sm", "md", "lg"].includes(value);
            },
        },
    },

    computed: {
        resolvedData() {
            if (this.value) {
                return this.value + "";
            }
            return undefined;
        },
    },

    methods: {
        handleCopy() {
            const copyText = this.$refs.textRef;
            
            console.log({ copyText });
            navigator.clipboard.writeText(copyText.innerText);


            if (!copyText.innerText) {
                this.$toast.error("No value present");
            } else {
                this.$toast.success("Copied");
            }
        },
    },
};
</script>