<template>
    <nav class="landing__nav">
        <div class="landing__nav__wrap">
            <a class="landing__nav__item one" href="">
                <img src="@/assets/img/logo.svg">
            </a>
            <!-- <div class="landing__nav__item two">
                <ul class="landing__nav__list">
                    <li class="landing__nav__list__item">
                        <router-link to="/">
                            Features
                        </router-link>
                    </li>
                    <li class="landing__nav__list__item">
                        <router-link to="/">
                            Pricing
                        </router-link>
                    </li>
                    <li class="landing__nav__list__item">
                        <router-link to="/">
                            Resources
                        </router-link>
                    </li>
                    <li class="landing__nav__list__item">
                        <router-link to="/">
                            Blog
                        </router-link>
                    </li>
                </ul>
                <div class="landing__nav__cta">
                    <BaseButton 
                        size="md"
                        label="Login"
                        btn-style="ghost"
                        class="btn--ghost__secondary"
                        @submit="handleSubmit"
                    />
                    <BaseButton 
                        size="md"
                        label="Sign up"
                        @submit="handleSubmit"
                    />
                </div>
            </div> -->
        </div>
    </nav>
</template>

<script>
import { BaseButton } from "@/components/ui/index";

export default {
    components: {
        BaseButton,
    },

    computed: {
        isAuthenticated() {
            return this.$store.getters["auth/isAuthenticated"];
        },
    },
};
</script>