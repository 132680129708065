<template>
    <div class="h-100">
        <div class="dashboard--container" :class="{ 'disabled': logOutLoading }">
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <div class="mt--40">
                    <EmptyPage state="error">
                        <template #button>
                            <base-button 
                                :loading="pageLoading" 
                                label="Reload"
                                size="sm"
                                @submit="loadAllApiRequests"
                            />
                        </template> 
                    </EmptyPage>
                </div>
            </template>

            <template v-else>
                <Sidebar 
                    :is-collapse="isCollapse"
                    :show-settings-sidebar="showSettingsSidebar"
                    :rank-disabled="fetchProjectCount" :sidebar-width="sidebarWidth"
                    :rank-error="fetchCompletedProjectCountErr" 
                    @reload-count="handleCompletedProjectCount"
                    @toggle="toggleSidebar"
                    @open-rank="toggleRankModal(true)"
                />

                <DashboardContent
                    :is-collapse="isCollapse"
                    :show-settings-sidebar="showSettingsSidebar"
                    @toggle="toggleSidebar"
                />
            </template>
        </div>

        <welcome-modal
            :show-modal="showWelcomeModal"
            @close="showWelcomeModal = false"
        />

        <Rank 
            :show-modal="showRankModal" 
            @close="toggleRankModal(false)" 
        />

        <PageLoadingModal :show-modal="pageWrapperLoading" />

        <!-- <OnboardingModal :show-modal="isNewUser" /> -->

        <!-- <ProgressSuccess
            :show-modal="showRankModal" 
            show-image 
            custom-text
            @close="showRankModal = false"
        >
            <template #img>
                <div class="rank__img__item">
                    <div class="rank__img__item__symbol">
                        初心者
                    </div>
                </div>
            </template>
            <template #customText>
                <p class="text-center text--xl text--medium mb--10 mt--15">
                    Well done, Uzo!
                </p>
                <p class="text-center lh-20">
                    You are now a <strong>Shoshinsha</strong>
                </p>
            </template>
        </ProgressSuccess> -->
    </div>
</template>

<script>
import DashboardContent from "../layouts/DashboardContent.vue";
import { 
    Rank, 
    EmptyPage,
    WelcomeModal,
    Sidebar,
    BaseButton,
    PageSpinner,
    PageLoadingModal,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "AppDashboard",

    components: {
        Sidebar,
        DashboardContent,
        WelcomeModal,
        BaseButton,
        EmptyPage,
        Rank,
        PageSpinner,
        PageLoadingModal,
    },

    emits: ["openRank"],

    data() {
        return {
            isCollapse: false,
            sidebarWidth: "220px",
            showWelcomeModal: false,
            pageLoading: false,
            pageLoadingErr: false,
            showSettingsSidebar: false,
            fetchProjectCount: false,
            fetchCompletedProjectCountErr: false,
        };
    },

    computed: {
        logOutLoading() {
            return this.$store.getters["auth/logOutLoading"];
        },

        pageWrapperLoading() {
            return this.$store.getters["app/pageWrapperLoading"];
        },

        isNewUser() {
            const isNew = this.$store.getters["app/isNewUser"];
            const query = this.$route.query;

            return isNew && query?.nU;
        },

        isAuthenticated() {
            return this.$store.getters["auth/isAuthenticated"];
        },

        showRankModal() {
            return this.$store.getters["app/showRankModal"];
        },
    },

    watch: {
        "$route.path": {
            handler(newPath) {
                if (newPath.includes("/dashboard/settings")) {
                    this.showSettingsSidebar = true;
                } else {
                    localStorage.setItem("lastVisited", newPath);
                    this.showSettingsSidebar = false;
                }
            },
            deep: true,
            immediate: true,
        },
    },

    async mounted() {        
        await this.loadAllApiRequests();
        await this.handleCompletedProjectCount();
    }, 

    methods: {
        assembleQueryList,

        toggleSidebar() {
            if (this.isCollapse === false) {
                this.isCollapse = true;
                this.sidebarWidth = "30px";
            } else {
                this.isCollapse = false;
                this.sidebarWidth = "220px";
            }
        },

        setShowWelcomeModal() {
            if(this.$route.query?.new_user) {
                this.showWelcomeModal = true; 
            } else {
                this.showWelcomeModal = false;
            }
        },

        async handleFetchUserProfile() {
            try {
                await this.$store.dispatch("auth/fetchProfile", { refresh: true });
                await this.$store.dispatch("app/fetchSettings", { refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async handleCompletedProjectCount() {
            try {
                this.fetchCompletedProjectCountErr = false;
                this.fetchProjectCount = true;
                await this.$store.dispatch("app/fetchCompletedProjectCount", { refresh: true });
            } catch (error) {
                this.fetchCompletedProjectCountErr = true;
            } finally {
                this.fetchProjectCount = false;
            }
        },

        async handleFetchProjects() {
            try {
                const buildResult = assembleQueryList("projects", {
                    page: 1,
                    type: "active",
                });
                await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchClients() {
            try {
                const buildResult = assembleQueryList("clients", {
                    page: 1,
                    type: "all",
                });
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchInvoices(refresh = true) {
            try {
                const buildResult = assembleQueryList("invoices", {
                    page: 1,
                    type: "all",
                });

                await this.$store.dispatch("invoice/fetchInvoices", { params: buildResult, refresh });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchTags() {
            try {
                const buildResult = assembleQueryList("tags", {
                    page: 1,
                });

                await this.$store.dispatch("tag/fetchTags",  {
                    payload: buildResult, 
                    refresh: true, 
                    scroll: false, 
                });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchRateCards() {
            try {
                const pageQueryObj = {
                    page: 1,
                };

                const buildResult = assembleQueryList("rateCards", pageQueryObj);

                await this.$store.dispatch("revenue/fetchRateCards",  {
                    payload: buildResult, 
                    refresh: true, 
                    scroll: false, 
                });
            } catch (error) {
                throw error;
            }
        },

        async loadAllApiRequests() {
            try {
                this.pageLoadingErr = false;
                this.pageLoading = true;
                
                await Promise.all([
                    this.handleFetchProjects(),
                    this.handleFetchClients(),
                    this.handleFetchRateCards(),
                    this.handleFetchInvoices(),
                    this.handleFetchTags(),
                    this.handleFetchUserProfile(),
                ]);
            } catch (error) {
                console.log({ loadAllApiRequests: error });
                this.pageLoadingErr = true;
            } finally {
                this.pageLoading = false;
            }
        },

        toggleRankModal(bool) {
            this.$store.dispatch("app/toggleRank", bool);
        },
    },
};
</script>

<style scoped lang="scss">
    .dashboard--container {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
</style>