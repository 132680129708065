<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div>
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchInvoicesErr">
                <EmptyPage state="error">
                    <template #button>
                        <base-button 
                            label="Reload" icon-name="reload" icon-width="16px" 
                            size="sm" is-prefix-icon
                            class="flex align-items-center"
                            @submit="handleFetchInvoices"
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="w--100 justify-content-center mt--20">
                    <template v-if="!invoices.length">
                        <EmptyPage state="empty" type="invoice" icon-name="invoice" :width="'60px'" :height="'60px'">
                            <template #button>
                                <base-button 
                                    label="+ New invoice" size="sm"
                                    class-names="flex text--sm align-items-center" @submit="goToCreateInvoicePage"
                                />
                            </template>
                        </EmptyPage>
                    </template>

                    <template v-else>
                        <div class="flex mt--20">
                            <CustomTable
                                :columns="projectInvoiceHeaders"
                                :data="invoices"
                                is-row-link :is-checkbox="false"
                                @onRowClick="goToInvoiceDetailsPage"
                            >
                                <template #rows="data">
                                    <td class="first">
                                        {{ data.client.name }}
                                    </td>
                                    <td>
                                        <Status type="invoice" :value="data.status || 'N/A'" is-table />
                                    </td>
                                    <td class="text--upper">
                                        {{ data.currency }} {{ $format_number(data.totalAmount) }}
                                    </td>
                                    <td>#{{ data.invoiceNo }}</td>
                                    <td>{{ $format_date(data.createdAt) || "-" }}</td>
                                </template>
                            </CustomTable>
                        </div>
                        <div class="flex align-items-center justify-content-between">
                            <p class="text--color-gray-200 text--medium text--xs">
                                COUNT: <span>{{ invoices.length }}</span>
                            </p>
                            <pagination v-if="showPagination" :pagination-data="pageData" />
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {
    Pagination,
    EmptyPage,
    PageSpinner,
    BaseButton,
    CustomTable,
    Status,
} from "@/components/ui/index";
import { projectInvoiceHeaders } from "@/utils/dummy";

export default {
    name: "ProjectInvoices",

    components: {
        CustomTable,
        EmptyPage,
        Pagination,
        PageSpinner,
        BaseButton,
        Status,
    },

    data() {
        return {
            pageLoading: false,
            fetchInvoicesErr: false,
            fetchInvoicesErrMsg: "",
            projectInvoiceHeaders,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["project/projectInvoices"]?.pageData;
        },

        invoices() {
            return this.$store.getters["project/projectInvoices"].list;
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        currentProject() {
            return this.$store.getters["project/currentProject"];
        },
    },
    
    async mounted() {
        await this.handleFetchInvoices();
    },

    methods: {
        goToCreateInvoicePage() {
            this.$store.dispatch("invoice/setSelectedProject", this.currentProject);
            this.$router.push({ path: "/dashboard/invoices/create", query: { from_route: "project" } });
        },

        goToInvoiceDetailsPage(row) {
            if (!row._id) return;

            this.$router.push(`/dashboard/invoices/${row._id}`);
        },

        async handleFetchInvoices(params) {
            try {
                this.pageLoading = true;
                this.fetchInvoicesErr = false;
                this.fetchInvoicesErrMsg = "";

                let pageQueryObj = {
                    page: this.pageData.currentPage || 1,
                };

                await this.$store.dispatch("project/fetchProjectInvoices", { id: this.$route.params.id, params: pageQueryObj });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchInvoicesErr = true;
                this.fetchInvoicesErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },

        viewInvoice(row) {
            if (!row?._id) return;

            this.$router.push(`/dashboard/invoices/${row._id}`);
        },
    },
};
</script>