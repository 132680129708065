<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            is-close-button is-footer
            size="lg"
            title="Follow-Up Email"
            @close="closeModal"
        >
            <template #body>
                <div class="follow__up">
                    <form class="flex-1" @submit.prevent="">
                        <div class="client__modal__body mb--10">
                            <FormInput 
                                id="clientName"
                                v-model="form.clientName"
                                size="sm"
                                info-type="required"
                                label="Name"
                                disabled=""
                            />

                            <FormInput 
                                id="companyName"
                                v-model="form.companyName"
                                size="sm"
                                info-type="required"
                                label="Company Name"
                                disabled=""
                            />

                            <FormInput 
                                id="line"
                                v-model="form.intent"
                                type="email"
                                size="sm"
                                label="Follow-up intent"
                                required
                            />

                            <FormInput 
                                id="intent"
                                v-model="form.request"
                                type="email"
                                size="sm"
                                label="Follow-up request"
                                required
                            />

                            <div class="form-check form-check-inline">
                                <input id="inlineCheckbox1" v-model="isAddCCEmails" class="form-check-input" type="checkbox">
                                <label class="form-check-label text--sm" for="inlineCheckbox1">Add additional client CC Emails</label>
                            </div>
                        </div>
                    </form>

                    <div class="follow__up__preview">
                        <p class="follow__up__preview__text">
                            To: <span class="ml--5">{{ form.clientName }}</span>
                        </p>

                        <div v-if="isAddCCEmails && form.ccEmails" class="flex flex-wrap follow__up__preview__text">
                            <p>cc: </p>
                            <p v-for="email in form.ccEmails" :key="email" class="ml--5 text--color-gray-700">
                                {{ email }},
                            </p>
                        </div>

                        <p class="follow__up__preview__text">
                            Hello {{ form.clientName }},
                        </p>
                        <p class="follow__up__preview__text">
                            {{ form.followUpLine || 'Just wanted to follow up regarding my previous email' }}.
                        </p>
                        <p class="follow__up__preview__text">
                            {{ form.intent }}.
                        </p>
                        <p class="follow__up__preview__text">
                            {{ form.request }}.
                        </p>
                        <p class="follow__up__preview__text">
                            Thank you for your time. I look forward to speaking with you again!
                        </p>
                        <p class="text--sm mb--5 text--color-gray-700">
                            Best regards,
                        </p>
                        <p class="follow__up__preview__text">
                            {{ fullName }}
                        </p>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        class-names="mr--5" label="Cancel"
                        size="sm" btn-style="secondary" :disabled="loading"
                        @submit="closeModal"
                    />
                    <Base-Button 
                        label="Send Email"
                        size="sm" :loading="loading" :disabled="!form.intent"
                        @submit="sendEmail"                                       
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";

export default {
    name: "FollowUpEmail",

    components: {
        MainModal,
        BaseButton,
        FormInput,
    },

    props: {
        formData: {
            type: Object,
            default: () => {},
        },
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            form: {
                clientName: "",
                clientEmail: "",
                companyName: "",
                request: "",
                intent: "",
                followUpLine: "",
                ccEmails: [],
            },
            loading: false,
            isAddCCEmails: false,
        };
    },

    computed: {
        fullName() {
            return this.$store.getters["auth/profile"]?.fullName || "";
        },
    },

    watch: {
        formData: {
            handler(newVal, oldVal) {
                if (newVal?._id || oldVal?._id) {
                    this.fillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        resetForm() {
            this.form = {
                clientName: "",
                companyName: "",
                request: "",
                intent: "",
                followUpLine: "",
                ccEmails: [],
                clientEmail: "",
            };
        },

        closeModal() {
            this.resetForm();
            this.$emit("close", false);
        },
        
        fillForm(val) {
            this.form = {
                clientName: val?.name || "",
                companyName: val?.companyName || "",
                ccEmails: val?.ccEmails || [],
                clientEmail: val?.email || [],
            };
        },

        async sendEmail() {
            try {    
                if (!this.form.intent || !this.form.clientName || !this.form.clientEmail) {
                    this.$toast.error("Fill in required values");
                    return;
                }
                
                this.loading = true;

                const payload = {
                    clientName: this.form.clientName,
                    clientEmail: this.form.clientEmail,
                    companyName: this.form.companyName,
                    request: this.form.request,
                    intent: this.form.intent,
                    ccEmails: this.form.ccEmails,
                };
    
                await this.$store.dispatch("clients/followUpWithClient", payload);

                this.$toast.success("Follow-up email sent!");

                this.resetForm();
                this.$emit("close");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.client__modal__body {
    padding: 0px 15px;
    margin-bottom: 10px;
    
    &.scroll-height {
        height: 290px;
        overflow: scroll;
    }
}

.follow__up {
    width: 100%;
    display: flex;
    align-items: baseline;

    &__preview {
        flex: 1;
        background: #f9fbfc;
        padding: 20px;

        &__text {
            margin-bottom: 1.3rem !important;
            font-size: 0.875rem;
            font-weight: 400;
            color: #09090B;
        }
    }
}
</style>