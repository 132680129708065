import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,

    state: () => ({
        pageData: {
            currentPage: 1,
            totalPages: 1,
            pageSize: 50,
            total: 0,
        },
        earnings: [],
        goals: {
            data: [],
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
        },
        stats: [],
        rateCards: {
            list: [],
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 50,
                total: 0,
            },
        },
        refreshList: true,
    }),

    getters: {
        earnings: (state) => state.earnings,
        pageData: (state) => state.pageData,
        goals: (state) => state.goals,
        stats: (state) => state.stats,
        rateCards: state => state.rateCards,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },

        setYear({ commit }, payload) {
            commit("set", ["currentYear", payload]);
        },

        fetchStats({ commit }, params) {
            return api.revenue.getStats(params)
                .then((resp) => {
                    commit("set", ["stats", resp.data.data]);
                });
        },

        fetchEarnings({ commit, state }, { payload, refresh = false, scroll }) {
            if (!refresh && state.earnings.length) return;

            return api.revenue.getHistory(payload)
                .then((resp) => {
                    const data = resp?.data?.data;

                    if (!scroll) {
                        commit("set", ["earnings", data.data]);
                    } else {
                        commit("set", ["earnings", [ ...state.earnings, ...data.data ]]);
                    }
                    commit("set", ["pageData", {
                        ...state.pageData,
                        ... data.pageDetails,
                    }]);
                });
        },

        setPaginationData({ commit, state }, payload) {
            commit("set", ["pageData", {
                ...state.pageData,
                ...payload,
            }]);
        },

        fetchGoals({ commit, state }, { payload, refresh, scroll }) {
            if (!refresh && state.goals.data.length) return;

            return api.revenue.getGoals(payload)
                .then((resp) => {
                    const data = resp?.data?.data;

                    if (!scroll) {
                        commit("set", ["goals", { 
                            ...state.goals, 
                            data: data.data,
                            pageData: data.pageDetails,
                        }]);
                    } else {
                        commit("set", ["goals", { 
                            ...state.goals, 
                            data: [ ...state.goals.data, ...data.data ],
                            pageData: data.pageDetails,
                        }]);
                    }
                });
        },

        addGoal({ commit, state }, payload) {
            return api.revenue.createGoal(payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    
                    commit("set", ["goals", { 
                        ...state.goals, 
                        data: [ ...state.goals.data, data ],
                        pageData: {
                            ...state.goals.pageData,
                            total: state.goals.pageData.total + 1,
                            totalPages: Math.ceil(state.goals.data.length / 50),
                        },
                    }]);
                });
        },

        updateGoal({ commit, state }, payload) {
            return api.revenue.editGoal(payload._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    
                    const goals = state.goals.data;

                    const updatedGoals = goals.map(item => {
                        if (item._id === payload._id) {
                            return {
                                ...item,
                                ...payload,
                            };
                        } else {
                            return item;
                        }
                    });
        
                    commit("set", ["goals", { 
                        ...goals, 
                        data: updatedGoals,
                    }]);
                });
        },

        deleteGoal({ commit, state }, id) {
            return api.revenue.deleteGoal(id)
                .then((resp) => {
                    const data = resp?.data?.data;
                    
                    const goals = state.goals.data;

                    const updatedGoals = goals.filter(item => item._id !== id);

                    commit("set", ["goals", { 
                        ...goals, 
                        data: updatedGoals,
                    }]);
                });
        },

        // ---------------- RATE CARDS -----------------
        sortRateCards({ commit, state }, sortVal) {
            const copiedArr = state.rateCards.list.slice();
            
            sortList(sortVal, copiedArr, "title");
                        
            commit("set", ["rateCards", {
                ...state.rateCards,
                list: copiedArr,
            }]);
        },

        addRateCard({ state, commit }, payload) {
            return api.rateCard.create(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    
                    commit("set", ["rateCards", {
                        ...state.rateCards,
                        list: [ ...state.rateCards.list, data],
                        pageData: {
                            ...state.rateCards.pageData,
                            total: state.pageData.total + 1,
                            totalPages: Math.ceil(state.rateCards.list.length / 50),
                        },
                    }]);
                });
        },

        fetchRateCards({ commit, state }, { payload, refresh = false, config }) {
            if (!refresh && state.rateCards.list.length) {
                return;
            };

            return api.rateCard.getAll(payload, config)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["rateCards", {
                        ...state.rateCards,
                        list: data.data,
                        pageData: data.pageDetails,
                    }]);
                });
        },

        updateRateCard({ state, commit }, payload) {
            return api.rateCard.updateOne(payload?._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;

                    const rateCards = state.rateCards.list;

                    const updatedList = rateCards.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["rateCards", {
                        ...state.rateCards,
                        list: updatedList,
                    }]);         
                    return data;
                });
        },

        deleteRateCards({ state, commit }, id) {
            return api.rateCard.delete(id)
                .then((resp) => {
                    const rateCards = state.rateCards.list;
            
                    const updatedList = rateCards.filter(c => c._id !== id);

                    commit("set", ["rateCards", {
                        ...state.rateCards,
                        list: updatedList,
                        pageData: {
                            ...state.rateCards.pageData,
                            total: state.rateCards.pageData.total - 1,
                            totalPages: Math.ceil(updatedList.length / 50),   
                        },
                    }]);
                });
        },

        uploadRateCardSheet({ state, commit }, { formData, progressCB }) {            
            return api.rateCard.upload(formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: progressCB })
                .then((resp) => {
                    const data = resp.data.data;

                    console.log({ uploadRateCardSheet: data });

                    commit("set", ["rateCards", {
                        ...state.rateCards,
                        list: [ ...data, ...state.rateCards.list ],
                        pageData: {
                            ...state.rateCards.pageData,
                            total: state.pageData.total + (data.length || 1),
                            totalPages: Math.ceil(state.rateCards.list.length / 50),
                        },
                    }]);
                });
        },

        sendClientRateCard(_, payload) {
            return api.rateCard.sendRateCard(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },
    },
};