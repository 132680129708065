<template>
    <div ref="searchDropdown" class="input-search-select">
        <form class="input-wrap form-search">
            <input 
                v-model="searchInput" 
                :disabled="disabled" 
                type="text" class="form-control form-control-sm form-control-border-none"
                :placeholder="dropDownIsShown ? `Search ${listType}s by ${indexValue}` : placeholder" 
                @click="showDropdown" @input="waitTillTypingIsDone"
            >  
            <span class="pointer w-auto mr--10" @click="clearSearch">
                <icon-svg fill="rgba(194, 200, 212, 1)" :name="searchInput ? 'close' : 'search'" width="16px" /> 
            </span>
        </form>

        <div v-show="dropDownIsShown" class="input__dropdown">
            <div class="input__dropdown__items">
                <div v-if="loading">
                    <PageSpinner />
                </div>

                <div v-else class="search__wrap">
                    <template v-if="filteredItems.length">
                        <p class="text--color-dark text--upper text--bold text--xs mb--10 mt--10 ml--15">
                            All {{ listType }}s
                        </p>

                        <ul class="search__list" :class="{ 'search__list--sm' : filteredItems.length < 4}">
                            <li 
                                v-for="item in list" 
                                :key="item._id" 
                                tabindex="0" class="input__dropdown__item text--xs" 
                                @click="selectItem(item)" @keyup.enter="selectItem(item)"
                            >
                                <div class="input__dropdown__item__text">
                                    <!-- <slot name="search-type" v-bind="item" /> -->
                                    <span class="dropdown-item-subtitle text--capitalize">
                                        {{ getItemLabel(item) }} 
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <div class="no__result">
                            <p class="text--xs">
                                No {{ listType }}s found
                            </p>
                        </div>
                    </template>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import { debounce } from "@/utils/helpers";
import PageSpinner from "../loaders/PageSpinner.vue";
import IconSvg from "../icons/Icon-Svg.vue";

/**
 * This component adds a new client/project or shows a list of existing clients/projects
 */
export default {
    name: "SearchListInput",

    components: { PageSpinner, IconSvg },

    model: {
        prop: "selection",
        event: "change",
    },

    props: {
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        list: { type: Array, default: () => [] },
        listType: { type: String, default: "client" },
        placeholder: { type: String, default: "" },
        indexValue: { type: String, default: "" },
        selection: { type: [String, Object], default: null },
    },
    
    emits: ["update:selection", "select", "search", "change"],

    data() {
        return {
            reactiveSelection: this.selection,
            dropDownIsShown: false,
        };
    },

    computed: {
        filteredItems() {
            if (!this.searchInput) return this.list;

            return this.list.filter(item => {
                return typeof item === "object" 
                    ? item[this.indexValue]?.toLowerCase().includes(this.searchInput.toLowerCase()) 
                    : item.toLowerCase().includes(this.searchInput.toLowerCase());
            });
        },

        searchInput: {
            get() {
                if (!this.reactiveSelection) return "";
                
                return typeof this.reactiveSelection === "string" 
                    ? this.reactiveSelection 
                    : this.reactiveSelection[this.indexValue];
            },
            set(value) {
                value = value && value.trim();

                // If no value is provided, clear the selection
                if (value.length === 0) {
                    this.reactiveSelection = "";
                    this.$emit("change", this.reactiveSelection);
                    this.$emit("search", "");
                } else {
                    const getItem = this.list.find(item => 
                        typeof item === "object" 
                            ? item[this.indexValue]?.toLowerCase() === value.toLowerCase() 
                            : item === value,
                    );

                    // If item is found, set it as the selection, else clear it
                    if (getItem) {
                        this.reactiveSelection = getItem;
                    } else {
                        this.reactiveSelection = { [this.indexValue]: value };
                    }

                    this.$emit("change", this.reactiveSelection);
                }
            },
        },
    },

    watch: {
        selection: {
            immediate: true,
            handler(newVal) {
                this.updateDisplayValue(newVal);
            },
        },
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },

    methods: {
        handleClickOutside(event) {
            if (this.$refs.searchDropdown && !this.$refs.searchDropdown.contains(event.target)) {
                this.dropDownIsShown = false;
            }
        },

        clearSearch() {
            this.searchInput = "";
        },

        getItemKey(item) {
            if (typeof item === "string") return item;

            return item[this.indexValue] || JSON.stringify(item);
        },

        getItemLabel(item) {
            if (typeof item === "string") return item;

            return item[this.indexValue] || "";
        },

        updateDisplayValue(value) {
            if (typeof value === "object") {
                if (!this.indexValue) return;

                this.reactiveSelection[this.indexValue] = value[this.indexValue];
            } else {
                this.reactiveSelection = value;
            }
        },

        selectItem(item) {
            console.log({ item });
            this.updateDisplayValue(item);

            // this.$emit("update:selection", item);
            this.$emit("select", item);
            this.hideDropdown();
        },
        /**
         * This is so you're not sending multiple requests to the server.
         */
        waitTillTypingIsDone() {
            debounce(() => {
                this.$emit("search", this.searchInput);
            }, 200);
        },

        hideDropdown() {
            this.dropDownIsShown = false;
        },

        showDropdown() {
            this.dropDownIsShown = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .input-search-select {
        position: relative;
    }

    .search  {
        &__list{
            height: 200px;
            scroll-behavior: smooth;
            overflow-y: scroll;

            &--sm {
                height: auto !important;
            }
        }
    }

    .input__dropdown {
        width: 100%;
        margin-top: 5px;
        background-color: #ffffff;
        position: absolute;
        z-index: 1;
        box-shadow: 0 6px 12px rgba(0,0,0,.175);
        border: solid 1px #c4cdd5;
        border-radius: 5px;
    }

    .input__dropdown__items {
        padding: 10px 0;
    }

    .input__dropdown__item {
        padding: 7px 0px;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 5px;

        &:hover {
            background: #f9f9f9;
        }
    }

    .no__result {
        display: flex;
        justify-content: center;
        font-size: 14px;
        flex-direction: column;
        align-items: center;

        p {
            &:nth-child(2) {
                color: tan;
                cursor: pointer;
                font-weight: 500;
            }
        }
    }

    .form-search {
        border: 1px solid #ddd;
        // padding: 0px 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>