<template>
    <div class="h-100">
        <div class="google-iframe-auth" :class="{ 'disabled': loading }" :style="{ actualWidth }">
            <div
                v-if="oneTap"
                id="g_id_onload"
                :data-client_id="clientId"
                data-callback="googleAuthHandler"
                data-ux_mode="popup"
                :data-auto_select="autoTap"
            />
            <div
                ref="signIn"
                class="g_id_signin"
                data-type="standard"
                data-shape="pill"
                data-size="large"
                data-theme="outline"
                data-text="continue_with"
                data-logo_alignment="center"
                :data-locale="locale"
                :data-width="actualWidth"
                data-button-style-id="google"
            />
        </div>
    </div>
</template>

<script>
import env from "@/config/env";

export default {
    name: "GoogleAuthButton",

    props: {
        width: {
            type: String,
            default: "360",
        },

        autoTap: {
            type: Boolean,
            default: false,
        },
        loading: Boolean,
    },

    emits: ["authenticated"],

    data() {
        return {
            oneTap: true,
            clientId: env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
            disabled: true,
        };
    },
    
    computed: {
        locale() {
            return this.$store.getters.locale;
        },
        actualWidth() {
            return window.innerWidth <= 500 ? "250" : this.width;
        },
    },

    mounted() {
        const input = document.getElementsByTagName("input")[0];
        
        if (input) {
            input.focus();
        }
    },

    beforeMount() {
        window.googleAuthHandler = this.googleAuthHandler;
    },

    methods: {
        googleAuthHandler(resp) {
            this.$emit("authenticated", resp);
        },
    },
};
</script>

<style>
    .g_id_signin, #g_id_onload {
        width: 100%;
    }
</style>