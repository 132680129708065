<template>
    <div>
        <div class="page--wrap" :class="{ 'disabled': deleteLoading }">
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchErr">
                <EmptyPage state="error">
                    <template #button>
                        <base-button 
                            label="Reload" icon-name="reload" icon-width="16px" 
                            size="sm" is-prefix-icon
                            class-names="flex align-items-center"
                            @submit="handleFetchProjects"
                        />
                    </template> 
                </EmptyPage>
            </template>
            
            <template v-else>
                <div class="flex align-items-center justify-content-between width-100">                        
                    <div v-if="showActionBtns" class="list--count">
                        <p class="text--medium">
                            {{ projects.length }} {{ projects.length !== 1 ? "projects" : "project" }}
                        </p>
                    </div>

                    <div class="filter__actions--list">
                        <template v-if="showActionBtns">
                            <div v-if="selectedProjects.length > 0" class="mr--10">
                                <base-button 
                                    btn-style="danger"
                                    size="sm"
                                    @submit="toggleDelete"
                                >
                                    Delete {{ selectedProjects.length }} {{ selectedProjects.length === 1 ? 'project' : 'projects' }}
                                </base-button>
                            </div>
                            <main-filter page-type="projects" />
                            <sort-filter :value="displayType" @setType="setDisplayType" />
                            <base-button 
                                label="+ Add project" 
                                size="sm"
                                class-names="'flex align-items-center mr--10"
                                @submit="showCreateProjectModal = true"
                            />
                            <!-- <import-button label="Download" icon-name="download" :disabled="downloadLoading" @select="setDownloadType" /> -->
                        </template>
                    </div>
                </div>

                <div class="w--100 justify-content-center mt--40">
                    <template v-if="!projects.length">
                        <EmptyPage v-if="isSearched" state="no_result" />
                        <EmptyPage
                            v-else state="empty" icon-name="project" width="60px"
                            height="60px" type="project"
                        >
                            <template #button>
                                <base-button 
                                    :label="'+ New project'" btn-style="primary" size="sm"
                                    :class-names="'flex text--sm align-items-center'" @submit="showCreateProjectModal = true"
                                />
                            </template>
                        </EmptyPage>
                    </template>

                    <template v-else>
                        <div class="flex mt--40">
                            <CustomTable
                                :columns="projectHeaders"
                                :data="projects"
                                :selected-for-deletion="selectedProjects"
                                router-name="project-details-view"
                                is-action-btns
                                is-row-link
                                @onRowClick="openDetailsPage"
                                @onRowSelected="selectProjects"
                            >
                                <template #rows="data">
                                    <td>{{ data.title || '-' }}</td>
                                    <td>
                                        <Status type="project" :value="data.status" />
                                    </td>
                                    <td>{{ $format_date(data.deadline) || '-' }}</td>
                                    <td class="text--capitalize">
                                        {{ data.projectType || '-' }}
                                    </td>
                                    <td class="text--capitalize">
                                        {{ data.priority || '-' }}
                                    </td>
                                    <td>{{ $format_date(data.createdAt) || "-" }}</td>
                                </template>
                            </CustomTable>
                        </div>
                        <pagination v-if="showPagination" :pagination-data="pageData" @pageChange="handlePaginationNumChange" />
                    </template>
                </div>
            </template>
        </div>

        <CreateProjectModal
            :show-modal="showCreateProjectModal"
            type="add"
            @close="showCreateProjectModal = false"
            @complete="onCreateComplete"
        />

        <ConfirmDeletionTwo
            type="project" custom-msg
            :show-modal="showDeleteModal" :items="selectedProjects"
            :loading="deleteLoading"
            @cancel="showDeleteModal = false" 
            @delete="deleteMultipleProjects"
        >
            <template #custom-msg>
                <p class="text--sm">
                    These project(s) will be removed from all related clients, invoices, and meetings. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>
    </div>
</template>

<script>
import {
    MainFilter,
    SortFilter,
    Pagination,
    EmptyPage,
    CreateProjectModal,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CustomTable,
    ImportButton,
    Status,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { projectHeaders } from "@/utils/dummy";

export default {
    name: "ProjectsPage",

    components: {
        CreateProjectModal,
        MainFilter,
        ConfirmDeletionTwo,
        SortFilter,
        Pagination,
        EmptyPage,
        BaseButton,
        PageSpinner,
        CustomTable,
        ImportButton,
        Status,
    },

    data() {
        return {
            displayType: "",
            isSearched: false,
            selectedProjects: [],
            showCreateProjectModal: false,
            selectedProject: null,
            createLoading: false,
            deleteLoading: false,
            fetchErr: false,
            fetchErrMsg: "",
            showDeleteModal: false,
            projectHeaders,
            pageLoading: false,
            downloadType: "",
            showUploadModal: false,
            uploadProgress: 0,
            uploadContent: [],
            uploadErr: false,
            downloadLoading: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["project/pageData"];
        },

        showActionBtns() {
            if (this.pageData.total) {
                return true;
            } else if (!this.pageData.total && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        projects() {
            return this.$store.getters["project/projects"];
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        refreshList() {
            return this.$store.getters["project/refreshList"];
        },
    },

    watch: {
        "$route": {
            async handler(newVal, oldVal) {
                if (newVal.path === oldVal.path) { // this only fires when same page navigation takes place
                    await this.handleFetchProjects({ refresh: true });
                }
            },
        },

        displayType(newType, oldType) {
            if (newType !== oldType) {
                this.sortProjects();
            }
        },
    },

    async mounted() {        
        if (!this.projects.length && this.refreshList) {
            await this.handleFetchProjects({ refresh: true });
            this.$store.dispatch("project/setRefreshList", false);
        }
    }, 

    methods: {
        openDetailsPage(row) {
            if (!row?._id) return;

            this.$router.push(`/dashboard/projects/${row._id}`);
        },

        setDownloadType(val) {
            this.downloadClients(val);
        },

        setDisplayType(val) {
            this.displayType = val;
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        sortProjects() {
            this.$store.dispatch("project/sortProjects", this.displayType);
        },

        startDelete(project) {
            if (project) {
                this.selectedProject = project;
            }
            this.showDeleteModal = true;
        },

        async onCreateComplete(data) {
            this.showCreateProjectModal = false;

            if (
                this.projects.length === 5 ||
                this.projects.length === 10 || 
                this.projects.length === 25 || 
                this.projects.length === 50
            ) {
                this.$store.dispatch("app/toggleRank", true);
                this.$store.dispatch("app/toggleRankConfetti", true);
            }
           
            if (data.type === "add") {
                this.$router.push({ name:"project-details-view", params: { id: data.resp?._id }});
            }
        },

        async handleFetchProjects(params) {
            try {
                this.pageLoading = true;
                this.fetchErr = false;
                this.fetchErrMsg = "";
                
                const query = this.$route.query;
                const queryKeys = Object.keys(this.$route.query);

                const pageQueryObj = {
                    page: query.page || 1,
                    type: query.type || "active",
                };

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }
                
                if (query.title) {
                    pageQueryObj.title = query.title;
                }

                if (query.priority) {
                    pageQueryObj.priority = query.priority;
                }

                if (query.status) {
                    pageQueryObj.status = query.status;
                }

                if (query.startDate) {
                    pageQueryObj.startDate = query.startDate;
                }

                if (query.endDate) {
                    pageQueryObj.endDate = query.endDate;
                }

                const buildResult = assembleQueryList("projects", pageQueryObj);
                await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchErr = true;
                this.fetchErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        }, 

        async handleFetchClients() {
            try {
                const pageQueryObj = {
                    page: 1,
                    type: "all",
                };

                const buildResult = assembleQueryList("clients", pageQueryObj);
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: true });
            } catch (error) {
            }
        },

        async deleteMultipleProjects() {
            try {
                this.deleteLoading = true;

                await this.$store.dispatch("project/deleteProjects", this.selectedProjects);

                this.$toast.success("Projects(s) deleted");
                
                this.selectProjects([]);
                this.showDeleteModal = false;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        selectProjects(val) {
            this.selectedProjects = val;
        },
    },
};
</script>

<style lang="scss" scoped>
    .page--wrap {
        height: 100%; 
        padding-right: 25px; 
        padding-left: 25px; 
        padding-top: 2rem; 
        position: relative;
    }
</style>