<template>
    <div class="tabs horizontal ">
        <ul class="tab-list left" role="tabList">
            <li 
                v-for="(tab, index) in tabs"
                :key="tab.id"
                class="tab-list__item"
                tabIndex="0"
                role="tabItem"
                :aria-selected="selectedIndex === index ? true : false"
                @click="getCurrentTab(index)"
            >
                <template v-if="isIcon">
                    <icon-svg :name="tab.iconName" :fill="tab.iconFill" :width="getSize(tab.size)" :height="getSize(tab.size)" />
                </template>
                <span>{{ tab.name }}</span>
            </li>
        </ul>
        
        <slot name="nonLinkedTab" v-bind="currentTab" />
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "NonLinkedTabs",

    components: { IconSvg },

    props: {
        list: {
            type: Array,
            default: () => [],
        },
        isIcon: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: this.list, 
            currentTab: {
                index: 0,
                name: "",
                component: "",
                isActive: false,
                route: "",
            },
        };
    },

    mounted() {
        this.getCurrentTab(0);
    },

    methods: {
        getCurrentTab(i) {
            this.selectedIndex = i;
            
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);

                if (index === i) {
                    this.currentTab = {
                        index: index,
                        name: tab.name,
                        component: tab.component,
                        isActive: tab.isActive,
                    };
                }
            });
        },

        getSize(val) {
            switch (val) {
            case "sm": return "12px";
            case "md": return "16px";
            case "lg": return "24px";
            
            default: return "12px";
            }
        },
    },
};
</script>