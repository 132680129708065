<template>
    <div class="sidebar__section__group">
        <p class="sidebar__section__title">
            Manage
        </p>
        <div class="home--content__wrap">
            <router-link
                v-for="link in links" 
                :key="link" 
                :to="{ name: `${link.name}-view` }" 
                class="sidebar__section__item"
                :class="{ 'active': activeName === link.name }"
            >
                <icon-svg class="sidebar__icon" :name="link.iconName" icon-position="left" :width="'16px'" />   
                <span class="sidebar__section__group__title">{{ computeName(link.name) }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "ManageNavigation",

    components: {
        "icon-svg": IconSvg,
    },
    props: {
        activeName: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            links: [
                { name: "tasks", iconName: "task", disabled: false },
                { name: "tags", iconName: "tag", disabled: false },
                { name: "rate-cards", iconName: "sheet", disabled: false },
                { name: "settings", iconName: "settings", disabled: false },
            ],

        };
    },

    methods: {
        computeName(name) {
            if (!name || typeof name !== "string") return "";

            return name.split("-").join(" ");
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .home--content__wrap {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        padding-left: 0;
    }
</style>../icons/Icon-Svg.vue