<template>
    <span 
        class="status__map text--medium text--xs tag" 
        :class="[currentStatus.tag, !isTable ? 'badge rounded-pill' : '' ]"
        v-bind="{...$attrs }"
    >
        <template v-if="isTable">
            <span class="status__circle" :class="[`${currentStatus.tag}__text`]" />
            {{ $capitalize_first_letter(value) || "" }}
        </template>
        <template v-else>
            <IconSvg v-show="isIcon" :name="currentStatus.iconName" width="16px" class="mr--5" />
            {{ $capitalize_first_letter(value) || "" }}
        </template>
    </span>
</template>

<script>
import { 
    projectStatusMap, 
    projectTypeMap,
    invoiceStatusMap,
    clientStatusMap,
} from "@/utils/enums";
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "Status",

    components: { IconSvg },

    props: {
        isIcon: {
            type: Boolean,
            default: false,
        },
        isTable: {
            type: Boolean,
            default: true,
        },
        iconName: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
            validator: (value) => {
                return ["client", "project", "invoice", "project_payment"].includes(value);
            },
        },
    },

    computed: {
        statusMap() {
            switch (this.type) {
            case "client":
                return clientStatusMap;
                
            case "project":
                return projectStatusMap;

            case "project_payment":
                return projectTypeMap;

            case "invoice":
                return invoiceStatusMap;
            
            default:
                return clientStatusMap;
            }
        },

        currentStatus() {
            return this.statusMap[this.value] || { tag: "", iconName: "" };
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    &__map {
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: fit-content;
    }

    &__circle {
        border-radius: 50%;
        width: 5px;
        height: 5px;
        margin-right: 5px;
    }
}
</style>