<template>
    <a 
        v-if="isExternalLink" v-bind="$attrs" 
        :href="to" :target="isTarget ? '_blank' : ''" :class="[classes]"
    >
        <slot />
    </a>
    <router-link
        v-else
        v-bind="{
            ...$attrs, 
            ...$props,
            to,
            replace
        }"
        :class="[active || isPrimary ? 'text--color-primary' : '', classes]"
    >
        <slot />
    </router-link>
</template>
  
<script> 
import { RouterLink } from "vue-router";
 
export default {
    name: "CustomLink",
    
    inheritAttrs: false,
  
    props: {
        // add @ts-ignore if using TypeScript
        ...RouterLink.props,
        inactiveClass: {
            type: String,
            default: "",
        },
        to: {
            type: [Object, String],
            default: null,
        },
        replace: {
            type: Boolean,
            default: false,
        },
        classes: {
            type: String,
            default: "",
        },
        active: {
            type: Boolean,
            default: false,
        },
        isPrimary: {
            type: Boolean,
            default: false,
        },
        isTarget: {
            type: Boolean,
            default: false,
        },
    },
  
    computed: {
        isExternalLink() {
            return typeof this.to === "string" && this.to.startsWith("http");
        },
    },

    methods: {
        handleNavigate(nativeFunc) {
            console.log({ nativeFunc });
            // if (this.customFunc) {
            //     this.customFunc();
            //     nativeFunc();
            // } else {
            //     nativeFunc();
            // }
        },
    },
};
</script>