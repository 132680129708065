<template>
    <div
        class="form__item" :class="{ 'mb--0': noMarginBottom }"
    >
        <FormLabel
            v-if="label" 
            :info-type="infoType" 
            :html-for="id" 
            :title="label"
        />
        <div
            class="form__input-group form-control" 
            :class="{
                [`form-control-${size}`]: size,
                [`form-control-border-${border}`]: border,
                [`form-control-bg-${bg}`]: bg,

            }"
        >
            <span v-show="isPrefix" class="left-icon">
                <slot name="prefix" :class="['form-input__prefix', prefixClass]" />                                      
            </span>
        
            <input 
                v-bind="{...$attrs, type: !showPassword ? 'password' : 'text' }" 
                class="form__input"
                :class="{
                    'prefix': isPrefix,
                    'form__input-error': isError,
                    ...classNames
                }"
                :disabled="disabled"
                :readonly="readonly"
                :value="modelValue" 
                @input="emitValue"
                @change="e => $emit('change', e)"
                @focus="onFocus"
                @blur="onBlur"
                @keydown="e => $emit('keydown', e)"
                @keyup="e => $emit('keyup', e)"
            >
            <span class="input--password absolute me-1 pointer">
                <span @click="toggleViewPasswordIcon">
                    <icon-svg fill="rgba(194, 200, 212, 1)" :name="showPassword ? 'show' : 'eye-hide'" width="16px" height="16px" /> 
                </span>
            </span>
        </div>
        <p v-if="isError" class="form__err-msg">
            {{ errMsg }}
        </p>
    </div>
</template>

<script>
import FormLabel from "../forms/FormLabel.vue";
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "InputPassword",

    components: {
        FormLabel,
        IconSvg,
    },

    inheritAttrs: false,
    
    model: {
        prop: "value",
        event: "input",
    },

    props: {
        modelValue: { default: "", type: [Number, String] },
        modelModifiers: { type: Object, default: () => ({}) },
        label: { default: "", type: String },
        readonly: { type: Boolean },
        disabled: { type: Boolean, default: false },
        isPrefix: { type: Boolean, default: false },
        id: {
            type: String,
            default: "",
        },

        // Use this to minimize the effect of the @input event to make it trigger only after you're done typing.
        // This offers a performance boost to the Input element as it seems faster cause of less back and forth.
        lazy: {
            type: Boolean,
            default: false,
        },

        classNames: {
            type: Object,
            default: () => {},
        },
        required: {
            type: Boolean,
            default: undefined,
        },
        // Define a list of classes you want applied to your prefix
        prefixClass: {
            type: String,
            default: "",
        },
        isError: {
            type: Boolean,
            default: false,
        },
        errMsg: {
            type: String,
            default: "",
        },
        infoType: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "md",
            validator: (value) => {
                return ["sm", "md", "lg"].includes(value);
            },
        },
        bg: {
            type: String,
            default: "white",
            validator: (value) => {
                return ["gray", "white", ""].includes(value);
            },
        },
        border: {
            type: String,
            default: "solid",
            validator: (value) => {
                return ["solid", "none", "dotted"].includes(value);
            },
        },
        noMarginBottom: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["input", "blur", "change", "keydown", "focus", "keyup", "update:modelValue"],

    data() {
        return {
            focusState: "blur",
            showPassword: false,
        };
    },

    mounted() {
        setInterval(() => {
            if (this.autofocus) {
                this.focus();
            }
        }, 1500);
    },

    methods: {
        onFocus(e) {
            // Emitted when you focus on the input.
            this.focusState = "focus";
            this.$emit("focus", e);
        },

        onBlur(e) {
            // Emitted when you blur off the input.
            this.focusState = "blur";
            this.$emit("blur", e);
        },

        emitValue(e) {
            let value = e.target.value;
                        
            if (this.modelModifiers.capitalize) {
                value = value.charAt(0).toUpperCase() + value.slice(1);
            }
            this.$emit("update:modelValue", value);
        },

        toggleViewPasswordIcon() {
            this.showPassword = !this.showPassword;
        },
    },
};
</script>

<style lang="scss" scoped>
.form__input-group {
    border-radius: 6px !important;

    input {
        border: none;
        outline: none;
        background: transparent;
        min-width: 93%;
    }
}
</style>