import axios from "./axios";

const api = {
    app: {
        getProjectCount: ($body, $config) => axios.get("/projects/completed-count", $body, $config),
        getUserSettings: (params, $config) => axios.get("/settings", { params }, $config),
        updateSettings: ($body, $config) => axios.put("/settings", $body, $config),
        getNotifications: (params, $config) => axios.get("/settings/notifications", { params }, $config),
        updateNotifications: ($body, $config) => axios.put("/settings/notifications", $body, $config),
        getActivityLogs: (params, $config) => axios.get("/activity", { params }, $config),
        getGoals: (params, $config) => axios.get("/settings/goals", { params }, $config),
        updateGoals: ($body, $config) => axios.put("/settings/goals", $body, $config),
    },

    auth: {
        login: ($body, $config) => axios.post("/auth/login", $body, $config),
        signup: ($body, $config) => axios.post("/auth/register", $body, $config),
        logout: ($body, $config) => axios.post("/auth/logout", $body, $config),
        verifyToken: ($body, $config) => axios.post("/auth/validate-password", $body, $config),
        verifySession: ($body, $config) => axios.get("/auth/check-session", $body, $config),
        initiatePasswordReset: ($body, $config) => axios.post("/auth/initiate-reset", $body, $config),
        changePassword: ($body, $config) => axios.put("/auth/change-password", $body, $config),
        externalLogin: ($body, $config) => axios.post("/auth/external-auth", $body, $config),
    },

    client: {
        create: ($body, $config) => axios.post("/clients", $body, $config),
        getAll: (params, $config) => axios.get("/clients", { params}, $config),
        getOne: (id, $config) => axios.get(`/clients/${id}`, $config),
        updateOne: (id, $body, $config) => axios.put(`/clients/${id}`, $body, $config),
        delete: ($body, $config) => axios.patch("/clients/delete", $body, $config),
        upload: ($body, $config) => axios.post("/clients/upload", $body, $config),
        followUp: ($body, $config) => axios.post("/clients/follow-up", $body, $config),
        archive: (id, $body, $config) => axios.put(`/clients/${id}/archive`, $body, $config),
        getProjects: (id, params, $config) => axios.get(`/clients/${id}/projects`, { params }, $config),
        getInvoices: (id, params, $config) => axios.get(`/clients/${id}/invoices`, { params }, $config),
        getNotes: (id, params, $config) => axios.get(`/clients/${id}/notes`, { params }, $config),
        createNote: ($body, $config) => axios.post(`/clients/${$body.clientId}/notes`, $body, $config),
        editNote: (id, $body, $config) => axios.put(`/clients/${id}/notes/${$body._id}`, $body, $config),
        deleteNote: (id, $body, $config) => axios.delete(`/clients/${id}/notes/${$body._id}`, $config),
    },

    invoice: {
        getAll: (params, $config) => axios.get("/invoices", { params }, $config),
        getOne: (id, params, $config) => axios.get(`/invoices/${id}`, { params }, $config),
        sendAsDraft: ($body, $config) => axios.post("/invoices/draft", $body, $config),
        sendNew: ($body, $config) => axios.post("/invoices/send-new", $body, $config),
        sendExisting: ($body, $config) => axios.post("/invoices/send-existing", $body, $config),
        updateOne: (id, $body, $config) => axios.put(`/invoices/${id}`, $body, $config),
        delete: ($body, $config) => axios.patch("/invoices/delete", $body, $config),
        duplicate: ($body, $config) => axios.post("/invoices/duplicate", $body, $config),
        updateStatus: (id, $body, $config) => axios.put(`/invoices/${id}/update-status`, $body, $config),
    },

    project: {
        create: ($body, $config) => axios.post("/projects", $body, $config),
        getAll: (params, $config) => axios.get("/projects", { params }, $config),
        getOne: (id, $config) => axios.get(`/projects/${id}`, $config),
        updateOne: (id, $body, $config) => axios.put(`/projects/${id}`, $body, $config),
        updateSub: (id, $body, $config) => axios.put(`/projects/${id}/subs`, $body, $config),
        delete: ($body, $config) => axios.post("/projects/delete", $body, $config),
        upload: ($body, $config) => axios.post("/projectsq/upload", $body, $config),
        archive: (id, $body, $config) => axios.put(`/projects/${id}/archive`, $body, $config),
        addTag: (id, $body, $config) => axios.post(`/projects/${id}/tags`, $body, $config),
        removeTag: (id, $config) => axios.delete(`/projects/${id}/tags`, $config),
        getInvoices: (id, params, $config) => axios.get(`/projects/${id}/invoices`, { params }, $config),
        getNotes: (id, params, $config) => axios.get(`/projects/${id}/notes`, { params }, $config),
        createNote: ($body, $config) => axios.post(`/projects/${$body.projectId}/notes`, $body, $config),
        editNote: (id, $body, $config) => axios.put(`/projects/${id}/notes/${$body._id}`, $body, $config),
        deleteNote: (id, $body, $config) => axios.delete(`/projects/${id}/notes/${$body._id}`, $config),

    },

    rateCard: {
        create: ($body, $config) => axios.post("/rate-cards", $body, $config),
        getAll: (params, $config) => axios.get("/rate-cards", { params }, $config),
        getOne: (id, $config) => axios.get(`/rate-cards/${id}`, $config),
        updateOne: (id, $body, $config) => axios.put(`/rate-cards/${id}`, $body, $config),
        delete: (id, $config) => axios.delete(`/rate-cards/${id}`, $config),
        upload: ($body, $config) => axios.post("/rate-cards/upload", $body, $config),
        sendRateCard: ($body, $config) => axios.post("/rate-cards/send-rate-card", $body, $config),
    },

    revenue: {
        getStats: (params, $config) => axios.get("/revenue/stats", { params }, $config),
        getHistory: (params, $config) => axios.get("/revenue/list", { params }, $config),
        getGoals: (params, $config) => axios.get("/revenue/goals", { params }, $config),
        createGoal: ($body, $config) => axios.post("/revenue/goals", $body, $config),
        editGoal: (id, $body, $config) => axios.put(`/revenue/goals/${id}`, $body, $config),
        deleteGoal: (id, params, $config) => axios.delete(`/revenue/goals/${id}`, { params }, $config),
    },

    user: {
        get: (params, $config) => axios.get("/users/fetchprofile", { params }, $config),
        update: ($body, $config) => axios.put("/users/editprofile", $body, $config),
        delete: ($body, $config) => axios.delete("/users/deleteprofile", $body, $config),
        getReports: (params, $config) => axios.get("/users/reports", { params }, $config),
    },

    tag: {
        create: ($body, $config) => axios.post("/tags", $body, $config),
        getTags: (params, $config) => axios.get("/tags", { params }, $config),
        updateTag: (id, $body, $config) => axios.put(`/tags/${id}`, $body, $config),
        deleteTag: ($body, $config) => axios.patch("/tags/delete", $body, $config),
    },
    
    task: {
        addTask: ($body, $config) => axios.post("/tasks", $body, $config),
        duplicateTask: ($body, $config) => axios.post("/tasks/duplicate", $body, $config),
        getTasks: (params, $config) => axios.get("/tasks", { params }, $config),
        editTask: (id, $body, $config) => axios.put(`/tasks/${id}`, $body, $config),
        deleteTask: (id, $config) => axios.delete(`/tasks/${id}`, $config),
        modifyNotes: (id, $body, $config) => axios.put(`/tasks/${id}/notes`, $body, $config),
    },

    waitlist: {
        joinWaitlist: ($body, $config) => axios.post("/waitlist/register", $body, $config),
    },
};

export default api;