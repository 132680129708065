<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            size="sm"
            @close="$emit('cancel')"
        >
            <template #body>
                <div class="modal__wrapper--onboarding">
                    <div class="modal--section">
                        <div class="mb--20 mt--20">
                            <PageSpinner />
                        </div>
                    </div>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import PageSpinner from "../loaders/PageSpinner.vue";

export default {
    name: "PageLoading",

    components: { MainModal, PageSpinner },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel"],
};
</script>