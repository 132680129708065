import api from "@/api/url";

export default {
    namespaced: true,

    state: () => {
        return {
            currentRoute: {
                main: "",
                sub: "",
            },
            paths: [],
            completionStatus: "",
            completedProjectCount: 0,
            pdfFile: null,
            userSettings: {
                twoFactorAuth: {
                    authenticatorApp: {},
                    sms: {},
                },
                general: {
                    language: "en-US",
                    timeZone: "",
                    timeFormat: "12h",
                    dateFormat: "DD/MMMM/YYYY",
                    dayOfWeek: "sunday",
                    currency: "usd",
                    monthlyTarget: 0,
                    currentMonthlyTarget: 0,
                },
                appearance: {
                    theme: "default",
                },
                integrations: [],
                notifications: [],
                goals: {
                    _id: "",
                    isGoalsEnabled: false,
                    isProductivityEnabled: false,
                    goals: [],
                    productivity: {
                        status: "",
                        workingDays: [],
                    },
                },
            },
            activities: [],
            pageWrapperLoading: false,
            isNewUser: false,
            showRankModal: false,
            isRankConfetti: false,
        };
    },

    getters: {
        currentRoute: state => state.currentRoute,
        paths: state => state.paths,
        completionStatus: state => state.completionStatus,
        completedProjectCount: state => state.completedProjectCount,
        pdfFile: state => state.pdfFile,
        userSettings: state => state.userSettings,
        notifications: state => state.notifications,
        activities: state => state.activities,
        pageWrapperLoading: state => state.pageWrapperLoading,
        isNewUser: state => state.isNewUser,
        showRankModal: state => state.showRankModal,
        isRankConfetti: state => state.isRankConfetti,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setCurrentRouteTwo({ commit, state }, routePath) {
            const paths = typeof routePath === "string" && routePath.split("/");
            const modifiedPaths = [];

            for (let i = 0; i < paths.length; i++) {
                if (paths[i] !== "" && paths[i] !== "dashboard") {
                    if (paths[i].includes("and")) {
                        const replacedPath = paths[i].replace("-and-", " & ");
                        modifiedPaths.push(replacedPath);
                    } else if (paths[i].includes("-")) {
                        const replacedPath = paths[i].replace("-", " ");
                        modifiedPaths.push(replacedPath);
                    } else {
                        modifiedPaths.push(paths[i]);
                    }
                }
            }

            commit("set", ["paths", modifiedPaths]);
            commit("set", ["currentRoute", { ...state.currentRoute, main: modifiedPaths[0] }]);
        },

        fetchCompletedProjectCount({ commit, state }, { refresh = false }) {
            if (state.completedProjectCount && !refresh) {
                return state.completedProjectCount;
            }

            return api.app.getProjectCount()
                .then((resp) => {
                    const count = resp?.data.data.count;

                    commit("set", ["completedProjectCount", count]);
                });
        },

        generatePdf({ commit, state }) {

        }, 

        getCompletedStatus({ commit, state, rootGetters }) {

        }, 

        updateCompletedStatus({ commit, state }, boolValue) {

        },

        togglePageLoading({ commit }, boolValue) {
            commit("set", ["pageWrapperLoading", boolValue]);
        },
        
        // USER SETTINGS ---------------------------
        fetchSettings({ commit, state }, { refresh }) {
            if (state.userSettings?.accountType && !refresh) {
                return state.userSettings;
            }

            return api.app.getUserSettings()
                .then((res) => {
                    const data = res.data.data;
                    commit("set", ["userSettings", data]);
                });
        },

        updateGeneralSettings({ state, commit }, payload) {
            return api.app.updateSettings(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    
                    commit("set", ["userSettings", {
                        ...state.userSettings,
                        ...data,
                    }]);
                });
        },

        fetchNotifications({ commit, state }, { refresh }) {
            if (state.notifications?.length && !refresh) {
                return state.notifications;
            }

            return api.app.getNotifications()
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["notifications", data]);
                    return data;
                });
        },

        updateNotifications({ state, commit }, payload) {
            return api.app.updateNotifications(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    
                    commit("set", ["notifications", payload]);
                });
        },

        enableTwoFA({ commit }) {},

        fetchActivities({ commit, state }, { refresh }) {
            if (state.activities?.length && !refresh) {
                return state.activities;
            }

            return api.app.getActivityLogs()
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["activities", data]);
                });
        },

        fetchGoals({ commit, state }) {
            return api.app.getGoals()
                .then((res) => {
                    const data = res.data.data;
                    return data;
                });
        },

        updateGoals({ state, commit }, payload) {
            return api.app.updateGoals(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    
                    commit("set", ["goals", {
                        ...state.goals,
                        ...payload,
                    }]);
                });
        },

        toggleNewUser({ commit }, bool) {
            commit("set", ["isNewUser", bool]);
        },

        toggleRank({ commit }, bool) {
            commit("set", ["showRankModal", bool]);
        }, 

        toggleRankConfetti({ commit }, bool) {
            commit("set", ["isRankConfetti", bool]);
        },
    },
};