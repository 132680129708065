<template>
    <div>
        <div class="project__page--view">
            <template v-if="pageLoading">
                <page-spinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error" type="task">
                    <template #button>
                        <base-button label="Reload" size="sm" @submit="handleFetchTasks" />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="w-100 pb--25 pt--25">
                    <div v-if="showActionBtns" class="flex align-items-center w-100 mb--15 justify-content-between">
                        <div class="list--count">
                            <p class="text--sm text--semi--bold text--color-gray-700">
                                {{ tasks.length }} {{ tasks.length === 1 ? 'Task' : 'Tasks' }}
                            </p>
                        </div>
        
                        <div class="filter__actions--list">
                            <base-button 
                                label="+ New task" btn-style="outline" inner-style="primary"
                                size="sm" class="mr--5" @submit="showNewTask = true"
                            />
                            <main-filter page-type="tasks" />
                        </div>
                    </div>
                    <EmptyPage v-if="!tasks.length && isSearched" state="no_result" />
                    <EmptyPage
                        v-if="!tasks.length && !isSearched"
                        state="empty" type="task" icon-name="tasks"
                        width="60px" height="60px"
                    >
                        <template #button>
                            <base-button 
                                label="+ New task" size="sm"
                                class-names="flex align-items-center" @submit="showNewTask = true"
                            />
                        </template>
                    </EmptyPage>

                    <template v-else>
                        <div class="w--100 justify-content-center">
                            <div class="mt--40 mb--15">
                                <div v-for="item in tasks" :key="item._id" class="client__notes__item task__item">
                                    <div v-if="item" class="w--100 flex align-items-center justify-content-between" :class="{ 'disabled': taskId === item._id }">
                                        <div class="pointer flex-1">
                                            <div class="form-check mb--0">
                                                <input id="flexCheckDefault" class="form-check-input" type="checkbox" @change.stop="e => markTaskAsCompleted(e, item)">
                                                <label class="form-check-label task__item__label text--sm pointer" for="flexCheckDefault" @click.prevent="startTaskEdit(item)">
                                                    {{ item.name }}
                                                    <div class="flex align-items-center mt--5">
                                                        <div v-if="item.project" class="text--color-gray-200 text--xs task__project__title ml--5">
                                                            # {{ item.project.title }}
                                                        </div>
                                                        <span v-if="item.deadline" class="ml--5 mr--5 text--color-gray-200">|</span>
                                                        <div v-if="item.deadline" class="flex align-items-center">
                                                            <span class="mr--5">
                                                                <icon-svg name="calendar" fill="#BF2C6F" width="12px" height="12px" />
                                                            </span>
                                                            <span class="task__item__deadline">{{ $format_date(item.deadline) }}</span>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="dropdown">
                                            <button 
                                                id="noteDropdown" class="btn--ghost btn text--xs btn-sm text--bold" 
                                                type="button" data-bs-auto-close="true" 
                                                aria-expanded="false" data-bs-toggle="dropdown" 
                                            >
                                                <icon-svg name="dots-horizontal-rounded" fill="#637579" width="12px" height="12px" />
                                            </button>
                                            <ul id="noteDropdownList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="noteDropdown">
                                                <li class="dropdown-item pointer text--xs" @click="duplicateTask(item)">
                                                    Duplicate task
                                                </li>
                                                <li class="dropdown-item pointer text--xs" @click="handleDeleteTask(item)">
                                                    Delete task
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div v-if="showNewTask" class="w-100">
                                <form class="w--100 client__notes__form mt--5" @submit.prevent="">
                                    <div>
                                        <input 
                                            id="taskName"
                                            ref="newTask" 
                                            v-model="form.name" 
                                            class="form-control form-control-sm task__item__inline__input main" 
                                            type="text"
                                            placeholder="What do you want to do?"
                                        >
                                        <input 
                                            id="taskDescription" 
                                            v-model="form.description" 
                                            class="form-control form-control-sm sub task__item__inline__input" 
                                            type="text"
                                            placeholder="Add task description here..."
                                        >
                                        <div class="text--color-gray-200 text--xs mt--5 flex align-items-center">
                                            <span>#</span>
                                            <div class="task__add__dropdown">
                                                <CustomDropdown
                                                    id="projectsDropdown"
                                                    custom-trigger list-type="projects" item-label="title" :disabled="!projects.length"
                                                    :items="projects" prefix-text="#" dropdown-label-class="text--sm"
                                                    @select="selectProject"
                                                >
                                                    <template #dropdownTrigger>
                                                        <base-button 
                                                            :label="selectedProject.title" 
                                                            btn-style="ghost" 
                                                            size="sm"
                                                        />
                                                    </template>
                                                    <template #prefix>
                                                        <span class="task__add__dropdown__prefix" /> 
                                                    </template>
                                                </CustomDropdown>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="client__notes__action__btns task__action__btns pr--0">
                                        <base-button
                                            class-names="mr--5" label="Cancel" btn-style="secondary" size="sm" 
                                            @submit="showNewTask = false"
                                        />
                                        <base-button 
                                            type="submit" label="Add" :loading="addTaskLoading"
                                            size="sm" :disabled="(!form.name || !selectedProject?._id) || addTaskLoading" @submit="handleAddTask"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </template>
                </div>              
            </template>
        </div>

        <TaskDetails 
            :show-modal="showEditModal"
            :current-task="task"
            @cancel="closeEditModal"
        />
    </div>
</template>

<script>
import {
    IconSvg,
    EmptyPage,
    PageSpinner,
    BaseButton,
    MainFilter,
    TaskDetails,
    CustomDropdown,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";


export default {
    name: "TasksView",

    components: {
        EmptyPage,
        IconSvg,
        BaseButton,
        PageSpinner,
        MainFilter,
        TaskDetails,
        CustomDropdown,
    },

    data() {
        return {
            pageLoading: false,
            pageLoadingErr: false,
            pageLoadingErrMsg: "",
            showEditModal: false,
            showNewTask: false,
            isSearched: false,
            form: {
                name: "",
                description: "",
            },
            addTaskLoading: false,
            taskId: "",
            selectedProject: {},
            task: {
                project: {
                    _id: "",
                    title: "",
                },
                notes: [],
            },
        };
    },

    computed: {
        showActionBtns() {
            if (this.tasks.length) {
                return true;
            } else if (!this.tasks.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        tasks() {
            return this.$store.getters["task/tasks"] || [];
        },

        pageData() {
            return this.$store.getters["task/pageData"];
        },

        projects() {
            return this.$store.getters["project/projects"];
        },

        refreshList() {
            return this.$store.getters["task/refreshList"];
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal, oldVal) {
                const queryKeys = Object.keys(newVal);

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                await this.handleFetchTasks({ refresh: true, ...newVal });
            },
            deep: true,
            immediate: true,
        },

        showNewTask(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs?.newTask?.focus();
                });
            }
        },
    },

    async mounted() {
        if (!this.tasks.length && !this.isSearched && this.refreshList) {
            await this.handleFetchTasks({ refresh: true });
            this.$store.dispatch("task/setRefreshList", false);
        }
    },

    methods: {
        selectProject(project) {
            if (!project) return;

            this.selectedProject._id = project?._id;
            this.selectedProject.title = project?.title;
        },

        async handleFetchTasks(query) {
            try {
                this.pageLoading = true;
                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";

                // const query = this.$route.query;

                let pageQueryObj = {
                    page: query?.page || 1,
                };

                if (query.title) {
                    pageQueryObj.title = query.title;
                }

                if (query.priority) {
                    pageQueryObj.priority = query.priority;
                }

                if (query.startDate) {
                    pageQueryObj.startDate = query.startDate;
                }

                if (query.endDate) {
                    pageQueryObj.endDate = query.endDate;
                }

                const buildResult = assembleQueryList("tasks", pageQueryObj);

                await this.$store.dispatch("task/fetchTasks", { params: buildResult, refresh: query.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.pageLoadingErr = true;
                this.pageLoadingErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        }, 

        async handleDeleteTask(task) {
            try {
                if (!task?._id) return;

                this.taskId = task._id;

                await this.$store.dispatch("task/deleteTask", this.taskId);
                this.$toast.success("Task deleted");
            } catch (error) {
                const errMsg = err?.response?.data?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskId = "";
            }
        },

        startTaskEdit(task) {
            this.task = task;
            this.showEditModal = true;
        },

        closeEditModal() {
            this.showEditModal = false;
            
            this.task = {
                project: {
                    _id: "",
                    title: "",
                },
                notes: [],
            };
        },

        handleEditComplete() {            
            this.task = {
                project: {
                    _id: "",
                    title: "",
                },
                notes: [],
            };
        },

        async handleAddTask() {
            try {
                if (!this.selectedProject?._id) {
                    this.$toast.error("Select a project to create a new task");
                    return;
                }

                this.addTaskLoading = true;

                const payload = { 
                    name: this.form.name, 
                    description: this.form.description,
                    projectId: this.selectedProject?._id,
                    priority: "low",
                    deadline: null,
                    isCompleted: false,
                };

                await this.$store.dispatch("task/addTask", { payload, meta: this.selectedProject });

                this.$toast.success("Task added");

                this.form.name = "";
                this.form.description = "";
                this.selectedProject = {};
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.addTaskLoading = false;
            }
        },

        async markTaskAsCompleted(e, task) {
            try {                
                this.taskId = task._id;
                
                const val = e?.target?.checked;
                const payload = {
                    _id: task._id, 
                    isCompleted: val, 
                    projectId: task.project._id, 
                    name: task.name,
                    description: task.description,
                };

                await this.$store.dispatch("task/editTask", { 
                    payload,
                    fullData: {
                        _id: task._id, 
                        isCompleted: val, 
                        projectId: task.project._id, 
                        ...task,
                    },
                });
                
                this.$toast.success("Task completed");
            } catch (err) {
                const errMsg = err?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskId = "";
            }
        },

        async duplicateTask(task) {
            try {
                if (!task?._id) return;

                this.taskId = task._id;

                await this.$store.dispatch("task/duplicateTask", task);
                this.$toast.success("Task duplicated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskId = "";
            }
        },
    },
};
</script>