<template>
    <div class="form__item">
        <FormLabel v-if="label" :html-for="htmlFor" :title="label" />
        <textarea
            v-bind="{...$attrs}" 
            class="form-control form__input"
            :class="
                {
                    'form__input-error': isError,
                    ...classNames
                }"
            :disabled="disabled"
            :readonly="readonly"
            :value="modelValue" 
            autocomplete=""
            @input="emitValue"
            @change="e => $emit('change', e)"
            @focus="onFocus"
            @blur="onBlur"
            @keydown="e => $emit('keydown', e)"
            @keyup="handleKeyUp"
        />
        <p v-if="isError" class="form__err-msg">
            {{ errMsg }}
        </p>
    </div>
</template>

<script>
import FormLabel from "../forms/FormLabel.vue";

export default {
    name: "FormTextArea",

    components: {
        FormLabel,
    },

    inheritAttrs: false,
    
    model: {
        prop: "value",
        event: "input",
    },

    props: {
        modelValue: {
            default: "",
            type: [Number, String],
        },

        htmlFor: {
            required: true,
            type: String,
        },

        label: {
            default: "",
            type: String,
        },

        readonly: {
            type: Boolean,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        classNames: {
            type: Object,
            default: () => {},
        },

        required: {
            type: Boolean,
            default: false,
        },

        isError: {
            type: Boolean,
            default:false,
        },

        errMsg: {
            type: String,
            default: "",
        },
    },

    emits: ["input", "blur", "change", "keydown", "focus", "keyup", "update:modelValue"],

    data() {
        return {
            focusState: "blur",
        };
    },

    mounted() {
        setInterval(() => {
            if (this.autofocus) {
                this.focus();
            }
        }, 1500);
    },

    methods: {
        onFocus(e) {
            // Emitted when you focus on the input.
            this.focusState = "focus";
            this.$emit("focus", e);
        },

        onBlur(e) {
            // Emitted when you blur off the input.
            this.focusState = "blur";
            this.$emit("blur", e);
        },

        handleKeyUp(event) {
            this.$emit("keyup", event);
        },

        emitValue(e) {
            let value = e.target.value;

            this.$emit("update:modelValue", value);
        },
    },
};
</script>