<template>
    <div
        id="markInvoiceAsPaid"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="markInvoiceAsPaidLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        id="markInvoiceAsPaidLabel"
                        class="modal-title"
                    >
                        Update Invoice Status
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div class="modal-body">
                    <form
                        class="form--workspace__create mb--20"
                        @submit.prevent=""
                    >
                        <div class="form__item">
                            <div class="mb-3 mt--20 text-center">
                                <p>Are you sure you want to mark this invoice as <span class="text--medium">paid</span>?</p>
                                <p class="text--sm mt--10 text--mute">
                                    (Note that: this action cannot be undone)
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <base-button 
                        class-names="mr--5" label="Cancel" 
                        btn-style="secondary" size="sm" aria-label="Close"
                        data-bs-dismiss="modal" @submit="$emit('cancel')"
                    />

                    <base-button 
                        :loading="loading" 
                        :is-full-width="false"
                        btn-style="primary"
                        label="Mark as paid" 
                        size="sm"
                        @submit="handleMarkInvoiceAsPaid"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";

export default {
    name: "MarkInvoiceAsPaid",

    components: {
        BaseButton,
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        handleMarkInvoiceAsPaid() {
            this.$emit( "submit");
        },
    },
};
</script>