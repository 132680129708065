
export const industryData = ["arts", "fintech", "sales", "software", "ux", "research", "marketing", "operations", "design"];

export const revenueTabList = [
    {   
        id: "Overview",
        component: "RevenueOverview",
        route: "revenue/overview",
    },
    {   
        id: "History",
        component: "RevenueHistory",
        route: "revenue/history",
    },
];

export const clientTabList = [
    // {   
    //     name: "Activity",
    //     component: "ClientActivity",
    //     iconName: "map-pin",
    //     iconFill: "sm",
    // },
    {   
        name: "Notes",
        component: "ClientNotes",
        iconName: "notes",
        iconFill: "sm",
    },
    {   
        name: "Projects",
        component: "ClientProjects",
        iconName: "project",
        iconFill: "sm",
    },
    {   
        name: "Invoices",
        component: "ClientInvoices",
        iconName: "receipt",
        iconFill: "sm",
    },
    {   
        name: "Documents",
        component: "ClientDocuments",
        iconName: "",
        iconFill: "sm",
    },
];

export const projectTabList = [
    {   
        name: "Activity",
        component: "ProjectActivity",
    },
    {   
        name: "Notes",
        component: "ProjectNotes",
    },
    {   
        name: "Invoices",
        component: "ProjectInvoices",
    },
];

export const securityAccessQuestions = [
    "What is the name of your first pet?",
    "Who was your childhood hero?",
    "What is your mother's maiden name?",
    "What is the title of your favourite movie?",
    "What is the name of the street you lived in as a kid?",
    "What is the name of the street you lived in as a kid?",
];

export const settingsRoutesList = [
    {   
        name: "account",
        iconName: "user",
    },
    {   
        name: "general settings",
        iconName: "home",
    },
    // {   
    //     name: "reminders",
    //     iconName: "alarm",
    // },
    {   
        name: "notifications",
        iconName: "bell",
    },
    {   
        name: "billings & plans",
        iconName: "purchase-tag",
    },
    {   
        name: "integrations",
        iconName: "expand",
    },
    {   
        name: "goals & productivity",
        iconName: "map-pin",
    },
];

export const featuresList = [
    {
        id: 1, title: "Reports", 
        desc: "Get detailed reports on your completed projects, earnings and clients within a selected time period",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" style=\"fill: #296C49; transform: ;msFilter:;\"><path d=\"m20 8-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8zM9 19H7v-9h2v9zm4 0h-2v-6h2v6zm4 0h-2v-3h2v3zM14 9h-1V4l5 5h-4z\"></path></svg>",
    },
    {   id: 2, title: "Reminders", 
        desc: "Get custom reminders about specific tasks and projects when you are running out of time" ,
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" style=\"fill: #296C49; transform: ;msFilter:;\"><path d=\"M12 4c-4.879 0-9 4.121-9 9s4.121 9 9 9 9-4.121 9-9-4.121-9-9-9zm0 16c-3.794 0-7-3.206-7-7s3.206-7 7-7 7 3.206 7 7-3.206 7-7 7z\"></path><path d=\"M13 12V8h-2v6h6v-2zm4.284-8.293 1.412-1.416 3.01 3-1.413 1.417zm-10.586 0-2.99 2.999L2.29 5.294l2.99-3z\"></path></svg>", 
    },
    {   id: 3, title: "Billing & Invoicing", 
        desc: "Bill your clients for work done. Create invoices, download PDF copies of invoices or mail them over to your clients.",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" style=\"fill: #296C49; transform: ;msFilter:;\"><path d=\"M21 11h-3V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-6a1 1 0 0 0-1-1zM5 19a1 1 0 0 1-1-1V5h12v13c0 .351.061.688.171 1H5zm15-1a1 1 0 0 1-2 0v-5h2v5z\"></path><path d=\"M6 7h8v2H6zm0 4h8v2H6zm5 4h3v2h-3z\"></path></svg>",  
    },
    {   id: 4, title: "Project management", 
        desc: "Estimate your projects. Get insights and track your freelance projects with ease.",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" style=\"fill: #296C49; transform: ;msFilter:;\"><path d=\"M4.626 8.878a7.937 7.937 0 0 1 1.71-2.541 7.92 7.92 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.49 2.49 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059-1.19.5-2.26 1.22-3.18 2.139A9.98 9.98 0 0 0 2 12h2c0-1.086.211-2.136.626-3.122zm14.747 6.244c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.973 9.973 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z\"></path><path d=\"M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538c2.502 0 4.538-2.036 4.538-4.538S14.502 7.462 12 7.462z\"></path></svg>",  
    },
    {   id: 5, title: "Client management", 
        desc: "Organize and manage your client list. Add notes about clients that's accessible to only you.",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" style=\"fill: #296C49;transform: ;msFilter:;\"><path d=\"M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z\"></path><path d=\"M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z\"></path></svg>", 
    },
];

export const integrationList = [
    // {
    //     id: "Automation",
    //     desc: "Say hello to streamlined processes. Unlock the power of automation for a smoother, more productive experience as you automate tasks on the fly",
    // }, 
    {
        id: "Online visibility",
        desc: "Amplify your creativity by sharing your successful projects. Our app offers you a way to share your work with potential clients.",
    }, 
    {
        id: "Calendar",
        desc: "Sync your work processess and organize your work schedules effectively. Explore the convenience our calendar integration offers.",
        // Elevate your planning experience and make every moment count.
    },
    {
        id: "Time tracking",
        desc: "Track and manage your projects with ease. Stay on top of deadlines and boost productivity with our time tracking integration.",
        // Experience the convenience of integrated time management. Your efficiency, our priority – start tracking your success today!
    },
    {
        id: "AI Assistance",
        desc: "Elevate your experience with smart, automated solutions. Our AI Assistant integration helps with personalized guidance.",
    },
];

export const rankingList = [
    { 
        name: "Shoshinsha", 
        symbol: "初心者",
        desc: "Congratulations! You've completed up to 5 projects.", 
    },
    { 
        name: "Chūkyū", 
        symbol: "中級",
        desc: "Congratulations! You've completed up to 30 projects.", 
    },
    { 
        name: "Sensei", 
        symbol: "先生",
        desc: "Congratulations! You've completed up to 70 projects.", 
    },
    { 
        name: "Shishou", 
        symbol: "師匠",
        desc: "Congratulations! You've completed up to 160 projects.", 
    },
];

export const rankObj = {
    "Shoshinsha": "Congratulations! You've completed a minimum of 5 projects.", 
    "Chūkyū": "Congratulations! You've completed a minimum of 10 projects.", 
    "Sensei": "Congratulations! You've completed a minimum of 25 projects.", 
    "Shishou": "Congratulations! You've completed a minimum of 50 projects.", 
};

export const clientHeaders = [
    { id: "Name", accessorKey: "name", iconName: "user"},
    { id: "Company name", accessorKey: "companyName", iconName: "user"},
    { id: "email", accessorKey: "email", iconName: "envelope-solid"},
    { id: "industry", accessorKey: "industry", iconName: "buildings"},
    { id: "status", accessorKey: "status", iconName: "reports-solid"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const projectHeaders = [
    { id: "Project title", accessorKey: "title", iconName: "user"},
    { id: "status", accessorKey: "status", iconName: "envelope-solid"},
    { id: "deadline", accessorKey: "deadline", iconName: "buildings"},
    { id: "Payment Status", accessorKey: "paymentStatus", iconName: "reports-solid"},
    { id: "Priority", accessorKey: "priority", iconName: "buildings"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const invoiceHeaders = [
    { id: "Client", accessorKey: "clientName", iconName: "envelope-solid"},
    { id: "Email", accessorKey: "clientEmail", iconName: "envelope-solid"},
    { id: "Status", accessorKey: "Status", iconName: "user"},
    { id: "Amount", accessorKey: "amount", iconName: "buildings"},
    { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
    { id: "Due Date", accessorKey: "dueDate", iconName: "timer-solid"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const clientInvoiceHeaders = [
    { id: "Status", accessorKey: "Status", iconName: "user"},
    { id: "Amount", accessorKey: "amount", iconName: "buildings"},
    { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
    { id: "Due Date", accessorKey: "dueDate", iconName: "timer-solid"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const paymentHistoryHeaders = [
    { id: "Payment Method", accessorKey: "paymentMethod", iconName: "envelope-solid"},
    { id: "Price", accessorKey: "price", iconName: "user"},
    { id: "Duration", accessorKey: "duration", iconName: "buildings"},
    { id: "Billing Date", accessorKey: "billingDate", iconName: "reports-solid"},
];

export const workingDays = [
    { name: "Sunday", isActive: false },
    { name: "Monday", isActive: true },
    { name: "Tuesday", isActive: false },
    { name: "Wednesday", isActive: true },
    { name: "Thursday", isActive: true },
    { name: "Friday", isActive: true },
    { name: "Saturday", isActive: false },
];

export const allowedCurrencies = ["USD", "NGN", "GBP", "GHS", "EUR"];

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const dateFormats = ["DD/MM/YY", "DD/MM/YYYY", "DD/MMMM/YYYY", "MM/DD/YY", "MMMM/DDDD/YYYY"];

export const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const theme = ["default", "dark", "orange", "brick red"];

export const invoiceReminders = [
    { id: "14_days_before", name: "14 days before the due date"},
    { id: "7_days_before", name: "7 days before the due date"},
    { id: "on_due_date", name: "On the due date"},
    { id: "a_day_after", name: "A day after the due date"},
    { id: "3_days_after", name: "3 days after the due date"},
    { id: "7_days_after", name: "7 days after the due date"},
    { id: "14_days_after", name: "14 days after the due date"},
];

export const rateCardUploadHeaders = [
    { title: "Title", required: true },
    { title: "Currency", required: true },
    { title: "Amount", required: true },
    { title: "Rate Type", required: true },
    { title: "Description", required: false },
    { title: "Billable Hours", required: false },
    { title: "Billable Rate", required: false },
];

export const clientUploadHeaders = [
    { title: "Name", required: true },
    { title: "Company Name", required: true },
    { title: "Email", required: true },
    { title: "Status", required: true },
    { title: "Industry", required: false },
];

export const accountIntegrations = {
    calendar: [],
    time_tracking: [], // monday.com, google sheets
    email: [],
};

export const passwordChecks = {
    id: "sixCharacters",
};

export const mapReminders = {
    "14_days_before": "14 days before the due date",
    "7_days_before": "7 days before the due date",
    "on_due_date": "On the due date",
    "a_day_after": "A day after the due date",
    "3_days_after": "3 days after the due date",
    "7_days_after": "7 days after the due date",
    "14_days_after": "14 days after the due date",
};

export const headerResources = [
    {
        name: "Help center",
        iconName: "info",
    },
    {
        name: "Getting started",
        iconName: "map-pin",
    },
    {
        name: "Pricing",
        iconName: "money",
    },
];

export const projectInvoiceHeaders = [
    { id: "Client", accessorKey: "clientName", iconName: "envelope-solid"},
    { id: "Status", accessorKey: "Status", iconName: "user"},
    { id: "Amount", accessorKey: "amount", iconName: "buildings"},
    { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const billingMethods = [
    { id: "personal_account", name: "Personal account" },
    { id: "external", name: "Use a third-party service like Stripe, Paystack..." },
];