import { buildMap } from "../utils/helpers";

export const supportedCountries = [
    { code: "AD", currency: "EUR", countryCode: "AND", symbol: "€", fullname: "Andorra", phone: "+376" },
    { code: "AO", currency: "AOA", countryCode: "AGO", symbol: "Kz", fullname: "Angola", phone: "+376" },
    { code: "AU", currency: "AUD", countryCode: "AUS", symbol: "$", fullname: "Australia", phone: "+61" },
    { code: "AT", currency: "EUR", countryCode: "AUT", symbol: "€", fullname: "Austria", phone: "+43" },
    { code: "BY", currency: "EUR", countryCode: "BLR", symbol: "€", fullname: "Belarus", phone: "+375" },
    { code: "BE", currency: "EUR", countryCode: "BEL", symbol: "€", fullname: "Belgium", phone: "+32" },
    { code: "BJ", currency: "XOF", countryCode: "BEN", symbol: "CFA", fullname: "Benin", phone: "+229" },
    { code: "BA", currency: "EUR", countryCode: "BIH", symbol: "€", fullname: "Bosnia and Herzegovina", phone: "+387" },
    { code: "BR", currency: "BRL", countryCode: "BRA", symbol: "R$", fullname: "Brazil", phone: "+55" },
    { code: "BG", currency: "EUR", countryCode: "BGR", symbol: "€", fullname: "Bulgaria", phone: "+359" },
    { code: "BF", currency: "XOF", countryCode: "BFA", symbol: "CFA", fullname: "Burkina Faso", phone: "+226" },
    { code: "CM", currency: "XAF", countryCode: "CMR", symbol: "CFA", fullname: "Cameroon", phone: "+237" },
    { code: "CA", currency: "CAD", countryCode: "CAN", symbol: "$", fullname: "Canada", phone: "+1" },
    { code: "CF", currency: "XAF", countryCode: "CAF", symbol: "CFA", fullname: "Central African Republic", phone: "+236" },
    { code: "TD", currency: "XAF", countryCode: "TCD", symbol: "CFA", fullname: "Chad", phone: "+235" },
    { code: "HR", currency: "EUR", countryCode: "HRV", symbol: "€", fullname: "Croatia", phone: "+385" },
    { code: "CY", currency: "EUR", countryCode: "CYP", symbol: "€", fullname: "Cyprus", phone: "+357" },
    { code: "CZ", currency: "EUR", countryCode: "CZE", symbol: "€", fullname: "Czech Republic", phone: "+420" },
    { code: "DK", currency: "EUR", countryCode: "DNK", symbol: "€", fullname: "Denmark", phone: "+45" },
    { code: "EG", currency: "EGP", countryCode: "EGY", symbol: "$", fullname: "Egypt", phone: "+20" },
    { code: "GQ", currency: "XAF", countryCode: "GNQ", symbol: "CFA", fullname: "Equitorial Guinea", phone: "+240" },
    { code: "EE", currency: "EUR", countryCode: "EST", symbol: "€", fullname: "Estonia", phone: "+372" },
    { code: "ET", currency: "ETB", countryCode: "ETH", symbol: "Br", fullname: "Ethiopia", phone: "+251" },
    { code: "FI", currency: "EUR", countryCode: "FIN", symbol: "€", fullname: "Finland", phone: "+358" },
    { code: "FR", currency: "EUR", countryCode: "FRA", symbol: "€", fullname: "France", phone: "+33" },
    { code: "GA", currency: "XAF", countryCode: "GAB", symbol: "CFA", fullname: "Gabon", phone: "+241" },
    { code: "DE", currency: "EUR", countryCode: "DEU", symbol: "€", fullname: "Germany", phone: "+49" },
    { code: "GH", currency: "GHS", countryCode: "GHA", symbol: "GH₵", fullname: "Ghana", phone: "+233" },
    { code: "GI", currency: "EUR", countryCode: "GIB", symbol: "€", fullname: "Gibraltar", phone: "+350" },
    { code: "GR", currency: "EUR", countryCode: "GRC", symbol: "€", fullname: "Greece", phone: "+30" },
    { code: "GW", currency: "XOF", countryCode: "GNB", symbol: "CFA", fullname: "Guinea-Bissau", phone: "+245" },
    { code: "HU", currency: "EUR", countryCode: "HUN", symbol: "€", fullname: "Hungary", phone: "+36" },
    { code: "IS", currency: "EUR", countryCode: "ISL", symbol: "€", fullname: "Iceland", phone: "+354" },
    //   { code: "IN",   currency: "INR",    countryCode: "IND",   symbol: "₹",    fullname: "India", phone: "+91" },
    { code: "IE", currency: "EUR", countryCode: "IRL", symbol: "€", fullname: "Ireland", phone: "+353" },
    { code: "IL", currency: "ILS", countryCode: "ISR", symbol: "₪", fullname: "Israel", phone: "+972" },
    { code: "IT", currency: "EUR", countryCode: "ITA", symbol: "€", fullname: "Italy", phone: "+39" },
    { code: "CI", currency: "XOF", countryCode: "CIV", symbol: "CFA", fullname: "Côte d'Ivoire", phone: "+225" },
    { code: "JP", currency: "JPY", countryCode: "JPN", symbol: "¥", fullname: "Japan", phone: "+81" },
    { code: "KE", currency: "KES", countryCode: "KEN", symbol: "KSh", fullname: "Kenya", phone: "+254" },
    { code: "LV", currency: "EUR", countryCode: "LVA", symbol: "€", fullname: "Latvia", phone: "+371" },
    { code: "LI", currency: "EUR", countryCode: "LIE", symbol: "€", fullname: "Liechtenstein", phone: "+423" },
    { code: "LT", currency: "EUR", countryCode: "LTU", symbol: "€", fullname: "Lithuania", phone: "+370" },
    { code: "LU", currency: "EUR", countryCode: "LUX", symbol: "€", fullname: "Luxembourg", phone: "+352" },
    { code: "MW", currency: "MWK", countryCode: "MWI", symbol: "MK", fullname: "Malawi", phone: "+265" },
    { code: "ML", currency: "XOF", countryCode: "MLI", symbol: "CFA", fullname: "Mali", phone: "+223" },
    { code: "MT", currency: "EUR", countryCode: "MLT", symbol: "€", fullname: "Malta", phone: "+356" },
    { code: "MU", currency: "MUR", countryCode: "MUS", symbol: "Rs", fullname: "Mauritius", phone: "+230" },
    { code: "MC", currency: "EUR", countryCode: "MCO", symbol: "€", fullname: "Monaco", phone: "+377" },
    { code: "MA", currency: "MAD", countryCode: "MAR", symbol: "$", fullname: "Morocco", phone: "+212" },
    { code: "MZ", currency: "MZN", countryCode: "MOZ", symbol: "$", fullname: "Mozambique", phone: "+258" },
    { code: "NL", currency: "EUR", countryCode: "NLD", symbol: "€", fullname: "Netherlands", phone: "+31" },
    { code: "NE", currency: "XOF", countryCode: "NER", symbol: "CFA", fullname: "Niger", phone: "+227" },
    { code: "NG", currency: "NGN", countryCode: "NGA", symbol: "₦", fullname: "Nigeria", phone: "+234" },
    { code: "NO", currency: "EUR", countryCode: "NOR", symbol: "€", fullname: "Norway", phone: "+47" },
    { code: "PL", currency: "EUR", countryCode: "POL", symbol: "€", fullname: "Poland", phone: "+48" },
    { code: "PT", currency: "EUR", countryCode: "PRT", symbol: "€", fullname: "Portugal", phone: "+351" },
    { code: "MD", currency: "EUR", countryCode: "MDA", symbol: "€", fullname: "Republic of Moldova", phone: "+373" },
    { code: "MK", currency: "EUR", countryCode: "MKD", symbol: "€", fullname: "Republic of North Macedonia", phone: "+389" },
    { code: "RO", currency: "EUR", countryCode: "ROM", symbol: "€", fullname: "Romania", phone: "+40" },
    { code: "RW", currency: "RWF", countryCode: "RWA", symbol: "FRw", fullname: "Rwanda", phone: "+250" },
    { code: "SN", currency: "XOF", countryCode: "SEN", symbol: "$", fullname: "Senegal", phone: "+221" },
    { code: "CS", currency: "EUR", countryCode: "SCG", symbol: "€", fullname: "Serbia and Montenegro", phone: "+381" },
    { code: "SL", currency: "SLL", countryCode: "SLE", symbol: "Le", fullname: "Sierra Leonne", phone: "+232" },
    { code: "SK", currency: "EUR", countryCode: "SVK", symbol: "€", fullname: "Slovakia", phone: "+421" },
    { code: "SI", currency: "EUR", countryCode: "SVN", symbol: "€", fullname: "Slovenia", phone: "+386" },
    { code: "ZA", currency: "ZAR", countryCode: "ZAF", symbol: "R", fullname: "South Africa", phone: "+27" },
    { code: "ES", currency: "EUR", countryCode: "ESP", symbol: "€", fullname: "Spain", phone: "+34" },
    { code: "SE", currency: "EUR", countryCode: "SWE", symbol: "€", fullname: "Sweden", phone: "+46" },
    { code: "CH", currency: "EUR", countryCode: "CHE", symbol: "€", fullname: "Switzerland", phone: "+41" },
    { code: "TZ", currency: "TZS", countryCode: "TZA", symbol: "Tsh", fullname: "Tanzania", phone: "+255" },
    { code: "CG", currency: "XAF", countryCode: "COG", symbol: "CFA", fullname: "The Republic of Congo", phone: "+242" },
    { code: "TG", currency: "XOF", countryCode: "TGO", symbol: "CFA", fullname: "Togo", phone: "+228" },
    { code: "TR", currency: "EUR", countryCode: "TUR", symbol: "€", fullname: "Turkey", phone: "+90" },
    { code: "UG", currency: "UGX", countryCode: "UGA", symbol: "Ush", fullname: "Uganda", phone: "+256" },
    { code: "UA", currency: "EUR", countryCode: "UKR", symbol: "€", fullname: "Ukraine", phone: "+380" },
    { code: "GB", currency: "GBP", countryCode: "GBR", symbol: "£", fullname: "United Kingdom", phone: "+44" },
    { code: "US", currency: "USD", countryCode: "USA", symbol: "$", fullname: "United States of America", phone: "+1" },
    { code: "ZM", currency: "ZMW", countryCode: "ZMB", symbol: "ZK", fullname: "Zambia", phone: "+260" },
];

export const newSignupCountries = [
    // { code: "CM", currency: "XAF", countryCode: "CMR", symbol: "CFA", fullname: "Cameroon", phone: "+237" },
    // { code: "CI", currency: "XOF", countryCode: "CIV", symbol: "CFA", fullname: "Côte d'Ivoire", phone: "+225" },
    // { code: "GH", currency: "USD", countryCode: "GHA", symbol: "GH₵", fullname: "Ghana", phone: "+233" },
    // { code: "EG", currency: "USD", countryCode: "EGY", symbol: "$", fullname: "Egypt", phone: "+20" },
    // { code: "KE", currency: "KES", countryCode: "KEN", symbol: "KSh", fullname: "Kenya", phone: "+254" },
    // { code: "MW", currency: "MWK", countryCode: "MWI", symbol: "MK", fullname: "Malawi", phone: "+265" },
    // { code: "MU", currency: "MUR", countryCode: "MUS", symbol: "Rs", fullname: "Mauritius", phone: "+230" },
    // { code: "MA", currency: "USD", countryCode: "MAR", symbol: "$", fullname: "Morocco", phone: "+212" },
    // { code: "MZ", currency: "USD", countryCode: "MOZ", symbol: "$", fullname: "Mozambique", phone: "+258" },
    { code: "NG", currency: "NGN", countryCode: "NGA", symbol: "₦", fullname: "Nigeria", phone: "+234" },
    // { code: "RW", currency: "RWF", countryCode: "RWA", symbol: "FRw", fullname: "Rwanda", phone: "+250" },
    // { code: "ZA", currency: "ZAR", countryCode: "ZAF", symbol: "R", fullname: "South Africa", phone: "+27" },
    // { code: "TZ", currency: "TZS", countryCode: "TZA", symbol: "Tsh", fullname: "Tanzania", phone: "+255" },
    // { code: "UG", currency: "UGX", countryCode: "UGA", symbol: "Ush", fullname: "Uganda", phone: "+256" },
    //   { code: "GB",   currency: "GBP",    countryCode: "GBR",   symbol: "£",    fullname: "United Kingdom", phone: "+44" },
    //   { code: "US",   currency: "USD",    countryCode: "USA",   symbol: "$",    fullname: "United States of America", phone: "+1" },
    // { code: "ZM", currency: "ZMW", countryCode: "ZMB", symbol: "ZK", fullname: "Zambia", phone: "+260" },
];

export const supportedCurrencies = buildSupportedCurrencies(supportedCountries);

export const signupCountries = newSignupCountries.sort((a, b) => (a.fullname > b.fullname) ? 1 : ((b.fullname > a.fullname) ? -1 : 0)); ;

export const paymentSupportedCurrencies = [
    "AED", "ALL", "ARS", "AUD", "BGN", "BHD",
    "BND", "BRL", "BWP", "CAD", "CFA", "CHF",
    "CLP", "CNY", "COP", "CRC", "CZK", "DKK",
    "DOP", "DZD", "EUR", "GBP", "GHS", "GMD",
    "GTQ", "HKD", "HNL", "HUF", "IDR", "ILS",
    "INR", "IQD", "ISK", "JOD", "JPY", "KES",
    "KHR", "KRW", "KWD", "LBP", "LKR", "LYD",
    "MAD", "MOP", "MUR", "MWK", "MXN", "MYR",
    "NGN", "NOK", "NZD", "OMR", "PAB", "PEN",
    "PHP", "PLN", "PYG", "QAR", "RUB", "RWF",
    "SAR", "SDD", "SEK", "SGD", "SLL", "SVC",
    "SYP", "THB", "TND", "TRY", "TWD", "TZS",
    "UGX", "USD", "VEF", "VND", "XAF", "XOF",
    "YER", "ZAR", "ZMW", "ZWD",
];

export function getPaymentLinkCountry(currency = globalCountry.currency) {
    switch (currency) {
    case "GHS":
    case "KES":
    case "ZAR":
    case "TZS":
        return country.getCode(currency);
    default:
        return "NG";
    }
}

export const transferCurrencies = buildTransferCurrencies(["AUD", "ETB", "EUR", "GHS", "GBP", "KES", "NGN", "RWF", "SLL", "TZS", "UGX", "USD", "XAF", "XOF", "ZAR", "ZMW"]);

export const usdTransferCurrencies = [
    "AGO", "BDI", "COD", "CPV", "ETH",
    "EUR", "GBP", "GHS", "GIN", "GMB",
    "KES", "LBR", "MOZ", "MWI", "NGN",
    "RWF", "SLL", "STP", "TZS", "UGX",
    "USD", "XAF", "XOF", "ZAR", "ZMW",
    "ZWE",
];

export const internationalTransferCurrencies = ["AUD", "USD", "GBP", "EUR"];

export const transferCountries = buildTransferCountries(supportedCountries, transferCurrencies);

export const topupCurrencies = [
    // These are the currencies supported for top up
    "BIF", "CAD", "CDF", "CVE", "EUR",
    "GBP", "GHS", "GMD", "GNF", "KES",
    "LRD", "MWK", "MZN", "NGN", "RWF",
    "SLL", "STD", "TZS", "UGX", "USD",
    "XAF", "XOF", "ZAR", "ZMW", "ZWD",
];

export const supportedBankCountries = [
    // These are the countries we currently have banks for.
    "NG", "KE", "GH", "UG", "TZ", "RW",
    "ZA", "CI", "ZM", "CM", "TD", "SN",
    "SL", "RW", "ML", "GA", "CG", "BJ", "CF",
    "ET", "MW", "US",
];

export const supportedCountriesMap = buildMap(supportedCountries);

export const cardIssuingCurrencies = ["NGN", "USD"];

export const globalCountry = {
    code: "US",
    currency: "USD",
    countryCode: "USA",
    symbol: "$",
    fullname: "United States of America",
    phone: "+234",
};

export const newSettlementCountries = [
    { code: "CM", currency: "XAF", countryCode: "CMR", symbol: "CFA", fullname: "Cameroon", phone: "+237" },
    { code: "CI", currency: "XOF", countryCode: "CIV", symbol: "CFA", fullname: "Côte d'Ivoire", phone: "+225" },
    { code: "GH", currency: "USD", countryCode: "GHA", symbol: "GH₵", fullname: "Ghana", phone: "+233" },
    { code: "EG", currency: "USD", countryCode: "EGY", symbol: "$", fullname: "Egypt", phone: "+20" },
    { code: "KE", currency: "KES", countryCode: "KEN", symbol: "KSh", fullname: "Kenya", phone: "+254" },
    { code: "MW", currency: "MWK", countryCode: "MWI", symbol: "MK", fullname: "Malawi", phone: "+265" },
    { code: "MU", currency: "MUR", countryCode: "MUS", symbol: "Rs", fullname: "Mauritius", phone: "+230" },
    { code: "MA", currency: "USD", countryCode: "MAR", symbol: "$", fullname: "Morocco", phone: "+212" },
    { code: "MZ", currency: "USD", countryCode: "MOZ", symbol: "$", fullname: "Mozambique", phone: "+258" },
    { code: "NG", currency: "NGN", countryCode: "NGA", symbol: "₦", fullname: "Nigeria", phone: "+234" },
    { code: "RW", currency: "RWF", countryCode: "RWA", symbol: "FRw", fullname: "Rwanda", phone: "+250" },
    { code: "SN", currency: "XOF", countryCode: "SEN", symbol: "CFA", fullname: "Senegal", phone: "+221" },
    { code: "ZA", currency: "ZAR", countryCode: "ZAF", symbol: "R", fullname: "South Africa", phone: "+27" },
    { code: "TZ", currency: "TZS", countryCode: "TZA", symbol: "Tsh", fullname: "Tanzania", phone: "+255" },
    { code: "UG", currency: "UGX", countryCode: "UGA", symbol: "Ush", fullname: "Uganda", phone: "+256" },
    { code: "GB", currency: "GBP", countryCode: "GBR", symbol: "£", fullname: "United Kingdom", phone: "+44" },
    { code: "US", currency: "USD", countryCode: "USA", symbol: "$", fullname: "United States of America", phone: "+1" },
    { code: "ZM", currency: "ZMW", countryCode: "ZMB", symbol: "ZK", fullname: "Zambia", phone: "+260" },
];

export const getUserCountry = userCountryIso2 => ({

    /**
   * Returns the country code for the merchant E.g `NG`
   * @returns {string}
   */
    get code() {
        return userCountryIso2;
    },

    /**
   * Returns the country currency for the merchant E.g `NGN`
   * @returns {string}
   */
    get currency() {
        return country.getCurrency(this.code);
    },

    get countryCode() {
        return country.getCountryCode(this.code);
    },

    /**
   * Returns the country symbol for the merchant E.g `₦`;
   * @returns {string}
   */
    get symbol() {
        return country.getSymbol(this.code);
    },

    /**
   * Returns the country fullname for the merchant E.g `Nigeria`
   * @returns {string}
   */
    get fullname() {
        return country.getFullname(this.code);
    },

    /**
   * Returns the country dialing code for the merchant E.g `+234`
   * @returns {string}
   */
    get phone() {
        return country.getPhone(this.code);
    },

});

export const userCountry = getUserCountry("NG");

export const settlementAccountCurrencies = buildSupportedCurrencies(supportedCountries, ["INR", "MWK"]);

export const contactSalesCountries = [
    // These are the countries allowed to contact sales team
    "CM", "CI", "GH", "EG",
    "KE", "MW", "MU", "MA",
    "MZ", "RW", "ZA", "TZ",
    "UG", "GB", "US", "ZM",
];

function buildSupportedCurrencies(supportedCountries = [], excludedCurrencies = []) {
    const currenciesMap = {};
    for (const country of supportedCountries) {
        if (excludedCurrencies.includes(country.currency)) {
            continue;
        }
        currenciesMap[country.currency] = country.currency;
    }
    return Object.keys(currenciesMap);
}

function buildTransferCurrencies(currencies = []) {
    const currenciesObj = {};
    for (const curr of currencies) {
        currenciesObj[curr] = curr;
    }
    return currenciesObj;
};

function buildTransferCountries(supportedCountries, transferCurrencies = {}) {
    const countries = [];

    for (const currency in transferCurrencies) {
        const currencyCountries = supportedCountries.filter(c => c.currency === currency);
        countries.push(...currencyCountries);
    }

    return countries.sort((a, b) => (a.fullname > b.fullname) ? 1 : ((b.fullname > a.fullname) ? -1 : 0));
}

export const country = {
    getCodes(currency) {
        return supportedCountries
            .filter(country => country.currency === currency) || [globalCountry]
            .map(country => country.code);
    },

    getCurrency(code) {
        const country = supportedCountriesMap[code];
        if (country === undefined) {
            return globalCountry.currency;
        } else {
            return country.currency;
        }
    },

    getCountryCode(code) {
        const country = supportedCountriesMap[code];
        if (country === undefined) {
            return globalCountry.countryCode;
        } else {
            return country.countryCode;
        }
    },

    getSymbol(code) {
        if (typeof code !== "string") return "";

        const country = supportedCountriesMap[code];
        
        if (country === undefined) {
            return globalCountry.symbol;
        } else {
            return country.symbol;
        }
    },

    getFullname(code) {
        const country = supportedCountriesMap[code];
        if (country === undefined) {
            return globalCountry.fullname;
        } else {
            return country.fullname;
        }
    },

    getPhone(code) {
        const country = supportedCountriesMap[code];
        if (country === undefined) {
            return globalCountry.phone;
        } else {
            return country.phone;
        };
    },
};

function buildSupportedCurrenciesMap(supportedCountries) {
    if (!supportedCountries.length) {
        return {};
    }

    const map = {};

    for (const val of supportedCountries) {
        const currency = val?.currency?.toLowerCase();
        map[currency] = val;
    }
    return map;
}

export const supportedCurrenciesMap = buildSupportedCurrenciesMap(supportedCountries);

export default {
    install: (app) => {
        // Plugin code goes here
        app.config.globalProperties.$country = country;
    },
};