<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            :is-close-button="true" 
            size="sm"
            :is-show-header="true"
            is-footer
            :title="`Archive ${type}`"
            @close="$emit('cancel')"
        >
            <template #body>
                <div class="modal__wrapper--onboarding">
                    <div class="modal--section">
                        <div class="mb--20 mt--20">
                            <p class="text--sm">
                                {{ `Are you sure you want to archive this ${type}?` }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        label="Cancel" 
                        btn-style="secondary" size="sm"
                        @submit="$emit('cancel', false)"
                    />
                    <base-button label="Archive" :loading="loading" size="sm" @submit="$emit('archive')" />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "ArchiveRecord",

    components: { MainModal, BaseButton },

    props: {
        type: {
            type: String,
            default: "",
        },

        loading: {
            type: Boolean,
            default: false,
        },
        
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel", "archive"],
};
</script>