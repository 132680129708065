import { format } from "date-fns";
import { months } from "./dummy";
// export { domUtils, trapFocus, isControlKey } from "@/plugins/dom";
export { mockAxiosError, textSpacer, optimizeFileStackURL, splitArrayIntoSets } from "@/plugins/formatters";
// export { daysFromNow } from "@/plugins/dates";
// export { wait, poller } from "@/plugins/timers";
export { isAdmin, allowAccess } from "@/plugins/roles"; ;
// export {
//     supportedCountries, supportedCountriesMap, globalCountry, country,
//     supportedCurrencies, paymentLinkSupportedCurrencies,
//     getPaymentLinkCountry, transferCurrencies, usdTransferCurrencies, internationalTransferCurrencies,
//     transferCountries, topupCurrencies, supportedBankCountries, settlementAccountCurrencies, getUserCountry, userCountry,
// } from "@/plugins/countries";

export function simpleUniqueID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
};

export function objectIsEmpty(obj) {
    return (Object.keys(obj).length === 0 && obj.constructor === Object);
}

export function generateReference(prefix = "", suffix = "") {
    const length = 12;
    const timestamp = new Date().getTime();

    function _getRandInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function generate() {
        const ts = timestamp.toString();
        const parts = ts.split("").reverse();
        let ref = "";

        for (let i = 0; i < length; ++i) {
            const index = _getRandInt(0, parts.length - 1);
            ref += parts[index];
        }

        return ref;
    }

    return prefix + generate() + suffix;
}

/**
 * @param {string} filename
 * @param {string} content - Raw content.
 * @param {"text"| "image"} filetype
 */
export function downloadFromString(filename, content, filetype) {
    const element = document.createElement("a");

    if (filetype === "image") {
        element.setAttribute("href", "data:image/png;base64," + encodeURIComponent(content));
    } else {
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    }

    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function generateSlug() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 12; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

export function pushOrReplace(arr = [], value, predicateFn) {
    const foundIndex = arr.findIndex(predicateFn);
    if (foundIndex <= -1) {
        arr.push(value);
    } else {
        arr[foundIndex] = value;
    }
    return arr;
}

export function redirectWithReload(path = "") {
    if (path.startsWith("/") === false) {
        path = "/" + path;
    }
    if (process.browser) {
        window.location.href = window.location.origin + path;
    }
}

export function matchUrl(pattern = "", url = "") {
    pattern = pattern.startsWith("/") ? pattern : "/" + pattern;
    url = url.startsWith("/") ? url : "/" + url;

    const patternUrl = pattern.split("?")[0] || "";
    const patternParams = pattern.split("?")[1] || "";
    const urlUrl = url.split("?")[0] || "";
    const urlParams = url.split("?")[1] || "";

    const compareUrls = (urlOne = "", urlTwo = "") => {
        const urlOneParts = urlOne.split("/");
        const urlTwoParts = urlTwo.split("/");
        if (urlOneParts.length !== urlTwoParts.length) {
            return false;
        }

        for (let i = 0; i < urlOneParts.length; i++) {
            if (urlOneParts[i].startsWith(":") || urlTwoParts[i].startsWith(":")) {
                continue;
            }
            if (urlOneParts[i] !== urlTwoParts[i]) {
                return false;
            }
        }

        return true;
    };

    const compareParams = (paramsOne = "", paramsTwo = "") => {
        const paramsOneParts = paramsOne.split("&");
        const paramsTwoParts = paramsTwo.split("&");
        if (paramsOneParts.length !== paramsTwoParts.length) {
            return false;
        }

        for (let i = 0; i < paramsOneParts.length; i++) {
            const queryOne = (paramsOneParts[i] || "").split("=")[0] || "";
            const queryTwo = (paramsTwoParts[i] || "").split("=")[0] || "";

            if (queryOne !== queryTwo) {
                return false;
            }
        }

        return true;
    };

    if (!patternParams) {
        return compareUrls(patternUrl, urlUrl);
    } else {
        return compareUrls(patternUrl, urlUrl) && compareParams(patternParams, urlParams);
    }
}

export function goBackToLastUniqueRoute(query) {
    if (process.browser === false) {
        return;
    }

    const lastRoute = getLastUniqueRoute();
    if (lastRoute) {
        window.$nuxt.$router.push({ path: lastRoute.fullPath, query });
        return;
    }

    window.$nuxt.$router.go(-1);
}

export function getLastUniqueRoute() {
    if (process.browser === false) {
        return;
    }

    const currentPath = window.location.pathname;
    const previousRoutes = JSON.parse(window.localStorage.previousRoutes || "[]");

    for (let i = previousRoutes.length - 1; i >= 0; i--) {
        const previousRoute = previousRoutes[i] || {};
        if ((previousRoute.path.includes(currentPath) === false) && previousRoute.fullPath) {
            return previousRoute;
        }
    }
}

export function waitTill({ predicate = () => true, counts = 10, interval = 400 }) {
    let intervalId;
    let count = 0;
    return new Promise((resolve, reject) => {
        try {
            const val = predicate();
            if (val) {
                resolve(val);
            }

            intervalId = setInterval(() => {
                count = count + 1;
                const val = predicate();
                if (val) {
                    resolve(val);
                    clearInterval(intervalId);
                } else if (count > counts) {
                    reject(mockAxiosError({ message: "Waiting timeout error. Action took too long" }));
                    clearInterval(intervalId);
                }
            }, interval);
        } catch (err) {
            reject(err);
        }
    });
}

export function genCorridorString({ fromCountryIso, fromCurrencyCode, toCountryIso, toCurrencyCode }) {
    return fromCountryIso + "_" + fromCurrencyCode + ":" + toCountryIso + "_" + toCurrencyCode;
};

export function splitFullName(name = "") {
    const names = String(name).split(" ");
    if (names.length === 1) {
        return [names[0], ""];
    }
    const firstName = names.slice(0, names.length - 1).join(" ");
    const lastName = names[names.length - 1];
    return [firstName, lastName];
}

export const nth = (value) => {
    if (value > 3 && value < 21) {
        return "th";
    }
    switch (value % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
    }
};

export function toFixedDown(val, digits) {
    const re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)");
    const m = val.toString().match(re);
    return m ? parseFloat(m[1]) : parseFloat(String(val.valueOf()));
}

export function removeNan(val) {
    if (isNaN(val)) {
        return 0;
    }
    return val;
}

export function stripDecimals(n) {
    return removeNan(n) || 0;
}

export const stripNegative = (val) => {
    return Math.abs(val);
};

export function toFixedRound(val, digits) {
    const re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)");
    const m = val.toString().match(re);
    return m ? stripDecimals(m[1]) : stripDecimals(String(val.valueOf()));
}

export function formatCount(x) {
    return (x) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
};

export function convertObjectToQueryString( obj ) {
    // return Object.keys( obj ).map(key => key + '=' + obj[key]).join('&');
    var str = [];
    for (var p in obj) {
      
        if( obj[p] === undefined ) { 
            continue;
        }
      
        if (obj.hasOwnProperty(p)) {
            str.push((p) + "=" + (obj[p]));
        }
    }
    
    return str.join("&");
}

export function transformQueryToString( url = "", queryParams = undefined ) {
    let queryString = url;
    if( queryParams ) queryString += "?" + convertObjectToQueryString( queryParams );
    return queryString;
}
  
export function transformObjectToQueryString( obj ) {
    var str = [];
    for (var p in obj) {
      
        if( obj[p] === undefined ) { 
            continue;
        }
      
        if (obj.hasOwnProperty(p)) {
            str.push((p) + "=" + (obj[p]));
        }
    }
    
    return str.join("&");
}

/**
 * @param  {Date} date: Mon Feb 27 2017 18:33:38 GMT+0000 (UTC)
 * @returns {string} 27/02/2017
 */
export function formatDateTime(date) {
    return format(new Date(date), "dd/MM/yyyy");
}

/**
 * @param  {string} dateString: 2022-08-26T13:31:34.062Z
 * @returns {string} Fri 12 July 2024 3:02 PM
 */
export function formatDateStrings(dateString) { 
    if (!dateString) return "";
    
    const date = new Date(dateString);
    const pattern = "E dd MMMM yyyy h:mm a";

    return format(new Date(date), pattern);
}

/**
 * @param  {object} queryObj example: serialize({
  foo: "hi there",
  bar: {
    blah: 123,
    quux: [1, 2, 3]
  }
}
 * @returns {string} example: foo=hi%20there&bar=100%25
 */

export function serilaizeQuery(queryObj) {
    let str = [];
    for (let p in queryObj)
        if (queryObj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(queryObj[p]));
        }
    return str.join("&");
}

/**
 * @param  {object} routeData
 * @param  {object} pageData 
}
 * @returns {{
        page: number,
        email: string,
        name: string,
        download: boolean,
        title: string,
        clientName: string,
        status: boolean,
    }}
 */
export function assembleQueryList(path = "", pageData) {
    const queryObj = {};

    if (pageData?.page) {
        if (typeof pageData.page === "number") {
            queryObj.page = pageData.page;
        }

        if (typeof pageData.page === "string") {
            queryObj.page = parseInt(pageData.page);
        }
    } 

    if (pageData.clientEmail) {
        queryObj.clientEmail = pageData.clientEmail;
    }

    if (pageData.status) {
        queryObj.status = pageData.status;
    }

    if (pageData.currency) {
        queryObj.currency = pageData.currency;
    }

    if (pageData.invoiceNo) {
        queryObj.invoiceNo = pageData.invoiceNo;
    }

    if (pageData.name) {
        queryObj.name = pageData.name;
    }

    if (pageData.industry) {
        queryObj.industry = pageData.industry;
    }

    if (pageData.email) {
        queryObj.email = pageData.email;
    }

    if (pageData.type) {
        queryObj.type = pageData.type;
    }

    if (pageData.startDate) {
        queryObj.startDate = pageData.startDate;
    }

    if (pageData.endDate) {
        queryObj.endDate = pageData.endDate;
    }

    if (pageData.title) {
        queryObj.title = pageData.title;
    }

    if (pageData.priority) {
        queryObj.priority = pageData.priority;
    }

    if (pageData.name) {
        queryObj.name = pageData.name;
    }

    if (pageData.projectId) {
        queryObj.projectId = pageData.projectId;
    }
    
    if (pageData.workType) {
        queryObj.workType = pageData.workType;
    }

    return queryObj;
}

export function sortList(sortingType, arr, valueToSortBy) {
    arr.sort((a, b) => {
        // let compare = 0;

        var lowerValue = a[valueToSortBy].toLowerCase(); // ignore upper and lowercase
        var higherValue = b[valueToSortBy].toLowerCase();

        if (lowerValue < higherValue) {
            // compare = sortingType === "Ascending" ? -1 : 1;
            return sortingType === "Ascending" ? -1 : 1;
        } else if (lowerValue > higherValue) {
            // compare = sortingType === "Ascending" ? 1 : -1;
            return sortingType === "Ascending" ? 1 : -1;
        } 
        // return compare;
    }); 
}

export function formatAmount(x) {
    return x && typeof x === "number" ? x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
};

export function formatPhoneNumber(str) {
    if (str && typeof str === "string") {
        return str.slice(1, str.length);
    } else {
        return "";
    }
}

export function toLowerCaseTransform(str) {
    return str && typeof str === "string" ? str.toLowerCase() : "";
}

export function getMonth(date, isConverted) {
    let d;

    if (date && !isConverted) {
        d = new Date(date);
    } else if (date && isConverted) {
        d = date;
    } else {
        d = new Date();
    }

    return months[d.getMonth()];
}

export function getMonthFromNum(num) {
    if (!num || typeof num !== "number") return "";

    return months[num - 1];
}

export function setItemOnLS(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
};

export function getItemOnLS(key) {
    localStorage.getItem(key);
};

export function clearDataOnLs(key) {
    window.localStorage.removeItem(key);
}

export function setItemOnSS(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data));
};

export const getStoredTime = (key) => parseInt(sessionStorage.getItem(key) || "0", 10);

export function isTimeExpired(key) {
    if (!key) return;

    
    const now = Date.now();
    const refreshExpiresAt = getStoredTime("z:projectsListExpiresAt");

    if (!refreshExpiresAt || refreshExpiresAt <= now) {
        return true;
    } else {
        return false;
    }
}

export function debounce(fn, ms = 1000) {
    clearTimeout(window.debounceId);
  
    window.debounceId = setTimeout(fn, ms);
}

export function validateEmail(value) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    
    return emailRegex.test(value);
}

export function validatePassword(value) {
    const passwordRegex = /^[a-zA-Z0-9.!#$%@&'*+/=?^_`{|}~-]*$/;

    if (!value) {
        return false;
    }
  
    if (!passwordRegex.test(value)) {
        return false;
    } 
    
    if (value.length < 8) {
        return false;
    }
    
    return true;
}

export function validateNameString(value) {
    if (!value) {
        return false;
    }

    const nameRegex = /^[a-zA-Z\s]*$/;

    if (!nameRegex.test(value)) {
        return false;
    } 
    
    if (value.length < 4) {
        return false;
    }
    
    return true;

}

export function convertStringAmountToInt(str) {
    if (!str) return 0;

    const val = str.split(",").join("");
    return Number(val);
}

export function constructRoutePathName(name) {
    if (!name || typeof name !== "string") return;

    const regexPattern = /\s\W*/;

    const split = name?.trim()?.split(regexPattern)?.join("-")?.toLowerCase();

    return split;
};

export function groupList(list = [], keyGetterFunc) {   
    if (!keyGetterFunc || !list) return;
    
    const map = new Map();

    list.forEach(item => {
        const key = keyGetterFunc(item);
        const collection = map.get(key);

        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function time_ago(date) {
    const formatDate = new Date(date).getTimezoneOffset() + 60 * 60 * 1000;
  
    var time = new Date(Date.now() - formatDate);
    //
    switch (typeof time) {
    case "number":
        break;
    case "string":
        time = +new Date(time);
        break;
    case "object":
        if (time.constructor === Date) time = time.getTime();
        break;
    default:
        time = +new Date();
    }
    //
  
    var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
  
    var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;
  
    if (seconds == 0) {
        return "Just now";
    }
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
    }
  
    var i = 0,
        format;
    while ((format = time_formats[i++]))
        if (seconds < format[0]) {
            if (typeof format[2] == "string") return format[list_choice];
            else
                return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
        }
  
    //
    return time;
}

export function generateInvoiceRefNumber() {
    const baseChar = "01234567";
    let generatedNum = "";
    const length = 6;

    for (let i = length; i > 0; --i) {
        generatedNum += baseChar[Math.floor(Math.random() * baseChar.length)];
    }

    return generatedNum;
}

export function buildMap(arr = []) {
    const map = {};
    
    for (const val of arr) {
        map[val.code] = val;
    }
    return map;
}