import api from "@/api/url";
import tags from "@/assets/js/tags.json";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,

    state: () => {
        return {
            tags: [],
            pageData: {
                currentPage: 1,
                totalPages: 1,
                pageSize: 0,
                total: 0,
            },
            refreshList: true,
        };
    },

    getters: {
        tags: (state) => state.tags,
        pageData: (state) => state.pageData,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit, state }, bool) {
            commit("set", ["refreshList", bool]);
        },

        addTag({ state, commit }, payload) {
            return api.tag.create(payload)
                .then((resp) => {
                    const data = resp?.data?.data;

                    commit("set", ["tags", [ data, ...state.tags ]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: Math.ceil(state.tags.length / 50),
                    }]);
                });
        },

        fetchTags({ commit, state }, { payload, refresh, scroll }) {
            if (!refresh && state.tags.length) return;

            return api.tag.getTags(payload)
                .then((resp) => {
                    const data = resp?.data?.data;

                    if (!scroll) {
                        commit("set", ["tags", data.data]);
                    } else {
                        commit("set", ["tags", [ ...state.tags, ...data.data ]]);
                    }

                    commit("set", ["pageData", {
                        ...state.pageData,
                        ... data.pageDetails,
                    }]);
                });
        },

        updateTag({ commit, state }, payload) {  
            return api.tag.updateTag(payload._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    
                    const tags = state.tags;

                    const updatedTags = tags.map(item => {
                        if (item._id === payload._id) {
                            return {
                                ...item,
                                ...payload,
                            };
                        } else {
                            return item;
                        }
                    });
        
                    commit("set", ["tags", updatedTags]);
                });
        },

        deleteTag({ commit, state }, ids) {
            return api.tag.deleteTag({ ids })
                .then((resp) => {                    
                    const tags = state.tags;

                    const updatedTags = tags.filter(item => !ids.includes(item?._id));

                    commit("set", ["tags", updatedTags]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedTags.length / 50),
                    }]);
                });
        },

        sortTags({ commit, state }, sortVal) {
            const copiedArr = state.tags.slice();
            
            sortList(sortVal, copiedArr, "name");
                        
            commit("set", ["tags", copiedArr]);
        },
    },
};