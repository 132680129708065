<template>
    <div class="report__item__wrap">
        <div>
            <BaseCard class="pl--10 pr--10" is-shadow>
                <template #card-body>
                    <div class="report__item__header">
                        <p class="text--medium text--base">
                            Number of invoices created per month
                        </p>
                    </div>
                    <column-chart :data="transformedData.invoicesByMonth" />
                </template>
            </BaseCard>
            
            <div class="report__item mt--40">
                <div class="flex-2">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <div class="report__item__header">
                                <p class="text--medium text--base">
                                    Invoice status breakdown for {{ currentYear }}
                                </p>
                            </div>
                            
                            <div>
                                <div class="flex align-items-center mb--35 mt--15">
                                    <div class="report__item__total mr--25">
                                        <p class="text--color-gray-600 text--semi--bold text--2xl mb--5">
                                            {{ transformedData.totalNum }}
                                        </p>
                                        <p class="text--xs text--color-gray-700">
                                            Total number of invoices
                                        </p>
                                    </div>

                                    <div class="report__item__total">
                                        <div class="flex align-items-center">
                                            <span class="text--color-gray-600 text--semi--bold text--2xl mr--5 mb--5">
                                                {{ transformedData.sumPaidInvoices }}
                                            </span>
                                            <span class="stats__rate green">
                                                <span class="stats__rate__value">{{ paidRate }}%</span>
                                            </span>
                                        </div>
                                        <p class="text--xs text--color-gray-700">
                                            Total number of paid invoices
                                        </p>
                                    </div>
                                </div>
                                <bar-chart :data="transformedData.invoicesByStatus" />
                            </div>
                        </template>
                    </BaseCard>
                </div>

                <div class="flex-1 ml--20">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <p class="mb--35 text--semi--bold text--sm">
                                Summary
                            </p>
                            <pie-chart :data="transformedData.invoicesByCurrency" />
                        </template>
                    </BaseCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseCard } from "@/components/ui/index";
import { getMonthFromNum } from "@/utils/helpers";

export default {
    name: "ProjectReports",

    components: {
        BaseCard,
    },

    computed: {
        currentYear() {
            return this.$store.getters["report/currentYear"];
        },

        reportItemType() {
            return this.$store.getters["report/reportItemType"];
        },

        reportData() {
            return this.$store.getters["report/reportData"];
        },

        reportDataProps() {
            return this.reportData[this.reportItemType];
        },

        transformedData() {
            if (!this.reportDataProps) return {};
            
            const data = {};

            const keys = Object.keys(this.reportDataProps);

            if (!keys.length) return {};

            for (let i = 0; i < keys.length; i++) {
                const item = keys[i];
                
                const list = this.reportDataProps[item]; 

                switch(item) {  
                case "invoicesByCurrency":
                    data.invoicesByCurrency = list.map(i => (i?.data)) || [];
                    break;
                case "invoicesByMonth":
                    data.invoicesByMonth = list.map(i => ([this.getMonthFromNum(i?._id), i?.count])) || [];
                    break;

                case "sumPaidInvoices":
                    data.sumPaidInvoices = list.length && list[0]?.count || 0;
                    break;

                case "invoicesByStatus":
                    data.invoicesByStatus = list.map(i => (i?.data)) || [];
                    break;

                case "totalNum":
                    data.totalNum = list.length && list[0]?.count || 0;
                    break;

                default:
                    break;
                }
            }

            return data;
        },

        paidRate() {
            if (!this.transformedData?.sumPaidInvoices || !this.transformedData?.totalNum) return 0;

            const percentageValue = (this.transformedData?.sumPaidInvoices / this?.transformedData.totalNum) * 100;

            return percentageValue && typeof percentageValue === "number" && percentageValue.toFixed(2);
        },
    },

    methods: {
        getMonthFromNum,
    },
};
</script>