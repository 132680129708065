<template>
    <div class="logo--wrap" :class="[layout]" v-bind="$attrs">
        <div class="logo">
            <img src="@/assets/img/logo.svg">
        </div>
    </div>
</template>

<script>
import { layoutObj, shadowObj } from "../../utils/css";

export default {
    name: "Logo",
    
    props: {
        paddingLeftNum: {
            type: String,
            default: "",
        },

        type: {
            type: String,
            default: "",
        },

        shadowType: {
            type: String,
            default: "",
        },

        layoutPosition: {
            type: String,
            default: "",
        },
    },
    
    data() {
        return {
            layout: `logo--${layoutObj[this.layoutPosition]} logo--${layoutObj[this.layoutPosition]}--${this.paddingLeftNum}`,
        };
    },
};
</script>