<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div>
        <div class="clients__page--view" :class="{ 'disabled': deleteLoading }">
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchInvoicesErr">
                <EmptyPage state="error">
                    <template #button>
                        <base-button 
                            label="Reload" is-prefix-icon  
                            icon-name="reload" icon-width="16px"
                            size="sm"
                            @submit="handleFetchInvoices"
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="flex align-items-center justify-content-between width-100">
                    <div v-if="showActionBtns" class="list--count">
                        <p class="text--medium">
                            {{ invoices.length }} {{ invoices.length !== 1 ? 'Invoices' : 'Invoice' }}
                        </p>
                    </div>

                    <div class="filter__actions--list">
                        <template v-if="showActionBtns">
                            <div v-if="selectedInvoices.length > 0" class="mr--5">
                                <base-button 
                                    btn-style="danger" size="sm"
                                    @submit="toggleDelete"
                                >
                                    Delete {{ selectedInvoices.length }} {{ selectedInvoices.length === 1 ? 'invoice' : 'invoices' }}
                                </base-button>
                            </div>
                            <main-filter page-type="invoices" />
                            <base-button 
                                label="+ Add invoice" size="sm"
                                @submit="goToCreateInvoicePage"
                            />
                        </template>
                    </div>
                </div>

                <div class="w--100 justify-content-center mt--40">
                    <template v-if="!invoices.length">
                        <empty-page v-if="isSearched" state="no_result" />
                        <empty-page v-else state="empty" icon-name="invoice" type="invoice" width="60px" height="60px">
                            <template #button>
                                <base-button 
                                    label="+ New invoice" size="sm" class-names="flex align-items-center"
                                    @submit="goToCreateInvoicePage"
                                />
                            </template>
                        </empty-page>
                    </template>
                    
                    <template v-else>
                        <div class="flex mt--40">
                            <CustomTable
                                :columns="invoiceHeaders"
                                :data="invoices"
                                is-row-link
                                :selected-for-deletion="selectedInvoices"
                                router-name="invoice-details-view"
                                is-action-btns
                                @onRowClick="goToInvoiceDetailsPage"
                                @onRowSelected="selectInvoices"
                            >
                                <template #rows="data">
                                    <td class="first">
                                        {{ data.client.name }}
                                    </td>
                                    <td class="first">
                                        {{ data.client.email }}
                                    </td>
                                    <td>
                                        <Status type="invoice" :value="data.status" />
                                    </td>
                                    <td class="text--upper">
                                        {{ getSymbol(data.currency).symbol }}{{ $format_number(data.totalAmount) }}
                                    </td>
                                    <td>#{{ data.invoiceNo }}</td>
                                    <td>{{ $format_date(data.dueDate) }}</td>
                                    <td>{{ $format_date(data.createdAt) || "-" }}</td>
                                </template>
                            </CustomTable>
                        </div>

                        <pagination v-if="showPagination" :pagination-data="pageData" />
                    </template>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            :show-modal="showDeleteModal"
            type="invoice" custom-msg
            :items="selectedInvoices"
            :loading="deleteLoading" 
            @cancel="resetCurrentInvoice"
            @delete="deleteMultipleInvoices"
        >
            <template #custom-msg>
                <p class="text--sm">
                    These invoices will be removed from all related projects and clients. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <UpgradeAccountModal :show-modal="showUpgradeAccountModal" @cancel="showUpgradeAccountModal = false" />
    </div>
</template>

<script>
import {
    MainFilter,
    SortFilter,
    Pagination,
    EmptyPage,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CustomTable,
    Status,
    MainModal,
    UpgradeAccountModal,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { invoiceHeaders } from "@/utils/dummy";
import { invoiceStatusMap } from "@/utils/enums";
import { supportedCurrenciesMap } from "@/plugins/countries";

export default {
    name: "InvoicesPage",

    components: {
        CustomTable,
        ConfirmDeletionTwo,
        EmptyPage,
        Pagination,
        MainFilter,
        SortFilter,
        MainModal,
        PageSpinner,
        BaseButton,
        Status,
        UpgradeAccountModal,
    },

    data() {
        return {
            isSearched: false,
            displayType: "",
            selectedInvoices: [],
            deleteLoading: false,
            pageLoading: false,
            fetchInvoicesErr: false,
            fetchInvoicesErrMsg: "",
            invoiceHeaders,
            invoiceStatusMap,
            showDeleteModal: false,
            showUpgradeAccountModal: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["invoice/pageData"];
        },

        invoices() {
            return this.$store.getters["invoice/invoices"] || [];
        },

        showActionBtns() {
            if (this.invoices?.length) {
                return true;
            } else if (!this.invoices?.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        refreshList() {
            return this.$store.getters["project/refreshList"];
        },

        invoiceCount() {
            return this.pageData?.total;
        },

        accountType() {
            return this.$store.getters["auth/profile"]?.accountType;
        },

        showUpgradeAccountInfo() {
            if (this.accountType === "standard" && this.invoiceCount >= 5) {
                return true;
            } else {
                return false;
            }
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal, oldVal) {
                console.log({ newVal, oldVal });
                if (newVal.path === oldVal.path) { // this only fires when same page navigation takes place
                    await this.handleFetchInvoices({ refresh: true });
                }
            },
        },
    },
    
    async mounted() {
        if (!this.invoices.length && this.refreshList) {
            await this.handleFetchInvoices({ refresh: true });
            this.$store.dispatch("invoice/setRefreshList", false);
        }
    },

    methods: {
        goToInvoiceDetailsPage(row) {
            if (!row && !row._id) return;

            this.$router.push(`/dashboard/invoices/${row?._id}`);
        },

        checkIfSearched() {
            if (this.$route.query.name || this.$route.query.email || this.$route.query.industry) {
                this.isSearched = true;
            } else {
                this.isSearched = false;
            }
        },
        
        getSymbol(currency) {
            const lowerCaseCurrency = currency && currency?.toLowerCase();
            return supportedCurrenciesMap[lowerCaseCurrency] || "$";
        },

        goToCreateInvoicePage() {
            if (this.showUpgradeAccountInfo) {
                this.showUpgradeAccountModal = true;
                return;
            } else {
                this.$router.push("/dashboard/invoices/create");
            }
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        resetCurrentInvoice() {
            this.selectedInvoices = [];
            this.showDeleteModal = false;
        },

        async deleteMultipleInvoices() {
            try {
                this.deleteLoading = true;

                await this.$store.dispatch("invoice/deleteInvoices", this.selectedInvoices);

                this.$toast.success("Invoices deleted");
                
                this.resetCurrentInvoice();
                await this.handleFetchInvoices();
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        selectInvoices(val) {
            this.selectedInvoices = val;
        },

        async handleFetchInvoices(params) {
            try {
                this.pageLoading = true;
                this.fetchInvoicesErr = false;
                this.fetchInvoicesErrMsg = "";

                const query = this.$route.query;
                const queryKeys = Object.keys(this.$route.query);

                let pageQueryObj = {
                    page: query?.page || 1,
                    type: query?.type || "all",
                };

                if (queryKeys?.length) {
                    this.isSearched = true;
                }

                if (query.name) {
                    pageQueryObj.name = query.name;
                }

                if (query.industry) {
                    pageQueryObj.industry = query.industry;
                }

                if (query.status) {
                    pageQueryObj.status = query.status;
                }

                if (query.invoiceNo) {
                    pageQueryObj.invoiceNo = query.invoiceNo;
                }

                if (query.email) {
                    pageQueryObj.email = query.email;
                }

                if (query.startDate) {
                    pageQueryObj.startDate = query.startDate;
                }

                if (query.endDate) {
                    pageQueryObj.endDate = query.endDate;
                }

                console.log({ pageQueryObj });

                const buildResult = assembleQueryList("invoices", pageQueryObj);

                await this.$store.dispatch("invoice/fetchInvoices", { params: buildResult, isSearch: this.isSearched, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchInvoicesErr = true;
                this.fetchInvoicesErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },
    },
};
</script>