<template>
    <div class="pl--0 pr--0 pt--10 client__item__details__sub">
        <template v-if="fetchInvoiceLoading">
            <PageSpinner />
        </template>

        <template v-else-if="fetchInvoiceErr">
            <EmptyPage state="error">
                <template #button>
                    <base-button 
                        label="Reload" icon-name="reload" icon-width="16px" 
                        size="sm" is-prefix-icon
                        @submit="handleFetchInvoices"
                    />
                </template> 
            </EmptyPage>
        </template>

        <template v-else>
            <div v-if="invoices.length" class="list--count w--100">
                <p class="text--sm">
                    {{ invoices.length }} {{ invoices.length === 1 ? 'invoice' : 'invoices' }}
                </p>
            </div>

            <div class="w--100 mt--20">
                <template v-if="!invoices.length">
                    <EmptyPage
                        state="empty" icon-name="invoice" width="60px" height="60px"
                        type="invoice"
                    />
                </template>

                <template v-else>
                    <div>
                        <CustomTable
                            :columns="invoiceHeaders"
                            :data="invoices"
                            router-name="invoice-details-view"
                            is-action-btns
                            :is-checkbox="false"
                        >
                            <template #rows="data">
                                <td>
                                    <Status type="invoice" :value="data.status" />
                                </td>
                                <td>{{ $format_amount(data.amount) || "-" }}</td>
                                <td>
                                    {{ data.invoiceNo || "-" }}
                                </td>
                                <td>{{ $format_date(data.dueDate) }}</td>
                                <td>{{ $format_date(data.createdAt) || "-" }}</td>
                            </template>
                        </CustomTable>
                        <PageSpinner v-if="showScrollLoader" />
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import {
    EmptyPage,
    PageSpinner,
    BaseButton,
    CustomTable,
    Status,
} from "@/components/ui/index";

export default {
    name: "ClientInvoices",

    components: {
        CustomTable,
        EmptyPage,
        PageSpinner,
        BaseButton,
        Status,
    },

    data() {
        return {
            fetchInvoiceLoading: false,
            fetchInvoiceErr: false,
            fetchInvoiceErrMsg: "",
            invoiceHeaders: [
                { id: "Status", accessorKey: "Status", iconName: "user"},
                { id: "Amount", accessorKey: "amount", iconName: "buildings"},
                { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
                { id: "Due Date", accessorKey: "dueDate", iconName: "timer-solid"},
                { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
            ],
            showScrollLoader: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["clients/clientInvoices"]?.pageData;
        },

        invoices() {
            return this.$store.getters["clients/clientInvoices"]?.invoices;
        },
    },

    async mounted() {
        await this.handleFetchInvoices();

        window.addEventListener("scroll", this.handleScroll);
    },

    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        async handleFetchInvoices(payload) {
            try {
                this.fetchInvoiceErr = false;
                this.fetchInvoiceLoading = true;

                const id = this.$route.params.id;

                const params = {
                    page: payload?.pageNumber || this.pageData?.currentPage,
                };

                await this.$store.dispatch("clients/getClientInvoices", { id, params });
            } catch (error) {
                this.fetchInvoiceErr = true;
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchInvoiceErrMsg = errMsg;
            } finally {
                this.fetchInvoiceLoading = false;
            }
        },

        async handleScroll() {
            console.log({
                "window.innerHeight": window.innerHeight,
                "window.scrollY + 100": window.scrollY + 100,
                "document.body.offsetHeight": document.body.offsetHeight,
            });
            
            if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                await this.loadMoreInvoices();
            }
        },

        async loadMoreInvoices() {            
            if (this.showScrollLoader || this.pageData?.totalPages === 1 || this.pageData?.currentPage === this.pageData?.totalPages) return;
    
            this.showScrollLoader = true;
    
            await this.handleFetchInvoices({
                pageNumber: this.pageData?.currentPage + 1,
            });
            
            this.showScrollLoader = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .title {
        max-width: 170px;
    }
</style>