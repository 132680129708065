import api from "@/api/url";

export default {
    namespaced: true,
    state: () => {
        return {
            reportItemType: "clients",
            currentReportDateType: "Last 7 days",
            reportItemDates: [
                { name: "Last 7 days", value: 7 },
                { name: "Last 30 days", value: 30 },
                { name: "All", value: 0 },
            ],
            filter: {
                date: 7,
                type: "projects",
            },
            reportData: {
                clients: undefined,
                projects: undefined,
                invoices: undefined,
            },
            currentYear: "2024",
        };
    },

    getters: {
        reportItemType: state => state.reportItemType,
        reportData: state => state.reportData,
        currentYear: state => state.currentYear,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        fetchReports({ commit, state }, params) {
            const type = params?.type;

            if (state.reportData[type] && state.currentYear === params?.year) return; // to avoid making unnecessary calls

            return api.user.getReports(params)
                .then((res) => {
                    const data = res.data.data;
                    
                    const firstItem = data.length ? data[0] : {};

                    commit("set", ["reportData", {
                        ...state.reportData,
                        [type]: firstItem,
                    }]);
                });
        },

        setReportType({ commit }, type) {
            if (type) {
                commit("set", ["reportItemType", type]);
                localStorage.setItem("report_type", JSON.stringify(type));
                return;
            }

            const reportTypeOnLS = localStorage.getItem("report_type");

            const reportType = reportTypeOnLS ? JSON.parse(reportTypeOnLS) : "clients";
            commit("set", ["reportItemType", reportType]);
        },

        setReportYear({ commit }, year) {
            if (year) {
                commit("set", ["currentYear", year]);
                localStorage.setItem("report_year", JSON.stringify(year));
                return;
            }

            const reportTypeOnLS = localStorage.getItem("report_year");

            const reportType = reportTypeOnLS ? JSON.parse(reportTypeOnLS) : "2024";
            commit("set", ["currentYear", reportType]);
        },
    },
};