<template>
    <label class="form-label" :for="htmlFor" v-bind="$attrs" :class="{ 'mb--5': !noMarginBottom }">
        {{ title }}
        <span v-if="infoType" :class="[infoTypeObj[infoType].color || '']" class="text--xs">
            {{ infoTypeObj[infoType].text || "" }}
        </span>
    </label>
</template>

<script>
export default {
    name: "FormLabel",
    
    props: {
        title: {
            type: String,
            default: "",
        },
        htmlFor: {
            type: String,
            default: "",
        },
        infoType: {
            type: String,
            default: "",
            validator: value => !value || ["required", "optional", "info"].includes(value),
        },
        noMarginBottom: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            infoTypeObj: {
                "required": {
                    color: "text--color-warning",
                    text: "*",
                },
                "info": {
                    color: "text-label-info",
                    text: "",
                },
                "optional": {
                    color: "text-label-info",
                    text: "(optional)",
                },
            },
        };
    },
};
</script>