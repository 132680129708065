import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,

    state: () => {
        return {
            invoices: [],
            currentInvoiceId: "",
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
            currentInvoiceData: {},
            selectedProject: {},
            selectedClient: {},
            refreshList: true,
        };
    },

    getters: {
        invoices: state => state.invoices,
        currentInvoiceId: state => state.currentInvoiceId,
        pageData: state => state.pageData,
        currentInvoice: state => state.currentInvoice,
        selectedProject: state => state.selectedProject,
        selectedClient: state => state.selectedClient,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },

        sendNewInvoice({ state, commit }, payload) {
            return api.invoice.sendNew(payload)
                .then((resp) => {                    
                    const data = resp.data.data;
                    const invoices = state.invoices;
                    const updatedTotalPages = Math.ceil(invoices.length / 50);

                    commit("set", ["invoices", [ ...invoices, {
                        client: data.client,
                        ...data.invoice,
                        status: "issued",
                    }]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: updatedTotalPages,
                    }]);
                });
        },

        sendExistingInvoice({ state, commit }, payload) {
            return api.invoice.sendExisting(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    const invoices = state.invoices;
                    const updatedInvoices = invoices.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                                status: "issued",
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["invoices", updatedInvoices]);    
                });
        },

        saveInvoiceDraft({ state, commit }, payload) {
            return api.invoice.sendAsDraft(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    const invoices = state.invoices;
                    const updatedTotalPages = Math.ceil(invoices.length / 50);

                    commit("set", ["invoices", [ ...invoices, {
                        client: data.client,
                        ...data.invoice,
                    }]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: updatedTotalPages,
                    }]);
                });
        },

        fetchInvoices({ commit, state }, { params, refresh, config = {} }) {
            if (!refresh && state.invoices.length) return;

            return api.invoice.getAll(params, config)
                .then((resp) => {
                    if (config?.responseType) {
                        const data = resp?.data;
                        return data;
                    } else {
                        const data = resp?.data?.data;
    
                        commit("set", ["invoices", data.data]);
                        commit("set", ["pageData", { ...state.pageData, ...data.pageDetails }]);
                    }
                });
        },

        getInvoice(_, { id, params, config = {} }) {
            return api.invoice.getOne(id, params, config)
                .then((resp) => {
                    return  resp?.data;
                });
        },

        updateInvoice({ commit, state }, payload) {      
            return api.invoice.updateOne(payload._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data.updatedInvoice;   
                    
                    console.log({ updateInvoice: data });

                    const invoices = state.invoices;

                    const updatedInvoices = invoices.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["invoices", updatedInvoices]);        
                    commit("set", ["currentInvoice", {
                        ...state.currentInvoice,
                        ...data,
                    }]);

                    return data;
                });
        },

        deleteInvoices({ state, commit }, ids) {
            return api.invoice.delete({ ids: ids })
                .then((resp) => {
                    const invoices = state.invoices;
            
                    const updatedInvoices = invoices.filter(c => {
                        if (Array.isArray(ids)) {
                            return !ids.includes(c?._id);
                        } else {
                            return c._id !== ids;
                        }
                    });

                    commit("set", ["invoices", updatedInvoices]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(updatedInvoices.length / 50),
                    }]);
                });
        },

        duplicateInvoice({ commit, state }, { client, payload }) {      
            return api.invoice.duplicate(payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    
                    commit("set", ["invoices", [{
                        client: client,
                        ...data,
                    }, ...state.invoices ]]);

                    const updatedTotalPages = Math.ceil(state.invoices.length / 50);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: updatedTotalPages,
                    }]);

                    return data;
                });
        },

        setSelectedProject({ commit }, payload) {      
            commit("set", ["selectedProject", payload]);
        },

        setSelectedClient({ commit }, payload) {      
            commit("set", ["selectedClient", payload]);
        },
    },
};