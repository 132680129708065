export const layoutObj =  {
    center: "center",
    left: "left",
    right: "right",
    top: "top",
    bottom: "bottom",
};

export const shadowObj = {
    top: "top",
    bottom: "bottom",
};