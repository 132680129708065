<template>
    <div class="form__input-group">
        <input 
            v-bind="{...$attrs}" 
            class="form-control form__input"
            :class="
                {
                    'form-control-sm': size === 'sm',
                    'form-control-md': size === 'md',
                    'form-control-lg': size === 'lg',
                    ...classNames
                }"
            type="search"
            :value="modelValue" 
            autocomplete=""
            @input="emitValue"
            @change="e => $emit('change', e)"
            @focus="onFocus"
            @blur="onBlur"
            @keydown="e => $emit('keydown', e)"
            @keyup="handleKeyUp"
        >
        <span class="input--password absolute me-1 pointer">
            <span @click="$emit('click')">
                <icon-svg fill="rgba(194, 200, 212, 1)" name="search" :width="'16px'" :height="'16px'" /> 
            </span>
        </span>
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "InputSearch",

    components: { IconSvg },

    inheritAttrs: false,
    
    model: {
        prop: "value",
        event: "input",
    },

    props: {
        modelValue: {
            default: "",
            type: [Number, String],
        },
        modelModifiers: {
            type: Object,
            default: () => ({}),
        },
        borderStyle: {
            default: "solid",
            type: String,
        },
        id: {
            type: String,
            default: "",
        },

        // Use this to minimize the effect of the @input event to make it trigger only after you're done typing.
        // This offers a performance boost to the Input element as it seems faster cause of less back and forth.
        lazy: {
            type: Boolean,
            default: false,
        },

        classNames: {
            type: Object,
            default: () => {},
        },
        size: {
            type: String,
            default: "md",
            validator: (value) => {
                return ["sm", "md", "lg"].includes(value);
            },
        },
    },

    emits: ["input", "blur", "change", "keydown", "focus", "keyup", "update:modelValue", "click"],

    data() {
        return {
            focusState: "blur",
        };
    },

    mounted() {
        setInterval(() => {
            if (this.autofocus) {
                this.focus();
            }
        }, 1500);
    },

    methods: {
        onFocus(e) {
            // Emitted when you focus on the input.
            this.focusState = "focus";
            this.$emit("focus", e);
        },

        onBlur(e) {
            // Emitted when you blur off the input.
            this.focusState = "blur";
            this.$emit("blur", e);
        },

        handleKeyUp(event) {
            this.$emit("keyup", event);
        },

        emitValue(e) {
            let value = e.target.value;
                        
            if (this.modelModifiers.capitalize) {
                value = value.charAt(0).toUpperCase() + value.slice(1);
            }
            this.$emit("update:modelValue", value);
        },
    },
};
</script>