<template>
    <div class="flex justify-content-center align-items-center" :class="{ 'mt--40 mb--45': isPage }" v-bind="$attrs">
        <div class="spinner-border text-primary" role="status" style="color: #289CA9 !important">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>

export default {
    name: "PageSpinner",
    props: {
        isPage: { type: Boolean, default: true },
    },
};
</script>