<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="revenue__page">
        <div>
            <template v-if="pageState.loading">
                <PageSpinner />
            </template>
            
            <template v-else>
                <EmptyPage v-if="pageState.err" state="error" type="earning">
                    <template #button>
                        <base-button 
                            label="Reload"
                            size="sm"
                            icon-name="reload" 
                            icon-width="16px"
                            @submit="handleFetchEarnings"
                        />
                    </template> 
                </EmptyPage>

                <div v-else class="content__page__body mt--20" :class="{ 'empty': !groupByKeys.length }">
                    <div v-if="earnings.length || isSearched" class="content__page__header filter__actions--list">
                        <div class="list--count">
                            <p class="text--medium">
                                {{ earnings.length === 1 ? `${earnings.length} total earning` : `${earnings.length} total earnings` }}
                            </p>
                        </div>
                        <main-filter page-type="revenue" />
                    </div>

                    <template v-if="!groupByKeys.length">
                        <empty-page 
                            v-if="isSearched" 
                            icon-name="search" 
                            :is-italics="true" 
                            state="no_result"
                            type="earnings"
                        />
                        <empty-page 
                            v-if="!isSearched" 
                            type="earning"
                            result="empty"
                            subtitle="When you complete a project and get paid with an issued invoice, it gets added to your revenue history here." 
                            icon-name="earnings" 
                        />
                    </template>
    
                    <template v-else>
                        <ul v-for="item in groupByKeys" :key="item" class="revenue__wrap">
                            <div class="revenue__date__header">
                                {{ $format_date(item) }}
                            </div>
                            <li class="revenue__list__item">
                                <div 
                                    v-for="earning in groupedListByDate[item]" 
                                    :key="earning._id" 
                                    class="revenue__item pointer"
                                    @click="viewDetails(earning)"
                                >
                                    <div class="revenue__item__left">
                                        <div class="revenue__item__icon">
                                            <IconSvg name="navigation" width="16px" height="16px" fill="#70878D" />
                                        </div>
                                        <div class="revenue__item__left__text">
                                            <div class="revenue__item__title">
                                                {{ earning.project.title }}
                                            </div>
                                            <div class="revenue__item__ref mt--5">
                                                <span>Invoice ref: {{ earning.invoice.invoiceNo }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="revenue__item__right">
                                        <div class="revenue__item__amount">
                                            {{ $format_amount(earning.amount) }} {{ earning.currency }}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>

                    <PageSpinner v-if="showScrollLoader" />
                </div>
            </template>
        </div>

        <main-modal 
            :show-modal="showDetailsModal" is-close-button is-show-header is-footer
            size="sm" title="Revenue Details" @close="closeModal"
        >
            <template #body>
                <div class="mb--20 pr--20 pl--20">
                    <div>
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="money" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Currency
                                </p>
                            </div>
                            <p class="text-uppercase subtitle">
                                {{ currentEarningItem.currency || 'N/A' }}
                            </p>
                        </div>
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="money" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Amount paid
                                </p>
                            </div>
                            <p class="subtitle">
                                {{ $format_amount(currentEarningItem.amount) || 'N/A' }}
                            </p>
                        </div>
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="project" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Project title
                                </p>
                            </div>
                            <p class="subtitle">
                                {{ currentEarningItem.project.title || 'N/A' }}
                            </p>
                        </div>
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="receipt" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Invoice Ref
                                </p>
                            </div>
                            <p class="subtitle text--upper">
                                #{{ currentEarningItem.invoice.invoiceNo || 'N/A' }}
                            </p>
                        </div>
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="hour-glass" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5 text-capitalize">
                                    Invoice Status
                                </p>
                            </div>
                            <Status 
                                type="invoice" class="subtitle"
                                :value="currentEarningItem.invoice.status || 'N/A'" 
                            />
                        </div>
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="alarm" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5 text-capitalize">
                                    Date created
                                </p>
                            </div>
                            <p class="subtitle">
                                {{ $format_date(currentEarningItem.createdAt) || 'N/A' }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button label="Close" size="sm" btn-style="primary" @submit="closeModal" />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import { 
    IconSvg, 
    Pagination, 
    EmptyPage, 
    PageSpinner, 
    BaseButton, 
    InputSearch, 
    MainModal, 
    Status,
    MainFilter,
} from "@/components/ui/index";
import { groupList, assembleQueryList } from "@/utils/helpers";

export default {
    name: "RevenueHistory",

    components: {
        IconSvg,
        EmptyPage,
        Pagination,
        PageSpinner,
        BaseButton,
        InputSearch,
        MainModal,
        Status,
        MainFilter,
    },

    data() {
        return {
            isSearched: false,
            searchValue: "",
            showDetailsModal: false,
            pageState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            currentEarningItem: {
                project: {
                    _id:  "",
                    title: "",
                },
                invoice: {
                    _id: "",
                    invoiceNo: "",
                    status: "",
                },
            },
            showScrollLoader: false,
        };
    },

    computed: {
        earnings() {
            return this.$store.getters["revenue/earnings"];
        },

        pageData() {
            return this.$store.getters["revenue/pageData"];
        },

        showSearchBar() {
            if (this.earnings.length) {
                return true;
            } else if (!this.earnings.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        groupedListByDate() {
            if (!this.earnings.length) {
                return [];
            }

            const arr = this.groupList(this.earnings, (item) => {
                // createdAt comes in form of timestamp from API
                // Convert to YYYY-MM-DD
                const date = new Date(item.createdAt);

                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                const filter = `${year}-${month}-${day}`;

                return filter;
            });

            return Object.fromEntries(arr);
        },

        groupByKeys() {
            return Object.keys(this.groupedListByDate) || [];
        },
    },

    watch: {
        "$route": {
            async handler(newVal, oldVal) {
                if (newVal.path === oldVal.path) {
                    await this.handleFetchEarnings({ refresh: true });
                }
            },
        },
    },
    
    async mounted() {        
        await this.handleFetchEarnings({ refresh: false });

        window.addEventListener("scroll", this.handleScroll);
    },

    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        groupList,
        assembleQueryList,
          
        checkIfSearched() {
            if (this.$route.query.name || this.$route.query.email || this.$route.query.industry) {
                this.isSearched = true;
            } else {
                this.isSearched = false;
            }
        },

        async handleScroll() {
            if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                await this.loadMoreHistory();
            }
        },

        async handleFetchEarnings(params = {}) {
            try {
                this.pageState.loading = true;
                this.pageState.err = false;
                this.pageState.errMsg = "";

                if (!params?.pageNumber) {
                    this.pageLoading = true;
                }

                const query = this.$route.query;
                const queryKeys = Object.keys(this.$route.query);

                const pageQueryObj = {
                    page: params.pageNumber || this.pageData.currentPage,
                };

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                if (query.title) {
                    pageQueryObj.title = query.title;
                }

                if (query.currency) {
                    pageQueryObj.currency = query.currency;
                }

                const buildResult = assembleQueryList("revenue", pageQueryObj);

                await this.$store.dispatch("revenue/fetchEarnings", { 
                    payload: buildResult, 
                    refresh: params.refresh, 
                    scroll: params.scroll, 
                });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.pageState.err = true;
                this.pageState.errMsg = errMsg;
            } finally {
                this.pageState.loading = false;
            }
        },

        async loadMoreHistory() {
            // try {
            //     console.log("call the loadMoreHistory function");

            //     if (this.showScrollLoader) return;

            //     if (this.pageData.totalPages === 1 || (this.pageData.currentPage === this.pageData.totalPages)) return;

            //     this.showScrollLoader = true;

            //     await this.handleFetchEarnings({ pageNumber: this.pageData.currentPage + 1, refresh: true, scroll: true });
            // } catch (error) {
                
            // } finally {
            //     this.showScrollLoader = false;
            // }
        },

        viewDetails(item) {
            if (!item._id) return;

            this.currentEarningItem = {
                ...this.currentEarningItem,
                ...item,
            };
            this.showDetailsModal = true;
        },

        closeModal() {
            this.currentEarningItem = {
                project: {
                    _id:  "",
                    title: "",
                },
                invoice: {
                    _id: "",
                    invoiceNo: "",
                    status: "",
                },
            };
            this.showDetailsModal = false;
        },
    },
};
</script>