<template>
    <div>
        <main-modal :show-modal="showModal" size="lg" :width="'393px'">
            <template #body>
                <div class="modal__wrapper--onboarding mb--45">
                    <div class="modal--section pt--15">
                        <div v-if="step < 6" class="w--100 flex justify-content-end mb--15 mt--30">
                            <base-button 
                                v-if="step < 6"
                                :is-full-width="false"
                                btn-style="ghost"
                                label="Skip and close" 
                                size="sm"
                                @submit="closeModal"
                            />
                        </div>

                        <template v-if="step === 1">
                            <div class="flex justify-content-center">
                                <WelcomeIcon />
                            </div>
                            <p class="text-center text--3xl text--medium mb--5 mt--10">
                                Welcome to Ziner!
                            </p>
                            <p class="text-center lh-20 text--color-info">
                                We're happy to have you as a new user. Let's show you around. Click "Next" to continue.
                            </p>
                        </template>

                        <template v-if="step === 2">
                            <div class="flex justify-content-center">
                                <!-- <img
                                    src="../../../assets/img/welcome_reports.svg"
                                    width="70"
                                    height="70"
                                > -->
                            </div>
                            <p class="text--3xl text-center text--color-title text--medium mb--5 mt--10">
                                Reports
                            </p>
                            <ul class="welcome--list">
                                <li class="lh-20 text--color-info">
                                    Get generated weekly reports that tracks your progress with clients, projects, and tasks.
                                </li>
                            </ul>
                        </template>

                        <template v-if="step === 3">
                            <div class="flex justify-content-center">
                                <!-- <img
                                    src="../../../assets/img/welcome_client.svg"
                                    width="70"
                                    height="70"
                                > -->
                            </div>
                            <p class="text--3xl text-center text--color-title text--medium mb--5 mt--10">
                                Clients
                            </p>
                            <ul class="welcome--list">
                                <li class="lh-20 text--color-info">
                                    Create and manage multiple clients.
                                </li>
                                <li class="lh-20 text--color-info">
                                    You can add and link multiple projects to a client.
                                </li>
                                <li class="lh-20 text--color-info">
                                    You can add and link multiple invoices to a client.
                                </li>
                            </ul>
                        </template>

                        <template v-if="step === 4">
                            <div class="flex justify-content-center">
                                <!-- <img
                                    src="../../../assets/img/welcome_projects.svg"
                                    width="70"
                                    height="70"
                                > -->
                            </div>
                            <p class="text--3xl text-center text--color-title text--medium mb--5 mt--10">
                                Projects & Tasks
                            </p>
                            <ul class="welcome--list">
                                <li class="lh-20">
                                    Create and manage multiple projects per client.
                                </li>
                                <li class="lh-20">
                                    Delete and manage existing projects.
                                </li>
                                <li class="lh-20">
                                    Create, delete, drag or edit multiple tasks per project. 
                                </li>
                            </ul>
                        </template>

                        <template v-if="step === 5">
                            <div class="flex justify-content-center">
                                <!-- <img
                                    src="../../../assets/img/welcome_invoices.svg"
                                    width="70"
                                    height="70"
                                > -->
                            </div>
                            <p class="text--3xl text-center text--color-title text--medium mb--5 mt--10">
                                Invoices
                            </p>
                            <ul class="welcome--list">
                                <li class="lh-20">
                                    Create, preview or save invoices as drafts.
                                </li>
                                <li class="lh-20">
                                    Send invoices to clients and attach it to a project you've created.
                                </li>
                                <li class="lh-20">
                                    Duplicate or manage existing invoices.
                                </li>
                            </ul>
                        </template>

                        <template v-if="step === 6">
                            <div class="flex justify-content-center mt--30">
                                <!-- <img
                                    src="../../../assets/img/welcome_end.svg"
                                    width="70"
                                    height="70"
                                > -->
                            </div>
                            <p class="text-center text--3xl text--medium mb--5 mt--10">
                                All onboard!
                            </p>
                            <p class="text-center">
                                We hope you enjoy your journey with us.
                            </p>
                        </template>

                        <!-- action buttons -->
                        <div class="w--100 flex justify-content-end mt--30">
                            <base-button 
                                v-if="step > 1"
                                :is-full-width="false"
                                class-names="mr--10" 
                                btn-style="secondary"
                                label="Prev" 
                                size="sm"
                                @submit="prevStep"
                            />
                            <base-button 
                                v-if="step < 6"
                                :is-full-width="false"
                                btn-style="outline"
                                label="Next" 
                                size="sm"
                                @submit="nextStep"
                            />
                           
                            <base-button 
                                v-if="step === 6"
                                :is-full-width="false"
                                btn-style="primary"
                                label="Close" 
                                size="sm"
                                @submit="closeModal"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import WelcomeIcon from "../icons/WelcomeIcon.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "WelcomeUser",
    components: {
        MainModal,
        WelcomeIcon,
        BaseButton,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            step: 1,
        };
    },

    methods: {
        closeModal() {
            this.$emit("close");
            // strip query params of "new_user" query
            const query = Object.assign({}, this.$route.query);
            delete query.new_user;
            this.$router.replace({ query });
        },

        nextStep() {
            this.step = this.step + 1;
        },
        prevStep() {
            this.step = this.step - 1;
        },
    },
};
</script>

<style lang="scss" scoped>
    .welcome {
        &--list {
            list-style-type: circle;
            padding-left: 1rem !important;

            li {
                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }
    }
</style>