<template>
    <div class="landing__join landing__section">
        <div class="landing__group__text join">
            <div class="landing__group__text__big join">
                <h1 class="landing__group__text__title text--6xl">
                    Boost productivity & workflows
                </h1>
                <p class="landing__group__text__subtitle text--2xl">
                    Say goodbye to disorganized processes, and hello to increased productivity.
                </p>
                <join-waitlist />
            </div>
        </div>
    </div>
</template>

<script>
import JoinWaitlist from "./JoinWaitlist.vue";

export default {
    components: {
        JoinWaitlist,
    },

    props: {
        isAuthenticated: {
            type: Boolean,
            default: false,
        },
    },
};
</script>