import axios from "axios";
import api from "@/api/url";
import { clearLocalStorage, redirectAndReload } from "@/utils/auth";
import { matchUrl } from "@/utils/helpers";
import env from "@/config/env";

const axiosInstance = axios.create({
    baseURL: env.VUE_APP_BASE_API_URL || "https://ziner-backend.onrender.com/api",
    headers: {
        "Content-Type": "application/json", 
    },
    withCredentials: true,
    timeout: 80000,
});  

function logUserOut() {
    return api.auth.logout()
        .then(() => {
            clearLocalStorage();
            redirectAndReload("/login");
        });
}

function handleGeneralApiErrors(error) {
    const status = error?.response?.status;
    const url = error?.config.url || "";
    let retryCount = 0;

    if (status === 500) {
        // return api.request(error.config);

        // for internal server errors, wait for 2 secs before retrying the request

        // try {
        // await store.dispatch("auth/refreshToken", { token: existingToken, refreshToken: existingRefreshToken });
        // const token = store.getters.token;

        // if (token) {
        //     delete error.config.headers.authorization;
        // }
            
        // } catch (refreshError) {
        //     console.error("Token refresh failed:", refreshError);
        // handleGeneralApiErrors(ctx, error);
        // throw refreshError;
        // }
        throw error;
    } else if (status === 404 && url === "/users/fetchprofile") { // user record is not found
        logUserOut();
    } else if ([401, 403].includes(status)) {
        const ignored401Endpoints = ["auth/login","auth/register"];

        const privateEndpoints = ignored401Endpoints.find(p => matchUrl(p, url)) === undefined;
        const invalidTokenErr = error?.response?.data?.message.includes("Bearer token is invalid. Please provide a valid auth token");

        if (privateEndpoints || invalidTokenErr) {
            logUserOut();
        }
    }

    return Promise.reject(error);
}

// Interceptor for Axios Requests
// useRequestDynamicHeader({ ctx, config });        
axiosInstance.interceptors.request.use((config) => config, (error) => handleGeneralApiErrors(error));

// response interceptor
axiosInstance.interceptors.response.use((resp) => {
    console.log({ resp });
    return resp;
}, (error) => handleGeneralApiErrors(error));

export default axiosInstance;