<template>
    <div>
        <div class="pt--10 pl--0 pr--0">
            <div class="project__content__name">
                <div class="project__item__action__btns">                        
                    <base-button
                        btn-style="outline" inner-style="secondary" size="sm" label="Update status"
                        class-names="text--sm mr--5"
                        @submit="toggleUpdateStatusModal"
                    />   

                    <base-button
                        btn-style="outline" inner-style="secondary" size="sm" 
                        icon-name="edit" class-names="text--sm mr--5" is-prefix-icon
                        icon-fill="#667085" icon-width="16px" @submit="toggleEditModal"
                    />  
                                         
                    <div class="dropdown">
                        <base-button
                            id="projectDetailsAction" btn-style="outline" inner-style="secondary" 
                            size="sm" icon-name="dots-horizontal-rounded" class-names="text--sm mr--5"
                            is-prefix-icon icon-fill="#667085" icon-width="16px" 
                            aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="true" 
                        />  
                       
                        <ul id="projectDetailsActionList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="projectDetailsAction">
                            <li class="dropdown-item pointer text--xs" @click="toggleArchiveModal(true)">
                                Archive project
                            </li>
                            <li class="dropdown-item pointer text--xs" @click="toggleDelete">
                                Delete project
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="project__item--wrap">
                <div class="project__item__left">
                    <div class="project__item">
                        <p class="project__item__title">
                            Deadline
                        </p>
                        <p class="project__item__desc flex align-items-center">
                            <IconSvg name="alarm" width="16px" fill="#667085" />
                            <span class="ml--5"> {{ $format_date(currentProject.deadline) || '-' }}</span>
                        </p>
                    </div>

                    <div class="project__item mt--10">
                        <p class="project__item__title">
                            Priority
                        </p>
                        <p class="project__item__desc">
                            {{ currentProject.priority || "-" }}
                        </p>
                    </div>

                    <div class="project__item mt--10">
                        <p class="project__item__title">
                            Project type
                        </p>
                        <p class="project__item__desc">
                            {{ currentProject.projectType || "-" }}
                        </p>
                    </div>

                    <div class="project__item mt--10">
                        <p class="project__item__title">
                            Favourite
                        </p>
                        <p class="project__item__desc">
                            {{ currentProject.isFavourite ? 'Yes' : 'No' }}
                        </p>
                    </div>
                </div>

                <div class="project__item__right">
                    <div class="project__item mt--10">
                        <div class="flex align-items-center">
                            <p class="project__item__title">
                                Tags
                            </p>
                            <base-button 
                                btn-style="ghost" 
                                label="(Manage)" 
                                class-names="text--xs ml--5 text--color-primary p--0" 
                                @submit="startManageSub('tags')"
                            />
                        </div>
                        <div v-if="currentProject.tags.length" class="mt--5 project__item__tags">
                            <div v-for="tag in currentProject.tags" :key="tag" class="tag__wrap">
                                <div class="tags--item">
                                    <div class="tags--item--text">
                                        <span class="text--lower">{{ tag.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-else>
                            -
                        </p>
                    </div>

                    <div class="project__item mt--15">
                        <div class="flex align-items-center">
                            <p class="project__item__title">
                                Clients
                            </p>
                            <base-button 
                                btn-style="ghost" 
                                label="(Manage)" 
                                class-names="text--xs ml--5 text--color-primary p--0" 
                                @submit="startManageSub('clients')"
                            />
                        </div>

                        <template v-if="currentProject.clients && currentProject.clients.length">
                            <div class="project__row">
                                <router-link 
                                    v-for="client in currentProject.clients" 
                                    :key="client._id" 
                                    class="block project__item__desc text--underline mr--10"
                                    :to="`/dashboard/clients/${client._id}`"
                                >
                                    {{ client.name }}
                                </router-link>
                            </div>
                        </template>

                        <template v-else>
                            <p class="project__item__desc">
                                -
                            </p>
                        </template>
                    </div>

                    <div class="project__item mt--15">
                        <div class="flex align-items-center">
                            <p class="flex align-items-center">
                                <IconSvg name="bell" width="16px" fill="#667085" />
                                <span class="ml--5 project__item__title ">Reminders</span>
                            </p>
                        </div>

                        <template v-if="currentProject.reminders && currentProject.reminders.length">
                            <span 
                                v-for="reminder in currentProject.reminders" 
                                :key="reminder" 
                                class="block project__item__desc mb--5"
                            >
                                {{ mapReminders[reminder] }}
                            </span>
                        </template>

                        <template v-else>
                            <p class="project__item__desc">
                                -
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="client__content__left__body pl--0 pr--0">
            <div class="client__group">
                <p class="title">
                    Payment information
                </p>

                <div class="project__item--wrap">
                    <div class="project__item__left">
                        <div class="project__item">
                            <p class="project__item__title">
                                Estimated revenue for this project
                            </p>
                            <p class="project__item__desc text--upper">
                                {{ currentProject.currency || "-" }} {{ $format_number(currentProject.estimatedRevenue) || 0 }}
                            </p>
                        </div>
        
                        <div class="project__item mt--10">
                            <p class="project__item__title">
                                Billable hours
                            </p>
                            <p class="project__item__desc">
                                {{ currentProject.billableHours }} hours
                            </p>
                        </div>
        
                        <div class="project__item mt--10">
                            <p class="project__item__title">
                                Billable rates
                            </p>
                            <p class="project__item__desc">
                                {{ currentProject.billingRate }}%
                            </p>
                        </div>
        
                        <div class="project__item mt--10"> 
                            <p class="project__item__title">
                                Billing type
                            </p>
                            <p class="project__item__desc text--upper">
                                {{ currentProject.billingType || '-' }}
                            </p>
                        </div>
                    </div>

                    <div class="project__item__right">
                        <div class="project__item">
                            <div class="flex align-items-center">
                                <p class="project__item__title">
                                    Rate cards
                                </p>
                                <base-button 
                                    btn-style="ghost" 
                                    label="(Manage)" 
                                    class-names="text--xs ml--5 text--color-primary p--0" 
                                    @submit="startManageSub('rateCards')"
                                />
                            </div>
                            
                            <template v-if="currentProject.rateCards && currentProject.rateCards.length">
                                <div class="">
                                    <router-link 
                                        v-for="item in currentProject.rateCards" 
                                        :key="item._id" 
                                        class="block project__item__desc text--underline mb--5"
                                        :to="`/dashboard/rate-cards/${item._id}`"
                                    >
                                        {{ item.title }}
                                    </router-link>
                                </div>
                            </template>
                            <template v-else>
                                <p class="project__item__desc">
                                    -
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmDeletionTwo
            type="project" custom-msg 
            :loading="deleteState.loading" 
            :show-modal="showDeleteModal" 
            @cancel="toggleDelete"
            @delete="handleDeleteProject"
        >
            <template #custom-msg>
                <p class="text--sm text--color-gray-100">
                    We will permanently remove all data on this project immediately. All clients and invoices associated with the project will still remain.
                    This action cannot be undone.
                </p>
            </template>
        </ConfirmDeletionTwo>

        <UpdateStatus
            :show-modal="showUpdateStatusModal" 
            :current-status="currentProject.status"
            :status-list="projectFilterStatuses"
            :loading="updateStatusLoading"
            @cancel="toggleUpdateStatusModal"
            @submit="handleStatusUpdate"
        />

        <create-project-modal
            :show-modal="showEditModal" action-type="edit"
            :type="'edit'" :project-data="currentProject"
            @close="toggleEditModal"
            @complete="toggleEditModal"
        />

        <ProjectSubDocsModal 
            :show-modal="projectSubs.show" 
            :sub-data="projectSubs"
            :initial-list="projectSubs.list"
            @close="closeSubModal"
            @complete="onCompleteSubUpdate"
        />

        <ArchiveRecord 
            type="project" 
            :loading="pageLoading" 
            :show-modal="showArchiveModal" 
            @cancel="toggleArchiveModal" 
            @archive="handleArchiveProject"
        />
    </div>
</template>

<script>
import { projectTabList, mapReminders } from "@/utils/dummy";
import { projectFilterStatuses } from "@/utils/enums";
import {
    IconSvg,
    ConfirmDeletionTwo,
    BaseButton,
    UpdateStatus,
    CreateProjectModal,
    ProjectSubDocsModal,
    ArchiveRecord,
} from "@/components/ui/index";

export default {
    name: "ProjectDetailsOverview",

    components: {
        IconSvg,
        BaseButton,
        ConfirmDeletionTwo,
        UpdateStatus,
        CreateProjectModal,
        ProjectSubDocsModal,
        ArchiveRecord,
    },

    data() {
        return {
            deleteState: {
                loading: false,
                errMsg: "",
                err: false,
            },
            showEditModal: false,
            showDeleteModal: false,
            showUpdateStatusModal: false,
            projectTabList,
            projectFilterStatuses,
            mapReminders,
            projectSubs: {
                type: "",
                show: false,
                list: [],
                indexLabel: "",
            },
            showArchiveModal: false,
            updateStatusLoading: false,
            pageLoading: false,
        };
    },

    computed: {
        currentProject() {
            return this.$store.getters["project/currentProject"];
        },
    },

    methods: {
        toggleArchiveModal(bool) {
            this.showArchiveModal = bool;
        },

        async handleDeleteProject() {
            try {
                this.deleteState.loading = true;
                this.deleteState.err = false;
                this.deleteState.errMsg = "";

                const ids = [this.currentProject?._id];

                await this.$store.dispatch("project/deleteProject", ids);

                this.$toast.success("Project deleted successfully");
                this.$route.push({ path: "/dashboard/projects" });
            } catch (error) {
                const errMsg = error?.response?.message || error.message;

                this.deleteState.err = false;
                this.deleteState.errMsg = errMsg;
                this.$toast.error(errMsg);
            } finally {
                this.deleteState.loading = false;
            }
        },

        async handleArchiveProject() {
            try {
                const id = this.currentClient?._id || this.$route.params.id;

                if (!id) {
                    this.$toast.error("No project id passed");
                    return;
                }

                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                this.pageLoading = true;

                await this.$store.dispatch("project/archiveProject", id);

                this.showArchiveModal = false;
                this.$toast.success("Project archived");
                this.$router.push("/dashboard/projects");
            } catch (error) {
                const msg = error?.message;

                this.pageLoadingErr = true;
                this.$toast.error(msg);
            } finally {
                this.pageLoading = false;
            }
        },

        toggleEditModal() {
            this.showEditModal = !this.showEditModal;
        },
        
        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        toggleUpdateStatusModal() {
            this.showUpdateStatusModal = !this.showUpdateStatusModal;
        },

        goToTasks() {
            const projectId = this.$route.params.id;

            if (!projectId) return;

            this.$router.push(`/dashboard/projects/${projectId}/tasks`);
        },

        async handleStatusUpdate(data) {
            try {
                this.updateStatusLoading = true;
                
                await this.$store.dispatch("project/updateProject", data);
                
                this.$store.dispatch("app/fetchCompletedProjectCount", { refresh: true });
                this.$toast.success("Project status updated");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateStatusLoading = false;
            }
        },

        startManageSub(type) {
            this.projectSubs.type = type;
            this.projectSubs.show = true;

            switch (type) {
            case "tags":
                this.projectSubs.list = this.currentProject.tags;
                this.projectSubs.indexLabel = "name";
                break;

            case "clients":
                this.projectSubs.list = this.currentProject.clients;
                this.projectSubs.indexLabel = "name";
                break;

            case "rateCards":
                this.projectSubs.list = this.currentProject.rateCards;
                this.projectSubs.indexLabel = "title";
                break;
            
            default:
                this.projectSubs.list = [];
                this.projectSubs.indexLabel = "";
                break;
            }
        },

        closeSubModal() {
            this.projectSubs.show = false;
            this.projectSubs.type = "";
            this.projectSubs.list = [];
            this.projectSubs.indexLabel = "";
        },

        onCompleteSubUpdate(resp) {
            if (!resp.key) return;

            this.$store.dispatch("project/updateProjectSubsComplete", { id: this.$route.params.id, ...resp });
            this.closeSubModal();
        },
    },
};
</script>

<style lang="scss" scoped>
    .dropdown-item {
        padding: 0.3rem 0.75rem !important;
    }
</style>