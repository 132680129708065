<template>
    <div class="clients__page clients__page--view">
        <div>
            <div>
                <p class="text--sm">
                    You are currently on the Standard plan. We hope you’re enjoying the features made available to you. Upgrade to our Premium plan at <strong>$5/month or $55/yr</strong> to get access to more features and integrations
                </p>
            </div>

            <div>
                <div class="settings__auth__header mt--30">
                    <p>Available integrations</p>
                </div>

                <ul class="mt--30 flex flex-wrap">
                    <p class="text--semi--bold text--color-gray-700 text--sm">
                        Calendar
                    </p>

                    <BaseCard
                        v-for="(item, index) in integrationList" 
                        :key="index" 
                        aria-label="inner content shows on hover"
                        card-classes="settings__integrations__card__item"
                    >
                        <template #card-body>
                            <div class="outer__wrap"> 
                                <img class="img__responsive" :src="getImgUrl(item.id)" alt="Card image of app">
                                <p class="text--center mt--20 text--color-gray-400 text--semi--bold">
                                    {{ item.id }}
                                </p>
                                <p class="text-center text--sm mt--5 text--color-gray-100">
                                    {{ sliceText(item.desc) }}
                                </p>
                            </div>
                            <div>
                                <base-button 
                                    :class-names="`settings__integrations__card__btn ${status === 'Standard' ? 'standard' : 'premium'}`" 
                                    :label="status === 'unavailable' ? 'Premium' : '+ Add'" 
                                    size="sm"
                                    btn-style="ghost"
                                />
                            </div>
                        </template>
                    </BaseCard>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BaseCard,
    BaseButton,
    CustomTable,
    IconSvg,
} from "@/components/ui/index";
import { accountIntegrations } from "@/utils/dummy";
import { groupList } from "@/utils/helpers";

export default {
    name: "BillingsAndPlans",

    components: {
        BaseButton,
        BaseCard,
        IconSvg,
    },

    data() {
        return {
            accountIntegrations,
            status: "Standard",
        };
    },

    computed: {
        transfromData() {

        },
    },

    watch: {
    },

    mounted() {
    },

    methods: {
        // remove this function and load inline SVGs instead
        getImgUrl(name) {
            switch (name) {
            case "Online visibility":
                return require("@/assets/img/port-detection.svg");
            case "AI Assistance":
                return require("@/assets/img/surveillance-defense.svg");
            case "Time tracking":
                return require("@/assets/img/intelligent-positioning.svg");
            case "Calendar":
                return require("@/assets/img/calendar.svg");
            default:
                return require("@/assets/img/calendar.svg");
            }
        },

        sliceText(text) {
            if (typeof text !== "string") return;

            return text.slice(0, 70);
        },
    },
};
</script>