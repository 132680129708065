<template>
    <div class="dropdown" :disabled="disabled" :class="[dropdownClass]">
        <button 
            id="import" 
            class="btn--filter btn text--xs btn-sm text--bold" 
            type="button"
            aria-expanded="false"
            data-bs-toggle="dropdown" 
            data-bs-auto-close="true" 
        >
            <span class="mr--5 flex align-items-center">
                <icon-svg :name="iconName" fill="#637579" width="16px" height="16px" />
                <span class="ml--5 text--xs">{{ label }}</span>
            </span>
        </button>

        <ul id="importList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="importList">
            <li 
                v-for="(item, index) in list" 
                :key="index" 
                class="dropdown-item pointer text--xs text--upper"
                @click="$emit('select', item)"
            >
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "FileDropdownButton",

    components: { IconSvg },
    
    props: {
        label: {
            type: String,
            default: "",
        },
        iconName: {
            type: String,
            default: "",
        },
        dropdownClass: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => ["csv", "json"],
        },
    },

    data() {
        return {
            file: "",
            fileContent: [],
            isParsed: false,
        };
    },

    methods: {
        handleFileChange(event) {
            this.$emit("file-change", event);
        },
    },
};
</script>

<style lang="scss" scoped>
    .dropdown-menu--tag {
        min-width: 5rem !important;
        font-size: 14px !important;
    }
</style>