/**
 * Utility function that helps adds space formatting to a text depending on the spaces set.
 */
export function textSpacer(text = "", spaces = [3, 3, 4]) {
    const formatted = [];
    let i = 0;
    for (const space of spaces) {
        const next = i + space;
        const val = (text.slice(i, next).trim());
        if (val) {
            formatted.push(val);
        } else {
            break;
        }
        i = next;
    }
    const remaining = text.slice(i).trim();
    if (remaining) {
        formatted.push(remaining);
    }

    return formatted.join(" ");
};

export function optimizeFileStackURL(url, width = 200, height = 200) {
    try {
        const urlObject = new URL(url);
        return `${urlObject.origin}/resize=width:${parseFloat(width)},height:${parseFloat(height)},fit:crop,align:center${urlObject.pathname}`;
    } catch {
        return url;
    }
}

export function splitArrayIntoSets(arr = [], offset = 0) {
    const sets = [];
    for (let i = 0; i < arr.length; i += offset) {
        const set = arr.slice(i, offset + i);
        sets.push(set);
    }
    return sets;
}

export function buildQuery(object) {
    let query = "";
    Object.keys(object).forEach((key, index) => {
        if (index === 0) {
            query += `?${key}=${encodeURIComponent(object[key])}`;
        } else {
            query += `&${key}=${encodeURIComponent(object[key])}`;
        }
    });
    return query;
}

export function flatten2DArray(twoDArr = []) {
    return [].concat.apply([], twoDArr);
}

export function toKebabCase(val = "") {
    return val.toLowerCase().split(" ").join("-");
}

export function withErrors(promiseFn) {
    try {
        return Promise.resolve(promiseFn())
            .then(resp => [resp, undefined])
            .catch(err => [undefined, err]);
    } catch (err) {
        return Promise.resolve([undefined, err]);
    }
}

export function mockAxiosError(obj = {}) {
    /* eslint-disable */
    return Promise.reject({
        response: {
            data: {
                ...obj,
            },
        },
    });
}

export function removeAllQueryParams(url = "") {
    const parts = url.split("?");
    return parts[0].trim();
};

export function AxiosErr(err) {
    const parseMessage = () => {
        try {
            JSON.stringify(err);
        } catch {
            return ("(Unknown Error) - Cannot parse message");
        }
    };
    const message = typeof err === "string"
        ? err
        : (err?.response?.data?.message || err?.data?.message || err?.message || err?.data?.Message);
    const parsedMessage = message || parseMessage();

    const toError = () => new Error(parsedMessage);

    return {
        message,
        parsedMessage,
        toError,
    };
}

// this function is buggy
export function arrayOfObjectsToObjectWithKeys(arr = []) {
    const obj = {};
    for (const o of arr) {
        const field = Object.keys(o)[0];
        const value = Object.values(o)[0];
        obj[field] = value;
    }
    return obj;
}

export function createQueryString(url = "", queryParams = {}) {
    let queryString = url;
    if (queryParams && Object.keys(queryParams).length > 0) {
        queryString += "?" + convertObjectToQueryString(queryParams);
    }
    return queryString;
}

export function convertObjectToQueryString(obj) {
    // return Object.keys( obj ).map(key => key + '=' + obj[key]).join('&');
    const str = [];
    for (const p in obj) {
        if (obj[p] === undefined) {
            continue;
        }
        /* eslint-disable */
        if (obj.hasOwnProperty(p)) {
            str.push((p) + "=" + (obj[p]));
        }
    }

    return str.join("&");
}

export function decodeHTML(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export function replaceStringsAll(str, replaceStr) {
    if (typeof str !== 'string') return ;

    return str.replaceAll(replaceStr, "");
}