<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div>
        <div class="revenue__page">
            <div>
                <div class="revenue__stats__wrap">
                    <div>
                        <p class="text--semi--bold text--base text--color-gray-700">
                            Stats
                        </p>
                        <p class="text--normal text--sm text--color-gray-100">
                            Understand how the revenue from your completed projects typically progresses. Learn about <a class="text--color-primary text--medium" href="https://heythere.substack.com/publish/post/142082967">
                                earning stats 
                                <IconSvg name="arrow-right" class="icon-rotate-45" fill="#289CA9" />
                            </a>
                        </p>
                    </div>
                    
                    <div class="revenue__stats__list">
                        <template v-if="statsLoading">
                            <StatsSkeleton v-for="item in 4" :key="item" />
                        </template>
    
                        <template v-else-if="fetchStatsErr">
                            <EmptyPage state="error" type="revenue">
                                <template #button>
                                    <base-button 
                                        class-names="flex text--sm align-items-center"
                                        label="Reload" icon-name="reload" icon-width="16px"
                                        @submit="handleFetchStats"
                                    />
                                </template> 
                            </EmptyPage>
                        </template>   

                        <template v-else>
                            <div v-for="item in transformedStats" :key="item.revenueType" class="revenue__stats__list__item">
                                <Stats :data="item" type="revenue_stats" />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
    
            <div class="revenue__goals__wrap">
                <div class="revenue__goals__header">
                    <div>
                        <p class="text--semi--bold text--base text--color-gray-700">
                            Set revenue target
                        </p>
                        <p class="text--normal text--sm text--color-gray-100">
                            Maximize and measure your revenue potential by setting attainable goals.
                        </p>
                    </div>

                    <base-button 
                        label="+ Add new goal" 
                        btn-style="ghost"
                        size="sm" class="btn--ghost__primary"
                        @submit="openNewGoalModal"
                    />
                </div>

                <div class="revenue__goals__tasks">
                    <BaseCard>
                        <template #card-body>
                            <PageSpinner v-if="goalLoading" />
                            <EmptyPage v-else-if="goalLoadingErr" state="error" />
                            <div v-else>
                                <div v-if="goals.length">
                                    <div class="mb--15">
                                        <p class="text--semi--bold text--base text--color-gray-700">
                                            Goals
                                        </p>
                                        <p class="text--normal text--sm text--color-gray-100 mt--5">
                                            {{ completedGoals }} of {{ goals.length }} {{ goals.length === 1 ? 'goal' : 'goals' }} completed
                                        </p>
                                    </div>
    
                                    <div v-for="goal in goals" :key="goal._id" class="revenue__goals__tasks__item">
                                        <div class="form-check flex-1">
                                            <input 
                                                :id="goal.name" 
                                                :value="goal.isCompleted"
                                                class="form-check-input" 
                                                type="checkbox" 
                                                :checked="goal.isCompleted"
                                                @change="e => handleMarkGoalComplete(e, goal)"
                                            >
                                            <label 
                                                class="form-check-label text--color-gray-600" 
                                                :class="{ 'text-line-through' : goal.isCompleted }" 
                                                for="flexCheckDefault"
                                                @click=" setCurrentGoalForEdit(goal)"
                                            >
                                                {{ goal.name }}
                                            </label>
                                        </div>
                                        <div class="pointer" @click="deleteGoal(goal._id)">
                                            <icon-svg name="close" fill="#64748B" width="16px" />
                                        </div>
                                    </div>
                                </div>
                                <EmptyPage v-else class="mt--10 mb--10" state="empty" icon-name="notes" width="50px" height="60px" type="goal" />
                            </div>
                        </template>
                    </BaseCard>
                    
                    <PageSpinner v-if="showScrollLoader" />
                </div>
            </div>
        </div>

        <main-modal 
            :show-modal="showGoalModal" is-close-button 
            size="sm" is-show-header is-footer
            :title="formActionType === 'add' ? 'Add new goal' : 'Edit goal details'" 
            @close="resetCloseGoalModal"
        >
            <template #body>
                <form class="mb--20 pr--20 pl--20">
                    <div class="form--control flex-1">
                        <FormInput 
                            id="amount"
                            v-model="form.name"
                            size="sm"
                            label="Goal name"
                            no-margin-bottom
                        />
                    </div>
                </form>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        class-names="mr--5" label="Cancel"
                        size="sm" btn-style="secondary" :disabled="addGoalLoading"
                        @submit="resetCloseGoalModal"
                    />
                    <Base-Button 
                        :label="formActionType === 'add' ? 'Add' : 'Save'"
                        size="sm" :loading="addGoalLoading" :disabled="!form.name || addGoalLoading"
                        type="submit" @submit="handleGoalSubmit"
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import { IconSvg, EmptyPage, PageSpinner, BaseButton, BaseCard, MainModal, FormInput } from "@/components/ui/index";
import Stats from "@/components/revenue/Stats.vue";
import StatsSkeleton from "@/components/revenue/StatsSkeleton.vue";

export default {
    name: "RevenueOverview",

    components: {
        IconSvg,
        EmptyPage,
        PageSpinner,
        BaseButton,
        Stats,
        BaseCard,
        MainModal,
        FormInput, 
        StatsSkeleton,
    },

    data() {
        return {
            statsLoading: false,
            fetchStatsErr: false,
            fetchStatsErrMsg: "",
            form: {
                name: "",
                isCompleted: false,
            },
            formActionType: "add",
            addGoalLoading: false,
            addGoalErr: false,
            addGoalErrMsg: "",
            showGoalModal: false,
            deleteLoading: false,
            goalLoading: false,
            goalLoadingErr: false,
            showScrollLoader: false,
        };
    },

    computed: {
        goals() {
            return this.$store.getters["revenue/goals"].data;
        },

        stats() {
            return this.$store.getters["revenue/stats"];
        },

        earnings() {
            return this.$store.getters["revenue/earnings"];
        },

        completedGoals() {
            return this.goals.filter(x => x.isCompleted).length || 0;
        },
        
        pageData() {
            return this.$store.getters["revenue/goals"].pageData;
        },

        currency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        transformedStats() {
            if (!Array.isArray(this.stats) || !this.stats.length) return [];

            return this.stats.map(item => {
                if (item.revenueType === "average_quartely") {
                    return {
                        ...item,
                        amount: item.amount.currentQuarter,
                    };
                } else {
                    return item;
                }
            });
        },
    },

    watch: {
        "currency": {
            async handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    await this.handleFetchStats({
                        currency: newVal,
                    });
                }
            },
        },
    },
    
    async mounted() {
        // await this.handleFetchStats();
        await this.fetchGoals();

        window.addEventListener("scroll", this.handleScroll);
    },

    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {        
        openNewGoalModal() {
            this.showGoalModal = true;
        },

        resetCloseGoalModal() {
            this.showGoalModal = false;

            this.form._id = "";
            this.form.name = "";
            this.form.isCompleted = "";
        },

        async handleScroll() {
            if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                await this.loadMoreGoals();
            }
        },

        async loadMoreGoals() {            
            if (this.showScrollLoader || this.pageData.totalPages === 1 || this.pageData.currentPage === this.pageData.totalPages) return;

            this.showScrollLoader = true;

            await this.fetchGoals({
                pageNumber: this.pageData.currentPage + 1,
            });
            this.showScrollLoader = false;
        },

        async handleFetchStats(params) {
            try {
                console.log("call the handleFetchStats function");

                this.fetchStatsErr = false;
                this.statsLoading = true;

                const payload = {
                    currency: params?.currency || this.currency,
                };
                
                await this.$store.dispatch("revenue/fetchStats", payload);
            } catch (error) {
                this.fetchStatsErr = true;
            } finally {
                this.statsLoading = false;
            }
        },

        async fetchGoals(params) {
            try {                
                this.goalLoadingErr = "";
                this.goalLoading = true;

                const payload = {
                    pageNumber: params?.pageNumber || this.pageData.currentPage,
                };
                
                await this.$store.dispatch("revenue/fetchGoals", payload);
            } catch (error) {
                this.goalLoadingErr = true;
            } finally {
                this.goalLoading = false;
            }
        },

        async handleAddNewGoal() {
            try {
                this.addGoalErr = false;
                this.addGoalErrMsg = "";
                this.addGoalLoading = true;

                const payload = {
                    name: this.form.name,
                    isCompleted: this.form.isCompleted === "yes" ? true : false,
                };
                
                await this.$store.dispatch("revenue/addGoal", payload);
                this.$toast.success("New goal created");
                this.resetCloseGoalModal();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.addGoalErr = true;
                this.$toast.error(errMsg);
            } finally {
                this.addGoalLoading = false;
            }
        },

        setCurrentGoalForEdit(goal) {
            if (!goal._id) return;

            this.form.name = goal.name;
            this.form.isCompleted = goal.isCompleted;
            this.form._id = goal._id;

            this.formActionType = "edit";

            this.showGoalModal = true;
        },

        async handleMarkGoalComplete(event, goal) {
            if (!event) return;

            const value = event?.target?.checked;

            this.form.name = goal.name;
            this.form._id = goal._id;
            this.form.isCompleted = value;

            await this.updateGoal();
        },

        async updateGoal() {
            try {
                this.addGoalErr = false;
                this.addGoalLoading = true;
    
                const payload = {
                    _id: this.form._id || "",
                    name: this.form.name,
                    isCompleted: this.form.isCompleted,
                };

                if (!payload._id) return;
    
                await this.$store.dispatch("revenue/updateGoal", payload);

                this.$toast.success("Goal updated");

                this.resetCloseGoalModal();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.addGoalErr = true;
                this.$toast.error(errMsg);
            } finally {
                this.addGoalLoading = false;
            }
        }, 

        async handleGoalSubmit() {
            if (this.formActionType === "add") {
                this.handleAddNewGoal();
            } else {
                this.updateGoal();
            }
        },

        async deleteGoal(id) {
            try {
                this.deleteLoading = true;
    
                await this.$store.dispatch("revenue/deleteGoal", id);

                this.$toast.success("Goal deleted");
            } catch (error) {
                const errMsg = error?.response?.message || error.message;  
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
    .icon-rotate-45 {
        transform: rotate(-20deg);
        fill: #289CA9;
        width: 16px;
        height: 16px;
    }
</style>