<template>
    <div>
        <div class="client__page--view">
            <template v-if="pageLoading">
                <page-spinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error">
                    <template #button>
                        <base-button label="Reload" size="sm" @submit="handleFetchClient" />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div 
                    v-if="['reached out', 'in the works', 'potential lead'].includes(currentClient.status)" 
                    class="client__header__wrap"
                >
                    <div class="client__header__status">
                        <base-button
                            btn-style="outline" inner-style="primary" label="Follow up" size="sm"
                            class-names="mr--5"
                            @submit="openFollowUpEmail"
                        />  
                    </div>
                </div>

                <div class="client__content">
                    <div class="client__content__left">
                        <div class="client__content__left__header">
                            <div class="flex align-items-center justify-content-between">
                                <p class="text--xl text--semi--bold text--color-gray-400 flex-2">
                                    {{ currentClient.companyName || "N/A" }}
                                </p>
                                <div class="client__header__status mb--0">
                                    <Status type="client" :value="currentClient.status" />
                                </div>
                            </div>
                            <div class="mb--5">
                                <span class="text--xs text--color-light-300">
                                    Created on:
                                </span>
                                <span class="text--xs text--color-light-300">
                                    {{ $format_date(currentClient.createdAt) }}
                                </span>
                            </div>

                            <div class="client__item__action__btns">
                                <base-button
                                    size="sm" label="Update status" class-names="mr--5"
                                    @submit="showUpdateStatusModal = true"
                                />
                                <base-button
                                    btn-style="outline" inner-style="primary" label="Archive" size="sm"
                                    class-names="mr--5"
                                    @submit="toggleArchiveModal(true)"
                                />                      
                                <div class="dropdown">
                                    <button 
                                        id="clientDetailsAction" 
                                        class="btn--outline btn btn--sm text--bold" 
                                        type="button" aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="true" 
                                    >
                                        <icon-svg name="dots-horizontal-rounded" fill="#289CA9" width="24px" />
                                    </button>
                                    <ul id="clientDetailsActionList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="clientDetailsAction">
                                        <li class="dropdown-item pointer text--xs" @click="showEditModal = true">
                                            Edit client information
                                        </li>
                                        <li class="dropdown-item pointer text--xs text--color-warning" @click="toggleDelete">
                                            Delete client
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                       
                        <div class="client__content__left__body">
                            <div class="client__group">
                                <p class="title">
                                    Client details
                                </p>

                                <div class="client__item">
                                    <span class="title text--capitalize">
                                        Name
                                    </span>
                                    <span class="sub text--capitalize">
                                        {{ currentClient.name || 'N/A' }}
                                    </span>
                                </div>

                                <div class="client__item">
                                    <span class="title">
                                        Email
                                    </span>
                                    <span class="sub">
                                        {{ currentClient.email || 'N/A' }}
                                    </span>
                                </div>

                                <div class="client__item">
                                    <span class="title">
                                        Industry
                                    </span>
                                    <span class="sub text--capitalize">
                                        {{ currentClient.industry || 'N/A' }}
                                    </span>
                                </div>
                            </div>

                            <div class="client__group">
                                <div class="flex align-items-center justify-content-between">
                                    <p class="title">
                                        Billing Emails
                                    </p>
                                   
                                    <base-button 
                                        btn-style="ghost" 
                                        label="(Manage)" 
                                        class-names="text--xs ml--5 text--color-primary p--0" 
                                        @submit="openBillingModal('billingEmails')"
                                    />
                                </div>
                                
                                <div class="mb--20 mt--15 email__wrap">
                                    <div v-if="!currentClient.ccEmails.length">
                                        <span class="text--sm text--color-gray-400">
                                            No billings emails available
                                        </span>
                                    </div>

                                    <div v-else class="flex flex-wrap">
                                        <span v-for="email in currentClient.ccEmails" :key="email" class="sub__tag text--color-gray-400 mb--5">
                                            {{ email || '-' }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="client__group">
                                <div class="flex align-items-center justify-content-between">
                                    <p class="title">
                                        Billing Details
                                    </p>
                                   
                                    <base-button 
                                        btn-style="ghost" 
                                        label="(Manage)" 
                                        class-names="text--xs ml--5 text--color-primary p--0" 
                                        @submit="openBillingModal('billingDetails')"
                                    />
                                </div>
                                
                                <div class="mb--10">
                                    <div class="mt--10">
                                        <div class="client__item">
                                            <span class="title">
                                                Phone number
                                            </span>
                                            <span class="sub">
                                                {{ currentClient.phoneNumber || 'N/A' }}
                                            </span>
                                        </div>

                                        <div class="client__item">
                                            <span class="title">
                                                Country
                                            </span>
                                            <span class="sub">
                                                {{ currentClient.country || "N/A" }}
                                            </span>
                                        </div>

                                        <div class="client__item">
                                            <span class="title">
                                                City
                                            </span>
                                            <span class="sub">
                                                {{ currentClient.city || "N/A" }}
                                            </span>
                                        </div>

                                        <div class="client__item">
                                            <span class="title">
                                                State
                                            </span>
                                            <span class="sub">
                                                {{ currentClient.state || "N/A" }}
                                            </span>
                                        </div>

                                        <div class="client__item">
                                            <span class="title">
                                                Zip code
                                            </span>
                                            <span class="sub">
                                                {{ currentClient.zipCode || "N/A" }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="client__content__right">
                        <div class="client__item__meta">
                            <NonLinkedTabs :list="clientTabList">
                                <template #nonLinkedTab="data">
                                    <NonLinkedTab :is-active="data.name === 'Notes'">
                                        <ClientNotes />
                                    </NonLinkedTab>
                                    <NonLinkedTab :is-active="data.name === 'Projects'">
                                        <ClientProjects />
                                    </NonLinkedTab>
                                    <NonLinkedTab :is-active="data.name === 'Invoices'">
                                        <ClientInvoices />
                                    </NonLinkedTab>
                                    <NonLinkedTab :is-active="data.name === 'Documents'">
                                        <ClientDocuments />
                                    </NonLinkedTab>
                                </template>
                            </NonLinkedTabs>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            type="client" custom-msg
            :show-modal="showDeleteModal"
            :loading="deleteClient.loading" 
            @cancel="toggleDelete" 
            @delete="handleDeleteClient"
        >
            <template #custom-msg>
                <p class="text--sm">
                    This client will be removed from all related projects, meetings, and invoices. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <CreateClientModal
            :show-modal="showEditModal" :client-data="currentClient"
            action-type="edit" @close="showEditModal = false" @complete="handleDetailsUpdate"
        />

        <EditClientBillingInfo
            :show-modal="clientBillingModalInfo.show" 
            :data="clientBillingModalInfo.data"
            :sub-type="clientBillingModalInfo.type" 
            @close="resetBillingInfoModal" @update="updateClientData"
        />

        <ArchiveRecord 
            type="client" 
            :loading="archiveLoading" 
            :show-modal="showArchiveModal" 
            @cancel="toggleArchiveModal" 
            @archive="handleArchiveClient"
        />

        <UpdateStatus
            :show-modal="showUpdateStatusModal" 
            :current-status="currentClient.status"
            :status-list="CLIENT_STATUSES"
            @cancel="toggleUpdateStatusModal"
            @submit="handleStatusUpdate"
        />

        <FollowUpEmailModal
            :show-modal="showFollowUpModal"  
            :form-data="currentClient" 
            @close="showFollowUpModal = false" 
            @send="sendFollowUp"
        />
    </div>
</template>

<script>
import { industryData, clientTabList } from "@/utils/dummy";
import { CLIENT_STATUSES } from "@/utils/enums";
import {
    IconSvg,
    EmptyPage,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CreateClientModal,
    UpdateStatus,
    NonLinkedTabs,
    NonLinkedTab,
    ArchiveRecord,
    EditClientBillingInfo,
    Status,
    FollowUpEmailModal,
} from "@/components/ui/index";
import { ClientDocuments, ClientInvoices, ClientNotes, ClientProjects } from "@/components/clients/index";

export default {
    name: "ClientDetailsView",

    components: {
        EmptyPage,
        IconSvg,
        BaseButton,
        PageSpinner,
        ConfirmDeletionTwo,
        CreateClientModal,
        UpdateStatus,
        NonLinkedTabs,
        NonLinkedTab,
        ClientDocuments,
        ClientInvoices,
        ClientNotes,
        ClientProjects,
        ArchiveRecord,
        EditClientBillingInfo,
        Status,
        FollowUpEmailModal,
    },

    data() {
        return {
            CLIENT_STATUSES,
            pageLoading: false,
            pageLoadingErr: false,
            industries: industryData,
            showEditModal: false,
            showDeleteModal: false,
            showArchiveModal: false,
            showUpdateStatusModal: false,
            fetchProjects: {
                loading: false,
                err: false,
                msg: "",
            },
            deleteClient: {
                loading: false,
                err: false,
            },
            loading: false,          
            clientTabList,
            clientBillingModalInfo: {
                type: "",
                show: false,
                data: {
                    _id: "",
                    city: "",
                    state: "",
                    address: "",
                    zipCode: "",
                    country: "",
                    ccEmails: [],
                    phoneNumber: "",
                },
            },
            archiveLoading: false,
            showFollowUpModal: false,
            currentClient: {
                ccEmails: [],
            },
        };
    },

    async mounted() {
        await this.handleFetchClient();
    },

    methods: {
        async handleFetchClient() {
            try {
                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                this.pageLoading = true;

                const res = await this.$store.dispatch("clients/getClient", this.$route.params.id);
                this.currentClient = res;
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
               
                this.pageLoadingErr = true;
                this.pageLoadingErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },

        async handleDeleteClient() {
            try {
                const id = this.currentClient?._id || this.$route.params.id;
                
                this.deleteClient.err = false;
                this.deleteClient.loading = true;

                await this.$store.dispatch("clients/deleteClients", [`${id}`]);

                this.$toast.success("Client deleted");
                this.$router.push("/dashboard/clients");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                
                this.deleteClient.err = true;
                this.$toast.error(errMsg);
            } finally {
                this.deleteClient.loading = false;
            }
        },

        async handleArchiveClient() {
            try {
                this.archiveLoading = true;

                const id = this.currentClient?._id || this.$route.params.id;

                if (!id) {
                    this.$toast.error("No client id passed");
                    return;
                }

                await this.$store.dispatch("clients/archiveClient", id);

                this.$toast.success("Client archived");
                
                this.$router.push({ path: "/dashboard/clients" });
            } catch (error) {
                const errMsg = error?.response?.message || error.message;
                
                this.$toast.error(errMsg);
            } finally {
                this.archiveLoading = false;
            }
        },

        openBillingModal(type) {
            this.clientBillingModalInfo.type = type;
            this.clientBillingModalInfo.show = true;

            this.clientBillingModalInfo.data = {
                ...this.clientBillingModalInfo.data,
                _id: this.currentClient?._id,
                city: this.currentClient?.city || "",
                state: this.currentClient?.state || "",
                address: this.currentClient?.address || "",
                zipCode: this.currentClient?.zipCode || "",
                country: this.currentClient?.country || "",
                ccEmails: this.currentClient?. ccEmails || [],
                phoneNumber: this.currentClient?.phoneNumber || "",
            };
        },

        updateClientData(data) {
            this.currentClient = {
                ...this.currentClient,
                ...data,
            };
        },

        resetBillingInfoModal() {            
            this.clientBillingModalInfo.show = false;
            this.clientBillingModalInfo.type = "";
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        toggleArchiveModal(bool) {
            this.showArchiveModal = bool;
        },

        toggleUpdateStatusModal(bool) {
            this.showUpdateStatusModal = bool;
        },

        goToProject(projectId) {
            this.$router.push({ name: "project-details-overview", params: { id: projectId }});
        },

        async handleStatusUpdate(data) {
            try {
                this.loading = true;
                
                const res = await this.$store.dispatch("clients/updateClient", data);
                this.updateClientData(res);
                this.$toast.success("Client status updated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async openFollowUpEmail() {
            this.showFollowUpModal = true; 
        },

        async sendFollowUp() {
            try {
                this.followUpLoading = true;

                const payload = {
                    email: this.currentClient.email || "",
                };

                await this.$store.dispatch("clients/followUpWithClient", payload);
            } catch (error) {
                throw error;
            } finally {
                this.followUpLoading = false;
            }
        },

        handleDetailsUpdate(data) {
            this.currentClient = {
                ...this.currentClient,
                ...data,
            };
            this.showEditModal = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .dropdown-item {
        padding: 0.3rem 0.75rem !important;
        font-weight: 500;

        &:last-of-type {
            padding-bottom: 0.1rem !important;
        }
    }
</style>