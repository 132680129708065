<template>
    <div class="clients__page clients__page--view">
        <div>
            <div>
                <p class="text--sm">
                    Set goals to measure your progress, narrow focus, and boost productivity. Find motivation through goal setting today! 
                    <CustomLink to="/goals" is-primary>
                        Learn more
                    </CustomLink>.
                </p>
            </div>

            <div>
                <div class="settings__auth__header mt--30">
                    <div class="flex align-center justify-content-between w-100">
                        <p>Goals</p>
                        <div class="form-check form-switch">
                            <input
                                id="flexSwitchCheckDefault" v-model="goals.isActive" class="form-check-input" type="checkbox"
                                role="switch"
                            >
                            <label class="form-check-label" for="flexSwitchCheckDefault" />
                        </div>
                    </div>
                </div>
                <div class="mt--20">
                    <p class="mb--15 text--semi--bold text--base">
                        Select projects goal frequency
                    </p>
                    <div>
                        <p class="mb--10 text--medium text--sm">
                            Frequency
                        </p>
                        <div v-for="freq in goals.projects.target" :key="freq" class="form-check form-check-inline">
                            <input id="flexCheckDefault" class="form-check-input" type="checkbox" :value="freq">
                            <label class="form-check-label text--sm text--capitalize" for="flexCheckDefault">
                                {{ freq }}
                            </label>
                        </div>
                    </div>

                    <!-- if daily projects is selected -->
                    <div class="mt--20 w--40">
                        <p class="mb--10 text--sm text--medium">
                            Target for weekly projects
                        </p>
                        <FormInput id="weeklyProjects" v-model="goals.projects.weekly" type="number" />
                    </div>
    
                    <!-- if weekly target for projects is selected -->
                    <div class="mt--20 w--40">
                        <p class="mb--10 text--sm text--medium">
                            Target for monthly projects
                        </p>
                        <FormInput id="monthlyProjects" v-model="goals.projects.monthly" type="number" />
                    </div>

                    <!-- if yearly target for projects is selected -->
                    <div class="mt--20 w--40">
                        <p class="mb--10 text--sm text--medium">
                            Target for yearly projects
                        </p>
                        <FormInput id="yearlyProjects" v-model="goals.projects.yearly" type="number" />
                    </div>
                </div>
                <div class="mt--40">
                    <p class="mb--15 text--semi--bold text--base">
                        Select task goals
                    </p>
                    <div>
                        <p class="mb--10 text--medium text--sm">
                            Frequency
                        </p>
                        <div v-for="freq in goals.tasks.target" :key="freq" class="form-check form-check-inline">
                            <input id="flexCheckDefault" class="form-check-input" type="checkbox" :value="freq">
                            <label class="form-check-label text--sm" for="flexCheckDefault">
                                {{ freq }}
                            </label>
                        </div>
                    </div>
                    <!-- if daily tasks is selected -->
                    <div class="mt--20 w--40">
                        <p class="mb--10 text--sm text--medium">
                            Target for daily tasks
                        </p>
                        <FormInput id="dailyTasks" v-model="goals.tasks.daily" type="number" />
                    </div>
    
                    <!-- if weekly target for tasks is selected -->
                    <div class="mt--20 w--40">
                        <p class="mb--10 text--sm text--medium">
                            Target for weekly tasks
                        </p>
                        <FormInput id="weeklyTasks" v-model="goals.tasks.weekly" type="number" />
                    </div>

                    <!-- if monthly target for tasks is selected -->
                    <div class="mt--20 w--40">
                        <p class="mb--10 text--sm text--medium">
                            Target for monthly tasks
                        </p>
                        <FormInput id="monthlyTasks" v-model="goals.tasks.monthly" type="number" />
                    </div>
                </div>
            </div>

            <div>
                <div class="settings__auth__header mt--30">
                    <div class="flex align-center justify-content-between w-100">
                        <p>Productivity</p>
                        <div class="form-check form-switch">
                            <input
                                id="flexSwitchCheckDefault" v-model="goals.isActive" class="form-check-input" type="checkbox"
                                role="switch"
                            >
                            <label class="form-check-label" for="flexSwitchCheckDefault" />
                        </div>
                    </div>
                </div>
                <div class="mt--20">
                    <p class="mb--10 text--medium text--sm">
                        Working days
                    </p>
                    <div v-for="days in productivity.workingDays" :key="days.name" class="form-check form-check-inline">
                        <input
                            id="flexCheckDefault" v-model="days.isActive" class="form-check-input" type="checkbox"
                            :value="days.isActive"
                        >
                        <label class="form-check-label text--sm text--capitalize" for="flexCheckDefault">
                            {{ days.name }}
                        </label>
                    </div>
                </div>
                <div class="mt--15">
                    <p class="mb--10 text--medium text--sm">
                        Status
                    </p>
                    <div v-for="status in ['Work', 'Vacation']" :key="status" class="form-check form-check-inline">
                        <input
                            id="inlineRadio1" v-model="productivity.status" class="form-check-input" type="radio"
                            name="inlineRadioOptions" :value="status"
                        >
                        <label class="form-check-label" for="inlineRadio1">{{ status }}</label>
                    </div>
                </div>
            </div>

            <div class="settings__auth__header mt--30" />
            <div class="flex justify-content-end w-100 mt--20 mb--45">
                <base-button size="sm" btn-style="primary" label="Save changes" />
            </div>
        </div>
    </div>
</template>

<script>
import {
    BaseButton,
    FormInput,
    CustomLink,
} from "@/components/ui/index";
import { workingDays } from "@/utils/dummy";

export default {
    name: "GoalsAndProductivity",

    components: {
        BaseButton,
        CustomLink,
        FormInput,
    },

    data() {
        return {
            goals: {
                isActive: true,
                "projects": {
                    target: ["weekly", "monthly", "yearly"],
                    "weekly": 0,
                    "monthly": 0,
                    "yearly": 0,
                },
                "tasks": {
                    target: ["daily", "weekly", "monthly"],
                    "daily": 0,
                    "weekly": 0,
                    "monthly": 0,
                    "yearly": 0,
                },
            },
            productivity: {
                status: "Work",
                workingDays,
            },
        };
    },

    computed: {
    },

    watch: {
    },

    mounted() {
    },

    methods: {
    },
};
</script>