<template>
    <div>
        <div class="landing__main main">
            <div class="landing__main__bg" :class="[isNoisyBg ? 'noisy': 'normal']">
                <Navigation />
                <div class="landing__hero landing__section">
                    <!-- <div>
                        <BaseButton 
                            size="md"
                            label="Login"
                            btn-style="outline"
                            class="btn--outline__secondary"
                            @submit="handleSubmit"
                        />
                    </div> -->
                    <div class="landing__hero__wrap">
                        <h1 class="landing__hero__title text--8xl">
                            Streamline your freelance <mark>processes</mark>
                        </h1>
                        <p class="landing__hero__text text--2xl">
                            A modern, all-in-one platform for freelancers to manage clients, projects, and automate workflows. 
                        </p>
                        <join-waitlist />
                    </div>
                    <div class="landing__hero__img">
                        <img class="img__responsive" src="@/assets/img/dashboard.svg" alt="hero image">
                    </div>
                </div>
                <Features />
                <div class="landing__integrations landing__section" :style="{ background: isNoisyBg ? '#fff': '#FAF6EA' }">
                    <div class="landing__group__text">
                        <div class="landing__group__text__tiny">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                                fill="none"
                            >
                                <path d="M5 16H8V19C8 20.103 8.897 21 10 21H19C20.103 21 21 20.103 21 19V10C21 8.897 20.103 8 19 8H16V5C16 3.897 15.103 3 14 3H5C3.897 3 3 3.897 3 5V14C3 15.103 3.897 16 5 16ZM14.001 14L14 10H14.001V14ZM19 10L19.001 19H10V16H14C15.103 16 16 15.103 16 14V10H19ZM5 5H14V8H10C8.897 8 8 8.897 8 10V14H5V5Z" fill="#822755" />
                            </svg>
                            <p class="text--xl integration">
                                Our Integrations
                            </p>
                        </div>
                        <div class="landing__group__text__big">
                            <h1 class="landing__group__text__title text--6xl integration">
                                Additional integrations for easier workflows
                            </h1>
                            <p class="landing__group__text__subtitle text--2xl">
                                Be more productive with our latest integrations, designed to help you breeze through your daily workflows. We offer an expanded toolkit of integrations to help you become more efficient.
                            </p>
                        </div>
                    </div>
                    <div class="landing__integrations__stack">
                        <ul id="landing__integrations__stack__demo" class="landing__integrations__stack__demo">
                            <li 
                                v-for="(item, index) in integrationList" 
                                :key="item.id" 
                                :tabindex="index"
                                class="landing__integrations__stack__item" 
                                role="listitem" 
                                aria-label="inner content shows on hover"
                            >
                                <div>
                                    <img class="img__responsive" :src="getImgUrl(item.id)" alt="Card image of app">
                                    <p class="title">
                                        {{ item.id }}
                                    </p>
                                </div>
                                <p class="desc">
                                    {{ item.desc }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <Join />
                <div class="landing__divider">
                    <Divider />
                </div>
                <LandingFooter :is-noisy-bg="isNoisyBg" @toggle-background="toggleBackground" />
            </div>
            <div />
        </div>
    </div>
</template>

<script>
import Home from "@/components/home/index";
import { BaseButton, Divider } from "@/components/ui/index";
import { integrationList } from "@/utils/dummy";

export default {
    name: "LandingPage",
    components: {
        Navigation: Home.Navigation,
        Features: Home.Features,
        Join: Home.Join,
        LandingFooter: Home.LandingFooter,
        JoinWaitlist: Home.JoinWaitlist,
        Divider,
        // BaseButton,
    },

    // beforeRouteEnter( to, from, next ) {
    //     next(vm => {
    //         console.log({ toName: to.name });
    //         if(to.name === "edit-invoice-view") { 
    //             vm.handleFetchInvoice(vm.$route.params.id);
    //             vm.type.existingInvoice = true;
    //         } else vm.type.newInvoice = true;
    //     });
    //     next();
    // },

    data() {
        return {
            isNoisyBg: true,
            integrationList,
            isIntegrationCardsStacked: false,
            scrollPosition: "top",
        };
    },

    mounted() {
        this.getPosition();
    },

    methods: {
        toggleBackground() {
            this.isNoisyBg = !this.isNoisyBg;
        },

        createCards() {
            const _cardsWrapper = document.querySelector("#landing__features__stack__cards");
            const _cardListItems = document.querySelectorAll(".card__content");

            // Pass the number of cards to the CSS because it needs it to add some extra padding.
            // Without this extra padding, the last card won’t move with the group but slide over it.
            const numCards = _cardListItems.length;
            _cardsWrapper.style.setProperty("--numcards", numCards);
            // Each card should only shrink when it’s at the top(right).
            // We can’t use exit on the els for this (as they are sticky)
            // but can track $cardsWrapper instead.
            const viewTimeline = new ViewTimeline({
                subject: $cardsWrapper,
                axis: "block",
            });
        },

        getImgUrl(name) {
            switch (name) {
            case "Online visibility":
                return require("@/assets/img/port-detection.svg");
            case "AI Assistance":
                return require("@/assets/img/surveillance-defense.svg");
            case "Time tracking":
                return require("@/assets/img/intelligent-positioning.svg");
            case "Calendar":
                return require("@/assets/img/calendar.svg");
            default:
                return require("@/assets/img/calendar.svg");
            }
        },

        getPosition: () => {
            const listContainer = document.querySelector("#landing__integrations__stack__demo");
            const listItems = document.getElementsByClassName("landing__integrations__stack__item");

            let options = {
                root: null,
                rootMargin: "0px",
                threshold: 1.0,
            };

            let observer = new IntersectionObserver(function(entries, observer) {
                if (entries.length) {
                    const list = entries[0];

                    list.target.classList.toggle("vertical", list.isIntersecting);

                    Array.from(listItems).forEach((listElem) => {
                        listElem.classList.toggle("vertical", list.isIntersecting);

                        // get the last child
                        listElem.lastElementChild.classList.toggle("show", list.isIntersecting);
                    });
                    if (list.isIntersecting) observer.unobserve(list.target);
                }
            }, options);

            observer.observe(listContainer);
        },
    },
};
</script>