<template>
    <div class="clients__page clients__page--view">
        <div>
            <BaseCard 
                :header-title="`Current plan: ${billingsData.plan}`" 
                header-classes="bg--blue--300 border--0 text--capitalize text--medium text--sm"
                card-body-classes="bg--grey--200"
            >
                <template #card-body>
                    <div>
                        <h5 class="text--3xl text--semi--bold">
                            $0.00
                        </h5>
                        <p>Standard (Monthly)</p>
                    </div>
                    <div class="mt--20">
                        <base-button 
                            size="sm" label="Upgrade"
                        />
                    </div>
                </template>
            </BaseCard>

            <div class="mt--30">
                <p class="text--sm">
                    You are currently on the Standard plan. We hope you’re enjoying the features made available to you. Upgrade to our Premium plan at <strong>$5/month or $55/yr</strong> to get access to more features and integrations
                </p>
            </div>

            <div>
                <div class="settings__auth__header mt--30">
                    <p>Billing cards</p>
                </div>
                <div class="mt--20">
                    <template v-if="billingsData.savedCards.length">
                        <p class="text--sm">
                            You have no saved billing cards yet.
                        </p>
                    </template>
                    <template v-else>
                        <div class="form-check border--bottom--light pb--10 pt--10 w-100">
                            <input id="flexRadioDefault1" class="form-check-input" type="radio" name="flexRadioDefault">
                            <label class="form-check-label w-100" for="flexRadioDefault1">
                                <div class="flex align-items-center justify-content-between">
                                    <div>
                                        <p class="text--color-gray-500 text--semi--bold text--sm">MASTERCARD 531134XXXXXX9822</p>
                                        <p class="text--color-light-300 text--sm">Expiry: 10/31</p>
                                    </div>
                                    <div class="flex align-items-center">
                                        <span class="mr--15 pointer">
                                            <icon-svg name="edit" width="16px" fill="#4A5568" />
                                        </span>
                                        <span class="pointer">
                                            <icon-svg name="delete" width="16px" fill="#4A5568" />
                                        </span>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="form-check border--bottom--light pb--10 pt--10 w-100">
                            <input
                                id="flexRadioDefault1" class="form-check-input" type="radio" name="flexRadioDefault"
                                checked
                            >
                            <label class="form-check-label w-100" for="flexRadioDefault1">
                                <div class="flex align-items-center justify-content-between">
                                    <div>
                                        <p class="text--color-gray-500 text--semi--bold text--sm">MASTERCARD 531134XXXXXX9822</p>
                                        <p class="text--color-light-300 text--sm">Expiry: 10/31</p>
                                    </div>
                                    <div class="flex align-items-center">
                                        <span class="mr--15 pointer">
                                            <icon-svg name="edit" width="16px" />
                                        </span>
                                        <span class="pointer">
                                            <icon-svg name="delete" width="16px" />
                                        </span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </template>
                </div>
            </div>

            <div class="w-100 mt--40">
                <BaseCard 
                    header-title="Payment History" card-classes="border--0"
                    header-classes="bg--grey--200 border--0 text--capitalize text--medium text--sm"
                >
                    <template #card-body>
                        <div>
                            <template v-if="billingsData.paymentHistory.length">
                                <CustomTable :columns="paymentHistoryHeaders" :data="billingsData.paymentHistory" :is-checkbox="false">
                                    <template #rows="data">
                                        <td>{{ data.paymentMethod || "-" }}</td>
                                        <td>{{ data.price || "-" }}</td>
                                        <td>{{ data.duration || "-" }}</td>
                                        <td>{{ $format_date(data.billingDate) || "-" }}</td>
                                    </template>
                                </CustomTable>
                            </template>

                            <template v-else>
                                <p class="text--sm">
                                    You have no billing history yet.
                                </p>
                            </template>
                        </div>
                    </template>
                </BaseCard>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BaseCard,
    BaseButton,
    CustomTable,
    IconSvg,
} from "@/components/ui/index";
import { paymentHistoryHeaders } from "@/utils/dummy";

export default {
    name: "BillingsAndPlans",

    components: {
        BaseButton,
        BaseCard,
        CustomTable,
        IconSvg,
    },

    data() {
        return {
            paymentHistoryHeaders,
            billingsData: {
                plan: "Standard",
                paymentHistory: [],
                savedCards: [],
            },
        };
    },

    computed: {
    },

    watch: {
    },

    mounted() {
    },

    methods: {
    },
};
</script>