
<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            is-close-button is-show-header is-footer
            size="sm"
            title="Update status" @close="closeModal"
        >
            <template #body>
                <div class="status-body">
                    <form class="mb--10" @submit.prevent="">
                        <div v-for="item in statusList" :key="item" class="form-check text--capitalize">
                            <input
                                id="status" v-model="formStatus" class="form-check-input" type="radio"
                                name="inlineRadioOptions" :value="$str_to_lowercase(item)"
                            >
                            <label class="form-check-label text--sm" for="status">{{ item }}</label>
                        </div>
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        class-names="mr--5" btn-style="secondary"
                        label="Cancel" size="sm"
                        :disabled="loading" 
                        @submit="closeModal"
                    />

                    <base-button 
                        :loading="loading" :disabled="loading || !formStatus" 
                        type="submit" size="sm"
                        label="Update" @submit="handleContinue"
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import BaseButton from "../buttons/BaseButton.vue";

export default {
    name: "UpdateStatus",

    components: { MainModal, BaseButton },

    props: {
        currentStatus: {
            type: String,
            default: "",
        },

        showModal: {
            type: Boolean,
            default: false,
        },

        statusList: {
            type: Array,
            default: () => [],
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel", "submit"],

    data() {
        return {
            formStatus: this.currentStatus || "",
        };
    },

    watch: {
        currentStatus(newVal) {
            if (newVal) {
                this.formStatus = newVal;
            }
        },
    },

    mounted() {
        this.formStatus = this.currentStatus;
    },

    methods: {
        closeModal() {
            this.$emit("cancel", false);
        },

        handleContinue() {
            const _id = this.$route.params.id;
            const status = this.formStatus.toLowerCase() || "";

            this.$emit("submit", { _id, status });
            this.closeModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.status-body {
    padding: 10px 20px;
}
</style>