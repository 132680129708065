<template>
    <auth-form 
        :auth-type="'signup'"
        :loading="loading"
        :error="error"
        @form-submit="handleSignup"
    />
</template>

<script>
import AuthForm from "../components/auth/auth-form.vue";

export default {
    name: "SignupPage",

    components: {
        AuthForm,
    },

    data() {
        return {
            error: {
                show: false,
                value: "",
            },
            loading: false,
        };
    },

    methods: {
        async handleSignup(payload) {
            try {
                this.error.show = false;
                this.error.value = "";
                this.loading = true;

                await this.$store.dispatch("auth/signup", payload);
                
                this.$toast.success("Signup successful");
                
                this.$store.dispatch("app/toggleNewUser", true);
                this.$router.push("/dashboard/reports?nU=1");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.error.value = errMsg;
                this.error.show = true;

                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>