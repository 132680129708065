<template>
    <div class="h-100 content__page">
        <div>
            <template v-if="pageState.loading">
                <page-spinner />
            </template>

            <template v-else-if="pageState.err">
                <EmptyPage state="error">
                    <template #button>
                        <base-button 
                            :label="'Try again'" 
                            size="sm"
                            class="flex align-items-center"
                            @submit="handleFetchTags"
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div v-if="isListPresent" class="content__page__header">
                    <div class="list--count">
                        <p class="text--medium text--sm">
                            {{ tags.length === 1 ? `${tags.length} tag` : `${tags.length} tags` }}
                        </p>
                    </div>
                    <div class="flex align-items-center">
                        <base-button 
                            label="+ Add tag" 
                            size="sm" class="mr--5"
                            @submit="showTagModal = true"
                        />
                        <template v-if="tags.length > 8">
                            <main-filter page-type="tags" />
                            <sort-filter :value="displayType" @setType="setDisplayType" />
                        </template>
                    </div>
                </div>

                <div class="content__page__body" :class="{ 'empty': !tags.length }">
                    <div v-if="!tags.length">
                        <EmptyPage 
                            v-if="!isSearched" state="empty" icon-name="tag" fill="#BDBDBD"
                            width="60px" height="60px" type="tag"
                        >
                            <template #button>
                                <base-button 
                                    label="+ Add tag" 
                                    size="sm"
                                    @submit="showTagModal = true"
                                />
                            </template> 
                        </EmptyPage>
                        <EmptyPage v-else state="no_result" />
                    </div>
    
                    <div v-else class="flex mt--45"> 
                        <div class="tags__view">
                            <div v-for="tag in tags" :key="tag._id" class="tag__wrap" :class="{ 'disabled': tagToDisable === tag._id }">
                                <div class="tags--item">
                                    <div class="tags--item--text" @click="initiateTagEdit(tag)">
                                        <span class="text--lower">{{ tag.name }}</span>
                                    </div>
                                    <div class="icon" @click="handleDeleteTag(tag._id)">
                                        <icon-svg class="mr--0 flex" name="close" icon-position="left" :width="'1rem'" />  
                                    </div>
                                </div>
                            </div>
                            <pagination v-if="showPagination" :pagination-data="pageData" />
                        </div>
                    </div>        
                </div>
            </template>
        </div>

        <main-modal 
            :show-modal="showTagModal" is-close-button is-show-header is-footer
            size="sm" :title="action === 'add' ? 'Add tag' : 'Edit tag'" @close="closeModal"
        >
            <template #body>
                <div class="mb--15 pr--10 pl--10">
                    <template v-if="!showUpgradeAccountInfo">
                        <form class="form--workspace__create mb--20" @submit.prevent="">
                            <FormInput 
                                id="tagName"
                                v-model="form.tagName"
                                size="sm"
                                info-type="required"
                                label="Tag Name" @keyup.enter="handleAddTag"
                            />
                        </form>
                    </template>
                    <template v-else>
                        <div>
                            <span class="text--sm">You have exceeded the allowed number of tags for your account. <strong>Upgrade</strong> your account to create more tags</span>
                        </div>
                    </template>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        class-names="mr--5" 
                        label="Cancel" size="sm" btn-style="secondary" :disabled="addLoading"
                        @submit="closeModal"
                    />
                    <template v-if="!showUpgradeAccountInfo">
                        <Base-Button 
                            :label="action === 'add' ? 'Create' : 'Save'"
                            size="sm" :loading="addLoading" 
                            :disabled="addLoading || !form.tagName"
                            type="submit" @submit="handleSubmit" 
                        />
                    </template>
                    <template v-else>
                        <Base-Button label="Upgrade" size="sm" @submit="upgradeAccount" />
                    </template>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import {
    PageSpinner,
    BaseButton,
    EmptyPage,
    IconSvg,
    MainModal,
    FormInput,
    MainFilter,
    SortFilter,
    Pagination,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "TagsView",

    components: {
        IconSvg,
        EmptyPage,
        PageSpinner,
        BaseButton,
        MainModal,
        FormInput,
        MainFilter,
        SortFilter,
        Pagination,
    },

    data() {
        return {
            action: "add",
            showTagModal: false,
            isSearched: false,
            deleteLoading: false,
            form : {
                _id: "",
                tagName: "",
            },
            pageState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            addLoading: false,
            selectedTags: [],
            displayType: "",
            tagToDisable: "",
        };
    },

    computed: {
        isListPresent() {
            if (this.tags.length) {
                return true;
            } else if (!this.tags.length && this.isSearched) { // no result found state
                return true;
            } else {
                return false;
            }
        },

        accountType() {
            return this.$store.getters["auth/profile"]?.accountType;
        },

        showUpgradeAccountInfo() {
            if (this.accountType === "standard" && this.tags.length >= 50 && this.action === "add") {
                return true;
            } else {
                return false;
            }
        },

        tags() {
            return this.$store.getters["tag/tags"];
        },

        pageData() {
            return this.$store.getters["tag/pageData"];
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        refreshList() {
            return this.$store.getters["tag/refreshList"];
        },
    },

    watch: {
        "$route": {
            async handler(newVal, oldVal) {
                if (newVal.path === oldVal.path) { // this only fires when same page navigation takes place
                    await this.handleFetchTags({ refresh: true });
                }
            },
        },

        displayType(newType, oldType) {
            if (newType !== oldType) {
                this.sortTags();
            }
        },
    },

    async mounted() {        
        if (!this.tags.length && this.refreshList) {
            await this.handleFetchTags({ refresh: true });
            this.$store.dispatch("tag/setRefreshList", false);
        }
    }, 

    methods: {
        sortTags() {
            this.$store.dispatch("tag/sortTags", this.displayType);
        },

        setDisplayType(val) {
            this.displayType = val;
        },

        async handleFetchTags(params) {
            try {
                this.pageState.err = false;
                this.pageState.errMsg = "";
                this.pageState.loading = true;

                const query = this.$route.query;
                const queryKeys = Object.keys(this.$route.query);

                const pageQueryObj = {
                    page: query.page || 1,
                };

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                if (query.tagName) {
                    pageQueryObj.name = query.tagName;
                }

                const buildResult = assembleQueryList("tags", pageQueryObj);

                await this.$store.dispatch("tag/fetchTags",  {
                    payload: buildResult, 
                    refresh: params?.refresh, 
                    scroll: params?.scroll, 
                });
            } catch (error) {
                const errMsg = error?.response?.message || error.message;
                
                this.pageState.err = true;
                this.pageState.errMsg = errMsg;
            } finally {
                this.pageState.loading = false;
            }
        },

        prefillForm(newVal) {   
            this.form = {
                _id: newVal?._id || "",
                tagName: newVal?.name || "",
            };
        },

        resetForm() {
            this.form._id = "",
            this.form.tagName = "";
        },

        initiateTagEdit(tag) {
            this.action = "edit";
            this.form._id = tag?._id;
            this.form.tagName = tag?.name;
            this.showTagModal = true;
        },

        async handleAddTag() {
            try {
                this.addLoading = true;

                const payload = {
                    name: this.form.tagName,
                };

                await this.$store.dispatch("tag/addTag", payload);

                this.resetForm();
                this.showTagModal = false;
                this.$toast.success("New tag added");
            } catch (error) {
                const errMsg = error?.response?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.addLoading = false;
            }
        },

        async handleEditTag() {
            try {
                if (!this.form?._id) return;

                this.addLoading = true;

                const payload = {
                    _id: this.form._id,
                    name: this.form.tagName, 
                };                

                await this.$store.dispatch("tag/updateTag", payload);
                
                this.$toast.success("Tag updated");

                this.resetForm();

                this.showTagModal = false;
            } catch (error) {
                const errMsg = error?.response?.data?.error || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.addLoading = false;
            }
        },

        async handleDeleteTag(id) {
            try {
                const ids = id ? [id] : this.selectedTags;
                
                if (!Array.isArray(ids) || !ids.length) return;
                
                this.tagToDisable = id ? id : "";
                this.deleteLoading = true;

                await this.$store.dispatch("tag/deleteTag", ids);

                this.$toast.success("Tag(s) deleted");
            } catch (error) {
                const errMsg = error?.response?.data?.error || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
                this.tagToDisable = "";
            }
        },

        handleSubmit() {
            if (this.action === "add") {
                this.handleAddTag();
            } else {
                this.handleEditTag();
            }
        },
        
        upgradeAccount() {
            this.$router.push("/dashboard/settings/billings-and-plans");
        },

        closeModal() {
            this.showTagModal = false;
            this.resetForm();
        },
    },
};
</script>

<style lang="scss" scoped>

.tag {
    &__wrap {
        &.loading {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}
</style>