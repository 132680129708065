<template>
    <div class="pl--0 pr--0 pt--10 client__item__details__sub">
        <template v-if="fetchProjectLoading">
            <PageSpinner />
        </template>

        <template v-else-if="fetchProjectErr">
            <EmptyPage state="error">
                <template #button>
                    <base-button 
                        label="Reload" 
                        size="sm"
                        class="flex align-items-center"
                        @submit="handleFetchProjects"
                    />
                </template> 
            </EmptyPage>
        </template>

        <template v-else>
            <div class="flex align-items-center justify-content-between width-100">
                <div v-if="projects.length" class="list--count">
                    <p class="text--sm">
                        {{ projects.length }} {{ projects.length === 1 ? 'project' : 'projects' }}
                    </p>
                </div>
            </div>

            <div class="w--100 mt--20">
                <template v-if="!projects.length">
                    <EmptyPage
                        state="empty" icon-name="project" :width="'60px'" :height="'60px'"
                        type="project"
                    />
                </template>

                <template v-else>
                    <div>
                        <CustomTable
                            :columns="projectHeaders"
                            :data="projects"
                            router-name="project-details-view"
                            is-action-btns
                            :is-checkbox="false"
                        >
                            <template #rows="data">
                                <td>
                                    <p class="text--ellipsis title">
                                        {{ data.title || "-" }}
                                    </p>
                                </td>
                                <td>
                                    <Status type="project" :value="data.status" />
                                </td>
                                <td class="text--capitalize">
                                    {{ data.paidStatus || "-" }}
                                </td>
                                <td>{{ $format_date(data.createdAt) || "-" }}</td>
                            </template>
                        </CustomTable>
                        <PageSpinner v-if="showScrollLoader" />
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import {
    EmptyPage,
    PageSpinner,
    BaseButton,
    CustomTable,
    Status,
} from "@/components/ui/index";

export default {
    name: "ClientProjects",

    components: {
        CustomTable,
        EmptyPage,
        PageSpinner,
        BaseButton,
        Status,
    },

    data() {
        return {
            fetchProjectLoading: false,
            fetchProjectErr: false,
            fetchProjectErrMsg: "",
            projectHeaders: [
                { id: "Project title", accessorKey: "title", iconName: "user"},
                { id: "status", accessorKey: "status", iconName: "envelope-solid"},
                { id: "Payment Status", accessorKey: "paidStatus", iconName: "reports-solid"},
                { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
            ],
            showScrollLoader: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["clients/clientProjects"]?.pageData;
        },

        projects() {
            return this.$store.getters["clients/clientProjects"]?.projects;
        },
    },

    async mounted() {
        await this.handleFetchProjects();

        window.addEventListener("scroll", this.handleScroll);
    },

    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        async handleFetchProjects(payload) {
            try {
                this.fetchProjectErr = false;
                this.fetchProjectLoading = true;

                const id = this.$route.params.id;

                const params = {
                    page: payload?.pageNumber || this.pageData?.currentPage,
                };

                await this.$store.dispatch("clients/getClientProjects", { id, params });
            } catch (error) {
                this.fetchProjectErr = true;
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchProjectErrMsg = errMsg;
            } finally {
                this.fetchProjectLoading = false;
            }
        },

        async handleScroll() {
            if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                await this.loadMoreProjects();
            }
        },

        async loadMoreProjects() {            
            if (this.showScrollLoader || this.pageData?.totalPages === 1 || this.pageData?.currentPage === this.pageData?.totalPages) return;
    
            this.showScrollLoader = true;
    
            await this.handleFetchProjects({
                pageNumber: this.pageData?.currentPage + 1,
            });
            
            this.showScrollLoader = false;
        },

        handleRemoveProject(item) {

        },
    },
};
</script>

<style lang="scss" scoped>
    .title {
        max-width: 170px;
    }
</style>