<template>
    <div>
        <div class="flex justify-content-center align-items-center">
            <p>Upgrade your account to use this feature</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClientDocuments",
    data() {
        const todos = [
            {
                description: "Take Noah to basketball practice.",
                isComplete: false,
                dates: { weekdays: 6 }, // Every Friday
                color: "red",
            },
            {
                description: "Start project X.",
                isComplete: false,
                dates: [ new Date(2021, 11, 12) ], // Jan 12th// Every Friday
                color: "red",
            },
        ];
        return {
            incId: todos.length,
            todos,
        };
    },
    computed: {},
    methods: {},
};
</script>