<template>
    <div class="landing__features landing__section">
        <div class="landing__group__text">
            <div class="landing__group__text__tiny">
                <span>
                    <img src="@/assets/img/features_icon.svg" alt="left arrow">
                </span>
                <p class="text--xl">
                    Our Features
                </p>
            </div>
            <div class="landing__group__text__big">
                <h1 class="landing__group__text__title text--6xl">
                    Wide range of features for efficient processes
                </h1>
                <p class="landing__group__text__subtitle text--2xl">
                    Get access to a wide range of features to effectively manage your freelance processes. Ziner offers a consolidated platform to manage potential leads, invoicing, generate reports, set reminders, track projects and revenue.
                </p>
            </div>
        </div>
        <div class="landing__features__stack">
            <ul ref="cardsWrapper" class="landing__features__stack__cards animated__features__cards">
                <li v-for="(list, index) in featuresList" :id="`card_${list.id}`" :key="list.id" class="landing__features__stack__card animated__features__item">
                    <div class="card__content">
                        <div class="empty__title" style="height: 100px; width: 100%;" />
                        <div class="card__content__text">
                            <div :id="`title-${index}`" class="card__content__item title">
                                <!-- <span class="features__icon__wrap">
                                    <component :is="{ template: list.icon }" v-if="list.icon" class="mr--5" preserveAspectRatio="none" />
                                </span> -->
                                <h2 class="text--5xl">
                                    {{ list.title }}
                                </h2>
                                <div class="card__content__icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4697 5.46967C13.7626 5.17678 14.2374 5.17678 14.5303 5.46967L20.5303 11.4697C20.8232 11.7626 20.8232 12.2374 20.5303 12.5303L14.5303 18.5303C14.2374 18.8232 13.7626 18.8232 13.4697 18.5303C13.1768 18.2374 13.1768 17.7626 13.4697 17.4697L18.1893 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H18.1893L13.4697 6.53033C13.1768 6.23744 13.1768 5.76256 13.4697 5.46967Z" fill="#296C49" />
                                    </svg>
                                </div>
                            </div>
                            <ul class="card__content__item list">
                                <li>{{ list.desc }}</li>
                                <li>{{ list.desc }}</li>
                                <li>{{ list.desc }}</li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { featuresList } from "@/utils/dummy";

export default {
    data() {
        return {
            featuresList, 
        };
    },

    mounted() {
        this.initStackCards();
    },

    methods: {
        setWrapperHeight(cards) {
            const lastCard = cards[cards.length - 1]; // 5;
            const wrapperHeight = lastCard.offsetHeight + lastCard.offsetTop - 30;

            this.$refs.cardsWrapper.style.height = `${wrapperHeight}px`;
        },

        initStackCards() {
            const cards = [...this.$refs.cardsWrapper.querySelectorAll(".landing__features__stack__card")];
            let previousOffset = 0;
            const position = [0];

            let extraOffset;

            if (window.innerWidth < this.smallScreenWidth) {
                extraOffset = 16.5;
            } else {
                extraOffset = 30;
            }

            cards.forEach((card, index) => {
                let cardOffset = 0;

                function setPosition() {
                    if (index !== 0) {
                        // const previousWrap = cards[index - 1].querySelector(".card__content__text");
                        const previousWrap = cards[index - 1].querySelector(".empty__title");

                        cardOffset = previousWrap.offsetTop + previousWrap.offsetHeight + extraOffset + previousOffset;

                        position.push(cardOffset);
                        card.style.transform = `translate(0, ${cardOffset}px)`;
                        previousOffset = cardOffset;
                    }
                }

                function handleMouseEnter() {
                    if (index === cards.length - 1) {
                        return;
                    }

                    const info = card.querySelector(".card__content__text");
                    const offset = info?.offsetHeight + info?.offsetTop + cardOffset + 60;

                    let extraMargin = 0;

                    for (let i = index + 1; i < cards.length; i++) {
                        cards[i].style.transform = `translate(0, ${offset + extraMargin}px)`;
                        cards[i].style.border = "0.5px solid #228E9D";
                        extraMargin += 100;
                    }
                }

                function handleMouseLeave() {
                    for (let i = index + 1; i < cards.length; i++) {
                        cards[i].style.transform = `translate(0, ${position[i]}px)`;
                        cards[i].style.border = "0.5px solid #CCD8CD";
                    }
                }

                setPosition();
                card.addEventListener("mouseenter", handleMouseEnter);
                card.addEventListener("mouseleave", handleMouseLeave);
            });

            this.setWrapperHeight(cards);
        },
    },
};
</script>